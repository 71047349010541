import React, {useState, useContext, memo, Fragment} from 'react'
import { Link, useLocation} from 'react-router-dom'
import {Accordion, AccordionContext, useAccordionButton} from 'react-bootstrap'
import {RxDashboard} from 'react-icons/rx'
import {BsShieldCheck} from 'react-icons/bs'
import {IoMdNotificationsOutline} from 'react-icons/io'
import {MdOutlinePolicy, MdOutlineIntegrationInstructions} from 'react-icons/md'
import {FiUser} from 'react-icons/fi'
import {FaUsers} from 'react-icons/fa'
import {RxActivityLog} from 'react-icons/rx'
import {HiOutlineDocumentText, HiOutlineKey, HiOutlineTemplate, HiCreditCard, HiOutlineClock} from 'react-icons/hi'
import {GrPersonalComputer} from 'react-icons/gr'
import * as nl3Utils from '../../../../libs/nl3Utils'

const accType = process.env.REACT_APP_NL3_PORTALTYPE;

function getUserPermissions() {
    nl3Utils.nl3getLocation();
    nl3Utils.nl3getNotificationPermission();
    nl3Utils.nl3allowPersistentStorage();
  }

function CustomToggle({ children, eventKey, onClick }) {

    getUserPermissions();

    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, (active) => onClick({state: !active, eventKey: eventKey}));

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
      <Link to="#" aria-expanded={isCurrentEventKey ? 'true' : 'false'} className="nav-link" role="button" onClick={(e) => {
        decoratedOnClick(isCurrentEventKey)
      }}>
        {children}
      </Link>
    );
  }

const VerticalNav = memo((props) => {
    // eslint-disable-next-line
    const [activeMenu, setActiveMenu] = useState(false)
    const [active, setActive] = useState('')
    const dashPath = (accType === 'COMPANY') ? "/mydomains" : "/dashboard";
    const accountsPath = (accType === 'COMPANY') ? '/dashboard/company-accounts' : '/dashboard/accounts';
    const accountLabel = (accType === 'COMPANY') ? 'Applications' : 'Accounts';
    const activityPath = (accType === 'COMPANY') ? '/dashboard/company-activity' : '/dashboard/activity';
    //location
    let location = useLocation();
    return (
       <Fragment>
            <Accordion as="ul" className="navbar-nav iq-main-menu">
                <li className="nav-item static-item">
                    <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
                        <span className="default-icon">Home</span>
                        <span className="mini-icon">-</span>
                    </Link>
                </li>
                <li className={`${((location.pathname === '/mydomains') ||
                         (location.pathname === '/dashboard')) ? 'active' : ''} nav-item `}>
                    <Link className={`${((location.pathname === '/mydomains') ||
                               (location.pathname === '/dashboard')) ? 'active' : ''} nav-link `} aria-current="page"
                               to={dashPath} onClick={() => {}}>
                        <i className="icon">
                            <RxDashboard style={{color:'#000000', fontSize:'1.3rem'}}/>
                        </i>
                        <span className="item-name">Dashboard</span>
                    </Link>
                </li>
                <li className={`${((location.pathname === '/dashboard/company-accounts') ||
                         (location.pathname === '/dashboard/accounts')) ? 'active' : ''} nav-item `}>
                    <Link className={`${((location.pathname === '/dashboard/company-accounts') ||
                               (location.pathname === '/dashboard/accounts')) ? 'active' : ''} nav-link `} aria-current="page"
                               to={accountsPath} onClick={() => {}}>
                        <i className="icon">
                            <BsShieldCheck style={{color:'#000000', fontSize:'1.3rem'}}/>
                        </i>
                        <span className="item-name">{accountLabel}</span>
                    </Link>
                </li>
                <li className={`${location.pathname === '/dashboard/notifications' ? 'active' : ''} nav-item `}>
                    <Link className={`${location.pathname === '/dashboard/notifications' ? 'active' : ''} nav-link `} aria-current="page" to='/dashboard/notifications' onClick={() => {}}>
                        <i className="icon">
                            <IoMdNotificationsOutline style={{color:'#000000', fontSize:'1.3rem'}}/>
                        </i>
                        <span className="item-name">Requests</span>
                    </Link>
                </li>
                <li className={`${((location.pathname === '/dashboard/company-activity') ||
                         (location.pathname === '/dashboard/activity')) ? 'active' : ''} nav-item `}>
                    <Link className={`${((location.pathname === '/dashboard/company-activity') ||
                               (location.pathname === '/dashboard/activity')) ? 'active' : ''} nav-link `} aria-current="page"
                               to={activityPath} onClick={() => {}}>
                        <i className="icon">
                            <RxActivityLog style={{color:'#000000', fontSize:'1.3rem'}}/>
                        </i>
                        <span className="item-name">Activity Logs</span>
                    </Link>
                </li>
                {
                   (accType === 'COMPANY') ?
                     <>
                       <li><hr className="hr-horizontal"/></li>
                       <li className="nav-item static-item">
                           <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
                               <span className="default-icon">Management</span>
                               <span className="mini-icon">-</span>
                           </Link>
                       </li>
                        <li className={`${location.pathname === '/dashboard/company-users' ? 'active' : ''} nav-item `}>
                            <Link className={`${location.pathname === '/dashboard/company-users' ? 'active' : ''} nav-link `} aria-current="page" to='/dashboard/company-users' onClick={() => {}}>
                                <i className="icon">
                                    <FiUser style={{color:'#000000', fontSize:'1.3rem'}}/>
                                </i>
                                <span className="item-name">Users</span>
                            </Link>
                        </li>
                        <li className={`${location.pathname === '/dashboard/groups' ? 'active' : ''} nav-item `}>
                            <Link className={`${location.pathname === '/dashboard/groups' ? 'active' : ''} nav-link `} aria-current="page" to='/dashboard/groups' onClick={() => {}}>
                                <i className="icon">
                                    <FaUsers style={{color:'#000000', fontSize:'1.3rem'}}/>
                                </i>
                                <span className="item-name">Groups</span>
                            </Link>
                        </li>
                        <li className={`${location.pathname === '/dashboard/policies' ? 'active' : ''} nav-item `}>
                            <Link className={`${location.pathname === '/dashboard/policies' ? 'active' : ''} nav-link `} aria-current="page" to='/dashboard/policies' onClick={() => {}}>
                                <i className="icon">
                                    <MdOutlinePolicy style={{color:'#000000', fontSize:'1.3rem'}}/>
                                </i>
                                <span className="item-name">JIT Policies</span>
                            </Link>
                        </li>
                        <li className={`${location.pathname === '/dashboard/endpoint-security' ? 'active' : ''} nav-item `}>
                            <Link className={`${location.pathname === '/dashboard/endpoint-security' ? 'active' : ''} nav-link `} aria-current="page" to='/dashboard/endpoint-security' onClick={() => {}}>
                                <i className="icon">
                                    <GrPersonalComputer style={{color:'#000000', fontSize:'1.3rem'}}/>
                                </i>
                                <span className="item-name">PAM</span>
                            </Link>
                        </li>
                        <li className={`${location.pathname === '/dashboard/company-keys' ? 'active' : ''} nav-item `}>
                            <Link className={`${location.pathname === '/dashboard/company-keys' ? 'active' : ''} nav-link `} aria-current="page" to='/dashboard/company-keys' onClick={() => {}}>
                                <i className="icon">
                                    <HiOutlineKey style={{color:'#000000', fontSize:'1.3rem'}}/>
                                </i>
                                <span className="item-name">Keys & Tokens</span>
                            </Link>
                        </li>
                        <li className={`${location.pathname === '/dashboard/company-integrations' ? 'active' : ''} nav-item `}>
                            <Link className={`${location.pathname === '/dashboard/company-integrations' ? 'active' : ''} nav-link `} aria-current="page" to='/dashboard/company-integrations' onClick={() => {}}>
                                <i className="icon">
                                    <MdOutlineIntegrationInstructions style={{color:'#000000', fontSize:'1.3rem'}}/>
                                </i>
                                <span className="item-name">Integrations</span>
                            </Link>
                        </li>
                        <li className={`${location.pathname === '/dashboard/jit-templates' || location.pathname.startsWith('/dashboard/jit-templates-edit') ? 'active' : ''} nav-item `}>
                            <Link className={`${location.pathname === '/dashboard/jit-templates' || location.pathname.startsWith('/dashboard/jit-templates-edit') ? 'active' : ''} nav-link `} aria-current="page" to='/dashboard/jit-templates' onClick={() => {}}>
                                <i className="icon">
                                    <HiOutlineTemplate style={{color:'#000000', fontSize:'1.3rem'}}/>
                                </i>
                                <span className="item-name">JIT Approvals</span>
                            </Link>
                        </li>
                        <li className={`${location.pathname === '/dashboard/safelist' || location.pathname.startsWith('/dashboard/safelist') ? 'active' : ''} nav-item `}>
                            <Link className={`${location.pathname === '/dashboard/safelist' || location.pathname.startsWith('/dashboard/safelist') ? 'active' : ''} nav-link `} aria-current="page" to='/dashboard/safelist' onClick={() => {}}>
                                <i className="icon">

                                    <HiOutlineClock style={{color:'#000000', fontSize:'1.3rem'}}/>
                                </i>
                                <span className="item-name">Approvals History</span>
                            </Link>
                        </li>
                        <li className={`${location.pathname === '/dashboard/payments-billing' ? 'active' : ''} nav-item `}>
                            <Link className={`${location.pathname === '/dashboard/payments-billing' ? 'active' : ''} nav-link `} aria-current="page" to='/dashboard/payments-billing' onClick={() => {}}>
                                <i className="icon">
                                    <HiCreditCard style={{color:'#000000', fontSize:'1.3rem'}}/>
                                </i>
                                <span className="item-name">Payments &amp; Billing</span>
                            </Link>
                        </li>
                     <li><hr className="hr-horizontal"/></li>
                        <Accordion.Item as="li" eventKey="horizontal-menu" bsPrefix={`nav-item ${active === 'menustyle' ? 'active' : ''} `} onClick={() => setActive('menustyle')}  >
                            <CustomToggle eventKey="horizontal-menu" onClick={(activeKey) => setActiveMenu(activeKey)}>
                                <i className="icon">
                                    <HiOutlineDocumentText style={{color:'#000000', fontSize:'1.3rem'}}/>
                                </i>
                                <span className="item-name">Resources</span>
                                <i className="right-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                                    </svg>
                                </i>
                            </CustomToggle>
                            <Accordion.Collapse eventKey="horizontal-menu" >
                                <ul  className="sub-nav">
                                    <li className="nav-item">
                                        <a className="nav-link" href="https://docs.nextlevel3.com/Introduction" target="_blank" rel="noreferrer">
                                            <i className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 24" fill="currentColor">
                                                    <g> <circle cx="12" cy="12" r="8" fill="currentColor"></circle> </g>
                                                </svg>
                                            </i>
                                            <i className="sidenav-mini-icon"> GS </i>
                                            <span className="item-name">Getting Started</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="https://docs.nextlevel3.com/Features/Integrating%20your%20Identity%20Provider" target="_blank" rel="noreferrer">
                                            <i className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 24" fill="currentColor">
                                                    <g> <circle cx="12" cy="12" r="8" fill="currentColor"></circle> </g>
                                                </svg>
                                            </i>
                                            <i className="sidenav-mini-icon"> IP </i>
                                            <span className="item-name">Identity Providers</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="https://docs.nextlevel3.com/Features/Securing%20your%20Applications" target="_blank" rel="noreferrer">
                                            <i className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 24" fill="currentColor">
                                                    <g> <circle cx="12" cy="12" r="8" fill="currentColor"></circle> </g>
                                                </svg>
                                            </i>
                                            <i className="sidenav-mini-icon"> SA </i>
                                            <span className="item-name">Securing Applications</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="https://docs.nextlevel3.com/Features/Protecting%20your%20Endpoints" target="_blank" rel="noreferrer">
                                            <i className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 24" fill="currentColor">
                                                    <g> <circle cx="12" cy="12" r="8" fill="currentColor"></circle> </g>
                                                </svg>
                                            </i>
                                            <i className="sidenav-mini-icon"> EP </i>
                                            <span className="item-name">Endpoint Protection</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="https://docs.nextlevel3.com/Features/Native%20Language%20Integrations" target="_blank" rel="noreferrer">
                                            <i className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 24" fill="currentColor">
                                                    <g> <circle cx="12" cy="12" r="8" fill="currentColor"></circle> </g>
                                                </svg>
                                            </i>
                                            <i className="sidenav-mini-icon"> CI </i>
                                            <span className="item-name">Code Integrations</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="https://www.nextlevel3.com/software-versions-release-notes" target="_blank" rel="noreferrer">
                                            <i className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 24" fill="currentColor">
                                                    <g> <circle cx="12" cy="12" r="8" fill="currentColor"></circle> </g>
                                                </svg>
                                            </i>
                                            <i className="sidenav-mini-icon"> RN </i>
                                            <span className="item-name">Release Notes</span>
                                        </a>
                                    </li>
                                </ul>
                            </Accordion.Collapse>
                        </Accordion.Item>
                     </>
                   : <></>
                }
            </Accordion>
       </Fragment>
    )
})

export default VerticalNav
