export default function FirebaseType(state = [], action) {
  switch (action.type) {
    case 'FBTOKEN':
      state = action.text
      return state 
    default:
      return state
  }
}

