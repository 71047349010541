
import React from 'react';
import { Button, Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import DualListBox from "react-dual-listbox";
import Card from '../../components/Card';
import * as cognito from '../../libs/cognito';
import * as nl3Utils from '../../libs/nl3Utils';
import Store from '../../store';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { BiPencil, BiTrash } from 'react-icons/bi';

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;

const operationLabels = {
  'EQ' : 'Equal',
  'GE' : 'Greater than or Equal',
  'GTE' : 'Greater than',
  'LE' : 'Less than or Equal',
  'LTE' : 'Less than',
};

const getOpeationLabel = (key) => {
  return operationLabels[key];
}

const SafeListDropdown = ({ tempSelectedOperation, handleChange }) => {

  const options = [
    { value: 'EQ', label: getOpeationLabel('EQ') },
    { value: 'GE', label: getOpeationLabel('GE') },
    { value: 'GTE', label: getOpeationLabel('GTE') },
    { value: 'LE', label: getOpeationLabel('LE') },
    { value: 'LTE', label: getOpeationLabel('LTE') }
  ];

  return (
    <div>
      <Form.Group>
        <Form.Label>Type:</Form.Label>
        <Form.Control as="select" value={tempSelectedOperation} onChange={handleChange}>
          <option value="">Select an option</option>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

    </div>
  );
};

class JitTemplatesEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      templateName: '',
      templateTitle: '',
      templateData: '',
      templateKey: '',
      templateType: '',
      metaDataObject: {},
      metadataKey: '',
      nl3MetaData: '',
      defaultValue: '',
      tempSafeListType: '',
      tempSafeListValue: '',
      tempSelectedOperation: 'EQ', // Set default value to 'EQ'
      actions: [],
      defaultApprovers: [],
      defaultApproverGroups: [],
      approversOpts: [],
      groupsOpts: [],
      userList: [], // List of user objects
      groupList: [], // List of group objects
      showAddMeta: false,
      showApprovedRejectedForm: false,
      validated: false,
      showMap: false,
      allowSelfApproval: false,
      autoApprovedValues: [],
      autoRejectedValues: [],
      currentSafelistValue: null,
      safeListFormType: null
    };
  }



  async componentDidMount() {
    const session = await cognito.getSession();
    this.setState({companyToken : session.accessToken.jwtToken,groupID : Store.getState().group.id});

    // const id = this.props.id || '';
    const params = new URLSearchParams(this.props.location.search);
    const id = params.get('id');

    console.log("PROPS:", this.props);
    console.log("ID:", id);
    this.setState({ id });

    if (id) {
      this.fetchData(id);
    }

    this.fetchUserList();
    this.fetchGroupList();
    // this.fetchNl3MetaData();
  }

  fetchData = async (id) => {
    try {
      const response = await fetch(nl3ApiBase + `/approvalTemplates/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.state.companyToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const {
          templateName = data.name,
          templateTitle,
          templateData,
          templateKey = data.key,
          templateType = data.type,
          metaDataObject = data.metaData || {},
          actions,
          defaultApprovers,
          defaultApproverGroups,
          showMap,
          allowSelfApproval,
          autoApprovedValues,
          autoRejectedValues
        } = data;

        this.setState({
          templateName,
          templateTitle,
          templateData,
          templateKey,
          templateType,
          showMap,
          allowSelfApproval,
          metaDataObject,
          actions,
          defaultApprovers,
          defaultApproverGroups,
          autoApprovedValues,
          autoRejectedValues
        });

      } else {
        console.error('Failed to fetch data');
        // Additional error handling for data fetch failure
      }
    } catch (error) {
      console.error('An error occurred during data fetch:', error);
      // Additional error handling for data fetch error
    }
  };

  fetchUserList = async () => {
    try {

      var companyUsersUrl = nl3ApiBase;
      companyUsersUrl += `/companies/mycompany/users?orderBy=first_name:asc&page=0&pageSize=1000`;

      const response = await fetch(companyUsersUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.state.companyToken}`,
        },
      });
      const userList = await response.json();
      console.log("Got User List:", userList);
      const approversOpts = [];
      if ( userList && userList.items && userList.items.length > 0 ){
        userList.items.forEach(next => {
          approversOpts.push({
            value : next.id,
            label : next.username
          });
        });
      }
      this.setState({ userList });
      this.setState({ approversOpts });
    } catch (error) {
      console.error('Error fetching user list:', error);
    }
  };

  fetchGroupList = async () => {
    try {
      var groupsUrl = nl3ApiBase;
      groupsUrl += `/groups?orderBy=group_name:asc&page=0&pageSize=1000`;
      const response = await fetch(groupsUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.state.companyToken}`,
        },
      });
      const groupList = await response.json();

      const groupsOpts = [];
      if ( groupList  && groupList.length > 0 ){
        groupList.forEach(next => {
          groupsOpts.push({
            value : next.id,
            label : next.groupName
          });
        });
      }

      this.setState({ groupsOpts });
      this.setState({ groupList });
    } catch (error) {
      console.error('Error fetching group list:', error);
    }
  };

  fetchNl3MetaData = async () => {
    try {
      var baseUrl = nl3ApiBase;
      baseUrl += `/envDetails`;
      const response = await fetch(baseUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.state.companyToken}`,
        },
      });
      const nl3MetaData = await response.text();
      console.log("nl3MetaData", nl3MetaData);
      // const groupsOpts = [];
      // if ( groupList  && groupList.length > 0 ){
      //   groupList.forEach(next => {
      //     groupsOpts.push({
      //       value : next.id,
      //       label : next.groupName
      //     });
      //   });
      // }

      this.setState({ nl3MetaData });

    } catch (error) {
      console.error('Error fetching group list:', error);
    }
  }


  handleAddMetadata = () => {
    const { metaDataObject, metadataKey, defaultValue } = this.state;
   // const newMetadata = { key: metadataKey, defaultValue };
   metaDataObject[metadataKey] = defaultValue;
    this.setState({
      // metaDataObject: [...metaDataObject, newMetadata],
      metaDataObject,
      metadataKey: '',
      defaultValue: '',
      showAddMeta:false,
    });
  };

  handleAddAction = (action) => {
    this.setState((prevState) => ({
      actions: [...prevState.actions, action],
    }));
  };

  handleDefaultApproversChange = (tempSelectedOperations) => {
    //const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
    this.setState({ defaultApprovers: tempSelectedOperations });
  };

  handleDefaultApproverGroupsChange = (tempSelectedOperations) => {
    //const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
    this.setState({ defaultApproverGroups: tempSelectedOperations });
  };

  openMetaDataForm = () => {
    this.setState({showAddMeta:true});
  }

  closeMetaDataForm = () => {
    this.setState({showAddMeta:false})
  }

  openEditMetaDataForm = (mdKey, mdVal) => {
    this.setState({showAddMeta:true, metadataKey:mdKey, defaultValue: mdVal})
  }

  openSafeListForm = (arrayValue, formType) => {
    if (arrayValue ){
      //edit mode
      this.setState({
        showApprovedRejectedForm:true,
        safeListFormType: formType,
        currentSafelistValue: arrayValue,
        tempSafeListType: arrayValue.type,
        tempSafeListValue: arrayValue.value,
        tempSelectedOperation: arrayValue.operation
      });
    } else{
      //add mode
      this.setState({
        showApprovedRejectedForm:true,
        safeListFormType: formType,
        currentSafelistValue: undefined,
        tempSafeListType: undefined,
        tempSafeListValue: undefined,
        tempSelectedOperation: "EQ"
      });
    }

  }

  handleSafeListValue = () => {
    var val = this.state.currentSafelistValue;
    if ( !val ) {
      val = {};
      if ( this.state.safeListFormType === 'approved'){
        this.state.autoApprovedValues.push(val);
      }else if ( this.state.safeListFormType === 'rejected'){
        this.state.autoRejectedValues.push(val);
      }
    }
    val.type = this.state.tempSafeListType;
    val.value = this.state.tempSafeListValue;
    val.operation = this.state.tempSelectedOperation;
    this.cleanSafeListState();
  }

  removeSafeListValue = (removefromIndex, formType) => {
    if ( formType === 'approved'){
      const newArray = this.state.autoApprovedValues;
      newArray.splice(removefromIndex, 1);
      this.setState({autoApprovedValues: newArray});
    } else if ( formType === 'rejected'){
      const newArray = this.state.autoRejectedValues;
      newArray.splice(removefromIndex, 1);
      this.setState({autoRejectedValues: newArray});
    }
  }

  cleanSafeListState = () => {
    this.setState({
      currentSafelistValue: null,
      showApprovedRejectedForm: false,
      safeListFormType: null,
      tempSafeListType : '',
      tempSafeListValue : '',
      tempSelectedOperation : 'EQ',
    });
  }

  closeSafeListForm = () => {
    this.cleanSafeListState();
  }

  handleSubmit = async (event) => {

    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }else{
      const {
        id,
        templateName,
        templateTitle,
        templateData,
        templateKey,
        templateType,
        metaDataObject,
        showMap,
        allowSelfApproval,
        actions,
        defaultApprovers,
        defaultApproverGroups,
        autoApprovedValues,
        autoRejectedValues
      } = this.state;

      if (id) {
        await this.updateData(id, templateName, templateTitle, templateData, templateKey, templateType, showMap, allowSelfApproval, metaDataObject, actions, defaultApprovers, defaultApproverGroups, autoApprovedValues, autoRejectedValues);
      } else {
        await this.createData(templateName, templateTitle, templateData, templateKey, templateType, showMap, allowSelfApproval, metaDataObject, actions, defaultApprovers, defaultApproverGroups, autoApprovedValues, autoRejectedValues);
      }
    }


    this.setState({validated:true});


  };

  updateData = async (id, templateName, templateTitle, templateData, templateKey, templateType, showMap, allowSelfApproval, metaDataObject, actions, defaultApprovers, defaultApproverGroups, autoApprovedValues, autoRejectedValues) => {
    const data = {
      id,
      name : templateName,
      templateTitle,
      templateData,
      key : templateKey,
      type : templateType,
      metaData : metaDataObject,
      showMap,
      allowSelfApproval,
      defaultApprovers,
      defaultApproverGroups,
      autoApprovedValues,
      autoRejectedValues
    };
    try {
      const response = await fetch(nl3ApiBase + `/approvalTemplates/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.state.companyToken}`,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        console.log('Data updated successfully');
        nl3Utils.nl3Toast("Success! Template Updated.",true,"success");
        this.props.history.push('/dashboard/jit-templates');

      } else {
        console.error('Failed to update data');
        nl3Utils.nl3Toast("Error! Failed to Update Template",true,"error");
      }
    } catch (error) {
      console.error('An error occurred during the update:', error);
      nl3Utils.nl3Toast("Error! Failed to Update Template",true,"error");
    }
  };

  createData = async (templateName, templateTitle, templateData, templateKey, templateType, showMap, allowSelfApproval, metaDataObject, actions, defaultApprovers, defaultApproverGroups, autoApprovedValues, autoRejectedValues) => {
    const data = {
      name : templateName,
      templateTitle,
      templateData,
      key : templateKey,
      type : templateType,
      metaData : metaDataObject,
      showMap,
      allowSelfApproval,
      defaultApprovers,
      defaultApproverGroups,
      autoApprovedValues,
      autoRejectedValues
    };

    try {
      const response = await fetch(nl3ApiBase + `/approvalTemplates`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.state.companyToken}`,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        console.log('Data created successfully');
        nl3Utils.nl3Toast("Success! Template Updated.",true,"success");
        this.props.history.push('/dashboard/jit-templates');
      } else {
        console.error('Failed to create data');
        nl3Utils.nl3Toast("Error! Failed to Update Template",true,"error");
      }
    } catch (error) {
      console.error('An error occurred during the creation:', error);
      nl3Utils.nl3Toast("Error! Failed to Update Template",true,"error");
    }
  };

  cancelChanges = () => {
    this.props.history.push('/dashboard/jit-templates');
  }

  render() {

    const {
      templateName,
      templateTitle,
      templateData,
      templateKey,
      templateType,
      showMap,
      allowSelfApproval,
      metaDataObject,
      metadataKey,
      defaultValue,
      approversOpts,
      defaultApprovers,
      defaultApproverGroups,
      groupsOpts,
      validated,
      tempSafeListType,
      tempSafeListValue,
      tempSelectedOperation,
      autoApprovedValues,
      autoRejectedValues
    } = this.state;

    return (


      <Row className='jit-templates'>
        <Col xl="9" lg="8">
          <Card className="whiteCard">
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">JIT Approval Template</h4>
              </div>
            </Card.Header>
            <Card.Body>
              <Form noValidate validated={validated} onSubmit={this.handleSubmit}>


                <FloatingLabel controlId="tType" label="Approval Type" className="mb-3">
                  <Form.Select required aria-label="Approval Type" value={templateType} onChange={(e) => this.setState({ templateType: e.target.value })}>
                    <option value="">-- Select --</option>
                    <option value="APPROVAL">Approve</option>
                    {/* <option value="SIGN_AND_APPROVE">Sign and Approve</option> */}
                    <option value="APPROVE_AND_ASSIGN">Approve and Assign</option>
                  </Form.Select>
                </FloatingLabel>


                <FloatingLabel controlId="tName" label="Template Name" className="mb-3">
                  <Form.Control required type="text" size="md" placeholder="Template Name" value={templateName} onChange={(e) => this.setState({ templateName: e.target.value })} />
                </FloatingLabel>


                <FloatingLabel controlId="tTitle" label="Approval Title" className="mb-3">
                  <Form.Control required type="text" size="md" placeholder="Approval Title" value={templateTitle} onChange={(e) => this.setState({ templateTitle: e.target.value })} />
                </FloatingLabel>


                <FloatingLabel controlId="tKey" label="Approval Key" className="mb-3">
                  <Form.Control required type="text" size="md" placeholder="Approval Key" value={templateKey} onChange={(e) => this.setState({ templateKey: e.target.value })} />
                </FloatingLabel>


                <FloatingLabel controlId="tData" label="Approval Data" className="mb-3">
                  <Form.Control required as="textarea" placeholder="Approval Data" style={{ height: 300 }} value={templateData} onChange={(e) => this.setState({ templateData: e.target.value })} />
                </FloatingLabel>


                <Form.Check
                  type={'checkbox'}
                  label={"Show map in notification"}
                  id={"showInMap"}
                  checked={showMap}
                  onChange={(e) => this.setState({ showMap: e.target.checked })}
                />

                <Form.Check
                  type={'checkbox'}
                  label={"Allow self approval"}
                  id={"allowSelfApproval"}
                  checked={allowSelfApproval}
                  onChange={(e) => this.setState({ allowSelfApproval: e.target.checked })}
                />



{/*
                <FloatingLabel controlId="tData" label="Public Key" className="mb-3">
                  <Form.Control as="textarea" placeholder="Public Key" style={{ height: 150 }} value={publicKey} onChange={(e) => this.setState({ publicKey: e.target.value })} />
                </FloatingLabel> */}


                <h4 className='mt-5 mb-3'>Default Approvers:</h4>


                <DualListBox
                  options={approversOpts}
                  selected={defaultApprovers}
                  onChange={(value) => this.handleDefaultApproversChange(value)}
                  icons={{
                    moveLeft: "<",
                    moveAllLeft: "<<",
                    moveRight: ">",
                    moveAllRight: ">>"
                  }}
                  canFilter={true}
                  preserveSelectOrder={true}
                  showNoOptionsText={true}
                />

                <h4 className='mt-5 mb-3'>Default Approver Groups:</h4>

                <DualListBox
                  options={groupsOpts}
                  selected={defaultApproverGroups}
                  onChange={(value) => this.handleDefaultApproverGroupsChange(value)}
                  icons={{
                    moveLeft: "<",
                    moveAllLeft: "<<",
                    moveRight: ">",
                    moveAllRight: ">>"
                  }}
                  canFilter={true}
                  preserveSelectOrder={true}
                  showNoOptionsText={true}

                />

                <br />
                <h4 className='mt-5 mb-3'>Metadata:</h4>
                <TableContainer >
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Key</TableCell>
                        <TableCell>Default Value</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(metaDataObject).map((objKey, index) => (
                        <TableRow key={index}>
                          <TableCell>{objKey}</TableCell>
                          <TableCell>{metaDataObject[objKey]}</TableCell>
                          <TableCell  align='right'>
                            <IconButton  onClick={() => this.openEditMetaDataForm(objKey, metaDataObject[objKey])}>
                              <BiPencil size={20}></BiPencil>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {Object.keys(metaDataObject).length < 1 && (
                  <div className='nl3-no-records-in-table'> No Medata </div>
                )}
                <Button variant="light" type="button" onClick={this.openMetaDataForm}  style={{marginTop:10}}>
                  Add Metadata
                </Button>

                <br />
                <h4 className='mt-5 mb-3'>Auto Approve List:</h4>
                <TableContainer >
                <Table size="small"  >
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell>Operation</TableCell>
                      <TableCell>Value</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {autoApprovedValues.map((nextValue, index) => (
                      <TableRow key={index}>
                        <TableCell>{nextValue.type}</TableCell>
                        <TableCell>{nextValue.operation ? getOpeationLabel(nextValue.operation) : ''}</TableCell>
                        <TableCell>{nextValue.value}</TableCell>
                        <TableCell  align='right'>
                          <IconButton  onClick={() => this.openSafeListForm(nextValue, 'approved')} >
                          <BiPencil size={20}></BiPencil>
                          </IconButton>
                          <IconButton onClick={() => this.removeSafeListValue(index, 'approved')} >
                          <BiTrash size={20}></BiTrash>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                </TableContainer>
                {autoApprovedValues?.length < 1 && (
                  <div className='nl3-no-records-in-table'> No Auto Approved Values </div>
                )}
                <Button variant="light" type="button" onClick={() => this.openSafeListForm(null, 'approved')} style={{marginTop:10}}>
                  Add Value to Auto Approved List
                </Button>

                <br />
                <h4 className='mt-5 mb-3'>Auto Reject List:</h4>
                <TableContainer >
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell>Operation</TableCell>
                      <TableCell>Value</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {autoRejectedValues.map((nextValue, index) => (
                      <TableRow key={index}>
                        <TableCell>{nextValue.type}</TableCell>
                        <TableCell>{nextValue.operation ? getOpeationLabel(nextValue.operation) : ''}</TableCell>
                        <TableCell>{nextValue.value}</TableCell>
                        <TableCell align='right'>
                          <IconButton  onClick={() => this.openSafeListForm(nextValue, 'rejected')} >
                            <BiPencil size={20}></BiPencil>
                          </IconButton>
                          <IconButton onClick={() => this.removeSafeListValue(index, 'rejected')} >
                            <BiTrash size={20}></BiTrash>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                </TableContainer>
                {autoApprovedValues?.length < 1 && (
                  <div className='nl3-no-records-in-table'> No Auto Rejected Values </div>
                )}
                <Button variant="light" type="button" onClick={() => this.openSafeListForm(null, 'rejected')}  style={{marginTop:10}}>
                  Add Value to Auto Reject List
                </Button>


                <div className='mt-5'>
                  <Button variant="secondary" type="submit" onClick={this.cancelChanges}>Cancel</Button> <Button variant="primary" type="submit">Save</Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
          <Modal show={this.state.showAddMeta} onHide={this.closeMetaDataForm}>
            <Modal.Header closeButton>
              <Modal.Title>Meta Data Input</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group>
                  <Form.Label>Key:</Form.Label>
                  <Form.Control type="text" value={metadataKey} onChange={(e) => this.setState({ metadataKey: e.target.value })} /><br />
                  <Form.Label>Default Value:</Form.Label>
                  <Form.Control type='text' value={defaultValue} onChange={(e) => this.setState({ defaultValue: e.target.value })} />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.closeMetaDataForm}>
                Close
              </Button>
              <Button variant="primary" onClick={this.handleAddMetadata}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>


          <Modal show={this.state.showApprovedRejectedForm} onHide={this.closeApproveRejectedForm}>
            <Modal.Header closeButton>
              <Modal.Title>Add Value</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group>
                  <Form.Label>Type:</Form.Label>
                  <Form.Control type="text" value={tempSafeListType} onChange={(e) => this.setState({ tempSafeListType: e.target.value })} /><br />
                  <SafeListDropdown tempSelectedOperation={tempSelectedOperation} handleChange={(e) => this.setState({ tempSelectedOperation: e.target.value })} /><br />
                  <Form.Label>Value:</Form.Label>
                  <Form.Control type='text' value={tempSafeListValue} onChange={(e) => this.setState({ tempSafeListValue: e.target.value })} />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.closeSafeListForm}>
                Close
              </Button>
              <Button variant="primary" onClick={this.handleSafeListValue}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>


        </Col>
      </Row>


    );
  }
}

export default JitTemplatesEdit;
