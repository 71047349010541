import React, { Component } from 'react'
import {slideDown, slideUp } from './anim';
import Moment from 'react-moment';
import moment from 'moment-timezone';

//NL3 Styling
import "../../assets/nl3.css"
import { TableCell, TableRow } from '@material-ui/core';

class ImportTableRow extends Component {
   constructor(props) {
     super(props)
     this.state= {
        expanded: false,
        details: "+ ",
     }
   }

   toggleExpander = (e) => {
       if (!this.state.expanded) {
            this.setState({expanded: true, details:"- "},
               () => {
                  if (this.refs.expanderBody) {
                     slideDown(this.refs.expanderBody);
                  }
               }
            );
       } else {
            slideUp(this.refs.expanderBody, {
                onComplete: () => {this.setState({expanded: false, details: "+ "}); }
            });
       }
   }

   render() {
      const { importItem, itemIdx } = this.props;
      return [
           <TableRow key="main" onClick={this.toggleExpander}>
              <TableCell>{this.state.details} {importItem.id}</TableCell>
              <TableCell>{importItem.status}</TableCell>
              <TableCell><Moment utc tz={moment.tz.guess()} format="MM-DD-YYYY hh:mm:ssa z">{importItem.startTime}</Moment></TableCell>
              <TableCell><Moment utc tz={moment.tz.guess()} format="MM-DD-YYYY hh:mm:ssa z">{importItem.actualEndTime}</Moment></TableCell>
            </TableRow>,
            this.state.expanded && (
                 <TableRow key={itemIdx} className="expandable">
                   <TableCell colSpan={4}>
                     <div ref="expanderBody" className="greyCard d-flex justify-content-between m-1">
                         <div className="card-body d-flex justify-content-between flex-wrap m-1" style={{width:"30%"}}>
                            <div className="blackText">File Size<br/>{importItem.fileSize}</div>
                         </div>
                         <div className="card-body d-flex justify-content-between flex-wrap m-1" style={{width:"20%"}}>
                            <div className="blackText">Total Users<br/>{importItem.totalUsers}</div>
                         </div>
                         <div className="card-body d-flex justify-content-between flex-wrap m-1" style={{width:"20%"}}>
                            <div className="blackText">Success<br/>{importItem.success}</div>
                         </div>
                         <div className="card-body d-flex justify-content-between flex-wrap m-1" style={{width:"20%"}}>
                            <div className="blackText">Errors<br/>{importItem.error}</div>
                         </div>
                         <div className="card-body d-flex justify-content-between flex-wrap m-1" style={{width:"10%"}}>
                            <button className="actnButton" onClick={() => this.props.action(importItem.id)}>Full Details</button>
                         </div>
                     </div>
                   </TableCell>
                 </TableRow>
            ),
      ];
   }
}

export default ImportTableRow
