import React, { Component } from 'react'
import {slideDown, slideUp } from './anim';
import Moment from 'react-moment';
import moment from 'moment-timezone';

import { GrActions } from 'react-icons/gr';
import { BiCheckCircle } from 'react-icons/bi'
import { VscError } from 'react-icons/vsc'
import { BsGear } from 'react-icons/bs';
import { ImLock, ImUnlocked, ImUserTie } from 'react-icons/im'

//NL3 Styling
import "../../assets/nl3.css"
import { TableCell, TableRow } from '@material-ui/core';

class HistoryTableRow extends Component {
   constructor(props) {
     super(props)
     this.expanderBody = null;
     this.state= {
        expanded: false,
        details: "+ ",
     }
   }

   expanderBodyFunc = (e) => {
      this.expanderBody = e;
   }

   toggleExpander = (e) => {
       if (!this.state.expanded) {
            this.setState({expanded: true, details:"- "},
               () => {
                  if (this.expanderBody) {
                     slideDown(this.expanderBody);
                  }
               }
            );
       } else {
            slideUp(this.expanderBody, {
                onComplete: () => {this.setState({expanded: false, details: "+ "}); }
            });
       }
   }

   render() {
      const { record } = this.props;
      const text = record.message.split(' at ')[0];
      var header1 = "";
      var header2 = "";
      var header3 = "";
      var value1 = "";
      var value2 = "";
      var value3 = "";
      if (record.requestType === 'Company') {
          header1 = "Application";
          header2 = "Originator";
          header3 = "Status";
          value1 = "Policy";
          value2 = "System";
          value3 = record.message;
      }
      if (record.requestType === 'LockUnlockUserAccount') {
          header1 = "Application";
          header2 = "Originator";
          header3 = "Status";
          value1 = record.message.split('- ')[1];
          value3 = record.lockUnlockStatus;
          if (record.message.includes('AUTO_RELOCK_PROC')) {
              value2 = "System Process";
          } else {
              value2 = record.message.split('by ').pop().split(' ')[0];
          }
      }
      if (record.requestType === 'AccountProtectionCheckRequest') {
          header1 = "Application";
          header2 = "IP Address";
          header3 = "Location";
          if (record.domainUrl !== undefined) {
             value1 =  record.domainUrl;
          } else {
             value1 = record.message.split('- ')[1];
          }
          if (record.userLocation === "Undefined") {
             value2 = "Undetermined";
          } else {
             value2 = record.userIP;
          }
          if (record.inetegrationData !== undefined) {
             if (typeof(record.integrationData.geoip) === "undefined") {
                 value3 = record.integrationData.city + ", " + record.integrationData.country;
             } else {
                 value3 = record.integrationData.geoip.cityName + ", " + record.integrationData.geoip.countryCode;
             }
          } else {
             value3 = "Unreported";
          }
      }
      var statusImg = <></>;
      if (record.message.includes('AUTO_RELOCK_PROCCESS')) {
          statusImg = <GrActions style={{fontSize:"2.2rem"}} />
      } else if (record.message.includes('[UNLOCKED]')) {
          statusImg = <ImUnlocked fill="green" style={{fontSize:"2.5rem"}}/>
      } else if (record.message.includes('[LOCKED]')) {
          statusImg = <ImLock fill="red" style={{fontSize:"2.5rem"}}/>
      } else if (record.message.includes('attempt Allowed')) {
          statusImg = <BiCheckCircle fill="green" style={{fontSize:"2.2rem"}} />
      } else if (record.message.includes('attempt Blocked')) {
          statusImg = <VscError fill="red" style={{fontSize:"2.2rem"}} />
      } else if (record.message.includes('REJECTED')) {
          statusImg = <VscError fill="red" style={{fontSize:"2.2rem"}} />
      } else if (record.message.includes('Action [')) {
          statusImg = <><ImUserTie fill="#4052A4" style={{fontSize:"2.2rem"}} />
                        <BsGear fill="#888d91" style={{fontSize:"1.6rem", marginLeft:"-20px", marginTop:"25px"}} />
                      </>
      }
      return [
            <TableRow key="main" onClick={this.toggleExpander} style={{width:"70%"}}>
              <TableCell>{this.state.details}
                   <span align="right">
                     &nbsp;&nbsp;{statusImg}&nbsp;&nbsp;
                     <Moment utc tz={moment.tz.guess()} format="MM-DD-YYYY hh:mm:ssa z">{record.requestDate}</Moment>
                   </span>
              </TableCell>
              <TableCell style={{whiteSpace:"pre-wrap"}}>{text}</TableCell>
            </TableRow>,
            this.state.expanded && (
               <TableRow key={record.id} className="expandable">
                 <TableCell colSpan={3}>
                   <div ref={this.expanderBodyFunc} className="greyCard d-flex justify-content-between m-1">
                       <div className="card-body d-flex justify-content-between flex-wrap m-1"
                              style={{width:"30%"}}>
                          <div className="blackText">{header1}<br/>{value1}</div>
                       </div>
                       <div className="card-body d-flex justify-content-between flex-wrap m-1"
                              style={{width:"30%"}}>
                          <div className="blackText">{header2}<br/>{value2}</div>
                       </div>
                       <div className="card-body d-flex justify-content-between flex-wrap m-1"
                              style={{whiteSpace:"pre-wrap",width:"30%"}}>
                          <div className="blackText">{header3}<br/>{value3}</div>
                       </div>
                   </div>
                 </TableCell>
               </TableRow>
            )
      ];
   }
}

export default HistoryTableRow
