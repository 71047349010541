import { toast } from 'react-toastify';

var location = null;
var persistentStorage = null;
var notificationsAllowed = null;

export function nl3getUserName() {
    let userName = localStorage.getItem('NL3USR');
    if (userName === null) {
       userName = "";
    }
    return userName;
}

export function nl3setUserName(username) {
    localStorage.setItem('NL3USR', username);
}

export function nl3delUserName() {
    localStorage.removeItem('NL3USR');
}

export function nl3getCredId() {
    let credID = localStorage.getItem('NL3CRED');
    if (credID === null) {
       credID = "";
    }
    return credID;
}

export function nl3setCredId(cred) {
    localStorage.setItem('NL3CRED', cred);
}

export function nl3delCredId() {
    localStorage.removeItem('NL3CRED');
}

export function nl3getLocation() {
    let options = {
       enableHighAccuracy: true,
       timeout: 5000,
       maximumAge: 0,
    }

    function success(pos) {
       location = pos.coords;
    }

    function errors(err) {
       if (err.code === 1) {
          console.log("User denied the request for Geolocation.");
       } else if (err.code === 2) {
          console.log("Location information is not available");
       } else if (err.code === 3) {
          console.log("The request to get user location timed out.");
       } else {
          console.log(`Error (${err.code}): An unknown error occurred.`);
       }
    }

    if (location === null) {
       if (navigator.geolocation) {
          navigator.permissions
             .query({name: "geolocation"})
             .then(function (result) {
                 navigator.geolocation.getCurrentPosition(success,errors,options);
             });
       } else {
          console.log("Location information is not available");
       }
    }
    return location;
}

export function nl3getNotificationPermission() {
    if (notificationsAllowed === null) {
       if (Notification.permission === 'granted') {
          notificationsAllowed = true;
       } else {
          if (Notification.permission !== 'denied') {
             Notification.requestPermission()
               .then((permission) => {
                  if (permission === 'granted') {
                    notificationsAllowed = true;
                  } else {
                    notificationsAllowed = false;
                  }
             })
          } else {
             notificationsAllowed = false;
          }
       }
       console.log("Notifications: ",notificationsAllowed);
    }
    return notificationsAllowed;
}

export function nl3allowPersistentStorage() {
    if (persistentStorage === null) {
       if (navigator.storage && navigator.storage.persist) {
          navigator.storage.persist().then((persist) => {
             if (persist) {
                console.log("Storage will persist.");
                persistentStorage = true;
             } else {
                console.log("Storage will not persist.");
                persistentStorage = false;
             }
          });
       } else {
          console.log("Storage will not persist.");
       }
    }
    return persistentStorage;
}

export function nl3Toast(msg,timer,msgType) {
   if (!timer) {
       toast(msg, {
             type: msgType,
             position: "top-right",
             autoClose: false,
             hideProgressBar: true,
             closeOnClick: true,
             pauseOnHover: false,
             draggable: true,
             progress: undefined,
          });
   } else {
       toast(msg, {
             type: msgType,
             position: "top-right",
             autoClose: 1500,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: false,
             draggable: true,
             progress: undefined,
            });
   }
}
