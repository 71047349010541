import React, { Component } from 'react'
import { Row,Col } from 'react-bootstrap'
import Store from '../../store'
import ReactTooltip from 'react-tooltip';
import moment from 'moment-timezone';
import Checkbox from '@material-ui/core/Checkbox'
import FileSaver from 'file-saver';
import * as base64url from '../../libs/base64url-arraybuffer'
import * as cognito from '../../libs/cognito';
import * as nl3Utils from '../../libs/nl3Utils';

//NL3 Styling
import "../../assets/nl3.css"
import { IoMdRefreshCircle } from 'react-icons/io'
import { FaKey } from 'react-icons/fa'
import { GiSkeletonKey } from 'react-icons/gi'
import { GiUsbKey } from 'react-icons/gi'
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { BiLogIn, BiSave, BiTrash } from 'react-icons/bi';

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;
const nl3ApiLocalHost = process.env.REACT_APP_NL3_USE_LOCALHOST;

class DeviceManagement extends Component {
   constructor(props) {
     super(props)
     this.state= {
        statusCode: 0,
        deviceList: [],
        userToken: "",
        portalType: "",
     }
   }

   async componentDidMount() {
       try {
          // Get the access token
          let portal = Store.getState().company;
          const session = await cognito.getSession();
          this.setState({userToken : session.accessToken.jwtToken, portalType: portal});

          // get the account info
          this.getDeviceData();
       } catch (e) {
          console.log(e.message);
          this.props.history.push('/');
          return;
       }
    }

   async getDeviceData() {
      // try to get the device list
      var deviceUrl = nl3ApiBase + `/users/devices`;
      const apiResponse = await fetch(deviceUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.state.userToken}`,
        },
      });

      if (apiResponse.status === 200) {
         const records = await apiResponse.json();
         var devList = records;
         devList.sort(function(a,b){return moment.utc(b.lastUsedDate) - moment.utc(a.lastUsedDate)});
         this.setState({statusCode : apiResponse.status, deviceList: devList});
      } else {
         this.setState({statusCode : apiResponse.status, deviceList: null});
      }
      return apiResponse.status;
   }

   async updateDeviceInDB(credId, deviceName) {
     var devURL = process.env.REACT_APP_NL3_API_BASE + `/users/devices/${credId}`;
     const bodyData = JSON.stringify({"deviceName": `${deviceName}`});
     const apiResponse = await fetch(devURL, {
          method: "PATCH",
          headers: {
             'Authorization': `Bearer ${this.state.userToken}`,
             'accept': 'application/json',
             'Content-Type': 'application/json',
          },
          body: `${bodyData}`,
     });
     if (apiResponse.status > 204) {
        nl3Utils.nl3Toast('Error updating the name for the authorized device.',true,'error');
     } else {
        nl3Utils.nl3Toast('Successfully updated the name for the authorized device.',true,'success');
        this.getDeviceData();
     }
   }

   async removeDeviceFromDB(credId, currentCredId) {
     var devURL = process.env.REACT_APP_NL3_API_BASE + `/users/devices/${credId}`;
     const bodyData = JSON.stringify({"currentCredId": `${currentCredId}`});
     const apiResponse = await fetch(devURL, {
          method: "DELETE",
          headers: {
             'Authorization': `Bearer ${this.state.userToken}`,
             'accept': 'application/json',
             'Content-Type': 'application/json',
          },
          body: `${bodyData}`,
     });
     if (apiResponse.status > 204) {
        nl3Utils.nl3Toast('Error removing the authorized device.',true,'error');
     } else {
        nl3Utils.nl3Toast('Successfully removed the authorized device.',true,'success');
        this.getDeviceData();
     }
   }

   async getRecoveryKey() {
      // try to get the device list
      let recoveryKey = "";
      var keyUrl = nl3ApiBase + `/users/recoveryKey`;
      const apiResponse = await fetch(keyUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.state.userToken}`,
        },
      });

      if (apiResponse.status === 200) {
         const keyData = await apiResponse.json();
         recoveryKey = keyData.keyString;
      }
      return recoveryKey;
   }

   async getNewRecoveryKey() {
     var keyURL = process.env.REACT_APP_NL3_API_BASE + `/users/regenerateRecoveryKey`;
     const apiResponse = await fetch(keyURL, {
          method: "POST",
          headers: {
             'Authorization': `Bearer ${this.state.userToken}`,
          },
     });
     if (apiResponse.status > 200) {
        nl3Utils.nl3Toast('Error regenerating the recovery key. Contact Support.',true,'error');
     } else {
        nl3Utils.nl3Toast('Successfully regenerated the recovery key. Download the new key.',true,'success');
     }
   }

   async createCredential() {
      let globalRegisteredCredentials = "";
      let globalRegisteredCredentialsJSON = {};
      let username = cognito.getCurrentUser().username;
      try {
          //build the credentials options requirements
          var credOptionsRequest = {
            attestation: 'none',
            username: username,
            credentialNickname: 'web1',
            authenticatorSelection: {
              authenticatorAttachment: [ 'cross-platform' ],
              userVerification: 'required',
              residentKey: 'preferred',
              requireResidentKey: false,
            }
          };

          //generate credentials request to be sent to navigator.credentials.create
          var startURL = nl3ApiBase + '/registration/start';
          if (nl3ApiLocalHost === 'TRUE') {
             startURL += '?localhost=true';
          }
          var startAPIRsp = await fetch(startURL, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(credOptionsRequest),
          });
          if (startAPIRsp.status !== 200) {
             let rcText = 'Error registering hardware key (STRT-' + startAPIRsp.status + ')';
             nl3Utils.nl3Toast(rcText,true,'error');
          } else {
             var startResponse = await startAPIRsp.json();
             var nl3RegistrationId = startResponse.registrationId;
             var credOptions = startResponse.publicKeyCredentialCreationOptions;
             var challenge = credOptions.challenge;
             credOptions.challenge = base64url.decode(credOptions.challenge);
             credOptions.user.id = base64url.decode(credOptions.user.id);;

            //----------create credentials using available authenticator
            const cred = await navigator.credentials.create({
                publicKey: credOptions
            });
            // parse credentials response to extract id and public-key,
            // this is the information needed to register the user in Cognito
            var credential = {};
            credential.id =     cred.id;
            credential.rawId =  base64url.encode(cred.rawId);
            credential.type =   cred.type;
            credential.challenge = challenge;
            credential.clientExtensionResults = {};

            if (cred.response) {
              var clientDataJSON = base64url.encode(cred.response.clientDataJSON);
              var attestationObject = base64url.encode(cred.response.attestationObject);
              credential.response = {
                clientDataJSON,
                attestationObject
              };
            }

            var nl3Credential = {
              'credential' : credential,
              'reqiureUserVerification' : true,
              'registrationId' : nl3RegistrationId
            };

            var finishURL = nl3ApiBase + '/registration/finish';
            if (nl3ApiLocalHost === 'TRUE') {
               finishURL += '?localhost=true';
            }
            var finishAPIRsp = await fetch(finishURL, {
                  method: "POST",
                  headers: {
                      'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(nl3Credential),
            });
            if (finishAPIRsp.status !== 200) {
               let rcText = 'Error registering hardware key (FNSH-' + finishAPIRsp.status + ')';
               nl3Utils.nl3Toast(rcText,true,'error');
            } else {
               const credResponse = await finishAPIRsp.json();
               const credId = credResponse.credId;
               globalRegisteredCredentialsJSON = {id: credResponse.credId,publicKey: credResponse.publicKey};
               globalRegisteredCredentials = JSON.stringify(globalRegisteredCredentialsJSON);

               //----------credentials have been created, now sign-up the user in Cognito
               var publicKeyCred = btoa(globalRegisteredCredentials);
               var addRequest = {'username': username, 'publicKeyCred': publicKeyCred,
                                    'credId': credId, 'registrationId': nl3RegistrationId}
               var addURL = nl3ApiBase + '/users/devices';
               var addAPIRsp = await fetch(addURL, {
                     method: "POST",
                     headers: {
                         'Authorization': `Bearer ${this.state.userToken}`,
                         'Content-Type': 'application/json',
                     },
                     body: JSON.stringify(addRequest),
               });
               if (addAPIRsp.status !== 200) {
                  let rcText = 'Error registering hardware key (NWDV-' + addAPIRsp.status + ')';
                  nl3Utils.nl3Toast(rcText,true,'error');
               } else {
                  let rcText = 'Hardware key has been registered for this account';
                  nl3Utils.nl3Toast(rcText,true,'success');
                  return(addAPIRsp.status);
               }
            }
          }
      } catch (e) {
          console.log(e);
          if (e.message.includes("The operation either timed out or was not allowed")) {
             nl3Utils.nl3Toast("The operation either timed out or was not allowed.",true,'info');
          } else if (e.message.includes("This request has been cancelled")) {
             nl3Utils.nl3Toast(e.message,true,'info');
          } else {
             nl3Utils.nl3Toast(e.message,true,'error');
          }
      }
      return(0);
   }

   registerHardwareKey() {
      this.createCredential()
      .then(rc => {
          if (rc === 200) {
             this.refreshDevices();
          }
      })
   }

   refreshDevices() {
      this.setState({statusCode: 0});
      this.getDeviceData();
   }

   downloadRecoveryKey() {
      this.getRecoveryKey()
      .then((recoveryKey) => {
         if (recoveryKey === "") {
            nl3Utils.nl3Toast('Error retrieving the recovery key. Contact Support.',true,'error');
         } else {
            const output =  recoveryKey;
            const blob = new Blob([output]);
            const fileName = cognito.getCurrentUser().username + '-nl3RecoveryKey.txt'
            FileSaver.saveAs(blob, fileName);
            this.setState({statusCode: 200});
         }
      } );
   }

   regenerateRecoveryKey() {
      this.getNewRecoveryKey();
   }

   handleUpdate(e,index) {
      e.preventDefault();
      const credId = this.state.deviceList[index].credId;
      const deviceName = this.state.deviceList[index].deviceName;
      this.updateDeviceInDB(credId,deviceName);
   }

   handleRemove(e, index) {
      e.preventDefault();
      const credId = this.state.deviceList[index].credId;
      const currCred = nl3Utils.nl3getCredId();
      this.removeDeviceFromDB(credId,currCred);
   }

   handleKeyPress(e,index) {
      if (e.key === 'Enter') {
         this.handleUpdate(e,index);
      }
   }

   handleNameChange(e,index) {
      e.preventDefault();
      let tmpDeviceList = this.state.deviceList;
      tmpDeviceList[index].deviceName = e.target.value;
      this.setState({deviceList: tmpDeviceList});
   }

   renderTableData() {
      return this.state.deviceList.map((device, itemIdx) => {
            const currCred = nl3Utils.nl3getCredId();
            const {credId, deviceName, lastUsedDate, allowedNotifications} = device; //destructuring
            const useDate = moment.utc(lastUsedDate).local().format("MM/DD/YY");
            const useTime = moment.utc(lastUsedDate).local().format("h:mm a");
            const isCurrent = (currCred === credId) ? true : false;
            return (
               <TableRow key={itemIdx}>
                  { (isCurrent) ?
                      <TableCell style={{'color':'green'}} >
                            <BiLogIn size={25} title="Logged in on this device"></BiLogIn>
                      </TableCell>
                    :
                      <TableCell></TableCell>
                  }
                  <TableCell>{useTime}<br/>{useDate}</TableCell>
                  { (allowedNotifications) ?
                         <TableCell><Checkbox size="small" color="primary" checked={allowedNotifications} disable="true"/></TableCell>
                      :
                         <TableCell></TableCell>
                  }
                  <TableCell>
                      <input style={{background:"AliceBlue", width:"90%"}}type="text" value={deviceName}
                              onKeyPress={(e) => this.handleKeyPress(e,itemIdx)}
                              onChange={(e) => this.handleNameChange(e,itemIdx)}/>
                  </TableCell>
                  <TableCell>
                      <IconButton aria-label="Update device name" title="Update device name" onClick={(e) => this.handleUpdate(e,itemIdx)}>
                        {/* <RiSave3Fill size={20}/> */}
                        <BiSave ></BiSave>
                      </IconButton>
                     {/* <button className="clearButton" style={{width:"50px", align:"center"}}
                          onClick={(e) => this.handleUpdate(e,itemIdx)}
                          data-tip="Update device name" data-for="deviceTips" data-place="top">
                       <RiSave3Fill fill="green" style={{fontSize:'2.2rem'}} />
                     </button> */}
                     { this.state.deviceList.length > 1 && !isCurrent &&
                        // <button className="clearButton" style={{width:"50px", align:"center"}}
                        //      onClick={(e) => this.handleRemove(e,itemIdx)}
                        //      data-tip="Remove from Paired Devices" data-for="deviceTips" data-place="top">
                        //   <IoTrash fill="red" style={{fontSize:'2.2rem'}} />
                        // </button>
                        <IconButton aria-label="Remove from Paired Devices" title="Remove from Paired Devices" onClick={(e) => this.handleRemove(e,itemIdx)}>
                            <BiTrash></BiTrash>
                          </IconButton>
                     }
                  </TableCell>
                  <TableCell></TableCell>
               </TableRow>
            )
         })
   }

   render() {
     if ((isNaN(this.state.statusCode)) || (this.state.statusCode === 0)) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
               </button>
            </div>
          </>
        )
     } else if (this.state.statusCode > 200) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Error Retrieving Device Data. Please Contact support.
               </button>
            </div>
          </>
        )
     } else {
        if ((this.state.deviceList === null) || (this.state.deviceList.length  === 0)) {
          return (
            <>
               <Row>
                 <Col md="12" lg="12">
                   <Row className="row-cols-1">
                     <div className="whiteCard card">
                         <div className="card-header d-flex justify-content-between flex-wrap">
                           <h4 className="card-title">No Paired Devices found for this account.</h4>
                            <div>
                               <div>
                                  <button className="clearButton" style={{width:"100px"}}
                                        onClick={(e) => this.refreshDevices()}
                                        data-tip="Refresh device list"
                                        data-for="deviceTips" data-place="top">
                                    Refresh<br/>
                                    <IoMdRefreshCircle fill="grey" style={{fontSize:"2.4rem"}} />
                                  </button>
                               </div>
                            </div>
                         </div>
                         <br/>
                         <div className="card-footer d-flex justify-content-between flex-wrap p-1">
                         </div>
                     </div>
                   </Row>
                 </Col>
               </Row>
             <ReactTooltip className="nl3Tooltip" id="deviceTips" html={true}/>
            </>
          )
        } else {
          return (
            <>
              <div className="card whiteCard ">
                         <div className="card-header d-flex justify-content-between flex-wrap">
                            <h4 className="card-title">Paired Devices</h4>
                            <div>
                               <div>
                                  <button className="clearButton" style={{width:"100px"}}
                                        onClick={(e) => this.refreshDevices()}
                                        data-tip="Refresh device list"
                                        data-for="deviceTips" data-place="top">
                                    Refresh<br/>
                                    <IoMdRefreshCircle fill="grey" style={{fontSize:"2.4rem"}} />
                                  </button>
                                  <button className="clearButton" style={{width:"100px"}}
                                        onClick={(e) => this.registerHardwareKey()}
                                        data-tip="Register a hardware key (USB, Phone)"
                                        data-for="deviceTips" data-place="top">
                                    H/W Key<br/>
                                    <GiUsbKey fill="#4052A4" style={{fontSize:"1.8rem"}} />
                                  </button>
                                  <button className="clearButton" style={{width:"100px"}}
                                        onClick={(e) => this.downloadRecoveryKey()}
                                        data-tip="Download Recovery Key"
                                        data-for="deviceTips" data-place="top">
                                  Download<br/>
                                    <FaKey fill="#E3D313" style={{fontSize:"1.6rem"}} />
                                  </button>
                                  <button className="clearButton" style={{width:"100px"}}
                                        onClick={(e) => this.regenerateRecoveryKey()}
                                        data-tip="Regenerate the Recovery Key.<br/>
                                             Only do this if your recovery key<br/> has been lost or compromised."
                                        data-for="deviceTips" data-place="top">
                                    Regenerate<br/>
                                    <GiSkeletonKey fill="#E3D313" style={{fontSize:"1.8rem"}} />
                                  </button>
                               </div>
                            </div>
                         </div>


               <Row>
                 <Col md="12" lg="12">
                   <Row className="row-cols-1">

                     <div className="card-body p-3">

                            <div className="table-wrapper-scroll-y ">
                              <TableContainer>
                              <Table style={{tableLayout:"fixed", overflow:"auto"}}>
                                  <TableHead>
                                      <TableRow>
                                          <TableCell style={{"width":"5%"}}></TableCell>
                                          <TableCell style={{"width":"10%"}}>Last<br/>Used</TableCell>
                                          <TableCell style={{"width":"10%"}}>Push<br/>Enabled</TableCell>
                                          <TableCell style={{"width":"60%"}}>Name</TableCell>
                                          <TableCell style={{"width":"10%"}}>Action</TableCell>
                                          <TableCell style={{"width":"5%"}}></TableCell>
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                     {this.renderTableData()}
                                  </TableBody>
                              </Table>
                              </TableContainer>
                            </div>

                     </div>
                   </Row>
                 </Col>
             </Row>
             </div>
             <ReactTooltip className="nl3Tooltip" id="deviceTips" html={true}/>
         </>
        )
      }
     }
   }
}

export default DeviceManagement
