//router
import { useState, useEffect } from "react"
import IndexRouters from "./router/index"
import { useIdleTimer } from 'react-idle-timer'
import { getFBToken, onMessageListener } from  './firebase.js'
import { isSupported } from "firebase/messaging";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment-timezone'
import Store from './store'
import platform from 'platform';
import * as cognito from './libs/cognito'
import * as nl3Utils from './libs/nl3Utils'

//scss
import "./assets/scss/hope-ui.scss"
import "./assets/nl3.css"
import NL3Icon from "./assets/images/NL3-mark-only-blue_150x150.png"

function App() {
  // 15 minute inactivity timer
  const timeout = 1000 * 60 * 15;
  // 30 second prompt to stay logged in
  const promptBeforeIdle = 1000 * 30;

  const [ open, setOpen] = useState(false);
  const [ openFB, setOpenFB] = useState(false);
  const [ configFB, setConfigFB] = useState(false);
  const [ FBSupported, setFBSupported] = useState(false);
  const [ title, setTitle] = useState("");
  const [ body, setBody] = useState("");
  const [ button1, setButton1] = useState("");
  const [ button2, setButton2] = useState("");
  const [ notificationSessionId, setNotificationSessionId] = useState("");
  const [ remaining, setRemaining] = useState(0);

  const onIdle = () => {
    setOpen(false);
    setRemaining(0);
    if (cognito.isAuthenticated()) {
       cognito.signOut();
    }
  }

  const onPrompt = () => {
    if (cognito.isAuthenticated()) {
       setOpen(true);
       setRemaining(promptBeforeIdle);
    }
  }

  const onActive = () => {
    setOpen(false);
    setRemaining(0);
  }

  const onAction = () => {
     if (!configFB) {
        if (cognito.isAuthenticated()) {
           if (FBSupported) {
               let nl3ID = nl3Utils.nl3getCredId();
               getAuthToken()
               .then(authToken => {getFirebaseToken(authToken,nl3ID)});
           } else {
             // Firebase isn't supported, don't try process any notifications
             setConfigFB(true);
             console.log("Firebase Cloud Messaging is not supported on this browser");
             nl3Utils.nl3Toast("Push notifications are not supported in this browser. Use Chrome or Firefox to enable all site features.",false,"error");
           }
        }
     }
  }

  const { getRemainingTime, isPrompted, reset } = useIdleTimer({
    timeout, promptBeforeIdle, onPrompt, onIdle, onActive, onAction
  })

  const handleStillHere = () => {
    setOpen(false);
    reset();
  }

  async function processNotificationClicked(approval) {
    const session = await cognito.getSession();
    var userNotifs = process.env.REACT_APP_NL3_API_BASE + `/users/rejectNotification`;
    if (approval) {
        userNotifs = process.env.REACT_APP_NL3_API_BASE + `/users/approveNotification`;
    }
    const requestData = JSON.stringify({"sessionId": `${notificationSessionId}`});
    const apiResponse = await fetch(userNotifs, {
         method: "POST",
         headers: {
            "Authorization": `Bearer ${session.accessToken.jwtToken}`,
            'Content-Type': 'application/json',
         },
         body: `${requestData}`,
    });
    if (apiResponse.status > 202) {
       if (approval)
           console.log("Error approving push notification. ");
       else
           console.log("Error rejecting push notification. ");
    }
  }

  const handleButton1 = () => {
    if (button1 === 'Reject') {
       // call API to reject request
       processNotificationClicked(false);
    }
    handleFirebaseListener();
    setOpenFB(false);
    setButton1('');
    setButton2('');
  }

  const handleButton2 = () => {
    // call API to approve request
    processNotificationClicked(true);
    handleFirebaseListener();
    setOpenFB(false);
    setButton1('');
    setButton2('');
  }

  const handleFirebaseListener = () => {
      onMessageListener()
      .then(payload => {
           const isUpdate = ((payload.data.sessionId === undefined) ||
                             (payload.data.sessionId === null)) ? true : false;
           let currTime = new Date();
           let body = '';
           if ((payload.data.device === undefined) || (payload.data.device === null) ||
               (payload.data.location === undefined) || (payload.data.location === null)) {
              body = payload.data.body + "\nTime: " + moment(currTime).format("hh:mm:ss");
           } else {
              body = payload.data.body + "\nDevice: " + payload.data.device + "\nLocation: " + payload.data.location + "\nTime: " + moment(currTime).format("hh:mm:ss");
           }
           if (isUpdate) {
               setButton1("OK");
           } else {
               setButton1("Reject");
               setButton2("Approve");
           }
           setTitle(payload.data.title);
           setBody(body);
           if (isUpdate) {
              setNotificationSessionId('');
           } else {
              setNotificationSessionId(payload.data.sessionId);
           }
           setOpenFB(true);
      })
      .catch(err => console.log('Firebase Listener failed: ', err));
  }

  async function updateNotifications(authToken,fbToken,nl3ID) {
    const session = await cognito.getSession();
    const userNotifs = process.env.REACT_APP_NL3_API_BASE + `/users/enableNotifications`;
    const requestData = JSON.stringify({"allowedNotifications": true,
                             "credId": `${nl3ID}`,
                             "deviceName": platform.description,
                             "deviceToken": fbToken,
                             "location": ""});
    const apiResponse = await fetch(userNotifs, {
         method: "POST",
         headers: {
            "Authorization": `Bearer ${session.accessToken.jwtToken}`,
            'Content-Type': 'application/json',
         },
         body: `${requestData}`,
    });
    if (apiResponse.status > 202) {
       console.log("Error enabling notifications.");
    }
  }

  async function getFirebaseToken(authToken,nl3ID){
      const fbTokenRC = await getFBToken();
      const fbToken = Store.getState().fbToken.currentToken;
      if (fbTokenRC === 1) {
          //permission granted for FB notifications
          updateNotifications(authToken, fbToken, nl3ID);
          handleFirebaseListener();
          setConfigFB(true);
      } else if (fbTokenRC === 2) {
          //permission denied for FB messaging
          setConfigFB(true);
      }
  }

  async function getAuthToken() {
      const session = await cognito.getSession();
      const token = session.accessToken.jwtToken;
      return (token);
  }

  useEffect(() => {
    async function getFBSupported() {
       const rc = await isSupported();
       setFBSupported(rc);
    }
    getFBSupported();
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000))
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [getRemainingTime, isPrompted, configFB])

  return (
    <div className="App">
      <IndexRouters />
      <div className='nl3IdleWindow' style={{ display: open ? 'block' : 'none' }}>
          <div className="nl3IdleWindow_content">
             <div align="center" className="nl3IdleWindow_title">
                   <img height="75" width="75" src={NL3Icon} alt=""/>
                   <br/>
                   <p>Inactive Session</p>
             </div>
             <div align="center" className="nl3IdleWindow_text">
             <p>You will be logged out in {remaining} seconds</p>
             </div>
             <div align="center">
                <br/>
                <button className="actnButton" onClick={handleStillHere}>Stay Logged In</button>
             </div>
          </div>
      </div>
      <div className="nl3Window_firebase" style={{ display: openFB ? 'block' : 'none'}}>
         <div className="nl3Window_content">
            <div className="nl3Window_title">
               <p align="center">{title}</p>
            </div>
            <span className="nl3Window_text" style={{"whiteSpace":"pre-wrap"}}>{body}</span>
            <br/><br/>
            <div align="center">
                { button1 !== "" &&
                    <button className="notifButton" dataid={button1} onClick={handleButton1}>{button1}</button>
                }
                { button2 !== "" &&
                    <>&nbsp;&nbsp;&nbsp;&nbsp;
                    <button className="notifButton" dataid={button2} onClick={handleButton2}>{button2}</button>
                    </>
                }
            </div>
         </div>
      </div>
      <ToastContainer/>
    </div>
  );
}

export default App;
