export default function LoginType(state = [], action) {
  switch (action.type) {
    case 'EXTERNAL':
      state = "EXTERNAL"
      return state 
    case 'REGULAR':
      state = "REGULAR"
      return state 
    default:
      return state
  }
}

