import React, { Component } from 'react'
import {Row,Col} from 'react-bootstrap'
import {Prompt} from 'react-router-dom';
import Card from '../../components/Card'
import Store from '../../store'
import SlidingPane from 'react-sliding-pane';
import "../../assets/react-sliding-pane.css";
import * as cognito from '../../libs/cognito';
import * as nl3Utils from '../../libs/nl3Utils';

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;

class CompanyEdit extends Component {

   constructor(props) {
      super(props)
      this.state= {
         email : "",
         company : "",
         phone : "",
         address : "",
         city : "",
         state : "",
         zipcode : "",
         location : "",
         companyToken : "",
         aws_tenant_id : "",
         aws_access_token : "",
         azure_tenant_id : "",
         admins: [],
         statusCode : 0,
         isAdminOpen : false,
         isDirty : false,
      };
      // Company edit page
      Store.dispatch({type: 'COMPANY'});
   }

   async componentDidMount() {
       try {
          // Get the access token
          const session = await cognito.getSession();
          this.setState({companyToken : session.accessToken.jwtToken});

          const companyDetailsByIdUrl = nl3ApiBase + `/companies/mycompany`;
          const apiResponse = await fetch(companyDetailsByIdUrl, {
               method: 'GET',
               headers: {
                 'Authorization': `Bearer ${this.state.companyToken}`,
               },
             });

          if (apiResponse.status === 200) {
              const company_data = await apiResponse.json();
              this.setState({email : company_data.admins[0].user.email,
                             company : company_data.company,
                             phone : company_data.phone,
                             address : company_data.address,
                             city : company_data.city,
                             state : company_data.state,
                             zipcode : company_data.zipcode,
                             location : company_data.location,
                             aws_tenant_id : company_data.aws_tenant_id,
                             aws_access_token : company_data.aws_access_token,
                             azure_tenant_id : company_data.azure_tenant_id,
                             admins : company_data.admins});
          }
          this.setState({statusCode : apiResponse.status});
       } catch (e) {
          console.log(e.message);
          this.props.history.push('/');
          return;
       }
  }

   handleBack() {
     this.props.history.push('/mydomains');
   }

   checkPhoneNumber() {
       // eslint-disable-next-line
       var regEx = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
       if (this.state.phone.match(regEx)) {
           return true;
       } else {
           return false;
       }
   }

   async updateDatabase() {
        try {
           const companyData = JSON.stringify({"company": `${this.state.company}`,
                                               "address": `${this.state.address}`,
                                               "city": `${this.state.city}`,
                                               "state": `${this.state.state}`,
                                               "zipcode": `${this.state.zipcode}`,
                                               "location": `${this.state.location}`,
                                               "title": `${this.state.admins[0].title}`,
                                               "phone": `${this.state.phone}`,
                                               "aws_tenant_id": `${this.state.aws_tenant_id}`,
                                               "aws_access_token": `${this.state.aws_access_token}`,
                                               "azure_tenant_id": `${this.state.azure_tenant_id}`});

           const companyDetailsByIdUrl = nl3ApiBase + `/companies/mycompany`;
           const apiResponse = await fetch(companyDetailsByIdUrl, {
                method: 'PATCH',
                headers: {
                  'Authorization': `Bearer ${this.state.companyToken}`,
                  'Content-Type': 'application/json',
                },
                body: `${companyData}`,
              });

           if (apiResponse.status === 200) {
               const company_data = await apiResponse.json();
               this.setState({email : company_data.admins[0].user.email,
                             company : company_data.company,
                             phone : company_data.phone,
                             address : company_data.address,
                             city : company_data.city,
                             state : company_data.state,
                             zipcode : company_data.zipcode,
                             location : company_data.location,
                             aws_tenant_id : company_data.aws_tenant_id,
                             aws_access_token : company_data.aws_access_token,
                             azure_tenant_id : company_data.azure_tenant_id,
                             admins : company_data.admins,
                             isDirty: false});
           }
           this.setState({statusCode : apiResponse.status});
           return(apiResponse.status);
        } catch (e) {
           console.log(e.message);
        }
   }

   handleUpdateData(e) {
      e.preventDefault();
      if (this.checkPhoneNumber()) {
          this.updateDatabase()
             .then(status => {
                    if (status === 200) {
                        nl3Utils.nl3Toast("Successfully updated company information",true,"success");
                    } else {
                        nl3Utils.nl3Toast("Error! company information was not updated.",true,"error");
                    }
                  })
             .catch(e => {
                    console.log(e.message);
                  });
     } else {
         nl3Utils.nl3Toast("Error! Phone number format incorrect.",true,"error");
     }
   }

   handleAddressChange(e) {
     this.setState({address: e.target.value, isDirty: true});
   }

   handleCityChange(e) {
     this.setState({city: e.target.value, isDirty: true});
   }

   handleZipcodeChange(e) {
     this.setState({zipcode: e.target.value, isDirty: true});
   }

   handleStateChange(e) {
     this.setState({state: e.target.value, isDirty: true});
   }

   handleLocationChange(e) {
     this.setState({location: e.target.value, isDirty: true});
   }

   handlePhoneChange(e) {
     this.setState({phone: e.target.value, isDirty: true});
   }

   showPopup() {
      const modal = document.querySelector(".nl3Window")
      const closeBtn = document.querySelector(".nl3Window_close")
      modal.style.display = "block";
      closeBtn.addEventListener("click", () => {
        modal.style.display = "none";
      });
   }

   handleCancelSub(e) {
     e.preventDefault();
     this.showPopup();
   }

   renderAdmins() {
      return this.state.admins.map((item, index) => {
         const { title, user } = item //destructuring
         return (
            <tr key={index}>
              <td className="blackText">{user.first_name} {user.last_name}</td>
              <td className="blackText">{user.email}</td>
              <td className="blackText">{user.phone}</td>
              <td className="blackText">{title}</td>
            </tr>
         )
      })
   }

  render () {
      if (this.state.statusCode === 0) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
               </button>
            </div>
          </>
        )
      } else if (this.state.statusCode > 200) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Error Retrieving Company Data. Please Contact support.
               </button>
            </div>
          </>
        )
      } else {
        return (
            <>
              <Prompt message="Unsaved Changes! Are you sure you want leave?" when={this.state.isDirty}/>
              <div>
                  <Row>
                     <Col xl="6" lg="5">
                        <Card className="whiteCard">
                           <Card.Header className="d-flex justify-content-between">
                              <div className="header-title">
                                 <h4 className="card-title">Modify Company Information</h4>
                              </div>
                           </Card.Header>
                           <Card.Body>
                              <div className="new-user-info">
                                 <h2>{this.state.company}</h2>
                                 <hr/>
                                 <div>
                                      <label className="blackText" style={{"paddingRight":"5em"}}>Address:&nbsp;	</label>
                                      <input type="text" style={{width:"320px"}} value={this.state.address} onChange={this.handleAddressChange.bind(this)} /><br/>
                                      <label className="blackText" style={{"paddingRight":"7em"}}>City:&nbsp;</label>
                                      <input type="text" style={{width:"320px"}} value={this.state.city} onChange={this.handleCityChange.bind(this)} /><br/>
                                      <label className="blackText" style={{"paddingRight":"1.30em"}}>Zip/Postal Code:&nbsp;</label>
                                      <input type="text" style={{width:"320px"}} value={this.state.zipcode} onChange={this.handleZipcodeChange.bind(this)} /><br/>
                                      <label className="blackText" style={{"paddingRight":"1.90em"}}>State/Province:&nbsp;</label>
                                      <input type="text" style={{width:"320px"}} value={this.state.state} onChange={this.handleStateChange.bind(this)} /><br/>
                                      <label className="blackText" style={{"paddingRight":"5em"}}>Country:&nbsp;</label>
                                      <input type="text" style={{width:"320px"}} value={this.state.location} onChange={this.handleLocationChange.bind(this)} /><br/>
                                      <label className="blackText" style={{"paddingRight":"1.75em"}}>Phone Number:&nbsp;</label>
                                      <input type="text" style={{width:"320px"}} value={this.state.phone} onChange={this.handlePhoneChange.bind(this)} /><br/>
                                 </div>
                              </div>
                           </Card.Body>
                        </Card>
                     </Col>
                     <Col xl="4" lg="3">
                        <Card className="whiteCard">
                           <Card.Header className="d-flex justify-content-between">
                              <div className="header-title">
                                 <h4 className="card-title">Settings</h4>
                              </div>
                           </Card.Header>
                           <Card.Body>
                              <div>
                                 <button className="clearButton" onClick={this.handleCancelSub.bind(this)}>Cancel Subscription</button>
                              </div>
                           </Card.Body>
                        </Card>
                     </Col>
                  </Row>
                  <Row>
                     <div align="left">
                         <button className="actnButton" style={{width:"180px", marginRight:"200px"}}
                               onClick={() => this.setState({isAdminOpen: true})}>Show Administrators</button>
                         <button className="formButton" onClick={this.handleUpdateData.bind(this)}>Save</button>&nbsp;&nbsp;&nbsp;
                         <button className="formButton" onClick={this.handleBack.bind(this)}>Return</button>
                     </div>
                  </Row>
                  <Row>
                     <div>
                       <SlidingPane
                           isOpen={this.state.isAdminOpen}
                           title="Company Administrators"
                           from="right"
                           width="80%"
                           onRequestClose={() => this.setState({isAdminOpen: false})}>
                           <div className="card-body p-0">
                                <div className="table-responsive mt-4">
                                  <div className="table-wrapper-scroll-y admin-table">
                                    <table id="history-table" className="table table-info mb-0" role="grid">
                                        <thead className="thead-dark">
                                           <th>Name</th>
                                           <th>Email</th>
                                           <th>Phone</th>
                                           <th>Title</th>
                                        </thead>
                                        <tbody>
                                           {this.renderAdmins()}
                                        </tbody>
                                    </table>
                                  </div>
                                </div>
                           </div>
                       </SlidingPane>
                     </div>
                  </Row>
                  {/* The following code creates the popup */}
                      <div className="nl3Window">
                         <div className="nl3Window_content">
                            <div className="nl3Window_title">
                               <span className="nl3Window_close">&times;</span>
                               <p>Cancel Next Level3 Subscription</p>
                            </div>
                            <div className="nl3Window_text">
                               We are sorry to see you go! Before cancelling Next Level3 account protection, please review the
                               <a href="https://www.nextlevel3.com/terms-of-service" target="_blank" rel="noreferrer"> Terms of Service. </a>
                               If you still wish to unsubscribe from Next Level3 account protection, please email our team at
                               <a href="mailto:nl3@nextlevel3.com?subject=Cancel Next Level3 Subscription"> NL3 Support.</a>
                            </div>
                         </div>
                      </div>
                  {/* End of popup code */}
               </div>
            </>
        )
      }
  }
}
export default CompanyEdit
