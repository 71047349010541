export default function GroupType(state = [], action) {
  switch (action.type) {
    case 'GROUP':
      state = action.text
      return state 
    default:
      return state
  }
}

