import React, { Component } from 'react';
import { Row,Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Store from '../../store';
import Switch from 'react-input-switch';
import ReactTooltip from 'react-tooltip';
import * as cognito from "../../libs/cognito";
import * as nl3Utils from "../../libs/nl3Utils";

import { IoMdRefreshCircle } from 'react-icons/io'
import { ImLock, ImUnlocked } from 'react-icons/im'

import "../../assets/nl3.css"
  
const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;

var updateTimer = null;

class Accounts extends Component {

   constructor(props) {
     super(props)
     this.handleUpdateTimeout = this.handleUpdateTimeout.bind(this);
     this.state= { 
        allowed_total: 0,
        blocked_total: 0,
        statusCode: 0,
        autoLockMinutes : 0,
        userToken : "",
        accounts: [ ],
     }
     // This is the user dashboard page
     Store.dispatch({type: 'USER'});
   }

   async componentDidMount() {
      let cognitoUserSession = null;
      try {
          // Get the access token
          cognitoUserSession = await cognito.getSession();
          if (cognitoUserSession === null) {
             console.log("Error: cognitoUserSession is null");
             return;
          }
      } catch (e) {
          console.log(e.message);
          this.props.history.push('/');
          return;
      }
      this.setState({userToken : cognitoUserSession.accessToken.jwtToken});

      if (cognitoUserSession !== null) {
         this.getUserData();
         window.addEventListener("click", this.handleNotificationClicks);
      }
   }

   componentWillUnmount() {
      clearTimeout(updateTimer);
   }

   setUpdateTimer() {
       // 1000 * 60 = 1 minute, * autoLockMinute, + 2 seconds
       const updateTimerLength = (((60 * 1000) * this.state.autoLockMinutes) + 2000);
       clearTimeout(updateTimer);
       updateTimer = setTimeout(this.handleUpdateTimeout,updateTimerLength);
   }

   handleUpdateTimeout() {
       this.getUserData();
   }

   async getUserData() {
        if (this.state.userToken === "") return;
        try {
            //Get User Details from Database
            const userDetailsByIdUrl = nl3ApiBase + `/users/userinfo`;
            const apiResponse = await fetch(userDetailsByIdUrl, {
                method: "GET",
                headers: {
                "Authorization": `Bearer ${this.state.userToken}`
                },
            });
            if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
               const user = await apiResponse.json();
               cognito.setCurrentUserEmail(user.email);
               this.setState({statusCode: apiResponse.status,
                              allowed_total : user.aggregations.totalAllowed,
                              blocked_total : user.aggregations.totalBlocked,
                              autoLockMinutes : user.autoLockMinutes,
                              accounts: user.accounts});
               if (!user.isEULAAccepted) {
                   this.showEULAPopup();
               } else {
                   if (!user.isHelpViewed) {
                       this.handleShowGuide("Welcome!", "We are pleased to announce our new product feature - Data Breach Protection. Built from the ground up to provide you with best that technology can provide, we now automatically lock your account if it has been detected in a data breach.", "Got it. Next", 1);
                   } else {
                      if (user.promptForRecoveryKey) {
                         this.showRecoveryKey();
                      }
                   }
               }
            } else {
               this.setState({statusCode: apiResponse.status});
               await this.sleep(100);
            }
        } catch (e) {
            console.log(e.message);
        }
   }

   async lockUnlockAccount(index, action) {       
        const account = this.state.accounts[index];
        const lockUnlockEndpoint = (action ? nl3ApiBase + '/users/lock' : nl3ApiBase + '/users/unlock');
        const apiResponse = await fetch(lockUnlockEndpoint, {
            method: 'POST',
            headers: {
            Authorization: `Bearer ${this.state.userToken}`,
            "Content-Type": 'application/json'
            },
            body: `{ "application": "${account.application}", "domainName": "${account.domain.name}", "userAccountId": "${account.userAccountId}"}`
        }); 
        return apiResponse.status;
   }

   async lockUnlockAllAccounts(action) {
        const lockUnlockEndpoint = (action ? nl3ApiBase + '/users/lockAll' : nl3ApiBase + '/users/unlockAll');
        const apiResponse = await fetch(lockUnlockEndpoint, {
            method: 'POST',
            headers: {
            Authorization: `Bearer ${this.state.userToken}`,
            "Content-Type": 'application/json'
            },
        });
        return apiResponse.status;
   }

   async autoRelockUpdate(id,status) {
        const relockAccount = nl3ApiBase + `/users/accounts/${id}`;
        const apiResponse = await fetch(relockAccount, {
            method: 'PATCH',
            headers: {
               Authorization: `Bearer ${this.state.userToken}`,
               "Content-Type": 'application/json'
            },
            body: `{ "autoRelockAllowed": ${status}}`
        });
        return apiResponse.status;
   }

   handleRelockChange(e, index, id) {
      var tempAccounts = this.state.accounts;
      this.autoRelockUpdate(id, e)
        .then(status => {
               if (status === 200) {
                   tempAccounts[index].autoRelockAllowed = e;
                   this.setState({accounts : tempAccounts});
                   nl3Utils.nl3Toast("Successfully updated Auto ReLock status!",true,"success");
               } else {
                   nl3Utils.nl3Toast("Error! Auto ReLock status was not updated.",true,"error");
               }
             })
        .catch(e => {
               console.log(e.message);
             });
   }

   handleAction(e, index, action) {
      e.preventDefault();
      var tempAccounts = this.state.accounts;
      this.lockUnlockAccount(index, action)
        .then(status => {
               if (status === 200) {
                   tempAccounts[index].lockStatus = action;
                   this.setState({accounts : tempAccounts});
                   nl3Utils.nl3Toast("Successfully updated lock status!",true,"success");
               } else {
                   nl3Utils.nl3Toast("Error! Lock status was not updated.",true,"error");
               }
             })
        .then(status => {
               // If changing to unlocked all and auto-relock enabled, set update timer
               if (!action) {
                   if (this.state.autoUnlockMinutes !== 0) {
                      this.setUpdateTimer();
                   }
               }
             })
        .catch(e => {
               console.log(e.message);
             });
   }

   handleUnlockAll(e) {
        e.preventDefault();
        var tempAccounts = this.state.accounts;
        this.lockUnlockAllAccounts(0)
              .then(status => {
                     if (status === 200) {
                         for (var i=0; i < tempAccounts.length; i++) {
                            tempAccounts[i].lockStatus = 0;
                         }
                         this.setState({accounts : tempAccounts});
                         nl3Utils.nl3Toast("Successfully unlocked all accounts!",true,"success");
                     } else {
                         nl3Utils.nl3Toast("Error! Failed to unlock all accounts.",true,"error");
                     }
                   })
              .then(status => {
                     // If auto-relock enabled, set update timer
                     if (this.state.autoUnlockMinutes !== 0) {
                        this.setUpdateTimer();
                     }
                   })
              .catch(e => {
                     console.log(e.message);
                   });
   }

   handleLockAll(e) {
        e.preventDefault();
        clearTimeout(updateTimer);
        var tempAccounts = this.state.accounts;
        this.lockUnlockAllAccounts(1)
              .then(status => {
                     if (status === 200) {
                         for (var i=0; i < tempAccounts.length; i++) {
                            tempAccounts[i].lockStatus = 1;
                         }
                         this.setState({accounts : tempAccounts});
                         nl3Utils.nl3Toast("Successfully locked all accounts!",true,"success");
                     } else {
                         nl3Utils.nl3Toast("Error! Failed to lock all accounts.",true,"error");
                     }
                   })
              .catch(e => {
                     console.log(e.message);
                   });
   }

   handleRefresh() {
      this.getUserData();
   }

   renderTableData() {
      return this.state.accounts.map((account, index) => {
         const { id, userAccountId, domain, lockStatus, userAccountAllowedCount, userAccountBlockedCount, autoRelockAllowed } = account //destructuring
         const statusImg = (lockStatus ? <ImLock fill="red" style={{fontSize:"2.4rem"}} onClick={(e) => this.handleAction(e,index,0)}/> : <ImUnlocked fill="green" style={{fontSize:"2.4rem"}} onClick={(e) => this.handleAction(e,index,1)}/>);
         const statusTip = (lockStatus) ? "This account is <b>locked.</b><br/><b>Click the icon to Unlock the account.</b>" : "This account is <b>unlocked.</b><br/><b>Click the icon to Lock the account.</b>";
         var login_count = (isNaN(userAccountAllowedCount) ? 0 : userAccountAllowedCount);
         var blocked_count = (isNaN(userAccountBlockedCount) ? 0 : userAccountBlockedCount);

         return (
             <tr key={id}>
               <td>
                 <div className="d-flex align-items-center">
                   <h6>{domain.name}<br/></h6>
                 </div>
                 <div>
                   <span>{domain.url}<br/>Account ID: {userAccountId}</span>
                 </div>
               </td>
               <td><div className="d-flex align-items-center"
                           data-tip={statusTip}
                           data-for="userDashTips" data-place="top">{statusImg}</div></td>
               <td>{login_count}</td>
               <td>{blocked_count}</td>
               <td>
                  <div
                     data-tip="<b>Enabling</b> this option means, <i>this account</i> will<br/>
                               follow the Security Settings in the User Profile.<br/><br/>
                               <b>Disabling</b> this option means, <i>this account</i> will<br/>
                               ignore the Security Settings in the User Profile.<br/>"
                     data-for="userDashTips" data-place="top">
                        <Switch value={autoRelockAllowed} on={true} off={false} onChange={(e) => this.handleRelockChange(e, index, id)}/>
                  </div>
               </td>
             </tr>
         )
      })
   }

   render() {
     //User Dashboard
     if (this.state.statusCode === 0) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
               </button>
            </div>
          </>
        )
     } else if (this.state.statusCode > 201) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Error Retrieving User Data. Please Contact support.
               </button>
            </div>
          </>
        )
     } else {
        if (this.state.accounts.length === 0) {
          return (
            <>
               <Row>
                 <Col md="12" lg="12">
                   <Row className="row-cols-1">
                     <div className="whiteCard card overflow-hidden">
                         <div className="card-header d-flex justify-content-between flex-wrap">
                             <div className="header-title">
                                 <h4 className="card-title">No Accounts registered for NL3 Protection</h4>
                             </div>
                         </div>
                         <br/>
                     </div>
                   </Row>
                 </Col>
               </Row>
            </>
          )
        } else {
          return (
            <>
               <Row>
                   <Col md="12" lg="12">
                     <Row className="row-cols-1">
                       <div className="whiteCard card overflow-hidden">
                           <div className="card-header d-flex justify-content-between flex-wrap">
                               <div className="header-title">
                                   <h4 className="card-title">Accounts</h4>
                                   <br></br>
                               </div>
                               <div className="d-flex align-items-center align-self-center">
                                   <div className="d-flex align-items-center text-primary">
                                       <div className="ms-2">
                                          <Link to="#" className="nav-link" role="button" onClick={(e) => this.handleRefresh(e)}
                                             data-tip="Refresh Accounts"
                                             data-for="userDashTips" data-place="top">
                                             <span style={{color:"#1BBEE9"}}>Refresh<br/>
                                                <IoMdRefreshCircle fill="grey" style={{fontSize:"2.4rem", marginTop:"18px"}} />
                                             </span>
                                          </Link>
                                       </div>
                                   </div>
                                   <div className="d-flex align-items-center text-primary">
                                       <div className="ms-2">
                                          <Link to="#" className="nav-link" role="button" onClick={(e) => this.handleLockAll(e)}
                                             data-tip="<b>Click icon</b> to Lock all of<br/>your protected accounts"
                                             data-for="userDashTips" data-place="top">
                                             <span style={{color:"#1BBEE9"}}>Lock All<br/>
                                               <ImLock fill="red" style={{fontSize:"2.4rem", marginTop:"18px"}} />
                                             </span>
                                          </Link>
                                       </div>
                                   </div>
                                   <div className="d-flex align-items-center ms-3 text-info">
                                       <div className="ms-2">
                                          <Link to="#" className="nav-link" role="button" onClick={(e) => this.handleUnlockAll(e)}
                                             data-tip="<b>Click icon</b> to Unlock all of<br/>your protected accounts"
                                             data-for="userDashTips" data-place="top">
                                             <span style={{color:"#1BBEE9"}}>Unlock All<br/>
                                               <ImUnlocked fill="green" style={{fontSize:"2.4rem", marginTop:"18px"}} />
                                             </span>
                                          </Link>
                                         </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div className="card-body p-0">
                            <div className="table-responsive mt-4">
                              <div className="table-wrapper-scroll-y accounts-table">
                                  <table id="acct-table" className="table table-primary mb-0" role="grid">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th>Account<br/></th>
                                            <th data-tip="Current status of the account.<br/>
                                                          <b>Click the icon</b> to change the account status."
                                                data-for="userDashTips" data-place="top">Current<br/>Status</th>
                                            <th data-tip="Number of login attempts<br/>
                                                          with the account <b>unlocked.</b>"
                                                data-for="userDashTips" data-place="top">Logins<br/>Allowed</th>
                                            <th data-tip="Number of login attempts<br/>
                                                          with the account <b>locked.</b>"
                                                data-for="userDashTips" data-place="top">logins<br/>Blocked</th>
                                            <th data-tip="Determines if the account<br/>
                                                          follows the Security Setting<br/>
                                                          in the User Profile."
                                               data-for="userDashTips" data-place="top">Auto<br/>Relock</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                       {this.renderTableData()}
                                    </tbody>
                                </table>
                              </div>
                            </div>
                       </div>
                     </Row>
                     <ReactTooltip className="nl3Tooltip" id="userDashTips" html={true}/>
                   </Col>
               </Row>
           </>
          )
        }
     }
   }
}

export default Accounts
