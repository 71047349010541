import React, { Component } from 'react'
import { Row,Col } from 'react-bootstrap'
import Select from 'react-select'
import Store from '../../store'
import { Map, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react'
import moment from 'moment-timezone'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';
import FileSaver from 'file-saver';
import * as cognito from '../../libs/cognito'
import * as nl3Utils from "../../libs/nl3Utils";

//swiper
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/components/navigation/navigation.scss';

//NL3 Styling
import "../../assets/nl3.css"
//import mapIcon from '../../assets/images/NL3-20x20.png';

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;
const filterOptions =  [
      {value: '1', label: 'Policy Events' },
      {value: '2', label: 'Account Status'},
      {value: '3', label: 'Lock Status'}
]

const customStyles = {
  control: base => ({
    ...base,
    width: "400px",
  }),
  menu: base => ({
    ...base,
    width: "400px",
  })
};

class Index extends Component {
   constructor(props) {
     super(props)
     this.handleSelect = this.handleSelect.bind(this)
     this.handleNotificationClicks = this.handleNotificationClicks.bind(this);
     this.state= {
        statusCode: 0,
        userToken: "",
        showInfoWindow: true,
        selectedElement: null,
        activeMarker: null,
        startDate: null,
        endDate: null,
        currentPage: 1,
        totalPages: 1,
        totalItems: 0,
        itemsPerPage: 0,
        pageSize: 5000,
        allEvents: [],
        stats: [],
        policyStats: [],
        accountStats: [],
        lockStats: [],
        selectedOptions: null,
        allowed_total: 0,
        blocked_total: 0,
        blocked_today: 0,
     }
     // This is the user dashboard page
     Store.dispatch({type: 'USER'});
   }

   async componentDidMount() {
       try {
          // Get the access token
          let now = new Date();
          let currDate = moment(moment.utc(now).toDate()).format("YYYY-MM-DD");
          let startingDate = moment(moment.utc(now).subtract(7,"days").toDate()).format("YYYY-MM-DD");
          const cognitoUserSession = await cognito.getSession();
          this.setState({startDate: startingDate, endDate: currDate,
                         userToken : cognitoUserSession.accessToken.jwtToken});

          let authToken = localStorage.getItem("auth-token");
          if ((authToken === undefined) || (authToken === null)) {
             authToken = "";
          }
          if (authToken !== "") {
             await this.enableUser(authToken)
             .then (() => {
                localStorage.removeItem("auth-token");
             })
          }

          if (cognitoUserSession !== null) {
             window.addEventListener("click", this.handleNotificationClicks);
             await this.getuserHistoryData(startingDate, currDate)
             .then(() => { this.getUserData()});
          }

       } catch (e) {
          console.log(e.message);
          this.props.history.push('/');
          return;
       }
   }

   componentWillUnmount() {
      window.removeEventListener("click", this.handleNotificationClicks);
   }

   async handleNotificationClicks(e, data) {
      e.preventDefault();
      if (e.target.classList[0] === "notifButton") {
         await this.sleep(1000)
         .then(() => {
            this.getUserData(this.state.startDate, this.state.endDate);
         });
      }
   }

   sleep (milliseconds) {
      return new Promise(resolve => setTimeout(resolve, milliseconds))
   }

   async enableUser(authToken) {
      //Enable User
      const enableUserUrl = nl3ApiBase.replace("internal.", "") + `/enableUser`;
      const apiResponse = await fetch(enableUserUrl, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${this.state.userToken}`,
          "x-nl3-authorization-token": `${authToken}`
        },
      });
      if (apiResponse.status >= 201) {
         nl3Utils.nl3Toast('Account enablement failed! Log out and re-try account enablement from the application website again. This time click on "Sign in with an existing account" on the enablement screen. If the error persists, contact support.',false,'error');
      }
   }

   async getUserData() {
     if (this.state.userToken === "") return;
     try {
         //Get User Details from Database
         const userDetailsByIdUrl = nl3ApiBase + `/users/userinfo`;
         const apiResponse = await fetch(userDetailsByIdUrl, {
             method: "GET",
             headers: {
             "Authorization": `Bearer ${this.state.userToken}`
             },
         });
         if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
            const user = await apiResponse.json();
            cognito.setCurrentUserEmail(user.email);
            this.setState({allowed_total : user.aggregations.totalAllowed,
                           blocked_today : user.aggregations.todayBlocked,
                           blocked_total : user.aggregations.totalBlocked});
            if (!user.isHelpViewed) {
                this.handleShowGuide("Welcome!", "We are pleased to announce our new product feature - Data Breach Protection. Built from the ground up to provide you with best that technology can provide, we now automatically lock your account if it has been detected in a data breach.", "Got it. Next", 1);
            } else {
               if (user.promptForRecoveryKey) {
                  this.showRecoveryKey();
               }
            }
         } else {
            this.setState({statusCode: apiResponse.status});
            await this.sleep(100);
         }
     } catch (e) {
         console.log(e.message);
     }
   }

   async getuserHistoryData(startDate, endDate) {
      // try to get the user history
      var userHistoryUrl = nl3ApiBase;
      var queryStartDate = moment(startDate).format("yyyy-MM-DDTHH:mm:ss.SSS-00:00");
      var queryEndDate = moment(endDate).format("yyyy-MM-DDT23:59:59.999-00:00");
      userHistoryUrl += `/users/history?filterAnd=requestDate lte ${queryEndDate} and requestDate gte ${queryStartDate}&orderBy=requestDate%3Adesc&page=${this.state.currentPage}&pageSize=${this.state.pageSize}`;
      const apiResponse = await fetch(userHistoryUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.state.userToken}`,
        },
      });

      if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
         const records = await apiResponse.json();
         var policyEvents = [];
         var accountEvents = [];
         var lockEvents = [];
         var totalRecs = records.items.length
         for (let i=0; i < totalRecs; i++) {
             if ((records.items[i].location.coordinates[0] === 0) && (records.items[i].location.coordinates[1] === 0)) {
                continue;
             }
             if (records.items[i].requestType === 'Company') {
                 policyEvents.push(records.items[i]);
             } else if ((records.items[i].requestType === 'LockUnlockUserAccount') ||
                 (records.items[i].requestType === 'LockUnlockAllUserAccount')) {
                 accountEvents.push(records.items[i]);
             } else if ((records.items[i].requestType === 'AccountProtectionCheckRequest') ||
                 (records.items[i].requestType === 'UnlockAccountRequestRejected')) {
                 lockEvents.push(records.items[i]);
             }
         }
         this.setState({statusCode : apiResponse.status, stats: [...policyEvents, ...accountEvents, ...lockEvents],
                        allEvents: [...policyEvents, ...accountEvents, ...lockEvents],
                        policyStats: policyEvents, accountStats: accountEvents, lockStats: lockEvents,
                        currentPage: records.currentPage, totalPages: records.totalPages,
                        totalItems: records.totalItems, itemsPerPage: records.itemsPerPage,
                        pageSize: records.pageSize,
                        startDate: moment(startDate).toDate(),
                        endDate: moment(endDate).toDate()});
      } else {
         this.setState({statusCode : apiResponse.status});
      }
      return apiResponse.status;
   }

   handleShowGuide(title, text, button, step) {
       this.setState({guideTitle: title, guideText: text, guideButton: button, guideStep: step});
       const guideWindow = document.querySelector(".nl3Window_guide")
       guideWindow.style.display = "block";
   }

   handleGuide() {
      if (this.state.guideStep === 1) {
         this.handleShowGuide("Helpful Tips","Immediately Lock or Unlock all of your protected accounts with a single click.\nYou can also lock or unlock individual accounts with a single click.","Next",2);
      }
      if (this.state.guideStep === 2) {
         this.handleShowGuide("Automatic Relocking!","When enabled, automatic relocking sets your account back to locked for you.","Next",3);
      }
      if (this.state.guideStep === 3) {
         this.handleShowGuide("Access more settings","Control more settings like 'Auto Relock' timing, data breach protection enablement and more in your Next Level3 account settings.","Next",4);
      }
      if (this.state.guideStep === 4) {
         this.handleShowGuide("Account Recovery Key","Download your recovery key in order to reset your account in case your authorized devices are lost, stolen or compromised. Store this recovery key somewhere safe and not on your authorized devices.","Download",5);
      }
      if (this.state.guideStep >= 5) {
         const guideWindow = document.querySelector(".nl3Window_guide")
         guideWindow.style.display = "none";
         this.recoveryKey();
         // update setting in the database
         this.updateHelpSetting();
      }
   }

   showRecoveryKey() {
      this.handleShowGuide("Account Recovery Key","Download your recovery key in order to reset your account in case your authorized devices are lost, stolen or compromised. Store this recovery key somewhere safe and not on your authorized devices.","Download",5);
   }

   async getRecoveryKey() {
      // try to get the device list
      let recoveryKey = "";
      var keyUrl = nl3ApiBase + `/users/recoveryKey`;
      const apiResponse = await fetch(keyUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.state.userToken}`,
        },
      });

      if (apiResponse.status === 200) {
         const keyData = await apiResponse.json();
         recoveryKey = keyData.keyString;
      }
      return recoveryKey;
   }

   recoveryKey() {
      this.getRecoveryKey()
      .then((recoveryKey) => {
         if (recoveryKey === "") {
            nl3Utils.nl3Toast('Error retrieving the recovery key. Contact Support.',true,'error');
         } else {
            const output =  recoveryKey;
            const blob = new Blob([output]);
            const fileName = cognito.getCurrentUser().username + '-nl3RecoveryKey.txt'
            FileSaver.saveAs(blob, fileName);
            nl3Utils.nl3Toast('Account Recovery key downloaded',true,'success');
            this.updatePromptForRecoveryKeySetting();
         }
      } );
   }

   async updateHelpSetting() {
        const userAccount = nl3ApiBase + `/users/userinfo`;
        const apiResponse = await fetch(userAccount, {
            method: 'PATCH',
            headers: {
               Authorization: `Bearer ${this.state.userToken}`,
               "Content-Type": 'application/json'
            },
            body: `{ "isHelpViewed": true}`
        });
        return apiResponse.status;
   }

   async updatePromptForRecoveryKeySetting() {
        const userAccount = nl3ApiBase + `/users/userinfo`;
        const apiResponse = await fetch(userAccount, {
            method: 'PATCH',
            headers: {
               Authorization: `Bearer ${this.state.userToken}`,
               "Content-Type": 'application/json'
            },
            body: `{ "promptForRecoveryKey": false}`
        });
        return apiResponse.status;
   }

   handleMarkerClick(props,marker) {
     this.setState({selectedElement: props.info, activeMarker: marker});
   }

   handleSelect(data) {
     let tmp = [];
     for (let i=0; i < data.length; i++) {
         if (data[i].value === '1') {
            tmp = [...tmp, ...this.state.policyStats];
         } else if (data[i].value === '2') {
            tmp = [...tmp, ...this.state.accountStats];
         } else if (data[i].value === '3') {
            tmp = [...tmp, ...this.state.lockStats];
         }
     }
     if (data.length === 0) {
         tmp = this.state.allEvents;
     }
     this.setState({stats: tmp, selectedOptions: data});
   }

   handleStartDateChange = e => {
     var newStartDate = moment(e).toDate();
     if (e !== null) {
        if (this.state.endDate !== null){
           this.setState({statusCode: 0});
           this.getuserHistoryData(newStartDate, this.state.endDate);
        }
     }
   }

   handleEndDateChange = e => {
     var newEndDate = moment(e).toDate();
     if (e !== null) {
        if (this.state.startDate !== null){
           this.setState({statusCode: 0});
           this.getuserHistoryData(this.state.startDate, newEndDate);
        }
     }
   }

   showInfoWindowContent() {
      if (this.state.selectedElement.requestType === 'Company') {
          return (
             <div style={{ color: "black", background: "white" }}>
                  <b>Time:</b> {moment.utc(this.state.selectedElement.requestDate).local().format("MM/DD/YY h:mm a")}<br/><br/>
                  <b>Action:</b> {this.state.selectedElement.actionType.replace(/_/g,' ')}<br/><br/>
                  <b>UserId:</b> {this.state.selectedElement.userId}<br/><br/>
                  <b>Details:</b> {this.state.selectedElement.message.substr(0,this.state.selectedElement.message.length-33)}
             </div>
          )
      } else if ((this.state.selectedElement.requestType === 'LockUnlockUserAccount') ||
                 (this.state.selectedElement.requestType === 'LockUnlockAllUserAccount')) {
          return (
             <div style={{ color: "black", background: "white" }}>
                  <b>Time:</b> {moment.utc(this.state.selectedElement.requestDate).local().format("MM/DD/YY h:mm a")}<br/><br/>
                  <b>Action:</b> {this.state.selectedElement.lockUnlockStatus}<br/><br/>
                  <b>Account Id:</b> {this.state.selectedElement.userAccountId}<br/><br/>
                  <b>Details:</b> {this.state.selectedElement.message.substr(0,this.state.selectedElement.message.length-33)}
             </div>
          )
      } else if (this.state.selectedElement.requestType === 'AccountProtectionCheckRequest') {
          return (
             <div style={{ color: "black", background: "white" }}>
                  <b>Time:</b> {moment.utc(this.state.selectedElement.requestDate).local().format("MM/DD/YY h:mm a")}<br/><br/>
                  <b>Action:</b> {this.state.selectedElement.accountProtectionCheckStatus}<br/><br/>
                  <b>Account Id:</b> {this.state.selectedElement.userAccountId}<br/><br/>
                  <b>Details:</b> {this.state.selectedElement.message.substr(0,this.state.selectedElement.message.length-33)}
             </div>
          )
      } else if( this.state.selectedElement.requestType === 'UnlockAccountRequestRejected') {
          return (
             <div style={{ color: "black", background: "white" }}>
                  <b>Time:</b> {moment.utc(this.state.selectedElement.requestDate).local().format("MM/DD/YY h:mm a")}<br/><br/>
                  <b>Action:</b> REJECTED<br/><br/>
                  <b>Account Id:</b> {this.state.selectedElement.userAccountId}<br/><br/>
                  <b>Details:</b> {this.state.selectedElement.message.substr(0,this.state.selectedElement.message.length-33)}
             </div>
          )

      } else {
          return (
             <div style={{ color: "black", background: "white" }}>
                  <b>Time:</b> {moment.utc(this.state.selectedElement.requestDate).local().format("MM/DD/YY h:mm a")}<br/><br/>
                  <b>Details:</b> {this.state.selectedElement.message.substr(0,this.state.selectedElement.message.length-33)}<br/>
             </div>
          )
      }
   }

   renderMarkers() {
       return this.state.stats.map((statObj,i) => {
            var color = "https://maps.google.com/mapfiles/ms/icons/";
            if (statObj.requestType === 'Company') {
                  color += "green-dot.png"
            } else if ((statObj.requestType === 'LockUnlockUserAccount') ||
                       (statObj.requestType === 'LockUnlockAllUserAccount')) {
                  color += "orange-dot.png"
            } else if ((statObj.requestType === 'AccountProtectionCheckRequest') ||
                       (statObj.requestType === 'UnlockAccountRequestRejected')) {
                  color += "yellow-dot.png"
            } else {
               color += "pink-dot.png"
            }
            return(
               <Marker
                   info={statObj}
                   position={{lat: statObj.location.coordinates[1]+(i/10000),
                              lng: statObj.location.coordinates[0]+(i/10000)}}
                   key={i}
                   id={i}
                   icon={color}
                   onClick={(props,marker) => {this.handleMarkerClick(props,marker)}}
                />
            )
       })
   }

   render() {
     if ((isNaN(this.state.statusCode)) || (this.state.statusCode === 0)) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
               </button>
            </div>
          </>
        )
     } else if (this.state.statusCode > 201) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Error Retrieving User Data. Please Contact support.
               </button>
            </div>
          </>
        )
     } else {
        return (
           <>
           <Row>
             <Col md="10" lg="10">
               <Row className="row-cols-4">
                 <div className="progress-detail" style={{"marginLeft":"20px"}}>
                    <p  className="blackText mb-2">Date Range:</p>
                    <DatePicker
                       dateFormat="MM/dd/yyyy"
                       selected={this.state.startDate}
                       onChange={this.handleStartDateChange}
                    />
                    <DatePicker
                       dateFormat="MM/dd/yyyy"
                       minDate={moment(moment(this.state.startDate).add(1,"days")).toDate()}
                       maxDate={new Date()}
                       selected={this.state.endDate}
                       onChange={this.handleEndDateChange}
                    />
                    <br/><br/>
                 </div>
                 <div className="progress-detail" style={{"marginLeft":"10%"}}>
                     <p  className="blackText mb-2">Filter:</p>
                     <Select
                       styles={customStyles}
                       options={filterOptions}
                       isMulti
                       onChange={this.handleSelect}
                       value={this.state.selectedOptions}
                     />
                 </div>
               </Row>

               <Row className="row-cols-1">
                  <div className="mapBox">
                    <Map
                      google={this.props.google}
                      style={{width:(window.innerWidth-350), height:window.innerHeight-300}}
                      zoom={4}
                      resetBoundsOnResize={true}
                      initialCenter={{lat: 37.97, lng: -98.2}}
                    >
                       { this.renderMarkers() }
                       { this.state.selectedElement ? (
                          <InfoWindow
                            maxWidth={300}
                            maxHeight={500}
                            visible={this.state.showInfoWindow}
                            marker={this.state.activeMarker}
                            onCloseClick={() => this.setState({selectedElement: null})}
                          >
                            {this.showInfoWindowContent()}
                          </InfoWindow>
                       ):(<></>)}
                    </Map>
                  </div>
               </Row>
               <Row>
                  <br/><br/>
               </Row>
               <Row className="row-cols-1">
                   <div>
                     <h4> Access Statistics</h4>
                   </div>
                   <div className="container-fluid overflow-hidden ">
                       <div className="row">
                          <div className="col">
                              <Swiper className="list-inline m-0 p-0 mb-2 "
                                  slidesPerView={'auto'}
                                  spaceBetween={10}
                              >
                                  <SwiperSlide className="purpleCard card card-slide">
                                      <div className="card-body">
                                          <div className="progress-widget" >
                                              <div className="progress-detail">
                                                  <p  className="mb-2">Logins Blocked Today</p>
                                                  <h4 style={{color:"white"}}>{this.state.blocked_today}</h4>
                                              </div>
                                          </div>
                                      </div>
                                  </SwiperSlide>
                              </Swiper>
                          </div>
                          <div className="col">
                              <Swiper className="list-inline m-0 p-0 mb-2 "
                                  slidesPerView={'auto'}
                                  spaceBetween={10}
                              >
                                  <SwiperSlide className="purpleCard card card-slide">
                                      <div className="card-body">
                                          <div className="progress-widget" >
                                              <div className="progress-detail">
                                                  <p  className="mb-2">Total Logins Allowed</p>
                                                  <h4 style={{color:"white"}}>{this.state.allowed_total}</h4>
                                              </div>
                                          </div>
                                      </div>
                                  </SwiperSlide>
                              </Swiper>
                          </div>
                          <div className="col">
                              <Swiper className="list-inline m-0 p-0 mb-2 "
                                  slidesPerView={'auto'}
                                  spaceBetween={10}
                              >
                                  <SwiperSlide className="purpleCard card card-slide">
                                      <div className="card-body">
                                          <div className="progress-widget">
                                              <div className="progress-detail">
                                              <p  className="mb-2">Total Logins Blocked</p>
                                              <h4 style={{color:"white"}}>{this.state.blocked_total}</h4>
                                              </div>
                                          </div>
                                      </div>
                                  </SwiperSlide>
                              </Swiper>
                          </div>
                       </div>
                   </div>
               </Row>
             </Col>
           {/* The following code creates the Guide Popup */}
           <div className="nl3Window_guide">
              <div className="nl3Window_content" style={{marginTop:'10%', marginLeft:'20%', width:'80%'}}>
                 <div className="nl3Window_title">
                    <p align="center">{this.state.guideTitle}</p>
                 </div>
                 <span className="nl3Window_text" style={{"whiteSpace":"pre-wrap"}}>{this.state.guideText}</span>
                 <br/><br/>
                 <div align="center">
                    <button className="actnButton" onClick={() => this.handleGuide()}>{this.state.guideButton}</button>
                 </div>
              </div>
           </div>
           {/* End of popup code */}
           </Row>
           </>
        )
     }
   }
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyCQLlOhv3DlBCITapkgRNRWSj0wB_peckE'
})(Index);

