import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import Store from '../../store'
import ReactTooltip from 'react-tooltip';
import moment from 'moment-timezone';
import * as cognito from '../../libs/cognito';
import * as nl3Utils from '../../libs/nl3Utils'

//NL3 Styling
import "../../assets/nl3.css"
import { FaKey } from 'react-icons/fa'
import { VscError } from 'react-icons/vsc'
import { MdAddCircle } from 'react-icons/md'
import { FaSlash } from 'react-icons/fa'
import { IoMdRefreshCircle } from 'react-icons/io'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;

class CompanyKeys extends Component {
   constructor(props) {
     super(props)
     this.state= {
        selectedDomain: null,
        selectedDomainIndex: 0,
        companyToken: "",
        currentKeyType: "App",
        popupTitle: "",
        popupText: "",
        domains: [],
     }
     // This is the company dashboard page
     Store.dispatch({type: 'COMPANY'});
   }

   async componentDidMount() {
     try {
        // Get the access token
        const session = await cognito.getSession();
        this.setState({companyToken : session.accessToken.jwtToken});

        const companyLoginUrl = nl3ApiBase + `/companies/login`;
        const apiResponse = await fetch(companyLoginUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${session.accessToken.jwtToken}`,
          },
        });

        if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
           const records = await apiResponse.json();
           if (records.domains.length > 0) {
              this.setState({statusCode : apiResponse.status, domains : records.domains,
                             selectedDomain : records.domains[0],
                             companyToken : session.accessToken.jwtToken});
           } else {
              var domain_records = [];
              this.setState({statusCode : apiResponse.status, domains : domain_records,
                             companyToken : session.accessToken.jwtToken});
           }
        } else {
              this.setState({statusCode : apiResponse.status});
        }
     } catch (e) {
        console.log(e.message);
        this.props.history.push('/');
        return;
     }
   }

   async getDomain(domainIndex, keyStr) {
      const domainID = this.state.domains[domainIndex].id;
      const sdkTokenURL = nl3ApiBase + `/domains/${domainID}`;
      const apiResponse = await fetch(sdkTokenURL, {
           method: 'GET',
            headers: {
               "Authorization": `Bearer ${this.state.companyToken}`,
               "Content-Type": 'application/json',
            },
      });
      if (apiResponse.status === 200) {
         const info = await apiResponse.json();
         this.setState({selectedDomain : info, currentKeyType: keyStr});
      }
   }

   // Must have popupTitle and popupText defined in state constructor
   showPopup(title, body, timeout) {
      this.setState({popupTitle : title});
      this.setState({popupText : body});
      const modal = document.querySelector(".modal")
      const closeBtn = document.querySelector(".close")
      modal.style.display = "block";
      if (timeout > 0) {
         setTimeout(function() {modal.style.display = "none"}, (timeout * 1000));
      }
      closeBtn.addEventListener("click", () => {
        modal.style.display = "none";
      });
   }

   handleDomainChange(e) {
       this.setState({selectedDomainIndex: e.target.value});
       this.getDomain(e.target.value, 'App');
   }

   handleKeyTypeChange(e) {
     this.setState({currentKeyType: e.target.value});
   }

   showSigningKey(e, index) {
      e.preventDefault();
      var apiKeys = "Key:\n\n";
      apiKeys += this.state.selectedDomain.applicationSigningKeys[index].applicationSigningKey;
      this.showPopup("Signing Key",apiKeys,0);
   }

   async regenerateDBSigningKey() {
      var domainID = this.state.selectedDomain.id;
      const keyURL = nl3ApiBase + `/domains/${domainID}/regenerateApplicationSigningKey`;
      const apiResponse = await fetch(keyURL, {
           method: 'POST',
            headers: {
               "Authorization": `Bearer ${this.state.companyToken}`,
               "Content-Type": 'application/json',
            },
      });
      var msg = "";
      if (apiResponse.status > 202) {
          msg = "Error! Failed to generate signing key (" + apiResponse.status + ")";
          nl3Utils.nl3Toast(msg,true,"error");
      } else {
          msg = "Success! Signing key has been generated.";
          nl3Utils.nl3Toast(msg,true,"success");
      }
   }

   async regenerateSigningKey() {
      await this.regenerateDBSigningKey();
      this.getDomain(this.state.selectedDomainIndex,this.state.currentKeyType);
   }

   renderAppScreen() {

     if (this.state.selectedDomain === null) {
       return(
         <>
           <br/>
           <h3 style={{padding:25}}>&nbsp;&nbsp;No Signing Keys have been created for this application.</h3>
           <br/>
         </>
       )
     } else if (this.state.selectedDomain.serverApiTokens.length === 0) {
       return(
         <>
           <br/>
           <h3 style={{padding:25}}>&nbsp;&nbsp;No keys have been created for this application.</h3>
           <br/>
         </>
       )
     } else {
       return (
           <>
             <br/><br/>
             <Table id="acct-table" >
               <TableHead className="TableHead-dark">
                 <TableRow key={1000}>
                   <TableCell>Name</TableCell>
                   <TableCell>ID</TableCell>
                   <TableCell>Action</TableCell>
                 </TableRow>
               </TableHead>
               <TableBody>
               {
                  this.state.selectedDomain.applicationSigningKeys.map((key, index) => {
                    return (
                        <TableRow key={index+1001}>
                           <TableCell>{key.name}</TableCell>
                           <TableCell>{key.id}</TableCell>
                           <TableCell>
                             <button className="clearButton" style={{width:"50px"}}
                                    onClick={(e) => this.showSigningKey(e,index)}
                                    data-tip="Show this key" data-for="companyKeyTips" data-place="top">
                               <FaKey fill="#E3D313" style={{fontSize:"1.6rem"}} />
                             </button>&nbsp;&nbsp;&nbsp;&nbsp;
                             <button className="clearButton" style={{width:"50px"}}
                                    onClick={(e) => this.regenerateSigningKey(e,index)}
                                    data-tip="Regenerate this key. If you<br/>
                                              regenerate the key, you need<br/>
                                              to update the client side website<br/>
                                              so that it can sign the API<br/>
                                              requests properly."
                                    data-for="companyKeyTips" data-place="top">
                               <IoMdRefreshCircle fill="grey" style={{fontSize:"2.4rem"}} />
                             </button>
                           </TableCell>
                         </TableRow>
                    )})
               }
               </TableBody>
             </Table>
             <p style={{color:'#000000', padding:25}}>This is a Secret Key you can use to sign the required JSON Web Tokens (JWT) for AccountProtectionCheck and ApprovalRequest rest API. This is secret key and you will need to secure it on your server side in a wallet or a key vault.</p>
             <ReactTooltip className="nl3Tooltip" id="companyKeyTips" html={true}/>
           </>
     )}
   }

   showSDKToken(e, index) {
      e.preventDefault();
      var apiKeys = "Token:\n\n";
      apiKeys += this.state.selectedDomain.serverApiTokens[index].token;
      this.showPopup("SDK Tokens",apiKeys,0);
   }

   async createDBSDKToken() {
      var domainID = this.state.selectedDomain.id;
      const sdkTokenURL = nl3ApiBase + `/domains/${domainID}/generateServerApiToken`;
      const apiResponse = await fetch(sdkTokenURL, {
           method: 'POST',
            headers: {
               "Authorization": `Bearer ${this.state.companyToken}`,
               "Content-Type": 'application/json',
            },
      });
      var msg = "";
      if (apiResponse.status > 202) {
          msg = "Error! Failed to generate token (" + apiResponse.status + ")";
          nl3Utils.nl3Toast(msg,true,"error");
      } else {
          msg = "Success! Token has been generated.";
          nl3Utils.nl3Toast(msg,true,"success");
      }
   }

   async createSDKToken() {
      await this.createDBSDKToken();
      this.getDomain(this.state.selectedDomainIndex,this.state.currentKeyType);
   }

   async removeDBSDKToken(e, index) {
      e.preventDefault();
      var domainID = this.state.selectedDomain.id;
      const sdkTokenURL = nl3ApiBase + `/domains/${domainID}/serverApiTokens/${this.state.selectedDomain.serverApiTokens[index].id}`;
      const apiResponse = await fetch(sdkTokenURL, {
           method: 'DELETE',
            headers: {
               "Authorization": `Bearer ${this.state.companyToken}`,
               "Content-Type": 'application/json',
            },
      });
      var msg = "";
      if (apiResponse.status > 202) {
          msg = "Error! Failed to remove token (" + apiResponse.status + ")";
          nl3Utils.nl3Toast(msg,true,"error");
      } else {
          msg = "Success! Token has been removed.";
          nl3Utils.nl3Toast(msg,true,"success");
      }
      return 0;
   }

   async removeSDKToken(e, index) {
      await this.removeDBSDKToken(e, index);
      this.getDomain(this.state.selectedDomainIndex,this.state.currentKeyType);
   }

   async inactDBSDKToken(e, index) {
      e.preventDefault();
      var domainID = this.state.selectedDomain.id;
      const sdkTokenURL = nl3ApiBase +
             `/domains/${domainID}/serverApiTokens/${this.state.selectedDomain.serverApiTokens[index].id}/markInactive`;
      const apiResponse = await fetch(sdkTokenURL, {
           method: 'POST',
            headers: {
               "Authorization": `Bearer ${this.state.companyToken}`,
               "Content-Type": 'application/json',
            },
      });
      var msg = "";
      if (apiResponse.status > 202) {
          msg = "Error! Failed to deactivate token (" + apiResponse.status + ")";
          nl3Utils.nl3Toast(msg,true,"error");
      } else {
          msg = "Success! Token has been deactivated.";
          nl3Utils.nl3Toast(msg,true,"success");
      }
   }

   async inactSDKToken(e, index) {
      await this.inactDBSDKToken(e, index);
      this.getDomain(this.state.selectedDomainIndex,this.state.currentKeyType);
   }

   renderSDKScreen() {
     if (this.state.selectedDomain.serverApiTokens.length === 0) {
       return(
         <>
           <br/>
           <h3 style={{padding:25}}>&nbsp;&nbsp;No tokens have been created for this application.</h3>
           <br/>
         </>
       )
     } else {
       return (
           <>
             <br/><br/>
             <Table id="acct-table">
               <TableHead >
                 <TableRow key={2000}>
                   <TableCell>Status</TableCell>
                   <TableCell>Created</TableCell>
                   <TableCell>Token ID</TableCell>
                   <TableCell>Action</TableCell>
                 </TableRow>
               </TableHead>
               <TableBody>
               {
                  this.state.selectedDomain.serverApiTokens.map((key, index) => {
                    const keyDate = moment.utc(key.createdDate).local().format("MM/DD/YY");
                    const keyTime = moment.utc(key.createdDate).local().format("h:mm a");
                    return (
                        <TableRow key={index+2001}>
                           <TableCell>{key.companyDomainJwtTokenState}</TableCell>
                           <TableCell>{keyTime}<br/>{keyDate}</TableCell>
                           <TableCell>{key.tokenId}</TableCell>
                           <TableCell>
                             <button className="clearButton" style={{width:"50px"}}
                                    onClick={(e) => this.showSDKToken(e,index)}
                                    data-tip="Show this token" data-for="companyKeyTips" data-place="top">
                               <FaKey fill="#E3D313" style={{fontSize:"1.6rem"}} />
                             </button>&nbsp;&nbsp;&nbsp;&nbsp;
                             <button className="clearButton" style={{width:"50px"}}
                                    onClick={(e) => this.removeSDKToken(e,index)}
                                    data-tip="Remove this token" data-for="companyKeyTips" data-place="top">
                               <FaKey fill="#E3D313" style={{fontSize:"1.6rem"}} />
                               <VscError fill="red" style={{fontSize:"1.2rem", marginTop:"20px", marginLeft:"-20px"}} />
                             </button>
                             { (key.companyDomainJwtTokenState === 'ACTIVE') &&
                                <button className="clearButton" style={{width:"50px"}}
                                    onClick={(e) => this.inactSDKToken(e,index)}
                                    data-tip="Deactivate this token" data-for="companyKeyTips" data-place="top">
                                  <FaKey fill="#E3D313" style={{fontSize:"1.6rem"}} />
                                  <FaSlash fill="red" style={{fontSize:"1.2rem", marginTop:"5px", marginLeft:"-23px"}} />
                                </button>
                             }
                           </TableCell>
                         </TableRow>
                    )})
               }
               </TableBody>
             </Table>
             <p style={{color:'#000000', padding:25}}>This token(s) is long lived and pregenerated by the Next Level3 system. You will use this token(s) when making calls using the Next Level3 SDK.</p>
             <ReactTooltip className="nl3Tooltip" id="companyKeyTips" html={true}/>
           </>
     )}
   }

   showClientKey(e, index) {
      e.preventDefault();
      var pubKey = JSON.parse(this.state.selectedDomain.clientSigningKeys[index].publicKey);
      var apiKeys = "Public Key :\n\n";
      apiKeys += "kty: " + pubKey.kty;
      apiKeys += "\ncrv: " + pubKey.crv;
      apiKeys += "\nkid: " + pubKey.kid;
      apiKeys += "\nx: " + pubKey.x;
      apiKeys += "\ny: " + pubKey.y;
      apiKeys += "\n\nPublic Key Certificate:\n\n";
      apiKeys += this.state.selectedDomain.clientSigningKeys[index].publicKeyCert;
      this.showPopup("Client Signing Keys",apiKeys,0);
   }

   async createDBClientKey() {
      var domainID = this.state.selectedDomain.id;
      const sdkTokenURL = nl3ApiBase + `/domains/${domainID}/generateClientSigningKey`;
      const apiResponse = await fetch(sdkTokenURL, {
           method: 'POST',
            headers: {
               "Authorization": `Bearer ${this.state.companyToken}`,
               "Content-Type": 'application/json',
            },
      });
      var msg = "";
      if (apiResponse.status > 202) {
          msg = "Error! Failed to generate Client Signing Key (" + apiResponse.status + ")";
          nl3Utils.nl3Toast(msg,true,"error");
      } else {
          msg = "Success! Client Signing Key has been generated.";
          nl3Utils.nl3Toast(msg,true,"success");
      }
   }

   async createClientKey() {
      await this.createDBClientKey();
      this.getDomain(this.state.selectedDomainIndex,this.state.currentKeyType);
   }

   async regenerateDBClientKey(e, index) {
      var domainID = this.state.selectedDomain.id;
      const sdkTokenURL = nl3ApiBase + `/domains/${domainID}/regenerateClientSigningKey`;
      const apiResponse = await fetch(sdkTokenURL, {
           method: 'DELETE',
            headers: {
               "Authorization": `Bearer ${this.state.companyToken}`,
               "Content-Type": 'application/json',
            },
      });
      var msg = "";
      if (apiResponse.status > 202) {
          msg = "Error! Failed to remove Client Signing Key (" + apiResponse.status + ")";
          nl3Utils.nl3Toast(msg,true,"error");
      } else {
          msg = "Success! Client Signing Key has been removed.";
          nl3Utils.nl3Toast(msg,true,"success");
      }
   }

   async regenerateClientKey(e, index) {
      await this.regenerateDBClientKey(e, index);
      this.getDomain(this.state.selectedDomainIndex,this.state.currentKeyType);
   }

   renderClientScreen() {
     if (this.state.selectedDomain.clientSigningKeys.length === 0) {
       return(
         <>
           <br/>
           <h3 style={{padding: 25}}>&nbsp;&nbsp;No Client Signing Keys have been created for this application.</h3>
           <br/>
         </>
       )
     } else {
       return (
           <>
             <br/><br/>
             <Table id="acct-table" >
               <TableHead >
                 <TableRow key={3000}>
                   <TableCell>Key ID</TableCell>
                   <TableCell>Created</TableCell>
                   <TableCell>Action</TableCell>
                 </TableRow>
               </TableHead>
               <TableBody>
               {
                  this.state.selectedDomain.clientSigningKeys.map((key, index) => {
                    const keyDate = moment.utc(key.createdDate).local().format("MM/DD/YY");
                    const keyTime = moment.utc(key.createdDate).local().format("h:mm a");
                    return (
                        <TableRow key={index+3001}>
                           <TableCell>{key.keyId}</TableCell>
                           <TableCell>{keyTime}<br/>{keyDate}</TableCell>
                           <TableCell>
                             <button className="clearButton" style={{width:"50px"}}
                                    onClick={(e) => this.showClientKey(e,index)}
                                    data-tip="Show this key" data-for="companyKeyTips" data-place="top">
                               <FaKey fill="#E3D313" style={{fontSize:"1.6rem"}} />
                             </button>&nbsp;&nbsp;&nbsp;&nbsp;
                             <button className="clearButton" style={{width:"50px"}}
                                    onClick={(e) => this.regenerateClientKey(e,index)}
                                    data-tip="Regenerate this key. If you<br/>
                                              regenerate the key, you need<br/>
                                              to update the client side website<br/>
                                              so that it can sign the API<br/>
                                              requests properly."
                                    data-for="companyKeyTips" data-place="top">
                               <IoMdRefreshCircle fill="grey" style={{fontSize:"2.4rem"}} />
                             </button>&nbsp;&nbsp;&nbsp;&nbsp;
                           </TableCell>
                         </TableRow>
                    )})
               }
               </TableBody>
             </Table>
             <p style={{color:'#000000', padding:25}}>This is the Public Key used to validate the signature of the ApprovalRequest response and the web socket response. This key can be kept on client side.</p>
             <ReactTooltip className="nl3Tooltip" id="companyKeyTips" html={true}/>
           </>
     )}
   }

   async createDBWebID() {
      var domainID = this.state.selectedDomain.id;
      const sdkTokenURL = nl3ApiBase + `/domains/${domainID}/generateWebauthnClientId`;
      const apiResponse = await fetch(sdkTokenURL, {
           method: 'POST',
            headers: {
               "Authorization": `Bearer ${this.state.companyToken}`,
               "Content-Type": 'application/json',
            },
      });
      var msg = "";
      if (apiResponse.status > 202) {
          msg = "Error! Failed to create Web AuthN Client ID (" + apiResponse.status + ")";
          nl3Utils.nl3Toast(msg,true,"error");
      } else {
          msg = "Success! Web AuthN Client ID created.";
          nl3Utils.nl3Toast(msg,true,"success");
      }
   }

   async createWebID() {
      await this.createDBWebID();
      this.getDomain(this.state.selectedDomainIndex,this.state.currentKeyType);
   }

   renderWebScreen() {
     if ((this.state.selectedDomain.webauthNClientId === undefined) ||
         (this.state.selectedDomain.webauthNClientId === null)) {
       return(
         <>
           <br/>
           <h3 style={{padding: 25}}>&nbsp;&nbsp;No Web AuthN Clients have been created for this application.</h3>
           <br/>
         </>
       )
     } else {
       return (
         <>
           <br/><br/>
           <Table id="acct-table" >
             <TableHead >
               <TableRow key={4000}>
                 <TableCell>Status</TableCell>
                 <TableCell>Client ID</TableCell>
               </TableRow>
             </TableHead>
             <TableBody>
               <TableRow key={4001}>
                 <TableCell>ACTIVE</TableCell>
                 <TableCell>{this.state.selectedDomain.webauthNClientId}</TableCell>
               </TableRow>
             </TableBody>
           </Table>
           <p style={{color:'#000000', padding:25}}>This is the Client ID that was generated by the Next Level3 system. If you plan to implement webauthn using Next Level3 SDK APIs, you will need to use this Client ID.</p>
         </>
     )}
   }

   render() {
     if (this.state.selectedDomain === null) {
       return (<></>);
     } else {
       return (
         <>
         <div className="whiteCard card">
                   <div className="card-header d-flex justify-content-between flex-wrap">
                     <h4 className="card-title">
                        <span style={{color:'green'}}>{this.state.selectedDomain.name}</span>
                            &nbsp;&nbsp;Key Management
                     </h4>
                   </div>
                   <br/><br/>

           <Row>
             <Col md="12" lg="12">
               <Row className="row-cols-1">

                 <div className=" card-body p-3 ">
                   <div className="">
                     <div className="table-wrapper-scroll-y accounts-table d-flex justify-content-between">
                       <div className='blackText'>
                       <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Select Application:&nbsp;&nbsp;</label>
                       <select
                               value={this.state.selectedDomainIndex}
                               onChange={this.handleDomainChange.bind(this)}>
                           {
                              this.state.domains.map((domain, index) => {
                                   return ( <option key={index} value={index}>{domain.name}</option> ) })
                           }
                       </select>
                       <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Select Key Type:&nbsp;&nbsp;</label>
                       <select id="keyTypeList"
                               value={this.state.currentKeyType}
                               onChange={this.handleKeyTypeChange.bind(this)}>
                         <option value="App">Application Signing Keys</option>
                         <option value="SDK">SDK API Tokens</option>
                         <option value="Client">Client Signing Keys</option>
                         <option value="Web">Web AuthN Client ID</option>
                       </select>
                       </div>
                     { (this.state.currentKeyType === 'App') &&
                       (this.state.selectedDomain.applicationSigningKeys.length < 1) &&
                       <div style={{marginRight:"10px"}}>
                         <button className="clearButton" onClick={(e) => this.createSDKToken()}
                            data-tip="Create new key" data-for="companyKeyTips" data-place="top">
                            <FaKey fill="#E3D313" style={{fontSize:"1.6rem"}} />
                            <MdAddCircle fill="green" style={{fontSize:"1.2rem", marginTop:"20px", marginLeft:"-20px"}} />
                         </button>
                         <ReactTooltip className="nl3Tooltip" id="companyKeyTips" html={true}/>
                       </div>
                     }
                     { (this.state.currentKeyType === 'SDK') &&
                       (this.state.selectedDomain.serverApiTokens.length < 2) &&
                       <div style={{marginRight:"10px"}}>
                         <button className="clearButton" onClick={(e) => this.createSDKToken()}
                            data-tip="Create new token" data-for="companyKeyTips" data-place="top">
                            <FaKey fill="#E3D313" style={{fontSize:"1.6rem"}} />
                            <MdAddCircle fill="green" style={{fontSize:"1.2rem", marginTop:"20px", marginLeft:"-20px"}} />
                         </button>
                         <ReactTooltip className="nl3Tooltip" id="companyKeyTips" html={true}/>
                       </div>
                     }
                     { (this.state.currentKeyType === 'Client') &&
                       (this.state.selectedDomain.clientSigningKeys.length < 1) &&
                       <div style={{marginRight:"10px"}}>
                         <button className="clearButton" onClick={(e) => this.createClientKey()}
                            data-tip="Create new key" data-for="companyKeyTips" data-place="top">
                            <FaKey fill="#E3D313" style={{fontSize:"1.6rem"}} />
                            <MdAddCircle style={{color:"green", fontSize:"1.2rem", marginTop:"20px", marginLeft:"-20px"}} />
                         </button>
                         <ReactTooltip className="nl3Tooltip" id="companyKeyTips" html={true}/>
                       </div>
                     }
                     { ( (this.state.currentKeyType === 'Web') &&
                         ( (this.state.selectedDomain.webauthNClientId === undefined) ||
                           (this.state.selectedDomain.webauthNClientId === null) ) ) &&
                       <div style={{marginRight:"10px"}}>
                         <button className="clearButton" onClick={(e) => this.createWebID()}
                            data-tip="Create Client ID" data-for="companyKeyTips" data-place="top">
                            <FaKey fill="#E3D313" style={{fontSize:"1.6rem"}} />
                            <MdAddCircle style={{color:"green", fontSize:"1.2rem", marginTop:"20px", marginLeft:"-20px"}} />
                         </button>
                         <ReactTooltip className="nl3Tooltip" id="companyKeyTips" html={true}/>
                       </div>
                     }
                     </div>
                     { this.state.currentKeyType === 'App' && this.renderAppScreen() }
                     { this.state.currentKeyType === 'SDK' && this.renderSDKScreen() }
                     { this.state.currentKeyType === 'Client' && this.renderClientScreen() }
                     { this.state.currentKeyType === 'Web' && this.renderWebScreen() }
                     <ReactTooltip className="nl3Tooltip" id="companyKeyTips" html={true}/>
                   </div>
                 </div>
               </Row>
             </Col>
           </Row>
           </div>
           {/* The following code creates the popup */}
               <div className="modal">
                  <div className="modal_content">
                     <div className="modal_title">
                        <span className="close">&times;</span>
                        <p>{this.state.popupTitle}&nbsp;&nbsp;</p>
                     </div>
                     <span className="modal_text" style={{"whiteSpace":"pre-wrap"}}>{this.state.popupText}</span>
                     <ReactTooltip className="nl3Tooltip" id="companyKeyTips" html={true}/>
                  </div>
               </div>
           {/* End of popup code */}
         </>
       )
     }
   }
}

export default CompanyKeys
