import { useState, useEffect } from 'react'

import * as yup from 'yup'

export const useValidEmail = (initialValue: string) => {
  const [email, setEmail] = useState(initialValue)
  const [emailIsValid, setEmailIsValid] = useState(true)

  useEffect(() => {
    const emailSchema = yup.object().shape({
      email: yup.string().email().required(),
    })

    if (email.length === 0) {
      setEmailIsValid(true)
      return
    }

    const isValid = emailSchema.isValidSync({ email })

    setEmailIsValid(isValid)
  }, [email])

  return { email, setEmail, emailIsValid }
}

export const useValidUsername = (initialValue: string) => {
  const [username, setUsername] = useState(initialValue)
  const [usernameIsValid, setUsernameIsValid] = useState(true)

  useEffect(() => {
    const usernameSchema = yup.object().shape({
      username: yup.string().min(4).required(),
    })

    if (username.length === 0) {
      setUsernameIsValid(true)
      return
    }

    const isValid = usernameSchema.isValidSync({ username })

    setUsernameIsValid(isValid)
  }, [username])

  return { username, setUsername, usernameIsValid }
}

export const useValidFirstname = (initialValue: string) => {
  const [firstname, setFirstname] = useState(initialValue)
  const [firstnameIsValid, setFirstnameIsValid] = useState(true)

  useEffect(() => {
    const firstnameSchema = yup.object().shape({
      firstname: yup.string().min(2).required(),
    })

    if (firstname.length === 0) {
      setFirstnameIsValid(true)
      return
    }

    const isValid = firstnameSchema.isValidSync({ firstname })

    setFirstnameIsValid(isValid)
  }, [firstname])

  return { firstname, setFirstname, firstnameIsValid }
}

export const useValidLastname = (initialValue: string) => {
  const [lastname, setLastname] = useState(initialValue)
  const [lastnameIsValid, setLastnameIsValid] = useState(true)

  useEffect(() => {
    const lastnameSchema = yup.object().shape({
      lastname: yup.string().min(3).required(),
    })

    if (lastname.length === 0) {
      setLastnameIsValid(true)
      return
    }

    const isValid = lastnameSchema.isValidSync({ lastname })

    setLastnameIsValid(isValid)
  }, [lastname])

  return { lastname, setLastname, lastnameIsValid }
}

export const useValidPhone = (initialValue: string) => {
  const [phone, setPhone] = useState(initialValue)
  const [phoneIsValid, setPhoneIsValid] = useState(true)

  useEffect(() => {
    const phoneSchema = yup.object().shape({
      phone: yup.string().min(10).required(),
    })

    if (phone.length === 0) {
      setPhoneIsValid(true)
      return
    }

    const isValid = phoneSchema.isValidSync({ phone })

    setPhoneIsValid(isValid)
  }, [phone])

  return { phone, setPhone, phoneIsValid }
}

export const useValidPhonecode = (initialValue: string) => {
  const [phonecode, setPhonecode] = useState(initialValue)

  useEffect(() => {
  }, [phonecode])

  return { phonecode, setPhonecode }
}

export const useValidAddress = (initialValue: string) => {
  const [address, setAddress] = useState(initialValue)
  const [addressIsValid, setAddressIsValid] = useState(true)

  useEffect(() => {
    const addressSchema = yup.object().shape({
      address: yup.string().min(5).required(),
    })

    if (address.length === 0) {
      setAddressIsValid(true)
      return
    }

    const isValid = addressSchema.isValidSync({ address })

    setAddressIsValid(isValid)
  }, [address])

  return { address, setAddress, addressIsValid }
}

export const useValidCity = (initialValue: string) => {
  const [city, setCity] = useState(initialValue)
  const [cityIsValid, setCityIsValid] = useState(true)

  useEffect(() => {
    const citySchema = yup.object().shape({
      city: yup.string().min(3).required(),
    })

    if (city.length === 0) {
      setCityIsValid(true)
      return
    }

    const isValid = citySchema.isValidSync({ city })

    setCityIsValid(isValid)
  }, [city])

  return { city, setCity, cityIsValid }
}

export const useValidZipcode = (initialValue: string) => {
  const [zipcode, setZipcode] = useState(initialValue)
  const [zipcodeIsValid, setZipcodeIsValid] = useState(true)

  useEffect(() => {
    const zipcodeSchema = yup.object().shape({
      zipcode: yup.string().min(5).required(),
    })

    if (zipcode.length === 0) {
      setZipcodeIsValid(true)
      return
    }

    const isValid = zipcodeSchema.isValidSync({ zipcode })

    setZipcodeIsValid(isValid)
  }, [zipcode])

  return { zipcode, setZipcode, zipcodeIsValid }
}

export const useValidProvince = (initialValue: string) => {
  const [province, setProvince] = useState(initialValue)
  const [provinceIsValid, setProvinceIsValid] = useState(true)

  useEffect(() => {
    const provinceSchema = yup.object().shape({
      province: yup.string().min(2).required(),
    })

    if (province.length === 0) {
      setProvinceIsValid(true)
      return
    }

    const isValid = provinceSchema.isValidSync({ province })

    setProvinceIsValid(isValid)
  }, [province])

  return { province, setProvince, provinceIsValid }
}

export const useValidLocation = (initialValue: string) => {
  const [location, setLocation] = useState(initialValue)
  const [locationIsValid, setLocationIsValid] = useState(true)

  useEffect(() => {
    const locationSchema = yup.object().shape({
      location: yup.string().min(2).required(),
    })

    if (location.length === 0) {
      setLocationIsValid(true)
      return
    }

    const isValid = locationSchema.isValidSync({ location })

    setLocationIsValid(isValid)
  }, [location])

  return { location, setLocation, locationIsValid }
}

export const useValidCompany = (initialValue: string) => {
  const [company, setCompany] = useState(initialValue)
  const [companyIsValid, setCompanyIsValid] = useState(true)

  useEffect(() => {
    const companySchema = yup.object().shape({
      company: yup.string().min(4).required(),
    })

    if (company.length === 0) {
      setCompanyIsValid(true)
      return
    }

    const isValid = companySchema.isValidSync({ company })

    setCompanyIsValid(isValid)
  }, [company])

  return { company, setCompany, companyIsValid }
}

export const useValidTitle = (initialValue: string) => {
  const [title, setTitle] = useState(initialValue)
  const [titleIsValid, setTitleIsValid] = useState(true)

  useEffect(() => {
    const titleSchema = yup.object().shape({
      title: yup.string().min(3).required(),
    })

    if (title.length === 0) {
      setTitleIsValid(true)
      return
    }

    const isValid = titleSchema.isValidSync({ title })

    setTitleIsValid(isValid)
  }, [title])

  return { title, setTitle, titleIsValid }
}

export const useValidKey = (initialValue: string) => {
  const [key, setKey] = useState(initialValue)
  const [keyIsValid, setKeyIsValid] = useState(true)

  useEffect(() => {
    const keySchema = yup.object().shape({
      key: yup.string().min(1).required(),
    })

    if (key.length === 0) {
      setKeyIsValid(true)
      return
    }

    const isValid = keySchema.isValidSync({ key })

    setKeyIsValid(isValid)
  }, [key])

  return { key, setKey, keyIsValid }
}

