import React, { Component, useState , useEffect } from 'react'
import { Col, FloatingLabel, Form,  Row } from 'react-bootstrap'
import Pagination from '../../components/pagination';
import ReactTooltip from 'react-tooltip';
import moment from 'moment-timezone';
import * as cognito from '../../libs/cognito';
import * as nl3Utils from '../../libs/nl3Utils';

//NL3 Styling
import "../../assets/nl3.css"
import { IoMdClock, IoMdRefreshCircle } from 'react-icons/io'
import { MdAttachment, MdDevices, MdOpenInNew } from 'react-icons/md';
import { FaBell, FaMapMarker } from 'react-icons/fa';


const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;



class Notifications extends Component {
   constructor(props) {
     super(props)
     this.state= {
        statusCode: 0,
        notificationList: [],
        userToken: "",
        currentPage: 1,
        totalPages: 1,
        totalItems: 0,
        itemsPerPage: 0,
        pageSize: 20,
        filterValue: "",
     }
   }

    componentDidMount = async() => {
       try {
          // Get the access token
          const session = await cognito.getSession();
          this.setState({userToken : session.accessToken.jwtToken});

          // get the account info
          this.getNotificationData(this.state.currentPage, this.state.pageSize);
       } catch (e) {
          console.log(e.message);
          this.props.history.push('/');
          return;
       }
    }

   getNotificationData = async (pageNum, pageSize) => {
      // try to get the notification list
      var notificationUrl = nl3ApiBase + `/users/notifications?page=${pageNum}&pageSize=${pageSize}`;
      const apiResponse = await fetch(notificationUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.state.userToken}`,
        },
      });

      if (apiResponse.status === 200) {
         const records = await apiResponse.json();
         var notifList = records.items;
         notifList.sort(function(a,b){return moment.utc(b.requestDate) - moment.utc(a.requestDate)});
         this.setState({statusCode : apiResponse.status, notificationList: notifList,
                        currentPage: records.currentPage,
                        totalPages: records.totalPages, totalItems: records.totalItems,
                        itemsPerPage: records.itemsPerPage, pageSize: records.pageSize});
      } else {
         this.setState({statusCode : apiResponse.status});
      }
      return apiResponse.status;
   }

   refreshNotifications = () => {
      this.setState({statusCode: 0});
      this.getNotificationData(this.state.currentPage, this.state.pageSize);
   }

   onPageChanged = (data) => {
       this.getNotificationData(data.currentPage, data.pageLimit)
       .then(status => { if (status > 200) console.log("Error from getNotificationData, RC=",status);})
       .catch(e => { console.log(e.message);});
   }

   handleFilter = (e) => {
       this.setState({filterValue: e.target.value});
   }

   handlePageLimitChange = (e) => {
       const newPageLimit = parseInt(e.target.value,10);
       this.setState({pageSize: newPageLimit});
       this.getNotificationData(0, newPageLimit)
       .then(status => { if (status > 200) console.log("Error pageLimit from getNotificationData, RC=",status);})
       .catch(e => { console.log(e.message);});
   }

   reloadNotificationData = () => {
    this.getNotificationData(this.state.currentPage, this.state.pageSize);
   }


   renderTableData = () => {
      return this.state.notificationList.filter(msg => {
            const {notificationTitle, notificationBody} = msg; //destructuring
            if (this.state.filterValue === "") {
              return msg;
            } else if ((notificationBody.toLowerCase().includes(this.state.filterValue.toLowerCase())) ||
                       (notificationTitle.toLowerCase().includes(this.state.filterValue.toLowerCase()))){
              return msg;
            } else {
              return null;
            }
         }).map((msg, itemIdx) => {
            //const {notificationBody, notificationData, requestDate} = msg; //destructuring
            //const reqDate = moment.utc(requestDate).local().format("MM/DD/YY");
            //const reqTime = moment.utc(requestDate).local().format("h:mm a");
            //const details = notificationBody + '\nDevice: ' + notificationData.device + '\nLocation: ' + notificationData.location;
            return (
               <NotificationItem key={msg.sessionId} message={msg} itemIdx={itemIdx} reloadNoticationData={this.reloadNotificationData} state={this.state} />
            )
         })
   }

   render() {
     if ((isNaN(this.state.statusCode)) || (this.state.statusCode === 0)) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
               </button>
            </div>
          </>
        )
     } else if (this.state.statusCode > 200) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Error Retrieving Request Data. Please Contact support.
               </button>
            </div>
          </>
        )
     } else {
        if (this.state.totalItems === 0) {
          return (
            <>
               <Row>
                 <Col md="12" lg="12">
                   <Row className="row-cols-1">
                     <div className="whiteCard card">
                         <div className="card-header d-flex justify-content-between flex-wrap">
                           <h4 className="card-title">No Requests found.</h4>
                            <div>
                               <div>
                                  Refresh&nbsp;&nbsp;
                                    <button className="clearButton" style={{width:"50px"}}
                                           onClick={(e) => this.refreshNotifications()}
                                           data-tip="Refresh notifications"
                                           data-for="notificationTips" data-place="top">
                                      <IoMdRefreshCircle fill="grey" style={{fontSize:"2.4rem"}} />
                                    </button>
                               </div>
                            </div>
                         </div>
                         <div className="card-footer d-flex justify-content-between flex-wrap p-1">
                         </div>
                     </div>
                   </Row>
                 </Col>
               </Row>
             <ReactTooltip className="nl3Tooltip" id="notificationTips" html={true}/>
            </>
          )
        } else {
          return (
            <>
               <Row>
                 <Col md="12" lg="12">
                   <Row className="row-cols-1">
                     <div className="card whiteCard ">
                         <div className="card-header d-flex justify-content-between flex-wrap">
                            <h4 className="card-title">Notifications</h4>
                            <div>
                               Refresh
                               <button className="clearButton" style={{width:"50px"}}
                                     onClick={(e) => this.refreshNotifications()}
                                     data-tip="Refresh notifications"
                                     data-for="notificationTips" data-place="top">
                                     <IoMdRefreshCircle fill="grey" style={{fontSize:"2.4rem"}} />
                               </button>
                            </div>
                         </div>

                         <div className="card-footer d-flex justify-content-between flex-wrap p-1">
                         </div>
                     </div>
                     <div className="card-body p-0">
                          <div className="table-responsive ">
                            <div className="d-flex justify-content-between flex-wrap mb-3">
                               <span className="blackText">Filter:&nbsp;
                                   <input placeholder=" filter by name" onChange={(e) => this.handleFilter(e)}/>
                               </span>
                            </div>
                             {this.renderTableData()}
                          </div>
                     </div>
                   </Row>
                 </Col>
                 <Col md="6" lg="6">
                     <div className="d-flex flex-row py-4 align-items-center">
                           <span className="blackText">Show&nbsp;
                             <select defaultValue={this.state.pageSize} onChange={(e) => this.handlePageLimitChange(e)}>
                                   <option value="1">1 Notification</option>
                                   <option value="5">5 Notifications</option>
                                   <option value="20">20 Notifications</option>
                                   <option value="50">50 Notifications</option>
                                   <option value="100">100 Notifications</option>
                             </select>
                           </span>&nbsp;&nbsp;&nbsp;
                           <Pagination totalRecords={this.state.totalItems}
                                  pageLimit={this.state.pageSize} pageNeighbours={1}
                                  onPageChanged={(data) => this.onPageChanged(data)} />
                           &nbsp;&nbsp;&nbsp;
                      </div>
                 </Col>
             </Row>
             <ReactTooltip className="nl3Tooltip" id="notificationTips" html={true}/>
         </>
        )
      }
     }
   }
}


export const NotificationItem = ({message, itemIdx, reloadNoticationData, state}) => {
  const [notificationLinks, setNotificationLinks] = useState([]);
  const [comments, setComments] = useState('');
  const [signature, setSignature] = useState('');
  const [signatureError, setSignatureError] = useState(false);

  //const {notificationBody, notificationData, requestDate} = message; //destructuring
  const {notificationBody, requestDate} = message; //destructuring
  const reqDate = moment.utc(requestDate).local().format("MM/DD/YY");
  const reqTime = moment.utc(requestDate).local().format("h:mm a");
  const details = notificationBody;

  useEffect(() => {
    console.log("notimessage", message);

    if (message.notificationData && message.notificationData.NL3_APPROVAL_REQUEST_LINKS) {
      try {
        const decodedStr = window.atob(message.notificationData.NL3_APPROVAL_REQUEST_LINKS);
        console.log("Decoded Links", decodedStr);
        const jsonLinks = JSON.parse(decodedStr);
        console.log("Links Object", jsonLinks);
        setNotificationLinks(jsonLinks);
      }catch(error){
        console.error("Could not display notification links", error);
      }

    }

  }, [message]);

   const processNotificationClicked = async (approval) => {

    if  (message.requestType === "SIGN_APPROVAL_REQUEST"){
      if (!signature || signature.trim() === ''){
        setSignatureError(true);
        console.log("Signature not supplied error");
        return;
      }

    }
    const sessionID = message.sessionId;
     var userNotifs = process.env.REACT_APP_NL3_API_BASE + `/users/rejectNotification`;
     if (approval) {
         userNotifs = process.env.REACT_APP_NL3_API_BASE + `/users/approveNotification`;
     }
     const requestData = { "sessionId": sessionID };

      if ( comments ) {
        requestData.comments = comments;
      }
      if ( signature ) {
        requestData.signature = window.btoa(signature);
      }
     const apiResponse = await fetch(userNotifs, {
          method: "POST",
          headers: {
             "Authorization": `Bearer ${state.userToken}`,
             'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
     });
     if (apiResponse.status > 202) {
        const textBody = (approval) ? "Error approving the notification." :
                                      "Error rejecting the notification.";
        nl3Utils.nl3Toast(textBody,true,"error");
     } else {
        const textBody = (approval) ? "Successfully approved the notification." :
                                      "Successfully rejected the notification.";
        nl3Utils.nl3Toast(textBody,true,"success");
        // this.getNotificationData(this.state.currentPage, this.state.pageSize);
        reloadNoticationData();
     }
   }

   const handleReject = () => {
      // call API to reject request
      processNotificationClicked(false);
   }

   const handleApprove = () => {
      // call API to approve request
     processNotificationClicked(true);
   }

   const renderLinks = () => {
    return  <div className="mt-3">
                <div className="nl3-notification-attachment-header">
                  <MdAttachment/> Attachements
                </div>
                <div className='p-2'>
                    {notificationLinks.map((link, index) => {
                    return (
                      <div key={index}>
                        <a href={link.linkUrl} target='_blank' rel="noreferrer">
                          <MdOpenInNew /> {link.linkTitle}
                        </a>
                      </div>
                    );
                    })}
                </div>
            </div>
  };



  return (


          <div key={message.sessionId} className='nl3-notification-item'>

              <div className='nl3-notification-header'> <FaBell /> {message.notificationTitle}</div>
              <div className='nl3-notification-info-container'>
                <div>
                  <IoMdClock /> {reqTime} {reqDate}
                </div>
                <div>
                  <MdDevices /> {message.notificationData.device}
                </div>
                <div>
                  <FaMapMarker /> {message.notificationData.location}
                </div>
                <div>
                  <span style={{fontSize:14,paddingTop:5, fontWeight:"bold"}}> Approval Code</span>
                  <span style={{fontSize:14,padding:4}}> {message?.sessionId?.slice(-2)?.toUpperCase()}</span>

                </div>
              </div>
              <div style={{whiteSpace:"pre-wrap"}}>
                    {details}
                    {notificationLinks && notificationLinks.length > 0 && renderLinks()}
                    <Form>
                    { (message.requestType === "APPROVAL_REQUEST" || message.requestType === "SIGN_APPROVAL_REQUEST") &&
                      <div className='mt-3'>

                        <FloatingLabel controlId="commentsInput" label="Comments" className="mb-3">
                        <Form.Control as="textarea"
                          placeholder="Comments"
                          style={{ height: 150 }}
                          value={comments}
                          onChange={(event) => {setComments(event.target.value)}}
                          />
                          </FloatingLabel>
                      </div>
                    }

                    {message.requestType === "SIGN_APPROVAL_REQUEST" &&
                      <>

                        <FloatingLabel  controlId="signatureInput" label="Signature" className="mb-3">
                            <Form.Control required type="text" size="sm" placeholder="Sign here"
                            value={signature}
                            onChange={(event) => {setSignature(event.target.value)}}
                            isInvalid={!!signatureError}
                              />
                          <Form.Control.Feedback type="invalid">Please provide a signature</Form.Control.Feedback>
                          </FloatingLabel>

                      </>
                    }
                    </Form>
              </div>
              <div className='nl3-notification-actions'>
                     <button type="button"  className="btn btn-danger nl3-notification-button"
                          onClick={(e) => handleReject()}
                          >
                       Reject
                     </button>
                     <button type="button"  className="btn btn-success nl3-notification-button"
                          onClick={(e) => handleApprove()}
                          >
                       Approve
                     </button>
              </div>


          </div>
  );
}

export default Notifications
