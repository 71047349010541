import React, { Component } from 'react'
import { Row,Col } from 'react-bootstrap'
import Store from '../../store'
import ReactTooltip from 'react-tooltip';
import * as cognito from '../../libs/cognito';
import * as nl3Utils from '../../libs/nl3Utils';

//NL3 Styling
import "../../assets/nl3.css"

import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { BiPencil, BiTrash } from 'react-icons/bi';

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;

class GroupManager extends Component {
   constructor(props) {
     super(props)
     this.state= {
        statusCode: 0,
        groupList: [],
        companyToken: "",
        groupID: null,
     }
     // This is the company dashboard page
     Store.dispatch({type: 'COMPANY'});
   }

   async componentDidMount() {
      // Get the access token
      try {
         const session = await cognito.getSession();
         this.setState({companyToken : session.accessToken.jwtToken});
         this.getGroups();
      } catch (e) {
          console.log(e.message);
          this.props.history.push('/');
          return;
      }
   }

   async getGroups() {
       try {
          // get the company groups
          const groupUrl = nl3ApiBase + `/groups`;
          const apiResponse = await fetch(groupUrl, {
               method: 'GET',
               headers: {
                 Authorization: `Bearer ${this.state.companyToken}`,
               },
             });

          if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
              const records = await apiResponse.json();
              this.setState({groupList: records, statusCode: 200});
          } else {
              this.setState({statusCode: apiResponse.status});
          }
       } catch (e) {
          console.log(e.message);
       }
    }

   async removeGroupFromDB(groupID) {
       try {
          const groupUrl = nl3ApiBase + `/groups/` + groupID;
          const apiResponse = await fetch(groupUrl, {
               method: 'DELETE',
               headers: {
                 Authorization: `Bearer ${this.state.companyToken}`,
               },
             });

          if (apiResponse.status === 200) {
            nl3Utils.nl3Toast("Success! Group has been removed.",true,"success");
          } else {
            nl3Utils.nl3Toast("Error! Group was not removed. Contact support if the problem persists.",true,"error");
          }
          this.getGroups();
       } catch (e) {
          console.log(e.message);
       }
   }

   handleAddGroup() {
       this.props.history.push('/dashboard/groups-add');
   }

   handleEditGroup(id) {
       Store.dispatch({type: 'GROUP', text: {id}});
       this.props.history.push('/dashboard/groups-edit');
   }

   handleCancelDelete() {
       const modal = document.querySelector(".nl3Window_guide")
       modal.style.display = "none";
   }

   handleConfirmDelete() {
       const modal = document.querySelector(".nl3Window_guide")
       modal.style.display = "none";
       this.removeGroupFromDB(this.state.groupID);
   }

   handleRemoveGroup(currentGroupID) {
       const modal = document.querySelector(".nl3Window_guide")
       modal.style.display = "block";
       this.setState({groupID: currentGroupID});
   }

   renderTableData() {
        return this.state.groupList.map((entry, index) => {
           return (
               <TableRow key={index}>
                 <TableCell>{entry.groupName}</TableCell>
                 <TableCell>{entry.groupDescription}</TableCell>
                 <TableCell>
                  <IconButton aria-label="edit" onClick={(e) => this.handleEditGroup(entry.id)}>
                        <BiPencil size={20}></BiPencil>
                    </IconButton>


                 </TableCell>
                 <TableCell>
                      <IconButton aria-label="delete" onClick={(e) => this.handleRemoveGroup(entry.id)}>
                        <BiTrash></BiTrash>
                      </IconButton>
                 </TableCell>
                 <TableCell>
                 </TableCell>
               </TableRow>
           )
        })
   }

   render() {
     //Company Dashboard
     if ((isNaN(this.state.statusCode)) || (this.state.statusCode === 0)) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
               </button>
            </div>
          </>
        )
     } else if (this.state.statusCode > 201) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Error Retrieving Company Group Data. Please Contact support.
               </button>
            </div>
          </>
        )
     } else {
        if (this.state.groupList.length === 0) {
          return (
            <>
               <Row>
                 <Col md="12" lg="12">
                   <Row className="row-cols-1">
                     <div className="whiteCard card overflow-hidden">
                         <div className="card-header d-flex justify-content-between flex-wrap">
                             <div className="header-title">
                               <h4 className="card-title">Group Manager - No Groups Defined</h4>
                             </div>
                             <button className="actnButton" onClick={() => this.handleAddGroup()}>+ New Group</button>
                         </div>
                         <br/>
                     </div>
                   </Row>
                 </Col>
               </Row>
            </>
          )
        } else {
          return (
            <>
              <div className="whiteCard card overflow-hidden">
              <div className="card-header d-flex justify-content-between flex-wrap">
                             <div className="header-title">
                               <h4 className="card-title">Group Manager</h4>
                             </div>
                             <button className="actnButton" onClick={() => this.handleAddGroup()}>+ New Group</button>
                         </div>

                     <div className="card-body p-3">
               <Row>
                 <Col md="12" lg="12">
                   <Row className="row-cols-1">


                            <div className="table-wrapper-scroll-y ">
                              <Table size="small" style={{tableLayout:"fixed"}}>
                                  <TableHead className="">
                                      <TableRow>
                                          <TableCell>Name</TableCell>
                                          <TableCell>Description</TableCell>
                                          <TableCell style={{"width":"20px"}}>&nbsp;&nbsp;&nbsp;&nbsp;Action</TableCell>
                                          <TableCell style={{"width":"20px"}}></TableCell>
                                          <TableCell style={{"width":"20px"}}></TableCell>
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                      {this.renderTableData()}
                                  </TableBody>
                              </Table>
                            </div>
                   </Row>
                 </Col>
             </Row>
                     </div>
             </div>
             <ReactTooltip className="nl3Tooltip" id="groupTips" html={true}/>
             {/* The following code creates the popup */}
                 <div className="nl3Window_guide">
                    <div className="nl3Window_content" style={{"width":"300px"}}>
                       <div className="nl3Window_title">
                          <p align="center">Remove Group?</p>
                       </div>
                         <div align="center">
                            <button className="formButton" onClick={this.handleCancelDelete.bind(this)}>Cancel</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button className="formButton" onClick={this.handleConfirmDelete.bind(this)}>Remove</button>&nbsp;&nbsp;&nbsp;
                         </div>
                    </div>
                 </div>
             {/* End of popup code */}
         </>
        )
      }
     }
   }
}

export default GroupManager
