import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { useValidUsername, useValidKey } from '../hooks/useAuthHooks'
import { Username, Key } from '../components/authComponents'
import Store from '../store'
import * as nl3Utils from '../libs/nl3Utils'
import "../assets/nl3.css"

const useStyles = makeStyles({
  root: {
    height: '100vh',
  },
})

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;

const RecoverAcct: React.FunctionComponent<{}> = () => {
  const classes = useStyles()
  const history = useHistory()
  var portalType = Store.getState().company;
  if (portalType.length === 0) {
    // must be a refresh
    history.push('/');
  }

  let storedUsername = nl3Utils.nl3getUserName();
  const { username, setUsername, usernameIsValid } = useValidUsername(storedUsername)
  const { key, setKey, keyIsValid } = useValidKey('')
  const [error, setError] = useState('')
  const [accountRecovered, setAccountRecovered] = useState(false)

  var isValid = !usernameIsValid || username.length === 0 || !keyIsValid ||  key.length === 0
  const backGround = (portalType === 'USER') ? 'login-component' : 'company-login-component';

  const handleSendRecovery = async () => {
      setError("");
      try {
          var recoveryData = { 'recoveryKey': key, 'username': username };

          const recoveryURL = nl3ApiBase + '/registration/recoverAccount';
          var recoverAPIRsp = await fetch(recoveryURL, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(recoveryData),
          });
          if (recoverAPIRsp.status >= 201) {
             setError("An error occurred, please contact support.");
          } else {
             setAccountRecovered(true);
             setError("Sign in to re-authenticate your account.");
          }
      } catch (e) {console.log(e);}
  }

  const recoverDisplay = (
    <>
      <Grid container direction="column" justifyContent="center" alignItems="center">
         <Box width="80%" m={1}>
            <Username usernameIsValid={usernameIsValid} defUserName={username} setUsername={setUsername} />
            <Key keyIsValid={keyIsValid} setKey={setKey} />
         </Box>
      </Grid>
      <Grid container direction="row" justifyContent="center">
      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>
      </Grid>
      {/* Buttons */}
      <Box mt={2}>
        <Grid container direction="row" justifyContent="center">
          <Box m={1}>
            <Button onClick={() => history.push("/")} color="secondary" variant="contained">
              Cancel
            </Button>
          </Box>
              <Box m={1}>
                 <Button disabled={isValid} color="primary" variant="contained" onClick={handleSendRecovery}>
                   Recover
                 </Button>
              </Box>
        </Grid>
      </Box>
    </>
  )

  const handleSignInClicked = () => {
     nl3Utils.nl3setUserName(username);
     history.push('/');
  }

  const recoveredDisplay = (
    <>
      <Grid container direction="row" justifyContent="center" alignItems="center">
         <Typography variant="h5">{`Recovered: ${username}`}</Typography>
         <Grid container direction="row" justifyContent="center">
         <Box mt={2}>
           <Typography color="error" variant="body2">
             {error}
           </Typography>
         </Box>
         </Grid>
         <Box m={4}>
           <Button onClick={() => handleSignInClicked() } color="primary" variant="contained">
                 Return
           </Button>
         </Box>
      </Grid>
    </>
  )

  return (
    <section className="login-content">
      <div className={backGround}>
        <Grid className={classes.root} container direction="row" justifyContent="center" alignItems="center">
          <Grid xs={11} sm={6} lg={4} container direction="row" justifyContent="center" alignItems="center" item>
            <Paper style={{ backgroundColor: '#EBF4FA', width: '100%', padding: 16 }}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                {/* Title */}
                <Box m={3}>
                  <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Typography variant="h3">Account Recovery</Typography> 
                  </Grid>
                </Box>
                {accountRecovered && recoveredDisplay}
                {!accountRecovered && recoverDisplay}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </section>
  )
}

export default RecoverAcct
