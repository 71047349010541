import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import Store from '../../store'
import {SiWindows11} from 'react-icons/si'
import {FcLinux} from 'react-icons/fc'
import {GrApple} from 'react-icons/gr'
import * as cognito from '../../libs/cognito';

//NL3 Styling
import "../../assets/nl3.css"

const windowsFile = 'pam_nextlevel3-windows-x86_64-1.0.8.zip'
const macFile = 'pam_nextlevel3-macos-arm64-1.0.8.zip'
const linuxArmFile = 'pam_nextlevel3-linux-arm64-1.0.8.zip'
const linuxX86File = 'pam_nextlevel3-linux-x86_64-1.0.8.zip'

const windowsHash = 'c752a85547b396dabcece523d6526aa0e3f00f9adb6bb2a98e7d224abab2f0c1'
const macHash = '81c6f3e901d3e22a8a375606c3f24130cfa735cfe88f143790bcff95ced63eaf'
const linuxArmHash = 'c67be8a67dd8733d84efce5444335b7d4b50417bdd871ccaf3373e7e0b2b76a8'
const linuxX86Hash = '2b9bde420d85c23d1ceba690cf0da81bfca6c6cf20a88d9b9c40391469492f68'

class EndpointSecurity extends Component {
   constructor(props) {
     super(props)
     this.state= {
        companyToken: "",
     }
     // This is the company dashboard page
     Store.dispatch({type: 'COMPANY'});
   }

   async componentDidMount() {
     try {
        // Get the access token
        const session = await cognito.getSession();
        this.setState({companyToken : session.accessToken.jwtToken});
     } catch (e) {
        console.log(e.message);
        this.props.history.push('/');
        return;
     }
   }

   render() {
     return (
       <>
         <Row>
           <Col md="12" lg="12">
             <Row className="row-cols-1">
               <div className="whiteCard card">
                 <div className="card-header d-flex justify-content-between flex-wrap">
                   <h4 className="card-title">
                     Endpoint Security
                   </h4>
                 </div>
                 <div className="card-header d-flex justify-content-between flex-wrap blackText">
                      An endpoint security strategy is essential because every endpoint can be the starting  point for an attack. The number of endpoints in the attack surface is only increasing with the rapid shift to hybrid and remote work. Next Level3 offers a variety of Pluggable Authentication Modules (PAM) to deploy onto your endpoints. These PAM modules integrate Next Level3's enhanced Just-In-Time (JIT) Access with the native authentication flow of your endpoints. Below, you can download the PAM module that best fits your endpoints. <span><br/>Detailed documentation is available at <a href="https://docs.nextlevel3.com/Features/Protecting%20your%20Endpoints" target="_blank" rel="noreferrer">Next Level3 Endpoint Protection</a>.</span>
                 </div>
                 <br/><br/>
               </div>
             </Row>
             <Row className="row-cols-2 justify-content-between p-4">
               <div className="purpleCard" style={{width:"47%"}}>
                 <Row className="row-cols-1">
                   <span align="center" style={{align:"center"}}>
                     <br/>
                     Next Level3 Windows PAM Module
                     <br/><br/>
                     <a href={ '/pam/' + windowsFile } target="_blank" rel="noreferrer">
                       <SiWindows11 fill="#0099FF" style={{fontSize:"5.0rem"}} className="dlButton"/>
                     </a>
                   </span>
                 </Row>
                 <br/><br/>
                 Windows 10/11 Compatible<br/>
                 File Name: {windowsFile}<br/>
                 SHA-256 Hash Value:<br/>
                 <span style={{wordWrap:'break-word'}}>{windowsHash}</span>
               </div>
               <div className="purpleCard" style={{width:"47%"}}>
                 <Row className="row-cols-1">
                   <span align="center" style={{align:"center"}}>
                     <br/>
                     Next Level3 Linux x86 PAM Module
                     <br/><br/>
                     <a href={ '/pam/' + linuxX86File } target="_blank" rel="noreferrer">
                       <FcLinux fill="#FFFFFF" style={{fontSize:"5.0rem"}} className="dlButton"/>
                     </a>
                   </span>
                 </Row>
                 <br/><br/>
                 Linux x86_64 Compatible<br/>
                 File Name: {linuxX86File}<br/>
                 SHA-256 Hash Value:<br/>
                 <span style={{wordWrap:'break-word'}}>{linuxX86Hash}</span>
                 <br/><br/>
               </div>
             </Row>
             <Row className="row-cols-2 justify-content-between p-4">
               <div className="purpleCard" style={{width:"47%"}}>
                 <Row className="row-cols-1">
                   <span align="center" style={{align:"center"}}>
                     <br/>
                     Next Level3 Mac OSX Module
                     <br/><br/>
                     <a href={ '/pam/' + macFile } target="_blank" rel="noreferrer">
                       <GrApple fill="#D1D1CE" style={{fontSize:"5.0rem"}} className="dlButton"/>
                     </a>
                   </span>
                 </Row>
                 <br/><br/>
                 Mac OSX 12/13 ARM Compatible<br/>
                 File Name: {macFile}<br/>
                 SHA-256 Hash Value:<br/>
                 <span style={{wordWrap:'break-word'}}>{macHash}</span>
                 <br/><br/>
               </div>
               <div className="purpleCard" style={{width:"47%"}}>
                 <Row className="row-cols-1">
                   <span align="center" style={{align:"center"}}>
                     <br/>
                     Next Level3 Linux ARM PAM Module
                     <br/><br/>
                     <a href={ '/pam/' + linuxArmFile } target="_blank" rel="noreferrer" className="dlButton">
                       <FcLinux fill="#FFFFFF" style={{fontSize:"5.0rem"}} className="dlButton"/>
                     </a>
                   </span>
                 </Row>
                 <br/><br/>
                 Linux ARM64 Compatible<br/>
                 File Name: {linuxArmFile}<br/>
                 SHA-256 Hash Value:<br/>
                 <span style={{wordWrap:'break-word'}}>{linuxArmHash}</span>
                 <br/><br/>
               </div>
             </Row>                  
           </Col>
         </Row>
       </>
     )
   }
}

export default EndpointSecurity
