import React, { Component } from 'react'
import { Row,Col,Table, } from 'react-bootstrap'
import Card from '../../components/Card'
import Store from '../../store'

//const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;

class Invoice extends Component {

   constructor(props) {
     super(props)
     this.state= {
        invoice_id: 0,
        total_cost: 0,
        total_discount: 0,
        taxes: 0,
        net_total: 0,
        billing: [ ]
     }
     // This is the company dashboard page
     Store.dispatch({type: 'COMPANY'});
   }

   async componentDidMount() {
       try {
          var invoice_records = [
            { id: 1, period: 'Jul 15th - Aug 15th 2021', invoice: '#215462', status: 'paid', users: 2500, rate: 3.00, discount: 0.50},
            { id: 2, period: 'Aug 15th - Sep 15th 2021', invoice: '#215603', status: 'paid', users: 2750, rate: 3.00, discount: 0.50},
            { id: 3, period: 'Sep 15th - Oct 15th 2021', invoice: '#215749', status: 'paid', users: 3035, rate: 3.00, discount: 0.50},
            { id: 4, period: 'Oct 15th - Nov 15th 2021', invoice: '#216121', status: 'pending', users: 3450, rate: 3.00, discount: 0.50},
          ]

          let invoiceID = Store.getState().invoice.id;
          let percentage = Math.trunc(this.state.current_logins / 30000 * 100);
          let total_cost = (invoice_records[(invoiceID - 1)].users * invoice_records[(invoiceID - 1)].rate);
          let total_discount = (invoice_records[(invoiceID - 1)].users * invoice_records[(invoiceID - 1)].discount);
          let total_taxes = ((total_cost - total_discount) * 0.075);
          let total_net = total_cost - total_discount + total_taxes;
          this.setState({current_percentage: percentage, billing: invoice_records, invoice_id: invoiceID,
                         total_cost: total_cost, total_discount: total_discount, taxes: total_taxes, net_total: total_net})
       } catch (e) {
          console.log(e.message);
       }
   }

   handleBack() {
       this.props.history.push('/dashboard/billing');
   }

   render() {
     if (this.state.billing.length === 0) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
               </button>
            </div>
          </>
        )
     } else {
        return (
        <>
            <Row className="">                  
                <Col lg="12" className="">
                    <Card className="rounded whiteCard">
                        <Card.Body className="">
                            <Row className="">
                                <Col sm="12" className="">    
                                    <h4 className="mb-2">Invoice: {this.state.billing[(this.state.invoice_id-1)].invoice} - {this.state.billing[(this.state.invoice_id-1)].status}</h4>
                                    <h5 className="mb-3">Subscription Billing Period: {this.state.billing[(this.state.invoice_id-1)].period} </h5>
                                </Col>
                            </Row>
                            <Row className="">
                                <Col sm="12" className=" mt-4">
                                <div className="table-responsive-lg">
                                    <Table className="table table-warning">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th scope="col">Item</th>
                                                <th className="text-center" scope="col">Quantity</th>
                                                <th className="text-center" scope="col">Price</th>
                                                <th className="text-center" scope="col">Totals</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <h6 className="mb-0">Protected User Accounts</h6>
                                                    <p className="mb-0">Next Level3 Account Protection
                                                    </p>
                                                </td>
                                                <td className="text-center">{this.state.billing[(this.state.invoice_id-1)].users}</td>
                                                <td className="text-center">${(this.state.billing[(this.state.invoice_id-1)].rate).toFixed(2)}</td>
                                                <td className="text-center">${(this.state.total_cost).toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h6 className="mb-0">Total</h6>
                                                </td>
                                                <td className="text-center"></td>
                                                <td className="text-center"></td>
                                                <td className="text-center">${(this.state.total_cost).toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h6 className="mb-0">Taxes</h6>
                                                </td>
                                                <td className="text-center"></td>
                                                <td className="text-center"></td>
                                                <td className="text-center">${(this.state.taxes).toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h6 className="mb-0">Discount</h6>
                                                    <p className="mb-0">Per User Discount Rate (${(this.state.billing[(this.state.invoice_id-1)].discount).toFixed(2)})
                                                    </p>
                                                </td>
                                                <td className="text-center"></td>
                                                <td className="text-center"></td>
                                                <td className="text-center">${(this.state.total_discount).toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h6 className="mb-0">Net Amount</h6>
                                                </td>
                                                <td className="text-center"></td>
                                                <td className="text-center"></td>
                                                <td className="text-center"><b>${(this.state.net_total).toFixed(2)}</b></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                </Col>                              
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <div className="d-flex justify-content-center mt-4">
                                        <button type="button" className="btn btn-primary" onClick={() => this.handleBack()}>Back</button>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>                                    
            </Row>
        </>
     )}
   }
}

export default Invoice
