import React from 'react'

import nl3AppleIcons from '../../../../assets/images/apple.png';
import nl3AndroidIcons from '../../../../assets/images/android.png';
//import nl3ChromeIcons from '../../../../assets/images/chrome.png';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-body">
                <ul className="left-panel list-inline mb-0 p-0">
                    <li className="list-inline-item"><a className="nl3wlink" href="https://www.nextlevel3.com/privacy" target="_blank" rel="noreferrer">Privacy Policy&nbsp;&nbsp;&nbsp;</a></li>
                    <li className="list-inline-item"><a className="nl3wlink" href="https://www.nextlevel3.com/terms-of-service" target="_blank" rel="noreferrer">Terms of Use</a></li>
                </ul>
                <div className="right-panel">
                    <b>Download Our Clients:</b>
                    &nbsp;&nbsp;
                    <a href="https://apps.apple.com/us/app/next-level-3/id1605502487" target="_blank" rel="noreferrer">
                       <img className="rounded img-fluid" style={{height:"30px", width:"30px"}} src={nl3AppleIcons} alt=""/>
                    </a>
                    &nbsp;&nbsp;&nbsp;
                    <a href="https://play.google.com/store/apps/details?id=com.nextlevel3" target="_blank" rel="noreferrer">
                       <img className="rounded img-fluid" style={{height:"30px", width:"30px"}} src={nl3AndroidIcons} alt=""/>
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;©&nbsp;{(new Date().getFullYear())} Next Level3, Patents Pending.
                </div>
            </div>
        </footer>
    )
}

export default Footer
