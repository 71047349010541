import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { useValidUsername } from '../hooks/useAuthHooks'
import { Username } from '../components/authComponents'
import Store from '../store'
import * as cognito from '../libs/cognito'
import * as nl3Utils from '../libs/nl3Utils'
import * as base64url from '../libs/base64url-arraybuffer'
import ReactTooltip from 'react-tooltip';

import nl3_icon from '../assets/images/NL3-banner-icon.png'

import '../assets/nl3.css'

import { IoFingerPrint } from 'react-icons/io5'
import { TbFaceId } from 'react-icons/tb'
import { GiUsbKey } from 'react-icons/gi'
import { MdOutlinePhoneAndroid } from 'react-icons/md'

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;
const nl3ApiLocalHost = process.env.REACT_APP_NL3_USE_LOCALHOST;
const nl3Production = process.env.REACT_APP_NL3_PRODUCTION;
const nl3PortalType = process.env.REACT_APP_NL3_PORTALTYPE;

const useStyles = makeStyles({
  root: {
    height: '100vh',
  },
  hover: {
    '&:hover': { cursor: 'pointer',color:'red' },
  },
})

let cognitoUser = null;
let userCredentials = null;

const EnableAcct: React.FunctionComponent<{}> = () => {
  const classes = useStyles()
  const history = useHistory()

  // eslint-disable-next-line
  const [error, setError] = useState('')
  const [disableButtons, setDisableButtons] = useState(false);
  const [accountCode, setAccountCode] = useState(0);

  const portalType = nl3PortalType;
  const backGround = (portalType === 'COMPANY') ? 'company-login-component' : 'login-component';
  Store.dispatch({type: portalType});

  const authUserId = JSON.parse(new TextDecoder().decode(base64url.decode(localStorage.getItem('auth-token').split('.')[1])));
  const { username, setUsername, usernameIsValid } = useValidUsername('')
  var isValid = (!usernameIsValid || username.length === 0 || disableButtons);

  const enableThisAccount = (e) => {
     setDisableButtons(true);
     Store.dispatch({type: 'REGULAR'});
     if (accountCode === 228) {
        localStorage.setItem('NL3ENABLENEWACCT', authUserId.sub);
        history.push('/signUp');
     } else if (accountCode === 225) {
        localStorage.setItem('NL3IMPORTUSERNAME', authUserId.sub);
        history.push('/signUp');
     } else if (accountCode === 223) {
        localStorage.setItem('NL3ADDDEVICE', authUserId.sub);
        history.push('/signUp');
     } else {
        signInClicked();
     }
  }

  const handleKeyPress = async (e) => {
     if (e.key === 'Enter') {
        setDisableButtons(true);
        Store.dispatch({type: 'REGULAR'});
        signInClicked();
     } 
  }

  const signInThisDevice = async (e) => {
     setDisableButtons(true);
     Store.dispatch({type: 'REGULAR'});
     signInClicked()
  }

  const signInExternalDevice = async (e) => {
     setDisableButtons(true);
     Store.dispatch({type: 'EXTERNAL'});
     signInClicked()
  }

  const executeLoginAPI = async () => {
     let loginURL = nl3ApiBase + '/users/login';
     if (portalType === 'COMPANY') {
        loginURL = nl3ApiBase + '/companies/login';
     }
     const session = await cognito.getSession();
     var loginAPIRsp = await fetch(loginURL, {
           method: "POST",
           headers: {
            'Content-Type': 'application/json',
               'Authorization': `Bearer ${session.accessToken.jwtToken}`,
           },
     });
     if (loginAPIRsp.status !== 200) {
        setError("Sign In Failed");
     } else {
        await cognito.setIsAuthenticated(true);
        if (portalType === 'COMPANY') {
           history.push("/mydomains");
        } else {
           history.push("/dashboard");
        }
     }
  }

  const signInClicked = async () => {
     setError('');
     const authenticationDetails = new cognito.authenticationDetails(username)
     cognitoUser = cognito.getCognitoUser(username);

     cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');
     cognitoUser.initiateAuth(authenticationDetails, authCallBack);
  }

  const authCallBack = {
    onSuccess: function(result) {
      //var accessToken = result.getAccessToken().getJwtToken();
      //var idToken = result.getIdToken().getJwtToken();
      //var refreshToken = result.getRefreshToken().getToken();
      executeLoginAPI();
    },
    customChallenge: async function(challengeParameters) {
      // User authentication depends on challenge response
      let loginType = Store.getState().login;
      var rpIDOrigin = 'dev.nextlevel3.com';
      if (nl3Production === 'TRUE') {
          rpIDOrigin = 'nextlevel3.com';
      }
      if (nl3ApiLocalHost === 'TRUE') {
          rpIDOrigin = window.location.hostname;
      }

      //----------get creds from security key or platform authenticator
      let allowCredentials = [];
      console.log("allowCredentials = " + allowCredentials);
      for(const val of userCredentials) {
        const cred = {
          "id": base64url.decode(val.id),
          "type":val.type,
          "transports": val.transports
        }
        allowCredentials.push(cred);
      }
      console.log("allowCredentials = " + allowCredentials);
      let signinOptions = {
         //challenge was generated and sent from CreateAuthChallenge lambda trigger
         "challenge": base64url.decode(challengeParameters.challenge),
         "timeout":1800000,
         "rpId": rpIDOrigin,
         "userVerification":"preferred",
         "allowCredentials": (loginType === 'EXTERNAL') ? [] : (allowCredentials === null) ? [] : allowCredentials
      }

      //get sign in credentials from authenticator
      const cred = await navigator.credentials.get({
        publicKey: signinOptions
      });

      //prepare credentials challenge response
      const credential = {};
      if (cred.response) {
        const clientDataJSON = base64url.encode(cred.response.clientDataJSON);
        const authenticatorData = base64url.encode(cred.response.authenticatorData);
        const signature = base64url.encode(cred.response.signature);
        const userHandle = base64url.encode(cred.response.userHandle);
        
        credential.response = {clientDataJSON, authenticatorData, signature, userHandle};
      }
      
      //send credentials to Cognito VerifyAuthChallenge lambda trigger for verification
      //sending in cred.id for authVerify match as deviceID
      //let deviceID = nl3Utils.nl3getDeviceId(username);
      let deviceID = cred.id;
      nl3Utils.nl3setCredId(cred.id);
      cognitoUser.sendCustomChallengeAnswer(JSON.stringify(credential), this, {"deviceId":deviceID});
      
    },
    onFailure: function(err) {
      if (err.message.includes("User does not exist.")) {
          setError("Validate username or register a new account if needed.");
      } else if (err.message.includes('The operation either timed out or was not allowed')) {
          setError("Authentication either timed out or was cancelled by the user.");
      } else if (err.message.includes('The first argument must be of type string or an instance of Buffer, ArrayBuffer, or Array or an Array-like Object. Received undefined.')) {
          setError("Invalid Credential. Credential validation failed.");
      } else {
          setError(err.message);
      }
      setDisableButtons(false);
    },
  }

  useEffect(() => {
      const userLookup = async (userId) => {
         let lookupURL = nl3ApiBase + '/registration/lookup_';;
         var lookupAPIRsp = await fetch(lookupURL, {
               method: "POST",
               headers: { "Content-Type": 'application/json'},
               body: `{ "username": "${userId}"}`,
         });
         const lookupAPIRspJson = await lookupAPIRsp.json();
         setAccountCode(lookupAPIRspJson.status);
         userCredentials = lookupAPIRspJson.credentials;
      }

      let authToken = localStorage.getItem('auth-token');
      if (authToken !== null) {
         let enableInfo = JSON.parse(new TextDecoder().decode(base64url.decode(authToken.split('.')[1])));
         userLookup(enableInfo.sub);
      }
  },[])

  return (
    <section className="login-content">
      <div className={backGround}>
        <Grid className={classes.root} container direction="row" justifyContent="center" alignItems="center">
          <Grid xs={11} sm={6} lg={4} container direction="row" justifyContent="center" alignItems="center" item>
            <div align="center" >
                <img src={nl3_icon} alt=""/><br/><br/>
                <div className="header-content-container center">
                   <div className="header-content">
                        <h4 className="et_pb_module_header sub-header">Lock your account. On demand protection.</h4>
                   </div>
                   <div className="header-content">
                     <br/>
                     <span>Account Enablement Detected!</span>
                     <br/>
                   </div>
                </div><br/><br/>
            </div>
            <Paper style={{ backgroundColor: '#EBF4FA', width: '100%', padding: 32 }}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Box m={2}>
                  <Typography variant="h3">Enable Account</Typography>
                </Box>
              </Grid>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <span>Sign in with an existing Next Level3 account</span>
              </Grid>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Box width="80%" m={1}>
                  <Username usernameIsValid={usernameIsValid} setUsername={setUsername}
                      defUserName={username} keyPressFunc={handleKeyPress} />
                </Box>
                <Box mt={1}>
                  <Button disabled={isValid} color="primary" variant="contained" onClick={signInThisDevice}
                      style={{width:"100%"}}
                      data-tip="Sign In with passkey stored on this device"
                      data-for="signInTips" data-place="top">
                    Use a Passkey on<br/>this Device
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <TbFaceId fill="#FFFFFF" style={{fontSize:"1.5rem"}} />
                    <IoFingerPrint fill="#FFFFFF" style={{fontSize:"1.5rem"}} />
                  </Button>
                </Box>
                <Box m={1}>
                  <Button disabled={isValid} color="primary" variant="contained" onClick={signInExternalDevice}
                      style={{width:"100%"}}
                      data-tip="Sign In with an authorized physical device<br/> like a USB Key or Phone Passkey"
                      data-for="signInTips" data-place="top">
                      Use a Passkey on<br/>Phone or USB Device
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <MdOutlinePhoneAndroid fill="#FFFFFF" style={{fontSize:"1.5rem"}} />
                      <GiUsbKey fill="#FFFFFF" style={{fontSize:"1.5rem"}} />
                  </Button>
                </Box>
              </Grid>
              <Grid container direction="column" justifyContent="center" alignItems="center">
              <span><br/>---= OR =---<br/><br/></span>
              </Grid>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Box m={1}>
                  <Grid container direction="row" justifyContent="center">
                    <span>Register a new account</span>
                  </Grid>
                  <Grid container direction="row" justifyContent="center">
                    <Button color="primary" variant="contained" onClick={enableThisAccount}>
                      Register {authUserId.sub}
                    </Button>
                  </Grid>
                </Box>
              </Grid>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Box mt={2}>
                  <Typography color="error" variant="body2">
                    {error}
                  </Typography>
                </Box>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <ReactTooltip className="nl3Tooltip" id="signInTips" html={true}/>
    </section>
  )
}

export default EnableAcct
