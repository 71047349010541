import React from 'react'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

export const Email: React.FunctionComponent<{defaultValue: string; emailIsValid: boolean; setEmail: (_: string) => void }> = ({
  defaultValue,
  emailIsValid,
  setEmail,
}) => {
  return (
    <TextField style={{height:'35px'}}
      size="small"
      margin="dense"
      fullWidth
      variant="outlined"
      defaultValue={defaultValue}
      label={emailIsValid ? 'Email' : 'Invalid Email'}
      error={!emailIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setEmail(evt.target.value)
      }}
    />
  )
}

export const Username: React.FunctionComponent<{ usernameIsValid: boolean; defUserName: string; setUsername: (_: string) => void; keyPressFunc:(_: string) => void }> = ({
  usernameIsValid,
  defUserName,
  setUsername,
  keyPressFunc,
}) => {
  return (
    <TextField style={{height:'35px'}}
      fullWidth
      size="small"
      margin="dense"
      variant="outlined"
      defaultValue={defUserName}
      label={usernameIsValid ? 'Username' : 'Minimum 8 characters'}
      error={!usernameIsValid}
      onKeyPress={keyPressFunc}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setUsername(evt.target.value)
      }}
    />
  )
}

export const Firstname: React.FunctionComponent<{defaultValue: string; firstnameIsValid: boolean; setFirstname: (_: string) => void }> = ({
  defaultValue,
  firstnameIsValid,
  setFirstname,
}) => {
  return (
    <TextField style={{height:'35px'}}
      fullWidth
      size="small"
      margin="dense"
      variant="outlined"
      defaultValue={defaultValue}
      label={firstnameIsValid ? 'First Name' : 'Minimum 2 characters'}
      error={!firstnameIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFirstname(evt.target.value)
      }}
    />
  )
}

export const Lastname: React.FunctionComponent<{defaultValue: string; lastnameIsValid: boolean; setLastname: (_: string) => void }> = ({
  defaultValue,
  lastnameIsValid,
  setLastname,
}) => {
  return (
    <TextField style={{height:'35px'}}
      fullWidth
      size="small"
      margin="dense"
      variant="outlined"
      defaultValue={defaultValue}
      label={lastnameIsValid ? 'Last Name' : 'Minimum 3 characters'}
      error={!lastnameIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setLastname(evt.target.value)
      }}
    />
  )
}

export const Phone: React.FunctionComponent<{defaultValue: string; phoneIsValid: boolean; setPhone: (_: string) => void }> = ({
  defaultValue,
  phoneIsValid,
  setPhone,
}) => {
  return (
    <TextField style={{height:'35px'}}
      size="small"
      margin="dense"
      variant="outlined"
      defaultValue={defaultValue}
      label={phoneIsValid ? 'Phone Number' : 'Format: 3015551212 Minimum 10 characters'}
      error={!phoneIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setPhone(evt.target.value)
      }}
    />
  )
}

const phoneCodeOpts =
[
  { value: '+1', label: '+1'},
  { value: '+1242', label: '+1-242'},
  { value: '+1340', label: '+1-340'},
  { value: '+1345', label: '+1-345'},
  { value: '+1441', label: '+1-441'},
  { value: '+1649', label: '+1-649'},
  { value: '+1787', label: '+1-787'},
  { value: '+1939', label: '+1-939'},
  { value: '+27', label: '+27'},
  { value: '+297', label: '+297'},
  { value: '+30', label: '+30'},
  { value: '+31', label: '+31'},
  { value: '+32', label: '+32'},
  { value: '+33', label: '+33'},
  { value: '+34', label: '+34'},
  { value: '+39', label: '+39'},
  { value: '+351', label: '+351'},
  { value: '+353', label: '+353'},
  { value: '+354', label: '+354'},
  { value: '+358', label: '+358'},
  { value: '+377', label: '+377'},
  { value: '+41', label: '+41'},
  { value: '+44', label: '+44'},
  { value: '+45', label: '+45'},
  { value: '+46', label: '+46'},
  { value: '+47', label: '+47'},
  { value: '+48', label: '+48'},
  { value: '+49', label: '+49'},
  { value: '+420', label: '+420'},
  { value: '+52', label: '+52'},
  { value: '+55', label: '+55'},
  { value: '+506', label: '+506'},
  { value: '+60', label: '+60'},
  { value: '+61', label: '+61'},
  { value: '+63', label: '+63'},
  { value: '+64', label: '+64'},
  { value: '+65', label: '+65'},
  { value: '+66', label: '+66'},
  { value: '+7', label: '+7'},
  { value: '+81', label: '+81'},
  { value: '+82', label: '+82'},
  { value: '+86', label: '+86'},
  { value: '+853', label: '+853'},
  { value: '+886', label: '+886'},
  { value: '+91', label: '+91'},
  { value: '+972', label: '+972'},
];

export const Phonecode: React.FunctionComponent<{defaultValue: string; phonecodeIsValid: boolean; setPhonecode: (_: string) => void }> = ({
  defaultValue,
  phonecodeIsValid,
  setPhonecode,
}) => {
  const [dspVal, setDspVal] = React.useState('+1');
  return (
    <TextField style={{height:'35px'}}
      size="small"
      margin="dense"
      variant="outlined"
      defaultValue={defaultValue}
      select
      label="Country"
      value={dspVal}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setPhonecode(evt.target.value);
        setDspVal(evt.target.value);
      }}
    >
      {phoneCodeOpts.map((option) => (
         <MenuItem key={option.value} value={option.value}>
           {option.label}
         </MenuItem>
      ))}
    </TextField>
  )
}

export const Address: React.FunctionComponent<{defaultValue: string; addressIsValid: boolean; setAddress: (_: string) => void }> = ({
  defaultValue,
  addressIsValid,
  setAddress,
}) => {
  return (
    <TextField style={{height:'35px'}}
      fullWidth
      size="small"
      margin="dense"
      variant="outlined"
      defaultValue={defaultValue}
      label={addressIsValid ? 'Address' : 'Minimum 5 characters'}
      error={!addressIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setAddress(evt.target.value)
      }}
    />
  )
}

export const City: React.FunctionComponent<{defaultValue: string; cityIsValid: boolean; setCity: (_: string) => void }> = ({
  defaultValue,
  cityIsValid,
  setCity,
}) => {
  return (
    <TextField style={{height:'35px'}}
      fullWidth
      size="small"
      margin="dense"
      variant="outlined"
      defaultValue={defaultValue}
      label={cityIsValid ? 'City' : 'Minimum 3 characters'}
      error={!cityIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCity(evt.target.value)
      }}
    />
  )
}

export const Zipcode: React.FunctionComponent<{defaultValue: string; zipcodeIsValid: boolean; setZipcode: (_: string) => void }> = ({
  defaultValue,
  zipcodeIsValid,
  setZipcode,
}) => {
  return (
    <TextField style={{height:'35px'}}
      fullWidth
      size="small"
      margin="dense"
      variant="outlined"
      defaultValue={defaultValue}
      label={zipcodeIsValid ? 'Zip/Postal Code' : 'Minimum 5 characters'}
      error={!zipcodeIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setZipcode(evt.target.value)
      }}
    />
  )
}

export const Province: React.FunctionComponent<{ provinceIsValid: boolean; setProvince: (_: string) => void }> = ({
  defaultValue,
  provinceIsValid,
  setProvince,
}) => {
  return (
    <TextField style={{height:'35px'}}
      fullWidth
      size="small"
      margin="dense"
      variant="outlined"
      defaultValue={defaultValue}
      label={provinceIsValid ? 'State/Province' : 'Minimum 2 characters'}
      error={!provinceIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setProvince(evt.target.value)
      }}
    />
  )
}

export const Location: React.FunctionComponent<{defaultValue: string; locationIsValid: boolean; setLocation: (_: string) => void }> = ({
  defaultValue,
  locationIsValid,
  setLocation,
}) => {
  return (
    <TextField style={{height:'35px'}}
      fullWidth
      size="small"
      margin="dense"
      variant="outlined"
      defaultValue={defaultValue}
      label={locationIsValid ? 'Country' : 'Minimum 2 characters'}
      error={!locationIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setLocation(evt.target.value)
      }}
    />
  )
}

export const Company: React.FunctionComponent<{defaultValue: string; autoFocus: boolean; companyIsValid: boolean; setCompany: (_: string) => void }> = ({
  defaultValue,
  autoFocus,
  companyIsValid,
  setCompany,
}) => {
  return (
    <TextField style={{height:'35px'}}
      fullWidth
      autoFocus={autoFocus}
      size="small"
      margin="dense"
      variant="outlined"
      defaultValue={defaultValue}
      label={companyIsValid ? 'Company Name' : 'Minimum 4 characters'}
      error={!companyIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCompany(evt.target.value)
      }}
    />
  )
}

export const Title: React.FunctionComponent<{defaultValue: string; titleIsValid: boolean; setTitle: (_: string) => void }> = ({
  defaultValue,
  titleIsValid,
  setTitle,
}) => {
  return (
    <TextField style={{height:'35px'}}
      size="small"
      margin="dense"
      fullWidth
      variant="outlined"
      defaultValue={defaultValue}
      label={titleIsValid ? 'Title' : 'Minimum 3 characters'}
      error={!titleIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTitle(evt.target.value)
      }}
    />
  )
}

export const Key: React.FunctionComponent<{ keyIsValid: boolean; setKey: (_: string) => void }> = ({
  keyIsValid,
  setKey,
}) => {
  return (
    <TextField style={{height:'35px'}}
      size="small"
      margin="dense"
      fullWidth
      variant="outlined"
      label={keyIsValid ? 'Recovery Key' : 'Must be downloaded from authorized account'}
      error={!keyIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setKey(evt.target.value)
      }}
    />
  )
}

