import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import Store from '../store'
import Logo from '../components/partials/components/logo';
import * as cognito from '../libs/cognito'

//NL3 Styling
import "../assets/nl3.css"

// EULA
import EULAtext from '../components/eula';

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;
const nl3PortalType = process.env.REACT_APP_NL3_PORTALTYPE;
const backGround = (nl3PortalType === 'COMPANY') ? 'company-login-component' : 'login-component';

class Eula extends Component {
   constructor(props) {
     super(props)
     this.state= {
        statusCode: 0,
        userToken: "",
        userRecord: [],
     }
     // This is the user dashboard page
     Store.dispatch({type: nl3PortalType});
   }

   async componentDidMount() {
       try {
          // Get the access token
          const cognitoUserSession = await cognito.getSession();
          this.setState({userToken : cognitoUserSession.accessToken.jwtToken});

          //Get User Details from Database
          const userDetailsByIdUrl = nl3ApiBase + `/users/userinfo`;
          const apiResponse = await fetch(userDetailsByIdUrl, {
              method: "GET",
              headers: {
              "Authorization": `Bearer ${this.state.userToken}`
              },
          });
          if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
             const user = await apiResponse.json();
             cognito.setCurrentUserEmail(user.email);
             this.setState({statusCode: apiResponse.status});
             if (!user.isEULAAccepted) {
                 this.showEULAPopup();
             } else {
                 if (nl3PortalType === 'COMPANY') {
                    this.props.history.push('/mydomains');
                 } else {
                    this.props.history.push('/dashboard');
                 }
             }
          } else {
             this.setState({statusCode: apiResponse.status});
          }
       } catch (e) {
          console.log(e.message);
          this.props.history.push('/');
          return;
       }
   }

   showEULAPopup() {
      const modal = document.querySelector(".nl3Window")
      const closeBtn = document.querySelector(".nl3Window_close")
      modal.style.display = "block";
      closeBtn.addEventListener("click", () => {
        cognito.signOut();
      });
   }

   async updateEULASetting() {
        const userAccount = nl3ApiBase + `/users/userinfo`;
        const apiResponse = await fetch(userAccount, {
            method: 'PATCH',
            headers: {
               Authorization: `Bearer ${this.state.userToken}`,
               "Content-Type": 'application/json'
            },
            body: `{ "isEULAAccepted": true}`
        });
        return apiResponse.status;
   }

   handleEULAAccept() {
      // update setting in the database
      this.updateEULASetting();

      // Close the popup
      const modal = document.querySelector(".nl3Window")
      modal.style.display = "none";

      // Go to the main dashboard
      if (nl3PortalType === 'COMPANY') {
         this.props.history.push('/mydomains');
      } else {
         this.props.history.push('/dashboard');
      }
   }

   render() {
     if ((isNaN(this.state.statusCode)) || (this.state.statusCode === 0)) {
        return (
          <>
            <section className="login-content">
              <div className={backGround}>
                <div>
                   <br/><br/><br/>
                   <button className="btn btn-primary" type="button" disabled>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Loading...
                   </button>
                </div>
              </div>
            </section>
          </>
        )
     } else if (this.state.statusCode > 201) {
        return (
          <>
            <section className="login-content">
              <div className={backGround}>
                 <div>
                    <br/><br/><br/>
                    <button className="btn btn-primary" type="button" disabled>
                       <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                       Error Retrieving User Data. Please Contact support.
                    </button>
                 </div>
              </div>
            </section>
          </>
        )
     } else {
        return (
           <>
           <section className="login-content">
             <div className={backGround}>
                <Row>
                  {/* The following code creates the EULA popup */}
                  <div className="nl3Window" style={{background:"#ececec"}}>
                     <div className="nl3Window_content">
                        <div className="nl3Window_title">
                           <span className="nl3Window_close">&times;</span>
                           <Logo/> &nbsp;&nbsp;Next Level3 End User License Agreement
                        </div>
                        <div className="nl3EULA_text">
                           <EULAtext/>
                        </div>
                        <br/>
                        <div align="center">
                           <span className="blackText">By clicking Accept, you agree to the terms and conditions specified in this agreement.</span>
                           <br/><br/>
                           <button className="actnButton" onClick={(e) => this.handleEULAAccept()}>Accept</button>
                        </div>
                     </div>
                  </div>
                  {/* End of popup code */}
                </Row>
             </div>
           </section>
           </>
        )
     }
   }
}
export default Eula

