import React from 'react'
import {Switch,Route} from 'react-router-dom'

import Index from '../views/dashboard/index'
import Accounts from '../views/dashboard/accounts'
import Company from '../views/dashboard/company-dash'
import CompanyAccounts from '../views/dashboard/company-accounts'
import UserEdit from '../views/dashboard/user-edit';
import CompanyEdit from '../views/dashboard/company-edit';
import Billing from '../views/dashboard/billing';
import Invoice from '../views/dashboard/invoice';
import AddDomain from '../views/dashboard/add-domain';
import CompanyUsers from '../views/dashboard/company-users';
import CompanyAddUser from '../views/dashboard/company-add-user';
import CompanyImports from '../views/dashboard/company-imports';
import ImportDetails from '../views/dashboard/import-details';
import Activity from '../views/dashboard/activity';
import CompanyActivity from '../views/dashboard/company-activity';
import PolicyManager from '../views/dashboard/policies';
import NewPolicy from '../views/dashboard/policies-add';
import EditPolicy from '../views/dashboard/policies-edit';
import EditPolicyGroups from '../views/dashboard/policies-edit-groups';
import GroupManager from '../views/dashboard/groups';
import NewGroup from '../views/dashboard/groups-add';
import EditGroup from '../views/dashboard/groups-edit';
import CompanyKeys from '../views/dashboard/company-keys';
import CompanyIntegration from '../views/dashboard/company-integrations';
import JitTemplates from '../views/dashboard/jit-templates';
import JitTemplatesEdit from '../views/dashboard/jit-templates-edit';
import SafeList from '../views/dashboard/safelist';
import Notifications from '../views/dashboard/notifications';
import DeviceManagement from '../views/dashboard/device-mgmt';
import EndpointSecurity from '../views/dashboard/endpoint-security';
import PaymentsBilling from '../views/dashboard/payments-billing';

//TransitionGroup
import {TransitionGroup,CSSTransition} from "react-transition-group";

const DefaultRouter = () => {
    return (
        <TransitionGroup>
            <CSSTransition classNames="fadein" timeout={300}>
                <Switch>
                    {/* user sub pages */}
                    <Route path="/dashboard" exact component={Index} />
                    <Route path="/dashboard/accounts" exact component={Accounts}/>
                    <Route path="/dashboard/user-edit" exact component={UserEdit}/>
                    {/* company sub pages */}
                    <Route path="/mydomains" exact component={Company} />
                    <Route path="/dashboard/company-accounts" exact component={CompanyAccounts} />
                    <Route path="/dashboard/company-edit" exact component={CompanyEdit}/>
                    <Route path="/dashboard/billing" exact component={Billing} />
                    <Route path="/dashboard/invoice" exact component={Invoice} />
                    <Route path="/dashboard/add-domain" exact component={AddDomain} />
                    <Route path="/dashboard/company-users" exact component={CompanyUsers} />
                    <Route path="/dashboard/company-keys" exact component={CompanyKeys} />
                    <Route path="/dashboard/company-integrations" exact component={CompanyIntegration} />
                    <Route path="/dashboard/jit-templates" exact component={JitTemplates} />
                    <Route path="/dashboard/jit-templates-edit" exact component={JitTemplatesEdit} />
                    <Route path="/dashboard/safelist" exact component={SafeList} />
                    <Route path="/dashboard/company-add-user" exact component={CompanyAddUser} />
                    <Route path="/dashboard/company-imports" exact component={CompanyImports} />
                    <Route path="/dashboard/import-details" exact component={ImportDetails} />
                    <Route path="/dashboard/activity" exact component={Activity} />
                    <Route path="/dashboard/company-activity" exact component={CompanyActivity} />
                    <Route path="/dashboard/policies" exact component={PolicyManager} />
                    <Route path="/dashboard/policies-add" exact component={NewPolicy} />
                    <Route path="/dashboard/policies-edit" exact component={EditPolicy} />
                    <Route path="/dashboard/policies-edit-groups" exact component={EditPolicyGroups} />
                    <Route path="/dashboard/groups" exact component={GroupManager} />
                    <Route path="/dashboard/groups-add" exact component={NewGroup} />
                    <Route path="/dashboard/groups-edit" exact component={EditGroup} />
                    <Route path="/dashboard/notifications" exact component={Notifications} />
                    <Route path="/dashboard/device-mgmt" exact component={DeviceManagement} />
                    <Route path="/dashboard/endpoint-security" exact component={EndpointSecurity} />
                    <Route path="/dashboard/payments-billing" exact component={PaymentsBilling} />
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    )
}

export default DefaultRouter
