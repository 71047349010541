import {createStore, combineReducers} from 'redux'
import AccessType from './access-type'
import InvoiceType from './invoice-type'
import GroupType from './group-type'
import PolicyType from './policy-type'
import ImportType from './import-type'
import FirebaseType from './firebase-type'
import LoginType from './login-type'
export default createStore(
    combineReducers({
        company: AccessType,
        invoice: InvoiceType,
        group: GroupType,
        policy: PolicyType,
        fbToken: FirebaseType,
        login: LoginType,
        importId: ImportType
    })
)
