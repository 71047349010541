import React, { Component } from 'react'
import { Row,Col,Navbar,Container,Nav,Dropdown } from 'react-bootstrap'
import Store from '../../store'
import CustomToggle from '../../components/dropdowns'
import ReactTooltip from 'react-tooltip';
import * as cognito from '../../libs/cognito';
import * as nl3Utils from '../../libs/nl3Utils';

//img
import { BsShieldCheck } from 'react-icons/bs'
import { BsQuestionCircle } from 'react-icons/bs'
import { TbCopy } from 'react-icons/tb'
import { GrValidate } from 'react-icons/gr'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

//NL3 Styling
import "../../assets/nl3.css"

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;

class CompanyAccounts extends Component {
   constructor(props) {
     super(props)
     this.state= {
        todayPassed: 0,
        todayRejected: 0,
        totalPassed: 0,
        totalRejected: 0,
        statusCode: 0,
        retryCount: 0,
        domains: [ ],
        companyToken: "",
        popupTitle: "",
        popupText: "",
        guideTitle: "",
        guideText: "",
        guideButton: "",
        guideURL: "",
        guideURLText: "",
        guideStep: 0,
        location: null,
     }
     // This is the company dashboard page
     Store.dispatch({type: 'COMPANY'});
   }

   sleep (milliseconds) {
      return new Promise(resolve => setTimeout(resolve, milliseconds))
   }

   async componentDidMount() {
       try {
          // Get the user
          const user = await cognito.getCurrentUser();

          // Get the access token
          const session = await cognito.getSession();
          this.setState({companyToken : session.accessToken.jwtToken});

          // try to get the company data up to 3 times before giving up
          while (this.state.retryCount < 3) {
             // Make sure the company is created in our DB
             const companyLoginUrl = nl3ApiBase + `/companies/login`;
             const apiResponse = await fetch(companyLoginUrl, {
               method: 'POST',
               headers: {
                'Content-Type': 'application/json',
                 Authorization: `Bearer ${session.accessToken.jwtToken}`,
               },
             });

             if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
                const records = await apiResponse.json();
                if (records.domains.length > 0) {
                    // set the email for this admin in the cognito session
                    for (var i=0; i < records.admins.length; i++) {
                       if (records.admins[i].user.username === user.username) {
                          cognito.setCurrentUserEmail(records.admins[i].user.email);
                          break;
                       }
                    }
                    this.setState({statusCode : apiResponse.status, domains : records.domains,
                                   retryCount: 4, todayPassed: records.aggregations.todayPassed,
                                   todayRejected: records.aggregations.todayRejected,
                                   totalPassed: records.aggregations.totalPassed,
                                   totalRejected: records.aggregations.totalRejected});
                } else {
                    var domain_records = [];
                    this.setState({statusCode : apiResponse.status, domains : domain_records,
                      retryCount: (this.state.retryCount+1), todayPassed: 0, todayRejected: 0,
                      totalPassed: 0, totalRejected: 0});
                }
                if (!records.isHelpViewed) {
                    this.handleShowGuide("Getting Started", "Welcome to Next Level3 Company Portal. Let's start by walking you through adding an application, verifying your applications domain and creating the signing key required for Account Protection and User Enablement integration.", "OK, got it!", 1);
                }
             } else {
                   // API returned an error, try again if allowed in 100 milliseconds
                   this.setState({statusCode : apiResponse.status, retryCount: (this.state.retryCount+1)});
                   await this.sleep(100);
             }
          }
       } catch (e) {
          console.log(e.message);
          this.props.history.push('/');
          return;
       }
    }

   async updateHelpSetting() {
        const companyDetailsByIdUrl = nl3ApiBase + `/companies/mycompany`;
        const apiResponse = await fetch(companyDetailsByIdUrl, {
            method: 'PATCH',
            headers: {
               Authorization: `Bearer ${this.state.companyToken}`,
               "Content-Type": 'application/json'
            },
            body: `{ "isHelpViewed": true}`
        });
        return apiResponse.status;
   }

   showHelpGuide() {
       this.setState({guideURL:"", guideURLText:""})
       this.handleShowGuide("Getting Started", "Welcome to Next Level3 Company Portal. Let's start by walking you through adding an application, verifying your applications domain and creating the signing key required for Account Protection and User Enablement integration.", "OK, got it!", 1);
   }

   handleShowGuide(title, text, button, step) {
       this.setState({guideTitle: title, guideText: text, guideButton: button, guideStep: step});
       const guideWindow = document.querySelector(".nl3Window_guide")
       guideWindow.style.display = "block";
   }

   handleGuide() {
      if (this.state.guideStep === 1) {
         this.handleShowGuide("Add Application","The first step to setting up Next Level3 is to add an application. This would be any application for which you want to enable Account Protection.\n\nTo add an application, click 'Actions then Add Application', provide the name and url for the application and click 'save'.","Next Step",2);
      }
      if (this.state.guideStep === 2) {
         this.handleShowGuide("Domain Validation","After you have added an application, you need to validate you are the owner of the application domain by performing domain validation. To do this, you are going to need to click 'Show DNS String'.","Next Step",3);
      }
      if (this.state.guideStep === 3) {
         this.setState({guideURL:"https://www.nextlevel3.com/domain-validation/", guideURLText:"Domain Validation"})
         this.handleShowGuide("Domain Validation","Domain Control Validation is done by adding a DNS CNAME to your DNS records related to the application domain. To learn more on how to add a DNS CNAME and perform validation, visit ","Next Step",4);
      }
      if (this.state.guideStep === 4) {
         this.setState({guideURL:"https://www.nextlevel3.com/integrations/", guideURLText:"Integrations"})
         this.handleShowGuide("Signing Key","Once your application's domain ownership has been validated, you will see a 'Signing Key' has been generated. The 'Signing Key' is used when integrating Account Protection Checking into your authentication flow.\n\nTo learn more about how to integrate Account Protection Checking and User Enablement, visit ","Close",5);
      }
      if (this.state.guideStep >= 5) {
         const guideWindow = document.querySelector(".nl3Window_guide")
         guideWindow.style.display = "none";
         this.updateHelpSetting();
      }
   }

   handleAdd() {
       this.props.history.push('/dashboard/add-domain');
   }

   async validateDomain(index) {
      const domain_record = this.state.domains[index];
      const domainValidateUrl = nl3ApiBase + `/domains/${domain_record.id}/validate`;
      const apiResponse = await fetch(domainValidateUrl, {
          method: "POST",
          headers: {
            "Authorization": `Bearer ${this.state.companyToken}`
          },
      });
      if(apiResponse.status === 200) {
          var jsonResult = await apiResponse.json();
          var new_domains = this.state.domains;
          new_domains[index].validationStatus = jsonResult.validationStatus;;
          new_domains[index].applicationSigningKeys = jsonResult.applicationSigningKeys;
          this.setState({domains : new_domains});
      }
      return apiResponse.status;
   }

   // Must have popupTitle and popupText defined in state constructor
   showPopup(title, body, timeout) {
      this.setState({popupTitle : title});
      this.setState({popupText : body});
      const modal = document.querySelector(".modal")
      const closeBtn = document.querySelector(".close")
      modal.style.display = "block";
      if (timeout > 0) {
         setTimeout(function() {modal.style.display = "none"}, (timeout * 1000));
      }
      closeBtn.addEventListener("click", () => {
        modal.style.display = "none";
      });
   }

   showCNAMEData(e, index) {
      e.preventDefault();
      const messageText =
            "Instructions for setting up a DNS CNAME Record:\n" +
            "   Record Name: " + this.state.domains[index].validationText + "." + this.state.domains[index].url + "\n" +
            "   Record Type: CNAME\n" +
            "   Value : " + this.state.domains[index].url + "\n" +
            "   TTL: 300\n" +
            "   Routing Policy: Simple";
      this.showPopup("Domain Control Validation (DCV) by DNS CNAME",messageText,0);
   }


   handleValidate(e, index) {
      e.preventDefault();
      this.validateDomain(index)
         .then(status => {
                if (status === 200) {
                  if (this.state.domains[index].validationStatus === "VALIDATED") {
                      nl3Utils.nl3Toast("Success! Application validated.",true,"success");
                  } else {
                      nl3Utils.nl3Toast("Error! Invalid application status. Ensure DNS/CNAME is set up and resolving with the proper validation text.",true,"error");
                  }
                } else {
                  nl3Utils.nl3Toast("Error! Application validation failed.",true,"error");
                }
              })
          .catch(e => {
                 console.log(e.message);
              });
   }

   renderTableData() {
        return this.state.domains.map((domain, index) => {
           const { id, name, url, validationStatus, aggregations } = domain //destructuring
           const statusImg = (validationStatus === "VALIDATED" ?
                   <BsShieldCheck fill="green" style={{fontSize:"2.0rem"}}
                        data-tip="Application Validated" data-for="companyDashTips" data-place="top"/>
                   :
                   <div>
                     <button className="clearButton" style={{width:"2.0rem"}} onClick={(e) => this.showCNAMEData(e,index)}
                           data-tip="Show DNS CNAME Info" data-for="companyDashTips" data-place="top">
                        <BsQuestionCircle fill="orange" style={{fontSize:"2.0rem"}}/>
                     </button>
                     <br/><br/>
                     <button className="clearButton" style={{width:"2.0rem"}} onClick={(e) => this.handleValidate(e,index)}
                           data-tip="Validate Application" data-for="companyDashTips" data-place="top">
                        <GrValidate fill="green" style={{fontSize:"2.0rem"}}/>
                     </button>
                   </div>);
           return (
               <TableRow key={id}>
                 <TableCell><div className="d-flex align-items-center"><h6>{name}</h6></div><div><span>{url}</span></div></TableCell>
                 <TableCell><div className="d-flex align-items-center">{statusImg}</div></TableCell>
                 <TableCell>{aggregations.todayPassed}</TableCell>
                 <TableCell>{aggregations.todayRejected}</TableCell>
                 <TableCell>{aggregations.totalPassed}</TableCell>
                 <TableCell>{aggregations.totalRejected}</TableCell>
               </TableRow>
           )
        })
   }

   render() {
     //Company Dashboard
     if (this.state.statusCode === 0) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
               </button>
            </div>
          </>
        )
     } else if ((this.state.retryCount >= 3) && (this.state.statusCode > 201)) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Error Retrieving Company Data. Please Contact support.
               </button>
            </div>
          </>
        )
     } else {
        if (this.state.domains.length === 0) {
          return (
            <>
               <Row>
                 <Col md="12" lg="12">
                   <Row className="row-cols-1">
                     <div className="whiteCard card">
                         <div className="card-header d-flex justify-content-between flex-wrap">
                           <h4 className="card-title">No applications registered for this account</h4>
                            <Navbar expand="lg" className="nav" style={{backgroundColor:"white", width:"200px"}}>
                                <Container fluid className="navbar-inner">
                                    <Nav as="ul" className="ms-auto navbar-list mb-2 mb-lg-0">
                                        <Dropdown as="li" className="nav-item">
                                            <Dropdown.Toggle as={CustomToggle} variant="nav-link py-0 d-flex align-items-center"
                                                   href="#" id="actionDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <div className="caption ms-3 d-none d-md-block ">
                                                   <span className="clearButton" style={{color:"#4052A4",fontSize:"20px"}}>Actions</span>
                                                </div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-menu-end" >
                                                <div className="m-0">
                                                    <button className="clearButton" onClick={() => this.handleAdd()}>Add Application</button>
                                                    <Dropdown.Divider />
                                                    <button className="clearButton" onClick={() => this.showHelpGuide()}>Show Help</button>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Nav>
                                </Container>
                            </Navbar>
                         </div>
                         <br/>
                     </div>
                   </Row>
                 </Col>
               </Row>
               {/* The following code creates the Guide Popup */}
                   <div className="nl3Window_guide">
                      <div className="nl3Window_content">
                         <div className="nl3Window_title">
                            <p align="center">{this.state.guideTitle}</p>
                         </div>
                         <span className="nl3Window_text" style={{"whiteSpace":"pre-wrap"}}>{this.state.guideText}</span>
                         {
                           (this.state.guideURL !== "") &&
                               <a href={this.state.guideURL} target="_blank" rel="noreferrer">{this.state.guideURLText}</a>
                         }
                         <br/><br/>
                         <div align="center">
                            <button className="actnButton" onClick={() => this.handleGuide()}>{this.state.guideButton}</button>
                         </div>
                      </div>
                   </div>
               {/* End of popup code */}
            </>
          )
        } else {
          return (
            <>
               <Row>
                 <Col md="12" lg="12">
                   <Row className="row-cols-1">
                   <div className="whiteCard card overflow-hidden">
                     <div>
                         <div className="d-flex justify-content-between flex-wrap pt-3">
                           <h4>Applications</h4>
                            <Navbar expand="lg" className="nav" style={{width:"200px", background:"none"}}>
                                <Container fluid className="navbar-inner">
                                    <Nav as="ul" className="ms-auto navbar-list mb-2 mb-lg-0">
                                        <Dropdown as="li" className="nav-item">
                                            <Dropdown.Toggle as={CustomToggle} variant="nav-link py-0 d-flex align-items-center"
                                                   href="#" id="actionDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <div className="caption ms-3 d-none d-md-block ">
                                                   <span className="clearButton"
                                                        style={{fontSize:"20px", width:"200px"}}>Actions</span>
                                                </div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-menu-end" >
                                                <div className="m-0">
                                                    <button className="clearButton" onClick={() => this.handleAdd()}>Add Application</button>
                                                    <Dropdown.Divider />
                                                    <button className="clearButton" onClick={() => this.showHelpGuide()}>Show Help</button>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Nav>
                                </Container>
                            </Navbar>
                         </div>
                         <br/>
                     </div>
                     <div className="card-body p-0">
                          <div className="table-responsive mt-4">
                            <div className="table-wrapper-scroll-y accounts-table">
                              <Table id="acct-table" role="grid">
                                  <TableHead className="thead-dark">
                                      <TableRow>
                                          <TableCell className="apiKeyText">Application</TableCell>
                                          <TableCell className="apiKeyText">Validated</TableCell>
                                          <TableCell className="apiKeyText">Todays<br/>Logins<br/>Allowed</TableCell>
                                          <TableCell className="apiKeyText">Todays<br/>Logins<br/>Rejected</TableCell>
                                          <TableCell className="apiKeyText">Total<br/>Allowed</TableCell>
                                          <TableCell className="apiKeyText">Total<br/>Rejected</TableCell>
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                     {this.renderTableData()}
                                  </TableBody>
                              </Table>
                              <ReactTooltip className="nl3Tooltip" id="companyDashTips" html={true}/>
                            </div>
                          </div>
                     </div>
                     </div>
                   </Row>
                 </Col>
             </Row>
             <br/>
             {/* The following code creates the Guide Popup */}
                 <div className="nl3Window_guide">
                    <div className="nl3Window_content">
                       <div className="nl3Window_title">
                          <p align="center">{this.state.guideTitle}</p>
                       </div>
                       <span className="nl3Window_text" style={{"whiteSpace":"pre-wrap"}}>{this.state.guideText}</span>
                       {
                         (this.state.guideURL !== "") &&
                             <a href={this.state.guideURL} target="_blank" rel="noreferrer">{this.state.guideURLText}</a>
                       }
                       <br/><br/>
                       <div align="center">
                          <button className="actnButton" onClick={() => this.handleGuide()}>{this.state.guideButton}</button>
                       </div>
                    </div>
                 </div>
             {/* End of popup code */}
             {/* The following code creates the popup */}
                 <div className="modal">
                    <div className="modal_content">
                       <div className="modal_title">
                          <span className="close">&times;</span>
                          <p>{this.state.popupTitle}&nbsp;&nbsp;
                          <TbCopy fill="brown" style={{fontSize:"1.5rem"}} role="button"
                               onClick={() => {navigator.clipboard.writeText(`${this.state.popupText}`);}}
                               data-tip="Copy to Clipboard" data-for="companyDashTips" data-place="top"/>
                          </p>
                       </div>
                       <span className="modal_text" style={{"whiteSpace":"pre-wrap"}}>{this.state.popupText}</span>
                       <ReactTooltip className="nl3Tooltip" id="companyDashTips" html={true}/>
                    </div>
                 </div>
             {/* End of popup code */}
         </>
        )
      }
     }
   }
}

export default CompanyAccounts
