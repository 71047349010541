import React, { Component } from 'react'
import Switch from 'react-input-switch';
import {slideDown, slideUp } from './anim';
import ReactTooltip from 'react-tooltip';
import Checkbox from '@material-ui/core/Checkbox'

//NL3 Styling
import "../../assets/nl3.css"
import { IoMdRefreshCircle } from 'react-icons/io'
import { TableCell, TableRow } from '@material-ui/core';

class UserTableRow extends Component {
   constructor(props) {
     super(props)
     this.expanderBody = null;
     this.state= {
        expanded: false,
        details: "+ ",
     }
   }

   expanderBodyFunc = (e) => {
      this.expanderBody = e;
   }

   toggleExpander = (e) => {
       if (!this.state.expanded) {
            this.setState({expanded: true, details:"- "},
               () => {
                  if (this.expanderBody) {
                     slideDown(this.expanderBody);
                  }
               }
            );
       } else {
            slideUp(this.expanderBody, {
                onComplete: () => {this.setState({expanded: false, details: "+ "}); }
            });
       }
   }

   render() {
      const { user, itemIdx, admin } = this.props;
      var nameDisplay = user.username;
      var emailDisplay = user.email;
      var fullName = user.first_name + " " + user.last_name;
      var phoneDisplay = user.phone;
      if (user.email.length > 30) {
          emailDisplay = user.email.slice(0,30) + " ...";
      }
      if (user.phone.length > 30) {
          phoneDisplay = user.phone.slice(0,30) + " ...";
      }
      return [
           <tr key={itemIdx} onClick={this.toggleExpander}>
              <TableCell style={{"wordWrap":"break-word","whiteSpace":"normal","width":"10%"}}>
              { (!admin) &&
              <div className="card-body d-flex justify-content-between flex-wrap m-1" style={{width:"10%"}}>
                 <div className="blackText"
                      data-tip="<b>Promote</b> this account to be a company administrator"
                      data-for="adminTips" data-place="top">Admin<br/>
                      <Switch value={admin} on={true} off={false}
                              onChange={(e) => this.props.adminAction(e, itemIdx)} />
                      <ReactTooltip className="nl3Tooltip" id="adminTips" html={true}/>
                 </div>
              </div>
              }
              { (admin) &&
              <div className="card-body d-flex justify-content-between flex-wrap m-1" style={{width:"10%"}}>
                 <div className="blackText"
                      data-tip="This account is a company <b>administrator</b>"
                      data-for="adminTips" data-place="top">Admin<br/>
                      <Checkbox size="small" color="default" checked={true} disabled={true}/>
                      <ReactTooltip className="nl3Tooltip" id="adminTips" html={true}/>
                 </div>
              </div>
              }
              </TableCell>
              <TableCell style={{"wordWrap":"break-word","whiteSpace":"normal"}}>{this.state.details} {nameDisplay} ({user.accounts.length} Accts)</TableCell>
              <TableCell style={{"wordWrap":"break-word","whiteSpace":"normal"}}>{emailDisplay}</TableCell>
              <TableCell style={{"wordWrap":"break-word","whiteSpace":"normal"}}>{phoneDisplay}</TableCell>
              <TableCell>
                 <button className="clearButton" style={{width:"50px"}}
                         onClick={(e) => this.props.resetAction(e,itemIdx)}
                         data-tip="Reset the user Account. Only use<br/>
                                   this option when the user can not<br/>
                                   access their account through any<br/>
                                   authorized devices and they have lost<br/>
                                   their recovery key."
                         data-for="adminTips" data-place="top">
                    <IoMdRefreshCircle fill="grey" style={{fontSize:"2.4rem"}} />
                 </button>
              </TableCell>
            </tr>,
            this.state.expanded && (
               user.accounts.map((acct, index) => {
                  return (
                     <TableRow key={acct.id} className="expandable">
                       <TableCell colSpan={5}>
                        <div className="greyCard m-2">
                         <div className="card-body d-flex justify-content-between flex-wrap m-1">

                            <div className="blackText">Protected Application: <label style={{color:"#4052A4"}}>{acct.domain.name}</label>
                              <br/>Account Owner: <label style={{color:"#4052A4"}}>{fullName}</label>
                            </div>

                         </div>
                         <div ref={this.expanderBodyFunc} className=" d-flex justify-content-between m-1">
                             <div className="card-body d-flex justify-content-between flex-wrap m-1" style={{width:"25%"}}>
                                <div style={{color:"#4052A4"}}>Application UserID<br/><label className="blackText">{acct.userAccountId}</label></div>
                             </div>
                             <div className="card-body d-flex justify-content-between flex-wrap m-1" style={{width:"25%"}}>
                                <div style={{color:"#4052A4"}}>URL<br/><label className="blackText">{acct.domain.url}</label></div>
                             </div>
                             <div className="card-body d-flex justify-content-between flex-wrap m-1" style={{width:"40%"}}>
                                { (acct.groups.length > 0) &&
                                   <div style={{color:"#4052A4"}}>Groups<br/>
                                     { acct.groups.map((grp, grpIdx) =>
                                        <span key={grpIdx} className="blackText">
                                          {grp.groupName}
                                          <br/>
                                        </span>
                                     )}
                                   </div>
                                }
                                { (acct.groups.length === 0) &&
                                   <div style={{color:"#4052A4"}}>Groups<br/>
                                        <span className="blackText">
                                          &lt;not in any groups&gt;
                                        </span>
                                   </div>
                                }
                             </div>
                             <div className="card-body d-flex justify-content-between flex-wrap m-1" style={{width:"10%"}}>
                                <div style={{color:"#4052A4"}}
                                     data-tip="<b>Enabling</b> this account means, <i>this account will</i><br/>
                                               be allowed to utilize account protection for this applciation.<br/><br/>
                                               <b>Disabling</b> this account means, <i>this account will not</i><br/>
                                               be allowed to utilize account protection for this applciation.<br/><br/>"
                                     data-for="enableTips" data-place="top">Enabled<br/>
                                     <Switch value={acct.enabled} on={true} off={false}
                                                    onChange={(e) => this.props.action(e, itemIdx, index)} />
                                     <ReactTooltip className="nl3Tooltip" id="enableTips" html={true}/>
                                </div>
                             </div>
                         </div>
                         </div>
                       </TableCell>
                     </TableRow>
                  )
               })
            ),
      ];
   }
}

export default UserTableRow
