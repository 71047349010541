import React, { Component } from 'react'
import { Row,Col } from 'react-bootstrap'
import Store from '../../store'
import Pagination from '../../components/pagination';
import HistoryTableRow from './history-table-row';
import * as cognito from '../../libs/cognito';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

//NL3 Styling
import "../../assets/nl3.css"

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;

class CompanyActivity extends Component {
   constructor(props) {
     super(props)
     this.state= {
        statusCode: 0,
        history: [],
        companyToken: "",
        currentPage: 1,
        totalPages: 1,
        totalItems: 0,
        itemsPerPage: 0,
        pageSize: 20,
        searchValue: "",
        filterValue: "",
     }
     // This is the company dashboard page
     Store.dispatch({type: 'COMPANY'});
   }

   async componentDidMount() {
       try {
          // Get the access token
          const session = await cognito.getSession();
          this.setState({companyToken : session.accessToken.jwtToken});

          // get the account info
          this.getCompanyHistoryData(this.state.currentPage, this.state.pageSize);
       } catch (e) {
          console.log(e.message);
          this.props.history.push('/');
          return;
       }
    }

   async getCompanyHistoryData(pageNum, pageSize) {
      // try to get the company user list
      var companyHistoryUrl = nl3ApiBase;
      if (this.state.searchValue === "") {
          companyHistoryUrl += `/companies/mycompany/history?orderBy=requestDate%3Adesc&page=${pageNum}&pageSize=${pageSize}`;
      } else {
          companyHistoryUrl += `/companies/mycompany/history?filterOr=message%20like%20${this.state.searchValue}`;
          companyHistoryUrl += `&orderBy=requestDate%3Adesc&page=${pageNum}&pageSize=${pageSize}`;
      }
      const apiResponse = await fetch(companyHistoryUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.state.companyToken}`,
        },
      });

      if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
         const records = await apiResponse.json();
         this.setState({statusCode : apiResponse.status, history: records, currentPage: records.currentPage,
                        totalPages: records.totalPages, totalItems: records.totalItems,
                        itemsPerPage: records.itemsPerPage, pageSize: records.pageSize});
      } else {
         this.setState({statusCode : apiResponse.status});
      }
      return apiResponse.status;
    }

   onPageChanged(data) {
       this.getCompanyHistoryData(data.currentPage, data.pageLimit)
       .then(status => { if (status > 201) console.log("Error from getCompanyHistoryData, RC=",status);})
       .catch(e => { console.log(e.message);});
   }

   handleFilter(e) {
       this.setState({filterValue: e.target.value});
   }

   handleSearchInput(e) {
       this.setState({searchValue: e.target.value});
   }

   handleSearch() {
       this.getCompanyHistoryData(0, this.state.pageSize)
       .then(status => { if (status > 201) console.log("Error search from getCompanyHistoryData, RC=",status);})
       .catch(e => { console.log(e.message);});
   }

   handlePageLimitChange(e) {
       const newPageLimit = parseInt(e.target.value,10);
       this.setState({pageSize: newPageLimit});
       this.getCompanyHistoryData(0, newPageLimit)
       .then(status => { if (status > 201) console.log("Error pageLimit from getCompanyHistoryData, RC=",status);})
       .catch(e => { console.log(e.message);});
   }

   renderTableData() {
      return this.state.history.items.filter(record => {
            const {message} = record; //destructuring
            if (this.state.filterValue === "") {
              return record;
            } else if (message.toLowerCase().includes(this.state.filterValue.toLowerCase())) {
              return record;
            } else {
              return null;
            }
         }).map((record) => {
            const {id} = record; //destructuring
            return (
                 <HistoryTableRow key={id} record={record}/>
             )
      })
   }

   render() {
     if ((isNaN(this.state.statusCode)) || (this.state.statusCode === 0)) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
               </button>
            </div>
          </>
        )
     } else if (this.state.statusCode > 201) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Error Retrieving Company Activity Data. Please Contact support.
               </button>
            </div>
          </>
        )
     } else {
        if (this.state.history.totalItems === 0) {
          return (
            <>
               <Row>
                 <Col md="12" lg="12">
                   <Row className="row-cols-1">
                     <div className="whiteCard card overflow-hidden">
                         <div className="card-header d-flex justify-content-between flex-wrap">
                           <h4 className="card-title">No Activity Logs were found.</h4><br/><br/>
                         </div>
                         <br/>
                     </div>
                   </Row>
                 </Col>
               </Row>
            </>
          )
        } else {
          return (
            <>
            <div className="whiteCard card overflow-hidden">
                <div className="card-header d-flex justify-content-between flex-wrap">
                    <h4 className="card-title">Activity Logs</h4>
                </div>
              <div className='card-body'>
               <Row>
                 <Col md="12" lg="12">
                   <Row className="row-cols-1">
                     <div className="card-body p-0">
                          <div className="table-responsive mt-4">
                            <div className="card-body d-flex justify-content-between flex-wrap mt-4">
                               <span className="blackText">filter:&nbsp;
                                   <input placeholder=" filter by name" onChange={(e) => this.handleFilter(e)}/>
                               </span>
                               <span>
                                  <span className="blackText">Search:&nbsp;
                                     <input placeholder=" search by name" onChange={(e) => this.handleSearchInput(e)}/>&nbsp;
                                     <button className="btn btn-primary" onClick={() => this.handleSearch()}> Go</button>
                                  </span>
                               </span>
                            </div>
                            <div className="table-wrapper-scroll-y accounts-table">
                              <Table className="">
                                  <TableHead className="thead-dark">
                                      <TableRow>
                                          <TableCell>Date</TableCell>
                                          <TableCell>Message</TableCell>
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                     {this.renderTableData()}
                                  </TableBody>
                              </Table>
                            </div>
                          </div>
                     </div>
                   </Row>
                 </Col>
                 <Col md="6" lg="6">
                     <div className="d-flex flex-row py-4 align-items-center">
                           <span className="blackText">Show&nbsp;
                             <select defaultValue={this.state.pageSize} onChange={(e) => this.handlePageLimitChange(e)}>
                                   <option value="10">10 records</option>
                                   <option value="20">20 records</option>
                                   <option value="50">50 records</option>
                                   <option value="100">100 records</option>
                                   <option value="200">200 records</option>
                             </select>
                           </span>&nbsp;&nbsp;&nbsp;
                           <Pagination totalRecords={this.state.totalItems}
                                  pageLimit={this.state.pageSize} pageNeighbours={1}
                                  onPageChanged={(data) => this.onPageChanged(data)} />
                           &nbsp;&nbsp;&nbsp;
                      </div>
                 </Col>
             </Row>
             </div>
             </div>
         </>
        )
      }
     }
   }
}

export default CompanyActivity
