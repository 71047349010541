import React, { Component } from 'react'
import { Row,Col } from 'react-bootstrap'
import Store from '../../store'
import Pagination from '../../components/pagination';
import * as cognito from '../../libs/cognito';

//NL3 Styling
import "../../assets/nl3.css"

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;

class ImportDetails extends Component {
   constructor(props) {
     super(props)
     this.state= { 
        statusCode: 0,
        userList: [],
        companyToken: "",
        currentPage: 1,
        totalPages: 1,
        totalItems: 0,
        itemsPerPage: 0,
        pageSize: 20,
        searchValue: "",
        filterValue: "",
        importId: "",
     }
     // This is the company dashboard page
     Store.dispatch({type: 'COMPANY'});
   }

   async componentDidMount() {
       try {
          // Get the access token
          const session = await cognito.getSession();
          this.setState({companyToken : session.accessToken.jwtToken});

          const id = Store.getState().importId;
          this.setState({importId : id.importId});

          // get the import info
          this.getUserImportData(this.state.currentPage, this.state.pageSize);
       } catch (e) {
          console.log(e.message);
          this.props.history.push('/');
          return;
       }
    }

   async getUserImportData(pageNum, pageSize) {
      // try to get the company user list
      var companyUsersUrl = nl3ApiBase;
      if (this.state.searchValue === "") {
          companyUsersUrl += `/companies/mycompany/imports/${this.state.importId}/details?orderBy=first_name:asc&page=${pageNum}&pageSize=${pageSize}`;
      } else {
          companyUsersUrl += `/companies/mycompany/imports/${this.state.importId}/details?filterOr=first_name%20like%20${this.state.searchValue} or `;
          companyUsersUrl += `last_name%20like%20${this.state.searchValue}&orderBy=first_name%3Aasc&page=${pageNum}&pageSize=${pageSize}`;
      }
      const apiResponse = await fetch(companyUsersUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.state.companyToken}`,
        },
      });

      if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
         const records = await apiResponse.json();
         this.setState({statusCode : apiResponse.status, userList: records, currentPage: records.currentPage,
                        totalPages: records.totalPages, totalItems: records.totalItems,
                        itemsPerPage: records.itemsPerPage, pageSize: records.pageSize});
      } else {
         this.setState({statusCode : apiResponse.status});
      }
      return apiResponse.status;
    }

   onPageChanged(data) {
       this.getUserImportData(data.currentPage, data.pageLimit)
       .then(status => { if (status > 201) console.log("Error from getUserImportData, RC=",status);})
       .catch(e => { console.log(e.message);});
   }

   handleFilter(e) {
       this.setState({filterValue: e.target.value});
   }

   handleSearchInput(e) {
       this.setState({searchValue: e.target.value});
   }

   handleSearch() {
       this.getUserImportData(0, this.state.pageSize)
       .then(status => { if (status > 201) console.log("Error search from getUserImportData, RC=",status);})
       .catch(e => { console.log(e.message);});
   }

   handlePageLimitChange(e) {
       const newPageLimit = parseInt(e.target.value,10);
       this.setState({pageSize: newPageLimit});
       this.getUserImportData(0, newPageLimit)
       .then(status => { if (status > 201) console.log("Error pageLimit from getUserImportData, RC=",status);})
       .catch(e => { console.log(e.message);});
   }

   handleBack() {
      this.props.history.push('/dashboard/company-imports');
   }

   renderTableData() {
      return this.state.userList.items.filter(user => {
            const {first_name, last_name} = user; //destructuring
            const fullName = first_name + " " + last_name;
            if (this.state.filterValue === "") {
              return user;
            } else if (fullName.toLowerCase().includes(this.state.filterValue.toLowerCase())) {
              return user;
            } else {
              return null;
            }
         }).map((user, itemIdx) => {
            const {id, first_name, last_name, email, phone, userAccountId, status, comments} = user; //destructuring
            const full_name = first_name + " " + last_name;
            const notes = status + " - " + comments;
            return (
               <tr key={id}>
                  <td>{full_name}</td>
                  <td>{email}</td>
                  <td>{phone}</td>
                  <td>{userAccountId}</td>
                  <td>{notes}</td>
                </tr>
             )
      })
   }

   render() {
     //Company Dashboard
     if ((isNaN(this.state.statusCode)) || (this.state.statusCode === 0)) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
               </button>
            </div>
          </>
        )
     } else if (this.state.statusCode > 201) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Error Retrieving Import Detailed Data for Import ID {this.state.importId}. Please Contact support.
               </button>
            </div>
          </>
        )
     } else {
        if (this.state.userList.totalItems === 0) {
          return (
            <>
               <Row>
                 <Col md="12" lg="12">
                   <Row className="row-cols-1">
                     <div className="whiteCard card overflow-hidden">
                         <div className="card-header d-flex justify-content-between flex-wrap">
                           <h4 className="card-title">No users were found for this import ({this.state.importId})</h4>
                           <button className="actnButton" onClick={() => this.handleBack()}>Back</button>
                         </div>
                         <br/>
                     </div>
                     <div className="card-body d-flex justify-content-between flex-wrap mt-4">
                        <span className="blackText">filter:&nbsp;
                            <input placeholder=" filter by name" onChange={(e) => this.handleFilter(e)}/>
                        </span>
                        <span>
                           <span className="blackText">Search:&nbsp;
                              { (this.state.searchValue === "") ?
                                 <input placeholder=" search by name" onChange={(e) => this.handleSearchInput(e)}/> :
                                 <input placeholder=" search by name" value={this.state.searchValue} onChange={(e) => this.handleSearchInput(e)}/>
                              }
                              &nbsp;&nbsp;<button className="btn btn-primary" onClick={() => this.handleSearch()}>Go</button>
                           </span>
                        </span>
                     </div>
                   </Row>
                 </Col>
               </Row>
            </>
          )
        } else {
          return (
            <>
               <Row>
                 <Col md="12" lg="12">
                   <Row className="row-cols-1">
                     <div className="card whiteCard overflow-hidden">
                         <div className="card-header d-flex justify-content-between flex-wrap">
                            <h4 className="card-title">Detailed Import Data ({this.state.importId})</h4>
                           <button className="actnButton" onClick={() => this.handleBack()}>Back</button>
                         </div>
                         <div className="card-footer d-flex justify-content-between flex-wrap p-1">
                         </div>
                     </div>
                     <div className="card-body p-0">
                          <div className="table-responsive mt-4">
                            <div className="card-body d-flex justify-content-between flex-wrap mt-4">
                               <span className="blackText">filter:&nbsp;
                                   <input placeholder=" filter by name" onChange={(e) => this.handleFilter(e)}/>
                               </span>
                               <span>
                                  <span className="blackText">Search:&nbsp;
                                     <input placeholder=" search by name" onChange={(e) => this.handleSearchInput(e)}/>&nbsp;
                                     <button className="btn btn-primary" onClick={() => this.handleSearch()}> Go</button>
                                  </span>
                               </span>
                            </div>
                            <div className="table-wrapper-scroll-y accounts-table">
                              <table className="table table-primary table-hover mb-0">
                                  <thead className="thead-dark">
                                      <tr>
                                          <th>Name</th>
                                          <th>Email</th>
                                          <th>Phone</th>
                                          <th>User Acct Id</th>
                                          <th>Notes</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                     {this.renderTableData()}
                                  </tbody>
                              </table>
                            </div>
                          </div>
                     </div>
                   </Row>                  
                 </Col>
                 <Col md="6" lg="6">
                     <div className="d-flex flex-row py-4 align-items-center">
                           <span className="blackText">Show&nbsp;
                             <select defaultValue={this.state.pageSize} onChange={(e) => this.handlePageLimitChange(e)}>
                                   <option value="1">1 user</option>
                                   <option value="5">5 users</option>
                                   <option value="20">20 users</option>
                                   <option value="50">50 users</option>
                                   <option value="100">100 users</option>
                             </select>
                           </span>&nbsp;&nbsp;&nbsp;
                           <Pagination totalRecords={this.state.totalItems}
                                  pageLimit={this.state.pageSize} pageNeighbours={1}
                                  onPageChanged={(data) => this.onPageChanged(data)} />
                           &nbsp;&nbsp;&nbsp;
                      </div>
                 </Col>
             </Row>
         </>
        )
      }
     }
   }
}

export default ImportDetails
