import React, { Component } from 'react'
import {Row,Col} from 'react-bootstrap'
import {Prompt} from 'react-router-dom';
import Card from '../../components/Card'
import DatePicker from 'react-datepicker'
import Store from '../../store'
import moment from 'moment-timezone';
import 'react-datepicker/dist/react-datepicker.css';
import NL3DualListBox from "./dual-listbox.js";
import Checkbox from '@material-ui/core/Checkbox'
import * as cognito from '../../libs/cognito';
import * as nl3Utils from '../../libs/nl3Utils';

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;

class NewPolicy extends Component {
   constructor(props) {
     super(props)
     this.handlePolicyChange = this.handlePolicyChange.bind(this);
     this.handlePolicyGroupChange = this.handlePolicyGroupChange.bind(this);
     this.state = {
         policyName : "",
         policyType: "SCHEDULED",
         companyToken: "",
         status: 0,
         isDirty: false,
         selectStartDate: null,
         selectEndDate: null,
         dailyAction: "UNLOCK",
         dailyTime: 0,
         frequency: "DAILY",
         noEnd: false,
         approvers: [] ,
         userList: [] ,
         members: [] ,
         groupList: [] ,
         groupMembers: [] ,
     };
     Store.dispatch({type: 'COMPANY'});
   }

   clearStateVariables() {
       this.setState({isDirty: false});
   }

   async componentDidMount () {
       try {
          // Get the access token
          const session = await cognito.getSession();
          this.getUserList(session.accessToken.jwtToken)
          .then(status => {
              if ((status === 200) || (status === 201)) {
                 const rc = this.getGroupList(session.accessToken.jwtToken);
                 if (rc > 201) {
                    nl3Utils.nl3Toast("Error retrieving Group List. Contact Support",true,"error");
                    this.setState({companyToken : session.accessToken.jwtToken});
                 }
              } else {
                 nl3Utils.nl3Toast("Error retrieving User List. Contact Support",true,"error");
                 this.setState({companyToken : session.accessToken.jwtToken});
              }
          })
       } catch (e) {
          console.log(e.message);
          this.props.history.push('/');
          return;
       }
   }

   async getUserList (jwtToken) {
      const companyUsersUrl = nl3ApiBase + `/companies/mycompany/users?orderBy=first_name:asc`;
      const apiResponse = await fetch(companyUsersUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
         const records = await apiResponse.json();
         this.setState({userList: records, companyToken : jwtToken});
      }
      return(apiResponse.status);
   }

   async getGroupList(jwtToken) {
      const groupUrl = nl3ApiBase + `/groups`;
      const apiResponse = await fetch(groupUrl, {
           method: 'GET',
           headers: {
             Authorization: `Bearer ${jwtToken}`,
           },
         });

      if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
          const records = await apiResponse.json();
          this.setState({groupList: records});
      }
      return(apiResponse.status);
    }

   async updateSchedulePolicyDB () {
      let startDate = new Date(this.state.selectStartDate);
      const allowUnlock = (this.state.dailyAction === "LOCK") ? false : true;
      let endDate = new Date(this.state.selectEndDate);
      if (this.state.noEnd) {
         endDate.setFullYear(endDate.getFullYear() + 200);
      }
      let hours = +this.state.dailyTime;
      var time = startDate.getTime();
      var addHours = hours * (60 * 60 * 1000);
      let scheduleTime = new Date(time + addHours);
      let tmpDate = new Date();
      const policyKey = moment(tmpDate).format("HH-mmss");
      const newPolicyUrl = nl3ApiBase + `/policies`;
      const policyData = JSON.stringify({"activeDate": `${startDate.toISOString()}`,
                                       "allowUnlock" : `${allowUnlock}`,
                                       "endDate": `${endDate.toISOString()}`,
                                       "frequencyOfAction": `${this.state.frequency}`,
                                       "policyKey": `${policyKey}`,
                                       "policyName": `${this.state.policyName}`,
                                       "policyType": `${this.state.policyType}`,
                                       "scheduleDateTime": `${scheduleTime.toISOString()}`,
                                       "scheduledAction": `${this.state.dailyAction}`});
      const apiResponse = await fetch(newPolicyUrl, {
             method: 'POST',
             headers: {
               'Authorization': `Bearer ${this.state.companyToken}`,
               'Content-Type': 'application/json',
             },
             body: `${policyData}`,
           });
      return(apiResponse.status);
   }

   async updateApprovalPolicyDB () {
      let startDate = new Date();
      const allowUnlock = (this.state.dailyAction === "LOCK") ? false : true;
      let endDate = moment(startDate).add(10, 'year');
      const policyKey = moment(startDate).format("HH-mmss");
      const newPolicyUrl = nl3ApiBase + `/policies`;
      const policyData = JSON.stringify({"activeDate": `${startDate.toISOString()}`,
                                       "allowUnlock" : `${allowUnlock}`,
                                       "endDate": `${endDate.toISOString()}`,
                                       "frequencyOfAction": `${this.state.frequency}`,
                                       "policyKey": `${policyKey}`,
                                       "policyName": `${this.state.policyName}`,
                                       "policyType": `${this.state.policyType}`,
                                       "approvers":  this.state.members,
                                       "groupApprovers": this.state.groupMembers,
                                       "multiApprovalAction": "UNLOCK"});
      const apiResponse = await fetch(newPolicyUrl, {
             method: 'POST',
             headers: {
               'Authorization': `Bearer ${this.state.companyToken}`,
               'Content-Type': 'application/json',
             },
             body: `${policyData}`,
           });
      return(apiResponse.status);
   }

   async updateAlertPolicyDB () {
      let startDate = new Date();
      const allowUnlock = (this.state.dailyAction === "LOCK") ? false : true;
      let endDate = moment(startDate).add(10, 'year');
      const policyKey = moment(startDate).format("HH-mmss");
      const newPolicyUrl = nl3ApiBase + `/policies`;
      const policyData = JSON.stringify({"activeDate": `${startDate.toISOString()}`,
                                       "allowUnlock" : `${allowUnlock}`,
                                       "endDate": `${endDate.toISOString()}`,
                                       "frequencyOfAction": `${this.state.frequency}`,
                                       "policyKey": `${policyKey}`,
                                       "policyName": `${this.state.policyName}`,
                                       "policyType": `${this.state.policyType}`,
                                       "alertAction": `${this.state.dailyAction}`});
      const apiResponse = await fetch(newPolicyUrl, {
             method: 'POST',
             headers: {
               'Authorization': `Bearer ${this.state.companyToken}`,
               'Content-Type': 'application/json',
             },
             body: `${policyData}`,
           });
      return(apiResponse.status);
   }

   handleNewSchedulePolicy() {
     this.updateSchedulePolicyDB()
       .then(status => {
            if (status === 200) {
               nl3Utils.nl3Toast("Success! Policy Added.",true,"success");
               this.clearStateVariables();
            } else {
               nl3Utils.nl3Toast("Error! Failed to add policy",true,"error");
            }})
        .catch(e => {
             console.log(e.message);
          });
   }

   async handleNewApprovalPolicy() {
     this.updateApprovalPolicyDB()
       .then(status => {
            if (status === 200) {
               nl3Utils.nl3Toast("Success! Policy Added.",true,"success");
               this.clearStateVariables();
            } else {
               nl3Utils.nl3Toast("Error! Failed to add policy",true,"error");
            }})
        .catch(e => {
             console.log(e.message);
          });
   }

   async handleNewAlertPolicy() {
     this.updateAlertPolicyDB()
       .then(status => {
            if (status === 200) {
               nl3Utils.nl3Toast("Success! Policy Added.",true,"success");
               this.clearStateVariables();
            } else {
               nl3Utils.nl3Toast("Error! Failed to add policy",true,"error");
            }})
        .catch(e => {
             console.log(e.message);
          });
   }

   handleNewPolicy() {
      if (this.state.policyType === "SCHEDULED") {
          this.handleNewSchedulePolicy();
      } else if (this.state.policyType === "MULTI_APPROVAL") {
          this.handleNewApprovalPolicy();
      } else if (this.state.policyType === "ALERT") {
          this.handleNewAlertPolicy();
      }
   }

   handleBack() {
     this.props.history.push('/dashboard/policies');
   }

   handleNameChange(e) {
     this.setState({policyName: e.target.value, isDirty: true});
   }

   handlePolicyTypeChange(e) {
     if (e.target.value === "MULTI_APPROVAL") {
         this.setState({dailyAction : "UNLOCK"});
     }
     if (e.target.value === "ALERT") {
         this.setState({dailyAction : "UNLOCK"});
     }
     this.setState({policyType : e.target.value, isDirty: true});
   }

   handleStartDateChange = e => {
     this.setState({selectStartDate: e});
   }
  
   handleEndDateChange = e => {
     this.setState({selectEndDate: e});
   }
  
   handleFrequencyChange(e) {
     this.setState({frequency : e.target.value, isDirty: true});
   }
  
   handleDailyActionChange(e) {
     this.setState({dailyAction : e.target.value, isDirty: true});
   }
  
   handleDailyTimeChange(e) {
     this.setState({dailyTime : e.target.value, isDirty: true});
   }
  
   handlePolicyChange(members) {
     this.setState({members: members, isDirty: true});
   }

   handlePolicyGroupChange(members) {
     this.setState({groupMembers: members, isDirty: true});
   }

   handleCheckBox(e) {
      this.setState({noEnd:e.target.checked});
   }

   renderPolicyForm() {
     if (this.state.policyType === "SCHEDULED") {
        return(
           <><tr>
              <td><label className="blackText">Policy Name:&nbsp;</label></td>
              <td><input type="text" value={this.state.policyName} onChange={this.handleNameChange.bind(this)} /></td>
           </tr>
           <tr>
              <td><label className="blackText">Start Date:&nbsp;</label></td>
              <td><DatePicker
                     dateFormat="MM/dd/yyyy"
                     minDate={new Date()}
                     selected={this.state.selectStartDate}
                     onChange={this.handleStartDateChange}
                  />
              </td>
           </tr>
           <tr>
              <td><label className="blackText">End Date:&nbsp;</label></td>
              <td><DatePicker
                     dateFormat="MM/dd/yyyy"
                     minDate={this.state.selectStartDate}
                     selected={this.state.selectEndDate}
                     onChange={this.handleEndDateChange}
                  />
              </td>
              <td><Checkbox size="small" color="primary" checked={this.state.noEnd}
                        disabled={false} onChange={(e) => this.handleCheckBox(e)}/>
                  <label className="blackText">No End Date</label>
              </td>
           </tr>
           <tr><td><br/></td></tr>
           <tr><td><br/></td></tr>
           <tr>
              <td><label className="blackText">Frequency:&nbsp;</label></td>
              <td><select id="Frequency1" defaultValue={this.state.frequency}
                                onChange={this.handleFrequencyChange.bind(this)}>
                                <option value="DAILY">Daily</option>
                                <option value="WEEKLY">Weekly</option>
                                <option value="WEEKDAYS">Weekdays</option>
                                <option value="BI_WEEKLY">Bi-Weekly</option>
                                <option value="MONTHLY">Monthly</option>
                                <option value="YEARLY">Yearly</option>
                           </select></td>
           </tr>
           <tr>
              <td><label className="blackText">Action:&nbsp;</label></td>
              <td><select id="Action1" defaultValue={this.state.dailyAction}
                                onChange={this.handleDailyActionChange.bind(this)}>
                                <option value="UNLOCK">Unlock Accounts</option>
                                <option value="LOCK">Lock Accounts</option>
                                <option value="ALLOW_UNLOCK">Allow Accounts to be Unlocked</option>
                           </select></td>
           </tr>
           <tr>
              <td><label className="blackText">Time:&nbsp;</label></td>
              <td><select id="Time1" defaultValue={this.state.dailyTime}
                                onChange={this.handleDailyTimeChange.bind(this)}>
                                <option value="0">12:00 am</option>
                                <option value="1">1:00 am</option>
                                <option value="2">2:00 am</option>
                                <option value="3">3:00 am</option>
                                <option value="4">4:00 am</option>
                                <option value="5">5:00 am</option>
                                <option value="6">6:00 am</option>
                                <option value="7">7:00 am</option>
                                <option value="8">8:00 am</option>
                                <option value="9">9:00 am</option>
                                <option value="10">10:00 am</option>
                                <option value="11">11:00 am</option>
                                <option value="12">12:00 pm</option>
                                <option value="13">1:00 pm</option>
                                <option value="14">2:00 pm</option>
                                <option value="15">3:00 pm</option>
                                <option value="16">4:00 pm</option>
                                <option value="17">5:00 pm</option>
                                <option value="18">6:00 pm</option>
                                <option value="19">7:00 pm</option>
                                <option value="20">8:00 pm</option>
                                <option value="21">9:00 pm</option>
                                <option value="22">10:00 pm</option>
                                <option value="23">11:00 pm</option>
                           </select></td>
           </tr></>
        )
     }
     if (this.state.policyType === "MULTI_APPROVAL") {
        return(
           <><tr>
              <td><label className="blackText">Policy Name:&nbsp;</label></td>
              <td><input type="text" value={this.state.policyName} onChange={this.handleNameChange.bind(this)} /></td>
           </tr>
           <tr>
              <td><label className="blackText">Action:&nbsp;</label></td>
              <td><select id="Action1" defaultValue="UNLOCK"
                                onChange={this.handleDailyActionChange.bind(this)}>
                                <option value="UNLOCK">Unlock Accounts</option>
                           </select></td>

           </tr></>
        )
     }
     if (this.state.policyType === "ALERT") {
        return(
           <><tr>
              <td><label className="blackText">Policy Name:&nbsp;</label></td>
              <td><input type="text" value={this.state.policyName} onChange={this.handleNameChange.bind(this)} /></td>
           </tr>
           <tr>
              <td><label className="blackText">Action:&nbsp;</label></td>
              <td><select id="Action1" defaultValue={this.state.dailyAction}
                                onChange={this.handleDailyActionChange.bind(this)}>
                                <option value="UNLOCK">Unlock Accounts</option>
                                <option value="LOCK">Lock Accounts</option>
                           </select></td>
           </tr></>
        )
     }
   }

   render() {
    return (
      <>
        <Prompt message="Unsaved Changes! Are you sure you want leave?" when={this.state.isDirty}/>
        <div>
            <Row>
               <Col xl="9" lg="8">
                  <Card className="whiteCard">
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">New Policy Definition</h4>
                        </div>
                     </Card.Header>
                     <Card.Body>
                        <div>
                           <label className="blackText">Policy Type:&nbsp;&nbsp;</label>
                           <select id="policyType" defaultValue={this.state.policyType}
                                   onChange={this.handlePolicyTypeChange.bind(this)}>
                                     <option value="SCHEDULED">Scheduled Policy</option>
                                     <option value="MULTI_APPROVAL">Multi-Approval Policy</option>
                                     <option value="ALERT">Alert Policy</option>
                           </select>
                        </div>
                        <br/><br/>
                        <div className="new-user-info">
                           <table>
                              <tbody>
                                 {this.renderPolicyForm()}
                              </tbody>
                           </table>
                           { (this.state.policyType === "MULTI_APPROVAL") &&
                               <>
                                  <div className="container">
                                     <hr/>
                                     <h4 className="card-title">Add Secondary User Approvers</h4>
                                     <NL3DualListBox type="policy" data={this.state.userList.items}
                                         action={this.handlePolicyChange}/>
                                  </div>
                                  <div className="container">
                                     <hr/>
                                     <h4 className="card-title">Add Secondary Group Approvers</h4>
                                     <NL3DualListBox type="policy-group" data={this.state.groupList}
                                         action={this.handlePolicyGroupChange}/>
                                  </div>
                               </>
                           }
                           <hr/>
                           <div align="center" className="button-container">
                              <button className="formButton" onClick={this.handleNewPolicy.bind(this)}>Save</button>&nbsp;&nbsp;&nbsp;
                              <button className="formButton" onClick={this.handleBack.bind(this)}>Return</button>
                           </div>
                        </div>
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
         </div>
      </>
    )
   }
}

export default NewPolicy
