import React, { Component } from 'react'
import {Prompt} from 'react-router-dom';
import { Row, Col, Form } from 'react-bootstrap'
import Store from '../../store'
import * as cognito from '../../libs/cognito';
import * as nl3Utils from '../../libs/nl3Utils';


//NL3 Styling
import "../../assets/nl3.css"

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;

class CompanyIntegration extends Component {
   constructor(props) {
     super(props)
     this.state= {
        selectedDomain: null,
        selectedDomainIndex: 0,
        selectedKeys: [],
        currentKeyType: "",
        companyToken: "",
        aws_url: "",
        aws_access_token: "",
        aws_tenant_id: "",
        azure_tenant_id: "",
        azure_client_id: "",
        azure_client_secret: "",
        showSecret: false,
        domains: [],
        isDirty: false,
     }
     // This is the company dashboard page
     Store.dispatch({type: 'COMPANY'});
   }

   async componentDidMount() {
     try {
        // Get the access token
        const session = await cognito.getSession();
        this.setState({companyToken : session.accessToken.jwtToken});

        const companyLoginUrl = nl3ApiBase + `/companies/login`;
        const apiResponse = await fetch(companyLoginUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${session.accessToken.jwtToken}`,
          },
        });

        if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
           const records = await apiResponse.json();
           if (records.domains.length > 0) {
              this.setState({statusCode : apiResponse.status, domains : records.domains,
                             selectedDomain : records.domains[0],
                             companyToken : session.accessToken.jwtToken});
              this.getDomain(0);
           } else {
              var domain_records = [];
              this.setState({statusCode : apiResponse.status, domains : domain_records,
                             companyToken : session.accessToken.jwtToken});
           }
        } else {
              this.setState({statusCode : apiResponse.status});
        }
     } catch (e) {
        console.log(e.message);
        this.props.history.push('/');
        return;
     }
   }

   async getDomain(domainIndex) {
      const domainID = this.state.domains[domainIndex].id;
      const sdkTokenURL = nl3ApiBase + `/domains/${domainID}/integrations`;
      const apiResponse = await fetch(sdkTokenURL, {
           method: 'GET',
            headers: {
               "Authorization": `Bearer ${this.state.companyToken}`,
               "Content-Type": 'application/json',
            },
      });
      if (apiResponse.status === 200) {
         const info = await apiResponse.json();
         let awsURL = "";
         let awsTenant = "";
         let awsAccess = "";
         let azureTenant = "";
         let azureClient = "";
         let azureSecret = "";
         let secretStatus = false;
         for (let i=0; i < info.length; i++) {
             if (info[i].key === "AWS") {
                awsURL = info[i].properties.awsUrl;
                awsTenant = info[i].properties.awsTenantID;
                awsAccess = info[i].properties.awsAccessToken;
             }
             if (info[i].key === "AZURE") {
                azureTenant = info[i].properties.azureTenantId;
                azureClient = info[i].properties.azureClientId;
                azureSecret = info[i].properties.azureClientSecret;
                if (azureSecret !== undefined ) {
                   secretStatus = true;
                }
             }
         }
         this.setState({selectedKeys : info, aws_url: awsURL,
                        aws_access_token: awsAccess,
                        aws_tenant_id: awsTenant,
                        azure_client_id: azureClient,
                        azure_tenant_id: azureTenant,
                        azure_client_secret: azureSecret,
                        showSecret: secretStatus,
                        currentKeyType: info[0].key})

      } else {
         this.setState({selectedKeys : [], aws_url: "",
                        aws_access_token: "",
                        aws_tenant_id: "",
                        azure_client_id: "",
                        azure_tenant_id: "",
                        azure_client_secret: "",
                        currentKeyType: ""})
      }
   }

   handleDomainChange(e) {
       this.setState({selectedDomainIndex: e.target.value,
                      aws_url: "", aws_access_token: "",
                      aws_tenant_id: "", azure_client_id: "",
                      azure_client_secret: "", azure_tenant_id: "",
                      showSecret: false, currentKeyType: ""});
       this.getDomain(e.target.value);
   }

   handleAWSUrlChange(e) {
     this.setState({aws_url: e.target.value, isDirty: true});
   }

   handleAWSTenantIDChange(e) {
     this.setState({aws_tenant_id: e.target.value, isDirty: true});
   }

   handleAWSAccessTokenChange(e) {
     this.setState({aws_access_token: e.target.value, isDirty: true});
   }

   handleAzureClientIdChange(e) {
     this.setState({azure_client_id: e.target.value, isDirty: true});
   }

   handleAzureClientSecretChange(e) {
     this.setState({azure_client_secret: e.target.value, isDirty: true});
   }

   handleAzureTenantIdChange(e) {
     this.setState({azure_tenant_id: e.target.value, isDirty: true});
   }

   async updateDatabase() {
        try {
           const domainID = this.state.domains[this.state.selectedDomainIndex].id;
           const key = this.state.currentKeyType;
           let integrationData = "";
           if (this.state.currentKeyType === "AWS") {
               integrationData = JSON.stringify({"awsAccessToken": `${this.state.aws_access_token}`,
                                               "awsTenantId": `${this.state.aws_tenant_id}`,
                                               "awsUrl": `${this.state.aws_url}`,
                                               "companyDomainId": `${domainID}`,
                                               "key": key,});
           } else if (this.state.currentKeyType === "AZURE") {
               integrationData = JSON.stringify({"azureClientId": `${this.state.azure_client_id}`,
                                               "azureClientSecret": `${this.state.azure_client_secret}`,
                                               "azureTenantId": `${this.state.azure_tenant_id}`,
                                               "companyDomainId": `${domainID}`,
                                               "key": key,});
           }
           const companyDetailsByIdUrl = nl3ApiBase + `/domains/${domainID}/integrations/${key}`;
           const apiResponse = await fetch(companyDetailsByIdUrl, {
                method: 'PUT',
                headers: {
                  'Authorization': `Bearer ${this.state.companyToken}`,
                  'Content-Type': 'application/json',
                },

                body: `${integrationData}`,
              });

           if (apiResponse.status === 200) {
               this.setState({isDirty: false});
           }
           return(apiResponse.status);
        } catch (e) {
           console.log(e.message);
        }
   }

   handleUpdateData(e) {
      e.preventDefault();
      this.updateDatabase()
         .then(status => {
                if (status === 200) {
                    nl3Utils.nl3Toast("Successfully updated integration information",true,"success");
                } else {
                    nl3Utils.nl3Toast("Error! integration information was not updated.",true,"error");
                }
              })
         .catch(e => {
                console.log(e.message);
              });
   }

   handleBack() {
     this.props.history.push('/mydomains');
   }

   handleKeyTypeChange(e) {
     this.setState({currentKeyType: e.target.value});
   }

   render() {
     if (this.state.selectedDomain === null) {
       return (<></>);
     } else {
       return (
         <>
         <Prompt message="Unsaved Changes! Are you sure you want leave?" when={this.state.isDirty}/>
         <div className="whiteCard card">
                   <div className="card-header d-flex justify-content-between flex-wrap">
                     <h4 className="card-title">
                        <span style={{color:'green'}}>{this.state.selectedDomain.name}</span>
                            &nbsp;&nbsp;Integration Management
                     </h4>
                   </div>
                   <br/><br/>

           <Row>
             <Col md="12" lg="12">
               <Row className="row-cols-1">

                 <div className=" card-body p-3 ">
                   <div className="">
                     <div className="table-wrapper-scroll-y accounts-table d-flex justify-content-between">
                       <div className='blackText'>
                       <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Select Application:&nbsp;&nbsp;</label>
                       <select
                               value={this.state.selectedDomainIndex}
                               onChange={this.handleDomainChange.bind(this)}>
                           {
                              this.state.domains.map((domain, index) => {
                                   return ( <option key={index} value={index}>{domain.name}</option> ) })
                           }
                       </select>
                       <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Select Integration:&nbsp;&nbsp;</label>
                       <select id="keyTypeList"
                               value={this.state.currentKeyType}
                               onChange={this.handleKeyTypeChange.bind(this)}>
                         <option value="AWS">AWS Configuration</option>
                         <option value="AZURE">Azure Configuration</option>
                       </select>
                       </div>
                     </div>
                     { this.state.selectedKeys[0] !== undefined &&
                         this.state.selectedKeys.map((item,index) => {
                            if ((item.key === this.state.currentKeyType) && (this.state.currentKeyType === "AWS")) {
                               return (
                                 <>
                                 <br/>
                                 <label className="blackText" style={{"paddingLeft":"1.8em", "paddingRight":"4.6em"}}>AWS URL:&nbsp;   </label>
                                 <input type="text" style={{width:"500px"}} value={this.state.aws_url} onChange={this.handleAWSUrlChange.bind(this)} /><br/>
                                 <label className="blackText" style={{"paddingLeft":"1.8em"}}>AWS Access Token:&nbsp;   </label>
                                 <input type="text" style={{width:"500px"}} value={this.state.aws_access_token} onChange={this.handleAWSAccessTokenChange.bind(this)} /><br/>
                                 <label className="blackText" style={{"paddingLeft":"1.8em","paddingRight":"2.0em"}}>AWS Tenant ID:&nbsp;   </label>
                                 <input type="text" style={{width:"500px"}} value={this.state.aws_tenant_id} onChange={this.handleAWSTenantIDChange.bind(this)} /><br/>
                                 </>
                               )
                            }
                            if ((item.key === this.state.currentKeyType) && (this.state.currentKeyType === "AZURE")) {
                               return (
                                 <>
                                 <br/>
                                 <label className="blackText" style={{"paddingLeft":"1.8em", "paddingRight":"3.5em"}}>Azure Tenant ID:&nbsp;   </label>
                                 <input type="text" style={{width:"500px"}} value={this.state.azure_tenant_id} onChange={this.handleAzureTenantIdChange.bind(this)} /><br/>
                                 <label className="blackText" style={{"paddingLeft":"1.8em", "paddingRight":"4.0em"}}>Azure Client ID:&nbsp;   </label>
                                 <input type="text" style={{width:"500px"}} value={this.state.azure_client_id} onChange={this.handleAzureClientIdChange.bind(this)} /><br/>
                                 <br/>
                                 <Form.Check style={{color:"black", marginLeft:"1.8em"}}
                                     type={'checkbox'}
                                     label={"Use Client Secret for access token"}
                                     id={"showAccSecret"}
                                     checked={this.state.showSecret}
                                     onChange={(e) => this.setState({ showSecret: e.target.checked })}
                                 />
                                 { this.state.showSecret &&
                                    <>
                                    <label className="blackText" style={{"paddingLeft":"1.8em", "paddingRight":"1.9em"}}>Azure Client Secret:&nbsp;   </label>
                                    <input type="text" style={{width:"500px"}} value={this.state.azure_client_secret} onChange={this.handleAzureClientSecretChange.bind(this)} /><br/>
                                    </>
                                 }
                                 </>
                               )
                            } else { return(<></>) }
                         })
                     }
                     this.renderInfo()
                   </div>
                 </div>
               </Row>
               <Row>
                  <div align="center">
                      <button className="formButton" onClick={this.handleUpdateData.bind(this)}>Save</button>&nbsp;&nbsp;&nbsp;
                      <button className="formButton" onClick={this.handleBack.bind(this)}>Return</button>
                      <br/><br/>
                  </div>
               </Row>
             </Col>
           </Row>
           </div>
         </>
       )
     }
   }
}

export default CompanyIntegration
