import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

import { useValidEmail, useValidUsername, useValidFirstname,
         useValidLastname, useValidPhone, useValidAddress, useValidPhonecode,
         useValidCity, useValidZipcode, useValidProvince,
         useValidLocation, useValidCompany, useValidTitle } from '../hooks/useAuthHooks'
import { Email, Username, Firstname, Lastname,
         Phone, Address, City, Zipcode, Province, Phonecode,
         Location, Company, Title } from '../components/authComponents'
import * as base64url from '../libs/base64url-arraybuffer'
import * as nl3Utils from '../libs/nl3Utils'
import "../assets/nl3.css"
import NL3Icon from "../assets/images/Next Level 3 - Square .png"

const useStyles = makeStyles({
  root: {
    height: '100vh',
  },
})

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;
const nl3ApiLocalHost = process.env.REACT_APP_NL3_USE_LOCALHOST;

var params = new URLSearchParams(window.location.search);
if (params === undefined|| params === null) {
  params = null;
}

const getUrlParameter = (paramName, defValue = null) => {
  if (params !== null) {
     const paramValue = params.get(paramName);
     if ((paramValue !== null) && (paramValue.length > 0)) {
        return paramValue;
     }
  }
  return defValue;
}

const SignUp: React.FunctionComponent<{}> = () => {
  const classes = useStyles()
  // eslint-disable-next-line
  let impUsrName = localStorage.getItem('NL3IMPORTUSERNAME');
  let addUsrName = localStorage.getItem('NL3ADDDEVICE');
  let enableUsrName = localStorage.getItem('NL3ENABLENEWACCT');
  let authToken = localStorage.getItem('auth-token');

  const stripeCustomerId = getUrlParameter('stripe_customer_id');
  const autoFocusCompany = stripeCustomerId !== null;

  // Initial form value state - special cases - email and username
  const initialEmail = getUrlParameter('_email', '');
  var initialUsername = getUrlParameter('_username', null);
  if (initialUsername == null) {
    initialUsername =
      (impUsrName !== null) ? impUsrName :
      (addUsrName !== null) ? addUsrName :
      (enableUsrName !== null) ? enableUsrName :
      (initialEmail !== null) ? initialEmail :
    '';
  }
  const { username, setUsername, usernameIsValid } = useValidUsername(initialUsername)
  const { email, setEmail, emailIsValid } = useValidEmail(initialEmail)

  // Initial form value state - standard mappings
  const { firstname, setFirstname, firstnameIsValid } = useValidFirstname(getUrlParameter('_firstname', ''))
  const { lastname, setLastname, lastnameIsValid } = useValidLastname(getUrlParameter('_lastname', ''))
  const { phone, setPhone, phoneIsValid } = useValidPhone(getUrlParameter('_phone', ''))
  const { phonecode, setPhonecode } = useValidPhonecode(getUrlParameter('_phonecode', '+1'))
  const { address, setAddress, addressIsValid } = useValidAddress(getUrlParameter('_address', ''))
  const { city, setCity, cityIsValid } = useValidCity(getUrlParameter('_city', ''))
  const { zipcode, setZipcode, zipcodeIsValid } = useValidZipcode(getUrlParameter('_zip', ''))
  const { province, setProvince, provinceIsValid } = useValidProvince(getUrlParameter('_province', ''))
  const { location, setLocation, locationIsValid } = useValidLocation(getUrlParameter('_location', ''))
  const { company, setCompany, companyIsValid } = useValidCompany(getUrlParameter('_company', ''))
  const { title, setTitle, titleIsValid } = useValidTitle(getUrlParameter('_title', ''))

  // Internal state
  const [error, setError] = useState('')
  const [code, setCode] = useState('')
  const [created, setCreated] = useState(false)
  const [imported, setImported] = useState(false)
  const [addDevice, setAddDevice] = useState(false)
  const [enableAcct, setEnableAcct] = useState(false)
  const [actionFailed, setActionFailed] = useState(false)

  const history = useHistory()

  var portalType = process.env.REACT_APP_NL3_PORTALTYPE;
  if (portalType.length === 0) {
    // must be a refresh
    history.push('/');
  }

  var isValid = false;

  if (portalType === 'COMPANY') {
    isValid = !emailIsValid ||
    email.length === 0 ||
    !usernameIsValid ||
    username.length === 0 ||
    !addressIsValid ||
    address.length === 0 ||
    !cityIsValid ||
    city.length === 0 ||
    !zipcodeIsValid ||
    zipcode.length === 0 ||
    !provinceIsValid ||
    province.length === 0 ||
    !locationIsValid ||
    location.length === 0 ||
    !companyIsValid ||
    company.length === 0 ||
    !firstnameIsValid ||
    firstname.length === 0 ||
    !lastnameIsValid ||
    lastname.length === 0 ||
    !phoneIsValid ||
    phone.length === 0 ||
    !titleIsValid ||
    title.length === 0

  } else {
    isValid = !emailIsValid ||
    email.length === 0 ||
    !usernameIsValid ||
    username.length === 0 ||
    !firstnameIsValid ||
    firstname.length === 0 ||
    !lastnameIsValid ||
    lastname.length === 0 ||
    !phoneIsValid ||
    phone.length === 0
  }

  const backGround = (portalType === 'USER') ? 'login-component' : 'company-login-component';

  const handleCodeChange = (e) => {
     setCode(e.target.value);
  }

  const createCredential = useCallback( async (imported, addDevice, enableAcct) => {
      let globalRegisteredCredentials = "";
      let globalRegisteredCredentialsJSON = {};
      setError("");
      try {
          //build the credentials options requirements
          var credOptionsRequest = {
            attestation: 'none',
            username: username,
            credentialNickname: 'web1',
            authenticatorSelection: {
              authenticatorAttachment: [ 'platform' ],
              userVerification: 'required',
              residentKey: 'preferred',
              requireResidentKey : false,
            }
          };

          //generate credentials request to be sent to navigator.credentials.create
          var startURL = nl3ApiBase + '/registration/start';
          if (nl3ApiLocalHost === 'TRUE') {
             startURL += '?localhost=true';
          }
          var startAPIRsp = await fetch(startURL, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(credOptionsRequest),
          }); 
          if (startAPIRsp.status !== 200) {
             setActionFailed(true);
             setError("Sign Up Failed - Contact Support");
          } else {
             setActionFailed(false);
             setError("");
             var startResponse = await startAPIRsp.json();
             var nl3RegistrationId = startResponse.registrationId;
             var credOptions = startResponse.publicKeyCredentialCreationOptions;
             var challenge = credOptions.challenge;
             credOptions.challenge = base64url.decode(credOptions.challenge); 
             credOptions.user.id = base64url.decode(credOptions.user.id);

            //----------create credentials using available authenticator
            const cred = await navigator.credentials.create({
                publicKey: credOptions
            });
            // parse credentials response to extract id and public-key,
            // this is the information needed to register the user in Cognito
            var credential = {};
            credential.id =     cred.id;
            credential.rawId =  base64url.encode(cred.rawId);
            credential.type =   cred.type;
            credential.challenge = challenge;
            credential.clientExtensionResults = {};
            let transports = [];
            if (cred.response.getTransports()) {
                transports = cred.response.getTransports();
            }
            if (cred.response) {
              var clientDataJSON = base64url.encode(cred.response.clientDataJSON);
              var attestationObject = base64url.encode(cred.response.attestationObject);
              credential.response = {
                clientDataJSON,
                attestationObject,
                transports
              };
            }

            var nl3Credential = {
              'credential' : credential,
              'reqiureUserVerification' : true,
              'registrationId' : nl3RegistrationId
            };

            var finishURL = nl3ApiBase + '/registration/finish';
            if (nl3ApiLocalHost === 'TRUE') {
               finishURL += '?localhost=true';
            }
            var finishAPIRsp = await fetch(finishURL, {
                  method: "POST",
                  headers: {
                      'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(nl3Credential),
            });
            if (finishAPIRsp.status !== 200) {
               setActionFailed(true);
               setError("Sign Up Failed - Contact Support");
            } else {
               setActionFailed(false);
               setError("");
               const credResponse = await finishAPIRsp.json();
               const credId = credResponse.credId;
               globalRegisteredCredentialsJSON = {id: credResponse.credId,publicKey: credResponse.publicKey};
               globalRegisteredCredentials = JSON.stringify(globalRegisteredCredentialsJSON);
               const experation = new Date();
               experation.setFullYear(experation.getFullYear() + 1);

               //----------credentials have been created, now sign-up the user in Cognito
               var publicKeyCred = btoa(globalRegisteredCredentials);
               var signupRequest = {};
               var signupURL = '';
               var fullPhone = phonecode + phone;
               setCode('');
               if (imported) {
                       signupURL = nl3ApiBase + '/registration/confirmUser';
                       signupRequest = {'username': username, 'publicKeyCred': publicKeyCred,
                                        'credId': credId, 'registrationId': nl3RegistrationId}
               } else if (addDevice) {
                       signupURL = nl3ApiBase + '/registration/addNewDevice';
                       signupRequest = {'username': username, 'publicKeyCred': publicKeyCred,
                                        'credId': credId, 'registrationId': nl3RegistrationId}
               } else if (enableAcct) {
                       signupURL = nl3ApiBase + '/registration/signup';
                       signupRequest = {
                          'username': username, 'email': email, 'first_name': firstname,
                          'last_name': lastname, 'phone': fullPhone, 'publicKeyCred': publicKeyCred,
                          'credId': credId, 'registrationId': nl3RegistrationId
                       }
               } else {
                   if (portalType === 'COMPANY') {
                       var awsToken = localStorage.getItem('awsToken');
                       signupURL = nl3ApiBase + '/registration/companySignup';
                       if (awsToken !== null) {
                          signupRequest = {
                             'username': username, 'email': email, 'first_name': firstname,
                             'last_name': lastname, 'phone': fullPhone, 'publicKeyCred': publicKeyCred,
                             'address': address, 'city': city, 'state': province, 'zipcode': zipcode,
                             'location': location, 'company': company, title: title,
                             'credId': credId, 'registrationId': nl3RegistrationId,
                             'awsMarketplaceToken': awsToken
                          }
                       } else if (stripeCustomerId != null) {
                          signupURL = nl3ApiBase + '/registration/stripeSignup';
                          const stripePriceId = getUrlParameter('stripe_price_id');
                          signupRequest = {
                            'username': username, 'email': email, 'first_name': firstname,
                            'last_name': lastname, 'phone': fullPhone, 'publicKeyCred': publicKeyCred,
                            'address': address, 'city': city, 'state': province, 'zipcode': zipcode,
                            'location': location, 'company': company, title: title,
                            'credId': credId, 'registrationId': nl3RegistrationId,
                            'stripeCustomerId': stripeCustomerId, 'stripePriceId': stripePriceId
                         }
                       }
                       else {
                        signupRequest = {
                             'username': username, 'email': email, 'first_name': firstname,
                             'last_name': lastname, 'phone': fullPhone, 'publicKeyCred': publicKeyCred,
                             'address': address, 'city': city, 'state': province, 'zipcode': zipcode,
                             'location': location, 'company': company, title: title,
                             'credId': credId, 'registrationId': nl3RegistrationId
                          }
                       }
                   } else {
                      signupURL = nl3ApiBase + '/registration/signup';
                       signupRequest = {
                          'username': username, 'email': email, 'first_name': firstname,
                          'last_name': lastname, 'phone': fullPhone, 'publicKeyCred': publicKeyCred,
                          'credId': credId, 'registrationId': nl3RegistrationId
                       }
                   }
               }
               var signupAPIRsp = await fetch(signupURL, {
                     method: "POST",
                     headers: {
                         'Content-Type': 'application/json',
                     },
                     body: JSON.stringify(signupRequest),
               });
               if (signupAPIRsp.status !== 200) {
                  setActionFailed(true);
                  if (imported) {
                    setError("Authentication failed - Contact Support");
                  } else if (addDevice) {
                    setError("Device Registration failed - Contact Support");
                  } else if (enableAcct) {
                    setError("Account enablement failed - Contact Support");
                  } else {
                    setError("Sign Up Failed - Contact Support");
                  }
               } else {
                  setActionFailed(false);
                  setError("");
                  localStorage.removeItem('awsToken');
                  if (addDevice) {
                    setError("After you have approved the pairing of the new device, click here to return to sign in.");
                    setCreated(true);
                  } else {
                     const modal = document.querySelector(".nl3MFAWindow")
                     modal.style.display = "block";
                  }
               }
            }
          }  
      } catch (e) {
          console.log(e);
          if (e.message.includes("The operation either timed out or was not allowed")) {
             setError("The operation either timed out or was not allowed.");
          } else {
             setError(e.message);
          }
          setActionFailed(true);
      }
  },[address, city, company, email, firstname,
     lastname, location, phone, phonecode, portalType, province,
     title, username, zipcode, stripeCustomerId
    ])

  const registerAccount = () => {
     createCredential(false,false);
  }

  const handleResendCode = async () => {
      try {
          var resendCode = { 'username':username};

          const resendURL = nl3ApiBase + '/registration/resendSignupCode';
          var resendAPIRsp = await fetch(resendURL, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(resendCode),
          });
          if (resendAPIRsp.status !== 200) {
             setActionFailed(true);
             setError("An error occurred, please contact support.");
             const modal = document.querySelector(".nl3MFAWindow")
             modal.style.display = "none";
          } else {
             setActionFailed(false);
             setError("New Code has been sent.");
          }
      } catch (e) {console.log(e);}
  }

  const handleSubmitCode = async () => {
      setError("");
      try {
          var confirmSignupRequest = { 'username':username, 'confirmationCode': code};

          const confirmURL = nl3ApiBase + '/registration/confirmSignup';
          var confirmSignupAPIRsp = await fetch(confirmURL, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(confirmSignupRequest),
          });
          if (confirmSignupAPIRsp.status !== 200) {
             setError("Please verify code was entered correctly.");
          } else {
             const modal = document.querySelector(".nl3MFAWindow")
             modal.style.display = "none";
             if (imported) {
                setError("Account Authenticated, now sign-in.");
             } else if (addDevice) {
                setError("After you have approved the pairing of the new device, click here to return to sign in.");
             } else if (enableAcct) {
                setError("Account enabled, now sign-in.");
             } else {
                setError("Registration successful, now sign-in.");
             }
             setCreated(true);
          }
      } catch (e) {console.log(e);}
  }

  const signUp = (
    <>
      <Grid container direction="column" justifyContent="center" alignItems="center">
         <Box width="90%" m={1}>
            <Username usernameIsValid={usernameIsValid} defUserName={username} setUsername={setUsername} />
            <Email defaultValue={email} emailIsValid={emailIsValid} setEmail={setEmail} />
         </Box>
      </Grid>
      { (portalType === 'COMPANY') &&
         <>
           <span style={{height:'10px'}}>Billing Address:</span>
           <Grid container direction="column" justifyContent="center" alignItems="center">
              <Box width="90%" m={1}>
                 <Company autoFocus={autoFocusCompany} defaultValue={company} companyIsValid={companyIsValid} setCompany={setCompany} />
                 <Address defaultValue={address} addressIsValid={addressIsValid} setAddress={setAddress} />
                 <City defaultValue={city} cityIsValid={cityIsValid} setCity={setCity} />
                 <Province defaultValue={province} provinceIsValid={provinceIsValid} setProvince={setProvince} />
                 <Zipcode defaultValue={zipcode} zipcodeIsValid={zipcodeIsValid} setZipcode={setZipcode} />
                 <Location defaultValue={location} locationIsValid={locationIsValid} setLocation={setLocation} />
              </Box>
           </Grid>
           <span style={{height:'10px'}}>Administrator:</span>
           <Grid container direction="column" justifyContent="center" alignItems="center">
              <Box width="90%" m={1}>
                 <Firstname defaultValue={firstname} firstnameIsValid={firstnameIsValid} setFirstname={setFirstname} />
                 <Lastname defaultValue={lastname} lastnameIsValid={lastnameIsValid} setLastname={setLastname} />
                 <Title defaultValue={title} titleIsValid={titleIsValid} setTitle={setTitle} />
                 <Phonecode defaultValue={phonecode} phonecodeIsValid={true} setPhonecode={setPhonecode} />&nbsp;
                 <Phone defaultValue={phone} phoneIsValid={phoneIsValid} setPhone={setPhone} />
              </Box>
           </Grid>
         </>
      }
      { (portalType === 'USER') &&
         <>
           <Grid container direction="column" justifyContent="center" alignItems="center">
              <Box width="90%" m={1}>
                <Firstname firstnameIsValid={firstnameIsValid} setFirstname={setFirstname} />
                <Lastname lastnameIsValid={lastnameIsValid} setLastname={setLastname} />
                 <Phonecode phonecodeIsValid={true} setPhonecode={setPhonecode} />&nbsp;
                <Phone phoneIsValid={phoneIsValid} setPhone={setPhone} />
              </Box>
           </Grid>
         </>
      }
      <Grid container direction="row" justifyContent="center">
      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>
      </Grid>

      {/* Buttons */}
      <Box mt={2}>
        <Grid container direction="row" justifyContent="center">
          { (authToken !== null) &&
             <>
             <Box m={1}>
               <Button onClick={() => history.push("/?auth-token="+authToken)} color="secondary" variant="contained">
                 Cancel
               </Button>
             </Box>
             <Box m={1}>
                <Button disabled={isValid} color="primary" variant="contained" onClick={registerAccount}>
                  Enable
                </Button>
             </Box>
             </>
          }
          { (authToken === null) &&
             <>
             <Box m={1}>
               <Button onClick={() => history.push("/")} color="secondary" variant="contained">
                 Sign In
               </Button>
             </Box>
             <Box m={1}>
                <Button disabled={isValid} color="primary" variant="contained" onClick={registerAccount}>
                  Register
                </Button>
             </Box>
             </>
          }
        </Grid>
      </Box>
      {/* The following code creates the MFA popup */}
      <div className="nl3MFAWindow" style={{background:"#ececec"}}>
         <div className="nl3MFAWindow_content">
            <div align="center" className="nl3MFAWindow_title">
                  <img height="75" width="75" src={NL3Icon} alt=""/>
                  <span/>
                  <p>Verify Code</p>
             </div>
             <div align="center">
                  <span>Check your email for a verification code.</span>
                  <br/><br/>
                  <input type="text" placeholder="Enter the 6-digit code" value={code}
                                onChange={handleCodeChange.bind(this)}/>
                  <br/>
                  <Grid container direction="row" justifyContent="center">
                  <Box mt={2}>
                    <Typography color="error" variant="body2">
                      {error}
                    </Typography>
                  </Box>
                  </Grid>
                  <br/>
                  <Button disabled={code.length !== 6} color="primary" variant="contained" onClick={(e) => handleSubmitCode()}>
                    Confirm
                  </Button>
                  <Box mt={2}>
                     <Box onClick={() => handleResendCode()}>
                       <button className="clearButton">Resend Code</button>
                     </Box>
                  </Box>
             </div>
          </div>
      </div>
      {/* End of popup code */}
    </>
  )

  const handleSignInClicked = () => {
     nl3Utils.nl3setUserName(username);
     history.push('/');
  }

  const accountCreated = (
    <>
      <Grid container direction="row" justifyContent="center" alignItems="center">
         { !imported && !addDevice &&
             <>
               <Typography variant="h5">{`Created account: ${username}`}</Typography>
               <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Box mt={2}>
                    <Typography color="primary" variant="body2">
                      {error}
                    </Typography>
                  </Box>
               </Grid>
               <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Box m={4}>
                    <Button onClick={() => handleSignInClicked() } color="primary" variant="contained">
                          Return
                    </Button>
                  </Box>
               </Grid>
             </>
         }
         { imported && !addDevice &&
             <>
               <Typography variant="h5">{`Authenticated: ${username}`}</Typography>
               <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Box mt={2}>
                    <Typography color="primary" variant="body2">
                      {error}
                    </Typography>
                  </Box>
               </Grid>
               <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Box m={4}>
                    <Button onClick={() => handleSignInClicked() } color="primary" variant="contained">
                          Return
                    </Button>
                  </Box>
               </Grid>
             </>
         }
         { addDevice && !imported &&
             <>
               <Typography variant="h5">{`A new device request has been sent to your previously paired devices. To complete pairing for this device, you must login and accept the new device request on a previously paired device.`}</Typography>
               <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Box mt={2}>
                    <Typography color="primary" variant="body2">
                      {error}
                    </Typography>
                  </Box>
               </Grid>
               <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Box m={4}>
                    <Button onClick={() => handleSignInClicked() } color="primary" variant="contained">
                          Return
                    </Button>
                  </Box>
               </Grid>
             </>
         }
      </Grid>
    </>
  )

  const accountImported = (
    <>
      <Grid container direction="row" justifyContent="center" alignItems="center">
         <Typography variant="h5">{`account: ${username}`}</Typography>
         <Grid container direction="row" justifyContent="center" alignItems="center">
            <Box mt={2}>
              <Typography color="error" variant="body2">
                  {error}
              </Typography>
            </Box>
         </Grid>
         <Grid container direction="row" justifyContent="center" alignItems="center">
         { (actionFailed) ?
             <Box m={4}>
               <Button onClick= {handleSignInClicked} color="primary" variant="contained">
                 Return
               </Button>
             </Box>
           :
             <Box m={4}>
             </Box>
         }
         </Grid>
      </Grid>
      {/* The following code creates the MFA popup */}
      <div className="nl3MFAWindow" style={{background:"#ececec"}}>
         <div className="nl3MFAWindow_content">
            <div align="center" className="nl3MFAWindow_title">
                  <img height="75" width="75" src={NL3Icon} alt=""/>
                  <span/>
                  <p>Verify Code</p>
             </div>
             <div align="center">
                  <span>Check your email for a verification code.</span>
                  <br/><br/>
                  <input type="text" placeholder="Enter the 6-digit code" value={code}
                                onChange={handleCodeChange.bind(this)}/>
                  <br/>
                  <Grid container direction="row" justifyContent="center">
                  <Box mt={2}>
                    <Typography color="error" variant="body2">
                      {error}
                    </Typography>
                  </Box>
                  </Grid>
                  <br/>
                  <Button disabled={code.length !== 6} color="primary" variant="contained" onClick={(e) => handleSubmitCode()}>
                    Confirm
                  </Button>
                  <Box mt={2}>
                     <Box onClick={() => handleResendCode()}>
                       <button className="clearButton">Resend Code</button>
                     </Box>
                  </Box>
             </div>
          </div>
      </div>
      {/* End of popup code */}
    </>
  )

  const accountNewDevice = (
    <>
      <Grid container direction="row" justifyContent="center" alignItems="center">
         <Typography variant="h5">{`account: ${username}`}</Typography>
      </Grid>
      <Grid container direction="row" justifyContent="center" alignItems="center">
         <Box mt={2}>
           <Typography color="error" variant="body2">
               {error}
           </Typography>
         </Box>
      </Grid>
      <Grid container direction="row" justifyContent="center" alignItems="center">
         { (actionFailed) ?
             <Box m={4}>
               <Button onClick= {handleSignInClicked} color="primary" variant="contained">
                 Return
               </Button>
             </Box>
           :
             <Box m={4}>
             </Box>
         }
      </Grid>
    </>
  )

  useEffect(() => {
      let impUsrName = localStorage.getItem('NL3IMPORTUSERNAME');
      let addUsrName = localStorage.getItem('NL3ADDDEVICE');
      let enableUsrName = localStorage.getItem('NL3ENABLENEWACCT');
      let stripeCustomerId = localStorage.getItem('stripeCustomerId');
      if (enableUsrName !== null) {
          setEnableAcct(true);
          localStorage.removeItem('NL3ENABLENEWACCT');
      } else if (impUsrName !== null) {
          setImported(true);
          localStorage.removeItem('NL3IMPORTUSERNAME');
          createCredential(true,false,false);
      } else if (addUsrName !== null) {
          setAddDevice(true);
          localStorage.removeItem('NL3ADDDEVICE');
          createCredential(false,true,false);
      } else if (stripeCustomerId !== null) {
         createCredential(false,false,false);
      }
  }, [createCredential])

  return (
    <section className="login-content">
      <div className={backGround}>
        <Grid className={classes.root} container direction="row" justifyContent="center" alignItems="center">
          <Grid xs={11} sm={6} lg={4} container direction="row" justifyContent="center" alignItems="center" item>
            <Paper style={{ backgroundColor: '#EBF4FA', width: '100%', padding: 16 }}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                {/* Title */}
                <Box m={3}>
                  <Grid container direction="row" justifyContent="center" alignItems="center">
                    { imported ? <Typography variant="h3">Authenticate Account</Typography> :
                      addDevice ? <Typography variant="h3">Passkey Creation</Typography> :
                      enableAcct ? <Typography variant="h3">Enable Account</Typography> :
                                    <Typography variant="h3">Register Account</Typography> }
                  </Grid>
                </Box>
                {created && accountCreated}
                {!created && !imported && !addDevice && signUp}
                {!created && imported  && !addDevice && accountImported}
                {!created && !imported && addDevice  && accountNewDevice}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </section>
  )
}

export default SignUp
