import React, { Component } from 'react'
import {Row,Col} from 'react-bootstrap'
import {Prompt} from 'react-router-dom';
import Card from '../../components/Card'
import Store from '../../store'
import moment from 'moment-timezone';
import NL3DualListBox from "./dual-listbox";
import * as cognito from '../../libs/cognito';
import * as nl3Utils from '../../libs/nl3Utils';

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;

class EditPolicyGroups extends Component {
   constructor(props) {
     super(props)
     this.handlePolicyChange = this.handlePolicyChange.bind(this);
     this.handleUserPolicyChange = this.handleUserPolicyChange.bind(this);
     this.state = {
         policyID : null,
         policyName : "",
         policyType: "",
         companyToken: "",
         status: 0,
         isDirty: false,
         startDate: "",
         endDate: "",
         origDailyAction: "",
         origDailyTime: 0,
         origFrequency: "",
         origMultiAction: "",
         origAlertAction: "",
         initGroups: [] ,
         members: [] ,
         groupList: [],
         initUsers: [] ,
         userMembers: [] ,
         userList: [],
     };
     Store.dispatch({type: 'COMPANY'});
   }

   async componentDidMount () {
      // Get the access token
      try {
         const session = await cognito.getSession();
         this.setState({companyToken : session.accessToken.jwtToken,
                        policyID : Store.getState().policy.id});

         this.getPolicy()
         .then(status => {
           if ((status === 200) || (status === 201)) {
               this.getGroupMembers()
               .then(status => {
                  if ((status === 200) || (status === 201)) {
                     this.getAllGroups()
                     .then(status => {
                        if ((status === 200) || (status === 201)) {
                           this.getUserList()
                           .then(status => {
                              if ((status === 200) || (status === 201)) {
                                 this.getUserMembers()
                                 .then(status => {
                                    if ((status === 200) || (status === 201)) {
                                       this.setState({status: 200});
                                    } else {
                                       this.setState({status: 400});
                                    }
                                 })
                                 .catch(e => {
                                    console.log(e.message);
                                 });
                              } else {
                                 this.setState({status: 400});
                              }
                           })
                           .catch(e => {
                              console.log(e.message);
                           });
                        } else {
                           this.setState({status: 400});
                        }
                     })
                     .catch(e => {
                        console.log(e.message);
                     });
                  } else {
                     this.setState({status: 400});
                  }
               })
               .catch(e => {
                  console.log(e.message);
               });
           } else {
               this.setState({status: 400});
           }
         })
         .catch(e => {
             console.log(e.message);
         });
      } catch (e) {
          console.log(e.message);
          this.props.history.push('/');
          return;
      }
   }

   async getUserList () {
      const companyUsersUrl = nl3ApiBase + `/companies/mycompany/users?orderBy=first_name:asc`;
      const apiResponse = await fetch(companyUsersUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.state.companyToken}`,
        },
      });

      if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
         const records = await apiResponse.json();
         let tmpMem = [];
         for (let y=0; y < records.length; y++) {
            tmpMem.push(records[y].id);
         }
         this.setState({userList: records.items});
      }
      return(apiResponse.status);
   }

   async getUserMembers() {
      const policyUrl = nl3ApiBase + `/policies/${this.state.policyID}/members`;
      const apiResponse = await fetch(policyUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.state.companyToken}`,
        },
      });

      if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
         const records = await apiResponse.json();
         let tmpMem = [];
         for (let y=0; y < records.items.length; y++) {
            tmpMem.push(records.items[y].id);
         }
         this.setState({initUsers: tmpMem, userMembers: tmpMem});
      }
      return( apiResponse.status);
   }

   async getPolicy() {
      // get the company policy
      const policyUrl = nl3ApiBase + `/policies/${this.state.policyID}`;
      const apiResponse = await fetch(policyUrl, {
           method: 'GET',
           headers: {
             Authorization: `Bearer ${this.state.companyToken}`,
           },
         });

      if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
          const record = await apiResponse.json();
          let start = moment(record.activeDate).format("MM/DD/YYYY");
          let end = moment(record.endDate).format("MM/DD/YYYY");
          let stDate = moment.utc(record.scheduleDateTime).toDate();
          let stTime = moment(stDate).local().format("h:mm a");
          this.setState({policyName: record.policyName, policyType: record.policyType, 
                         policyKey: record.policyKey,
                         startDate: start, endDate: end,
                         origFrequency: record.frequencyOfAction,
                         origDailyAction: record.scheduledAction,
                         origDailyTime: stTime,
                         origAlertAction: record.alertAction,
                         origMultiAction: record.multiApprovalAction});
      } else {
          this.setState({policyName: ""});
      }
      return(apiResponse.status);
    }

   async getGroupMembers() {
      const policyUrl = nl3ApiBase + `/policies/${this.state.policyID}/groupMembers`;
      const apiResponse = await fetch(policyUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.state.companyToken}`,
        },
      });

      if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
         const records = await apiResponse.json();
         let tmpMem = [];
         for (let y=0; y < records.length; y++) {
            tmpMem.push(records[y].id);
         }
         this.setState({initGroups: tmpMem, members: tmpMem});
      }
      return( apiResponse.status);
   }

   async getAllGroups() {
      // get the company groups
      const groupUrl = nl3ApiBase + `/groups`;
      const apiResponse = await fetch(groupUrl, {
           method: 'GET',
           headers: {
             Authorization: `Bearer ${this.state.companyToken}`,
           },
         });

      if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
          const records = await apiResponse.json();
          this.setState({groupList: records});
      }
      return (apiResponse.status);
    }

   async updatePolicyDB () {
      let removeGroups = [];
      let addGroups = [];
      let removeUsers = [];
      let addUsers = [];
      for (let i=0; i<this.state.members.length; i++) {
          // see if group was added
          if (!this.state.initGroups.includes(this.state.members[i])) {
             addGroups.push(this.state.members[i]);
          }
      }
      for (let i=0; i<this.state.initGroups.length; i++) {
          // see if group was removed
          if (!this.state.members.includes(this.state.initGroups[i])) {
             removeGroups.push(this.state.initGroups[i]);
          }
      }
      for (let i=0; i<this.state.userMembers.length; i++) {
          // see if user was added
          if (!this.state.initUsers.includes(this.state.userMembers[i])) {
             addUsers.push(this.state.userMembers[i]);
          }
      }
      for (let i=0; i<this.state.initUsers.length; i++) {
          // see if User was removed
          if (!this.state.userMembers.includes(this.state.initUsers[i])) {
             removeUsers.push(this.state.initUsers[i]);
          }
      }
      if ((removeGroups.length === 0) && (addGroups.length === 0) &&
          (removeUsers.length === 0) && (addUsers.length === 0)) {
         //nothing to do
         return(-1);
      }
      const newPolicyUrl = nl3ApiBase + `/policies/${this.state.policyID}`;
      const policyData = JSON.stringify({ "groupMembersToAdd" : addGroups,
                                          "groupMembersToRemove": removeGroups,
                                          "membersToAdd" : addUsers,
                                          "membersToRemove" : removeUsers});
      const apiResponse = await fetch(newPolicyUrl, {
             method: 'PATCH',
             headers: {
               'Authorization': `Bearer ${this.state.companyToken}`,
               'Content-Type': 'application/json',
             },
             body: `${policyData}`,
           });
      return(apiResponse.status);
   }

   handleBack() {
     this.props.history.push('/dashboard/policies');
   }

   handlePolicyChange(members) {
     this.setState({members: members, isDirty: true});
   }

   handleUserPolicyChange(members) {
     this.setState({userMembers: members, isDirty: true});
   }

   handleUpdatePolicy() {
      this.updatePolicyDB()
        .then(status => {
             if (status === -1) {
                nl3Utils.nl3Toast("No Changes Made.",true,"info");
             } else if (status === 200) {
                nl3Utils.nl3Toast("Success! Policy Updated.",true,"success");
                this.setState({initMembers: this.state.members, isDirty: false});
             } else {
                nl3Utils.nl3Toast("Error! Failed to update policy",true,"error");
             }})
         .catch(e => {
             console.log(e.message);
         });
   }

   renderPolicyForm() {
     if (this.state.policyType === "SCHEDULED") {
        return(
           <>
           <tr>
             <td><label className="blackText">Policy Type:&nbsp;</label></td>
             <td><label className="blackText">{this.state.policyType}</label></td>
           </tr>
           <tr>
             <td><label className="blackText">Policy Name:&nbsp;</label></td>
             <td><label className="blackText">{this.state.policyName}</label></td>
           </tr>
           <tr>
             <td><label className="blackText">Dates Valid:&nbsp;</label></td>
             <td><label className="blackText">{this.state.startDate} - {this.state.endDate}</label></td>
           </tr>
           <tr>
             <td><label className="blackText">Frequency:&nbsp;</label></td>
             <td><label className="blackText">{this.state.origFrequency}</label></td>
           </tr>
           <tr>
             <td><label className="blackText">Action:&nbsp;</label></td>
             <td><label className="blackText">{this.state.origDailyAction}</label></td>
           </tr>
           <tr>
             <td><label className="blackText">Time:&nbsp;</label></td>
             <td><label className="blackText">{this.state.origDailyTime}</label></td>
           </tr>
           <tr><td><br/></td></tr>
           <tr><td><br/></td></tr>
           </>
        )
     }
     if (this.state.policyType === "MULTI_APPROVAL") {
        return(
           <>
           <tr>
             <td><label className="blackText">Policy Type:&nbsp;</label></td>
             <td><label className="blackText">{this.state.policyType}</label></td>
           </tr>
           <tr>
             <td><label className="blackText">Policy Name:&nbsp;</label></td>
             <td><label className="blackText">{this.state.policyName}</label></td>
           </tr>
           <tr>
             <td><label className="blackText">Dates Valid:&nbsp;</label></td>
             <td><label className="blackText">ALWAYS</label></td>
           </tr>
           <tr>
             <td><label className="blackText">Frequency:&nbsp;</label></td>
             <td><label className="blackText">ALWAYS</label></td>
           </tr>
           <tr>
             <td><label className="blackText">Action:&nbsp;</label></td>
             <td><label className="blackText">{this.state.origMultiAction}</label></td>
           </tr>
           </>
        )
     }
     if (this.state.policyType === "ALERT") {
        return(
           <>
           <tr>
             <td><label className="blackText">Policy Type:&nbsp;</label></td>
             <td><label className="blackText">{this.state.policyType}</label></td>
           </tr>
           <tr>
             <td><label className="blackText">Policy Name:&nbsp;</label></td>
             <td><label className="blackText">{this.state.policyName}</label></td>
           </tr>
           <tr>
             <td><label className="blackText">Dates Valid:&nbsp;</label></td>
             <td><label className="blackText">ALWAYS</label></td>
           </tr>
           <tr>
             <td><label className="blackText">Frequency:&nbsp;</label></td>
             <td><label className="blackText">ALWAYS</label></td>
           </tr>
           <tr>
             <td><label className="blackText">Action:&nbsp;</label></td>
             <td><label className="blackText">{this.state.origAlertAction}</label></td>
           </tr>
           </>
        )
     }
   }

   render() {
    if ((isNaN(this.state.status)) || (this.state.status === 0)) {
       return (
         <>
           <div>
              <br/><br/><br/>
              <button className="btn btn-primary" type="button" disabled>
                 <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                 Loading...
              </button>
           </div>
         </>
       )
    } else if (this.state.status > 201) {
       return (
         <>
           <div>
              <br/><br/><br/>
              <button className="btn btn-primary" type="button" disabled>
                 <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                 Error Retrieving Policy Data. Please Contact support.
              </button>
           </div>
         </>
       )
    } else {
       return (
         <>
           <Prompt message="Unsaved Changes! Are you sure you want leave?" when={this.state.isDirty}/>
           <div>
               <Row>
                  <Col xl="9" lg="8">
                     <Card className="whiteCard">
                        <Card.Header className="d-flex justify-content-between">
                           <div className="header-title">
                              <h4 className="card-title">Edit Policy Assignments</h4>
                           </div>
                        </Card.Header>
                        <Card.Body>
                           <div className="new-user-info">
                              <table>
                                 <tbody>
                                    {this.renderPolicyForm()}
                                 </tbody>
                              </table>
                              <div className="container">
                                 <hr/>
                                 <h4 className="card-title">Assign or Remove Groups</h4>
                                 <NL3DualListBox type="policy-group" data={this.state.groupList}
                                     members={this.state.initGroups} action={this.handlePolicyChange}/>
                              </div>
                              <div className="container">
                                 <hr/>
                                 <h4 className="card-title">Assign or Remove Users</h4>
                                 <NL3DualListBox type="policy-user" data={this.state.userList}
                                     members={this.state.initUsers} action={this.handleUserPolicyChange}/>
                              </div>
                           </div>
                           <hr/>
                           <div align="center" className="button-container">
                                 <button className="formButton" onClick={this.handleUpdatePolicy.bind(this)}>Save</button>&nbsp;&nbsp;&nbsp;
                                 <button className="formButton" onClick={this.handleBack.bind(this)}>Return</button>
                           </div>
                        </Card.Body>
                     </Card>
                  </Col>
               </Row>
            </div>
         </>
       )
    }
  }
}

export default EditPolicyGroups
