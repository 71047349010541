import {React, Fragment} from 'react'
import HeaderStyle3 from '../../components/partials/dashboard/HeaderStyle/header-style-3'
import Sidebar from '../../components/partials/dashboard/SidebarStyle/sidebar'
import Footer from '../../components/partials/dashboard/FooterStyle/footer'
import DefaultRouter from '../../router/default-router'
import Loader from '../../components/Loader'

const Dashboard = () => {
    return (
      <>
      <Loader />
      <Sidebar />
      <main className="main-content">
        <div className="position-relative">
           <HeaderStyle3 />
        </div>
        <div className="container-fluid content-inner">
             <div className="row">
                <DefaultRouter />
              </div>
        </div>
        <Footer />
      </main>
      </>
    )
}

export default Dashboard
