import { initializeApp } from "firebase/app";
import { isSupported, getMessaging, getToken, onMessage } from "firebase/messaging";
import Store from './store';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
    measurementId: process.env.REACT_APP_FB_MEASURE_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
let messaging = null;
isSupported()
  .then(result => {
     if (result) {
        messaging = getMessaging(firebaseApp);
     }
   });

export const getFBToken = () => {
  return getToken(messaging, {vapidKey: process.env.REACT_APP_FB_PUB_KEY}).then((currentToken) => {
    if (currentToken) {
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
      Store.dispatch({type: 'FBTOKEN', text: {currentToken}});
      return 1;
    } else {
      // shows on the UI that permission is required 
      Store.dispatch({type: 'FBTOKEN', text: ""});
      return 0;
    }
  }).catch((err) => {
    // catch error while creating client token
    console.log('An error occurred while retrieving token. ', err.message);
    if (err.message.includes("not granted and blocked")) {
      // permission denied
      return 2;
    } else {
       return 0;
    }
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});
