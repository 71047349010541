import React, { Component } from "react";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";
import '../../assets/dual-listbox.css';

class NL3DualListBox extends Component {
  constructor(props) {
     super(props)
     this.state = {
        options: [],
        currSelected : [],
     }
  }

  componentDidMount () {
     let currOptions = [];
     if (this.props.type === "policy") {
         for (let i = 0; i < this.props.data.length; i++) {
             const uiDesc = this.props.data[i].email
             currOptions.push({
               label: uiDesc,
               value: this.props.data[i].id,
             });
         }
     } else if (this.props.type === "group") {
         for (let i = 0; i < this.props.data.length; i++) {
             for (let t = 0; t < this.props.data[i].accounts.length; t++) {
                 const uiDesc = this.props.data[i].accounts[t].userAccountId + " (" +
                                this.props.data[i].accounts[t].domain.name + ")";
                 currOptions.push({
                   label: uiDesc,
                   value: this.props.data[i].accounts[t].id,
                 });
             }
         }
     } else if (this.props.type === "group-approver") {
         for (let i = 0; i < this.props.data.length; i++) {
             const uiDesc = this.props.data[i].email;
             currOptions.push({
               label: uiDesc,
               value: this.props.data[i].id,
             });
         }
     } else if (this.props.type === "policy-group") {
         for (let i = 0; i < this.props.data.length; i++) {
             const uiDesc = this.props.data[i].groupName;
             currOptions.push({
               label: uiDesc,
               value: this.props.data[i].id,
             });
         }
     } else if (this.props.type === "policy-user") {
         for (let i = 0; i < this.props.data.length; i++) {
            for(let j = 0; j < this.props.data[i].accounts.length; j++) {
                const uiDesc = this.props.data[i].accounts[j].userAccountId;
                currOptions.push({
                  label: uiDesc,
                  value: this.props.data[i].accounts[j].id,
                });
             }
         }
     }
     this.setState({options: currOptions, currSelected: this.props.members});
  }

  render() {
     return (
       <DualListBox
           options={this.state.options}
           selected={this.state.currSelected}
           onChange={selectedItems => {this.setState({currSelected:selectedItems});
                     this.props.action(selectedItems);}}
           icons={{
              moveLeft: "<",
              moveAllLeft: "<<",
              moveRight: ">",
              moveAllRight: ">>"
           }}
           canFilter={true}
           preserveSelectOrder={true}
           showNoOptionsText={true}
       />
    );
  }
}
export default NL3DualListBox;

