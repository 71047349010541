import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import Store from '../../store'
import * as cognito from '../../libs/cognito';

//NL3 Styling
import "../../assets/nl3.css"

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;

class PaymentsBilling extends Component {
    constructor(props) {
        super(props)
        this.state= {
            companyToken: "",
            href: "",
            text: "",
        }
        // This is the company dashboard page
        Store.dispatch({type: 'COMPANY'});
    }

   async componentDidMount() {
        try {
            // Get the access token
            const session = await cognito.getSession();
            this.setState({companyToken : session.accessToken.jwtToken});

            this.getBillingInfo();
        } catch (e) {
            console.log(e.message);
            this.props.history.push('/');
            return;
        }
   }

   async getBillingInfo() {
        var billingInfoUrl = nl3ApiBase + '/companies/billing'
        console.log("getting billing info from " + billingInfoUrl)
        var newState = {text : 'Contact us at billing@nextlevel3.com for support', link: 'mailto:billing@nextlevel3.com'};
        try {
            const apiResponse = await fetch(billingInfoUrl, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${this.state.companyToken}`,
                },
            });
            if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
                const json = await apiResponse.json()
                console.log(json)
                newState = {text : json.text, link: json.link}
            }
        } catch (e) {
            console.log("Unable to get billing information")
            console.log(e)
        }
        this.setState(newState);
   }

   render() {
     return (
       <>
         <Row>
           <Col md="12" lg="12">
             <Row className="row-cols-1">
               <div className="whiteCard card">
                 <div className="card-header d-flex justify-content-between flex-wrap">
                   <h4 className="card-title">
                     Payments & Billing
                   </h4>
                 </div>
                 <div className="card-header d-flex justify-content-between flex-wrap blackText">
                      <a href={this.state.link}>{this.state.text}</a>
                 </div>
                 <br/><br/>
               </div>
             </Row>
           </Col>
         </Row>
       </>
     )
   }
}

export default PaymentsBilling
