import React, { Component } from 'react'
import { Row,Col } from 'react-bootstrap'
import { Navbar, Container, Nav, Dropdown} from 'react-bootstrap'
import Store from '../../store'
import CustomToggle from '../../components/dropdowns'
import Pagination from '../../components/pagination';
import UserTableRow from './user-table-row';
import ReactTooltip from 'react-tooltip';
import * as cognito from '../../libs/cognito';
import * as nl3Utils from '../../libs/nl3Utils';

//NL3 Styling
import "../../assets/nl3.css"
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;

class CompanyUsers extends Component {
   constructor(props) {
     super(props)
     this.handleAdminChange = this.handleAdminChange.bind(this);
     this.handleEnabledChange = this.handleEnabledChange.bind(this);
     this.handleChangeFile = this.handleChangeFile.bind(this);
     this.handleResetAccount = this.handleResetAccount.bind(this);
     this.state= {
        currentAdmin: "",
        statusCode: 0,
        userList: [],
        groups: [],
        admins: [],
        companyToken: "",
        currentPage: 1,
        totalPages: 1,
        totalItems: 0,
        itemsPerPage: 0,
        pageSize: 20,
        searchValue: "",
        filterValue: "",
        popupTitle: "",
        popupBody: "",
        resetAccount: "",
        resetIndex: null,
     }
     // This is the company dashboard page
     Store.dispatch({type: 'COMPANY'});
   }

   async componentDidMount() {
       try {
          // Get the access token
          const session = await cognito.getSession();
          const admin = cognito.getCurrentUser();
          this.setState({companyToken : session.accessToken.jwtToken,
                         currentAdmin : admin.username});

          // get the account info
          this.getUserAccountData(this.state.currentPage, this.state.pageSize)
          .then(() => { this.getAdmins() });
       } catch (e) {
          console.log(e.message);
          this.props.history.push('/');
          return;
       }
    }

   async getAdmins() {
      var companyAdminUrl = nl3ApiBase + `/companies/mycompany`;;
      const apiResponse = await fetch(companyAdminUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.state.companyToken}`,
        },
      });
      if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
         const records = await apiResponse.json();
         this.setState({admins : records.admins});
      } else {
         this.setState({admins : []});
      }
      return apiResponse.status;
   }

   async getUserAccountData(pageNum, pageSize) {
      // try to get the company user list
      var companyUsersUrl = nl3ApiBase;
      if (this.state.searchValue === "") {
          companyUsersUrl += `/companies/mycompany/users?orderBy=first_name:asc&page=${pageNum}&pageSize=${pageSize}`;
      } else {
          companyUsersUrl += `/companies/mycompany/users?filterOr=first_name%20like%20${this.state.searchValue} or `;
          companyUsersUrl += `last_name%20like%20${this.state.searchValue}&orderBy=first_name%3Aasc&page=${pageNum}&pageSize=${pageSize}`;
      }
      const apiResponse = await fetch(companyUsersUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.state.companyToken}`,
        },
      });

      if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
         const records = await apiResponse.json();
         this.setState({statusCode : apiResponse.status, userList: records, currentPage: records.currentPage,
                        totalPages: records.totalPages, totalItems: records.totalItems,
                        itemsPerPage: records.itemsPerPage, pageSize: records.pageSize});
      } else {
         this.setState({statusCode : apiResponse.status});
      }
      return apiResponse.status;
   }

   async updateEnabledField(value, application, domain, account) {
       const enabledEndpoint = nl3ApiBase + '/companies/mycompany/userAccount';
       const apiResponse = await fetch(enabledEndpoint, {
            method: 'PATCH',
            headers: {
               Authorization: `Bearer ${this.state.companyToken}`,
               "Content-Type": 'application/json'
            },
            body: `{ "application": "${application}", "domainName": "${domain}","enable": "${value}", "userAccountId": "${account}" }`
       });
       return apiResponse.status;
   }

   async updateAdminField(id, title) {
       const enableAdminEndpoint = nl3ApiBase + '/companies/mycompany/addAdmin';
       const apiResponse = await fetch(enableAdminEndpoint, {
            method: 'POST',
            headers: {
               Authorization: `Bearer ${this.state.companyToken}`,
               "Content-Type": 'application/json'
            },
            body: `{ "id": "${id}", "title": "${title}" }`
       });
       return apiResponse.status;
   }

   async sendResetAccount(username) {
       const resetUrl = nl3ApiBase + '/companies/mycompany/resetUser';
       const apiResponse = await fetch(resetUrl, {
            method: 'POST',
            headers: {
               Authorization: `Bearer ${this.state.companyToken}`,
               "Content-Type": 'application/json'
            },
            body: `{ "username": "${username}" }`
       });
       return apiResponse.status;
   }

   handleAdminChange(e, itemIdx) {
    const titleText = "Promoted by " + this.state.currentAdmin;
     this.updateAdminField( this.state.userList.items[itemIdx].id, titleText)
           .then(status => {
               if (status === 200) {
                    this.getAdmins();
                     nl3Utils.nl3Toast("Successfully promoted the account!",true,"success");
               } else {
                     nl3Utils.nl3Toast("Error! Failed to promote the account.",true,"error");
               }
           })
           .catch(e => {
                  console.log(e.message);
           });
   }

   handleEnabledChange(e, itemIdx, acctIdx) {
     this.updateEnabledField(e, this.state.userList.items[itemIdx].accounts[acctIdx].application,
                        this.state.userList.items[itemIdx].accounts[acctIdx].domain.name,
                        this.state.userList.items[itemIdx].accounts[acctIdx].userAccountId)
           .then(status => {
               if (status === 200) {
                     var updateList = this.state.userList;
                     updateList.items[itemIdx].accounts[acctIdx].enabled = e;
                     this.setState({userList: updateList});
                     nl3Utils.nl3Toast("Successfully changed the account!",true,"success");
               } else {
                     nl3Utils.nl3Toast("Error! Failed to change the account.",true,"error");
               }
           })
           .catch(e => {
                  console.log(e.message);
           });
   }

   handleResetAccount(e, itemIdx) {
      this.setState({resetAccount: this.state.userList.items[itemIdx].username, resetIndex: itemIdx});
      const modal = document.querySelector(".modal")
      const closeBtn = document.querySelector(".close")
      modal.style.display = "block";
      closeBtn.addEventListener("click", () => {
        modal.style.display = "none";
      });
   }

   handleCancelReset() {
      const modal = document.querySelector(".modal")
      modal.style.display = "none";
      this.setState({resetAccount: "", resetIndex: null});
   }

   handleSubmitReset() {
     this.sendResetAccount(this.state.resetAccount)
           .then(status => {
               if (status === 200) {
                     nl3Utils.nl3Toast("Successfully reset the account!",true,"success");
               } else {
                     nl3Utils.nl3Toast("Error! Failed to reset the account.",true,"error");
               }
               const modal = document.querySelector(".modal")
               modal.style.display = "none";
               this.setState({resetAccount: "", resetIndex: null});
           })
           .catch(e => {
                  console.log(e.message);
           });
   }

   onPageChanged(data) {
       this.getUserAccountData(data.currentPage, data.pageLimit)
       .then(status => { if (status > 201) console.log("Error from getUserAccountData, RC=",status);})
       .catch(e => { console.log(e.message);});
   }

   handleFilter(e) {
       this.setState({filterValue: e.target.value});
   }

   handleSearchInput(e) {
       this.setState({searchValue: e.target.value});
   }

   handleSearch() {
       this.getUserAccountData(0, this.state.pageSize)
       .then(status => { if (status > 201) console.log("Error search from getUserAccountData, RC=",status);})
       .catch(e => { console.log(e.message);});
   }

   handlePageLimitChange(e) {
       const newPageLimit = parseInt(e.target.value,10);
       this.setState({pageSize: newPageLimit});
       this.getUserAccountData(0, newPageLimit)
       .then(status => { if (status > 201) console.log("Error pageLimit from getUserAccountData, RC=",status);})
       .catch(e => { console.log(e.message);});
   }

   handleViewImports() {
      this.props.history.push('/dashboard/company-imports');
   }

   showPopup(title, body) {
      this.setState({popupTitle : title});
      this.setState({popupBody : body});
      const modal = document.querySelector(".nl3Window")
      const closeBtn = document.querySelector(".nl3Window_close")
      modal.style.display = "block";
      closeBtn.addEventListener("click", () => {
        modal.style.display = "none"});
   }

   handleImport() {
      const body = <div><p>CSV file with a header record in this format:<br/><span>email,first_name,last_name,phone,domain,userAccountId</span></p><input type="file" accept=".csv" onChange={this.handleChangeFile}/></div>;
      this.showPopup("Import Users",body);
   }

   async handleImportFile(file) {
       const fileEndpoint = nl3ApiBase + '/companies/mycompany/imports/users';
       const formData = new FormData();
       formData.append("file",file);
       const apiResponse = await fetch(fileEndpoint, {
            method: 'POST',
            headers: {
               Authorization: `Bearer ${this.state.companyToken}`,
            },
            body: formData
       });
       var details = [];
       if ((apiResponse.status > 200) && (apiResponse.status < 203)) {
          details = await apiResponse.json();
       }
       return details;
   }

   handleChangeFile(event) {
       //close the import dialog box
       const csvFile = event.target.files[0].name;
       const modal = document.querySelector(".nl3Window")
       modal.style.display = "none";

       // send the csv file to the API
       this.handleImportFile(event.target.files[0])
       .then(details => {
           if (details.status !== "SUCCESS") {
              const msg = "Failed to import users from file: " + csvFile + "\n";
              this.showPopup("Import Error!",msg);
           } else {
              const msg = "Users imported from file: " + csvFile + "\n" +
                          "Import ID: " + details.request.id + "\n" +
                          "Status: " + details.request.status + "\n" +
                          "Users: " + details.request.totalUsers + "\n";
              this.showPopup("Import Accepted!",msg);
           }
       })
       .catch(e => { console.log(e.message);});
   }

   handleAddUser() {
       this.props.history.push('/dashboard/company-add-user');
   }

   renderTableData() {
      return this.state.userList.items.filter(user => {
            const {email, username} = user; //destructuring
            if (this.state.filterValue === "") {
              return user;
            } else if (email.toLowerCase().includes(this.state.filterValue.toLowerCase()) ||
                       username.toLowerCase().includes(this.state.filterValue.toLowerCase())) {
              return user;
            } else {
              return null;
            }
         }).map((user, itemIdx) => {
            const {id} = user; //destructuring
            var admin = false;
            for (var i=0; i < this.state.admins.length; i++) {
               if (this.state.admins[i].user.id === id) {
                   admin = true;
                   break;
               }
            }
            return (
                 <UserTableRow key={id} itemIdx={itemIdx} user={user}
                     admin={admin} adminAction={this.handleAdminChange}
                     resetAction={this.handleResetAccount}
                     action={this.handleEnabledChange} />
             )
      })
   }

   render() {
     //Company Dashboard
     if ((isNaN(this.state.statusCode)) || (this.state.statusCode === 0)) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
               </button>
            </div>
          </>
        )
     } else if (this.state.statusCode > 201) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Error Retrieving Company User Data. Please Contact support.
               </button>
            </div>
          </>
        )
     } else {
        if (this.state.userList.totalItems === 0) {
          return (
            <>
               <Row>
                 <Col md="12" lg="12">
                   <Row className="row-cols-1">
                     <div className="whiteCard card">
                         <div className="card-header d-flex justify-content-between flex-wrap">
                           <h4 className="card-title">No users were found for any of the protected applications.</h4>
                            <Navbar expand="lg" className="nav" style={{backgroundColor:"white", width:"200px"}}>
                                <Container fluid className="navbar-inner">
                                    <Nav as="ul" className="ms-auto navbar-list mb-2 mb-lg-0">
                                        <Dropdown as="li" className="nav-item">
                                            <Dropdown.Toggle as={CustomToggle} variant="nav-link py-0 d-flex align-items-center"
                                                   href="#" id="actionDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <div className="caption ms-3 d-none d-md-block ">
                                                   <span className="clearButton" style={{color:"#4052A4",fontSize:"20px"}}>Actions</span>
                                                </div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-menu-end" >
                                                <div className="m-0">
                                                    <button className="clearButton" onClick={() => this.handleAddUser()}>Add User</button>
                                                    <Dropdown.Divider />
                                                    <button className="clearButton" onClick={() => this.handleImport()}>Import CSV File</button>
                                                    <Dropdown.Divider />
                                                    <button className="clearButton" onClick={() => this.handleViewImports()}>View Imports</button>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Nav>
                                </Container>
                            </Navbar>
                         </div>
                         <br/>
                     </div>
                   </Row>
                 </Col>
               </Row>
               <ReactTooltip className="nl3Tooltip" id="nl3ImportTip" html={true}/>
                 {/* The following code creates the import popup */}
                   <div className="nl3Window">
                      <div className="nl3Window_content">
                         <div className="nl3Window_title">
                            <span className="nl3Window_close">&times;</span>
                            {this.state.popupTitle}
                         </div>
                         <div className="nl3Window_text">
                            <div className="nl3window_text">
                               <br/>
                               {this.state.popupBody}
                               <br/>
                            </div>
                         </div>
                      </div>
                   </div>
                {/* End of import popup code */}
            </>
          )
        } else {
          return (
            <>
            <div className="card whiteCard ">
                     <div className="card-header d-flex justify-content-between flex-wrap">
                            <h4 className="card-title">Registered Users</h4>
                            <Navbar expand="lg" className="nav" style={{backgroundColor:"white", width:"200px"}}>
                                <Container fluid className="navbar-inner">
                                    <Nav as="ul" className="ms-auto navbar-list mb-2 mb-lg-0">
                                        <Dropdown as="li" className="nav-item">
                                            <Dropdown.Toggle as={CustomToggle} variant="nav-link py-0 d-flex align-items-center"
                                                   href="#" id="actionDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <div className="caption ms-3 d-none d-md-block ">
                                                   <span className="clearButton" style={{color:"#4052A4",fontSize:"20px"}}>Actions</span>
                                                </div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-menu-end" >
                                                <div className="m-0">
                                                    <button className="clearButton" onClick={() => this.handleAddUser()}>Add User</button>
                                                    <Dropdown.Divider />
                                                    <button className="clearButton" onClick={() => this.handleImport()}>Import CSV File</button>
                                                    <Dropdown.Divider />
                                                    <button className="clearButton" onClick={() => this.handleViewImports()}>View Imports</button>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Nav>
                                </Container>
                            </Navbar>
                         </div>
               <Row>
                 <Col md="12" lg="12">
                   <Row className="row-cols-1">
                     <div className="card-body p-3">
                          <div className="table-responsive mt-4">
                            <div className="card-body d-flex justify-content-between flex-wrap mt-4">
                               <span className="blackText">filter:&nbsp;
                                   <input placeholder=" filter by name" onChange={(e) => this.handleFilter(e)}/>
                               </span>
                               <span>
                                  <span className="blackText">Search:&nbsp;
                                     <input placeholder=" search by name" onChange={(e) => this.handleSearchInput(e)}/>&nbsp;
                                     <button className="btn btn-primary" onClick={() => this.handleSearch()}> Go</button>
                                  </span>
                               </span>
                            </div>
                            <div className="table-wrapper-scroll-y">
                              <Table className="table-hover " style={{tableLayout:"fixed"}} size='small'>
                                  <TableHead >
                                      <TableRow>
                                          <TableCell style={{width:"10%"}}>Admin</TableCell>
                                          <TableCell style={{width:"30%"}}>NL3 Account ID</TableCell>
                                          <TableCell style={{width:"30%"}}>Email</TableCell>
                                          <TableCell style={{width:"20%"}}>Phone</TableCell>
                                          <TableCell style={{width:"10%"}}>Reset<br/>Acct</TableCell>
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                     {this.renderTableData()}
                                  </TableBody>
                              </Table>
                            </div>
                          </div>
                     </div>
                   </Row>
                 </Col>
                 <Col md="6" lg="6">
                     <div className="d-flex flex-row py-4 align-items-center">
                           <span className="blackText">Show&nbsp;
                             <select defaultValue={this.state.pageSize} onChange={(e) => this.handlePageLimitChange(e)}>
                                   <option value="1">1 user</option>
                                   <option value="5">5 users</option>
                                   <option value="20">20 users</option>
                                   <option value="50">50 users</option>
                                   <option value="100">100 users</option>
                             </select>
                           </span>&nbsp;&nbsp;&nbsp;
                           <Pagination totalRecords={this.state.totalItems}
                                  pageLimit={this.state.pageSize} pageNeighbours={1}
                                  onPageChanged={(data) => this.onPageChanged(data)} />
                           &nbsp;&nbsp;&nbsp;
                      </div>
                 </Col>
             </Row>
             </div>
             <ReactTooltip className="nl3Tooltip" id="nl3ImportTip" html={true}/>
               {/* The following code creates the import popup */}
                 <div className="nl3Window">
                    <div className="nl3Window_content">
                       <div className="nl3Window_title">
                          <span className="nl3Window_close">&times;</span>
                          {this.state.popupTitle}
                       </div>
                       <br/>
                       <span className="nl3Window_text" style={{"whiteSpace":"pre-wrap"}}>{this.state.popupBody}</span>
                       <br/>
                    </div>
                 </div>
              {/* End of import popup code */}
              {/* The following code creates the popup */}
                  <div className="modal">
                     <div className="modal_content">
                        <div className="modal_title">
                           <span className="close">&times;</span>
                           <p>Reset User Account&nbsp;&nbsp;</p>
                        </div>
                        <span className="modal_text" style={{"whiteSpace":"pre-wrap"}}>
                              {this.state.resetAccount} : Are you sure you want to reset this account?
                        </span>
                        <div align="center">
                            <br/>
                            <button className="formButton" onClick={this.handleCancelReset.bind(this)}>Cancel</button>&nbsp;&nbsp;&nbsp;
                            <button className="formButton" onClick={this.handleSubmitReset.bind(this)}>Reset</button>
                        </div>
                     </div>
                  </div>
              {/* End of popup code */}
         </>
        )
      }
     }
   }
}

export default CompanyUsers
