import React, { Component } from 'react'
import {Row,Col} from 'react-bootstrap'
import {Prompt} from 'react-router-dom';
import Card from '../../components/Card'
import Store from '../../store'
import NL3DualListBox from "./dual-listbox.js";
import * as cognito from '../../libs/cognito';
import * as nl3Utils from '../../libs/nl3Utils';

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;

class EditGroup extends Component {
   constructor(props) {
     super(props)
     this.handleGroupChange = this.handleGroupChange.bind(this);
     this.handleApproverChange = this.handleApproverChange.bind(this);
     this.state = {
         groupID : null,
         groupName : "",
         groupDescription : "",
         companyToken: "",
         userList: [],
         initMembers: [],
         members: [],
         initApprovers: [],
         approvers: [],
         currentPage: 1,
         totalPages: 1,
         totalItems: 0,
         itemsPerPage: 0,
         pageSize: 20,
         status: 0,
         isDirty: false,
     };
     Store.dispatch({type: 'COMPANY'});
   }

   async componentDidMount () {
      // Get the access token
      try {
         const session = await cognito.getSession();
         this.setState({companyToken : session.accessToken.jwtToken,
                        groupID : Store.getState().group.id});

         this.getGroup()
         .then(status => {
             if ((status === 200) || (status === 201)) {
                 this.getUsers()
                 .then(status => {
                    if ((status === 200) || (status === 201)) {
                       this.getGroupMembers()
                       .then(status => {
                           this.getApprovers()
                           .then(status => {
                               this.setState({status: status});
                           })
                       })
                       .catch(e => {
                          console.log(e.message);
                       });
                    }
                 })
                 .catch(e => {
                    console.log(e.message);
                 });
             }
         })
         .catch(e => {
             console.log(e.message);
         });
      } catch (e) {
          console.log(e.message);
          this.props.history.push('/');
          return;
      }
   }

   async getGroup() {
      // get the company groups
      const groupUrl = nl3ApiBase + `/groups/${this.state.groupID}`;
      const apiResponse = await fetch(groupUrl, {
           method: 'GET',
           headers: {
             Authorization: `Bearer ${this.state.companyToken}`,
           },
         });

      if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
          const record = await apiResponse.json();
          this.setState({groupName: record.groupName,
                         groupDescription: record.groupDescription});
      } else {
          this.setState({groupName: "", groupDescription: ""});
      }
      return(apiResponse.status);
    }

   async getGroupMembers() {
      const groupUrl = nl3ApiBase + `/groups/${this.state.groupID}/users`;
      const apiResponse = await fetch(groupUrl, {
           method: 'GET',
           headers: {
             Authorization: `Bearer ${this.state.companyToken}`,
           },
         });

      if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
          const record = await apiResponse.json();
          let initMembers = [];
          for (let t=0; t < record.items.length; t++) {
               initMembers.push(record.items[t].id);
          }
          this.setState({initMembers: initMembers, members: initMembers});
      }
      return(apiResponse.status);
   }

   async getApprovers() {
      const groupUrl = nl3ApiBase + `/groups/${this.state.groupID}/approvers`;
      const apiResponse = await fetch(groupUrl, {
           method: 'GET',
           headers: {
             Authorization: `Bearer ${this.state.companyToken}`,
           },
         });

      if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
          const record = await apiResponse.json();
          let initApprvs = [];
          for (let t=0; t < record.items.length; t++) {
               initApprvs.push(record.items[t].id);
          }
          this.setState({initApprovers: initApprvs, approvers: initApprvs});
      }
      return(apiResponse.status);
   }

   async getUsers() {
      // try to get the company user list
      var companyUsersUrl = nl3ApiBase;
      companyUsersUrl += `/companies/mycompany/users?orderBy=first_name:asc`;
      const apiResponse = await fetch(companyUsersUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.state.companyToken}`,
        },
      });

      if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
         const records = await apiResponse.json();
         this.setState({userList: records.items, currentPage: records.currentPage,
                        totalPages: records.totalPages, totalItems: records.totalItems,
                        itemsPerPage: records.itemsPerPage, pageSize: records.pageSize});
      }
      return( apiResponse.status);
   }

   async updateGroupDB () {
      let removeMembers = [];
      let addMembers = [];
      let removeApprovers = [];
      let addApprovers = [];
      for (let i=0; i<this.state.members.length; i++) {
          // see if useraccount was added
          if (!this.state.initMembers.includes(this.state.members[i])) {
             addMembers.push(this.state.members[i]);
          }
      }
      for (let i=0; i<this.state.initMembers.length; i++) {
          // see if useraccount was removed
          if (!this.state.members.includes(this.state.initMembers[i])) {
             removeMembers.push(this.state.initMembers[i]);
          }
      }
      for (let i=0; i<this.state.approvers.length; i++) {
          // see if useraccount was added
          if (!this.state.initApprovers.includes(this.state.approvers[i])) {
             addApprovers.push(this.state.approvers[i]);
          }
      }
      for (let i=0; i<this.state.initApprovers.length; i++) {
          // see if useraccount was removed
          if (!this.state.approvers.includes(this.state.initApprovers[i])) {
             removeApprovers.push(this.state.initApprovers[i]);
          }
      }
      if ((removeMembers.length === 0) && (addMembers.length === 0) &&
          (removeApprovers.length === 0) && (addApprovers.length === 0)) {
         //nothing to do
         return(-1);
      }
      const groupData = JSON.stringify({"userAccountsToAdd": addMembers,
                                        "userAccountsToRemove": removeMembers,
                                        "defaultApproversToAdd": addApprovers,
                                        "defaultApproversToRemove": removeApprovers,
                                        "groupDescription":`${this.state.groupDescription}`,
                                        "groupName": `${this.state.groupName}`});
      const groupUrl = nl3ApiBase + `/groups/${this.state.groupID}`;
      const apiResponse = await fetch(groupUrl, {
             method: 'PATCH',
             headers: {
               'Authorization': `Bearer ${this.state.companyToken}`,
               'Content-Type': 'application/json',
             },
             body: `${groupData}`,
           });
      return(apiResponse.status);
   }

   handleBack() {
     this.props.history.push('/dashboard/groups');
   }

   handleDescChange(e) {
     this.setState({groupDescription: e.target.value, isDirty: true});
   }

   handleGroupChange(members) {
     this.setState({members: members, isDirty: true});
   }

   handleApproverChange(members) {
     this.setState({approvers: members, isDirty: true});
   }

   handleUpdateGroup() {
     this.updateGroupDB()
        .then(status => {
            if (status === -1) {
               nl3Utils.nl3Toast("No Changes Made.",true,"info");
            } else if (status === 200) {
               nl3Utils.nl3Toast("Success! Group Updated.",true,"success");
               this.setState({initMembers: this.state.members,
                              initApprovers: this.state.approvers,
                              isDirty: false});
            } else {
               nl3Utils.nl3Toast("Error! Failed to Update Group",true,"error");
            }})
        .catch(e => {
             console.log(e.message);
          });
   }

   render() {
    if ((isNaN(this.state.status)) || (this.state.status === 0)) {
       return (
         <>
           <div>
              <br/><br/><br/>
              <button className="btn btn-primary" type="button" disabled>
                 <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                 Loading...
              </button>
           </div>
         </>
       )
    } else if (this.state.status > 201) {
       return (
         <>
           <div>
              <br/><br/><br/>
              <button className="btn btn-primary" type="button" disabled>
                 <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                 Error Retrieving Group/User Data. Please Contact support.
              </button>
           </div>
         </>
       )
    } else {
       return (
         <>
           <Prompt message="Unsaved Changes! Are you sure you want leave?" when={this.state.isDirty}/>
           <div>
               <Row>
                  <Col xl="9" lg="8">
                     <Card className="whiteCard">
                        <Card.Header className="d-flex justify-content-between">
                           <div className="header-title">
                              <h4 className="card-title">Edit Group</h4>
                           </div>
                        </Card.Header>
                        <Card.Body>
                           <div style={{align:"left"}} className="new-user-info">
                              <table style={{width:"100%"}}>
                                 <tbody>
                                   <tr>
                                     <td><label className="blackText">Group Name:&nbsp;</label></td>
                                     <td style={{width:"70%"}}><label className="blackText">{this.state.groupName}</label></td>
                                   </tr>
                                   <tr>
                                     <td><label className="blackText">Group Description:&nbsp;</label></td>
                                     <td style={{width:"70%"}}><input type="text" value={this.state.groupDescription}
                                            style={{width:"90%"}} onChange={this.handleDescChange.bind(this)} /></td>
                                   </tr>
                                 </tbody>
                              </table>
                              <hr/>
                              <div className="container">
                              <h4 className="card-title">Edit Group Members</h4>
                              <NL3DualListBox type="group" data={this.state.userList}
                                  members={this.state.initMembers} action={this.handleGroupChange}/>
                              </div>
                              <hr/>
                              <div className="container">
                              <h4 className="card-title">Edit JIT Approvers</h4>
                              <NL3DualListBox type="group-approver" data={this.state.userList}
                                  members={this.state.initApprovers} action={this.handleApproverChange}/>
                              </div>
                              <hr/>
                              <div align="center" className="button-container">
                                 <button className="formButton" onClick={this.handleUpdateGroup.bind(this)}>Save</button>&nbsp;&nbsp;&nbsp;
                                 <button className="formButton" onClick={this.handleBack.bind(this)}>Return</button>
                              </div>
                           </div>
                        </Card.Body>
                     </Card>
                  </Col>
               </Row>
            </div>
         </>
       )
    }
  }
}

export default EditGroup
