import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import moment from 'moment-timezone';
import React from 'react';
import { Badge, Button, Col, Row } from 'react-bootstrap';
import { MdCheckCircle } from 'react-icons/md';
import Moment from 'react-moment';
import * as cognito from '../../libs/cognito';
import * as nl3Utils from '../../libs/nl3Utils';

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;

class SafeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      restResponse: [],
      restCallActive : false
    };
  }

  async componentDidMount() {
    // Get the access token
    try {
      const session = await cognito.getSession();
      this.setState({companyToken : session.accessToken.jwtToken});
      this.fetchObjects();

   } catch (e) {
       console.log(e.message);
       this.props.history.push('/');
       return;
   }
  }

  fetchObjects = async () => {
    try {
      const pageSize = 200;
      const response = await fetch(nl3ApiBase + `/actionValues?orderBy=requestDate:desc&pageSize=${pageSize}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.state.companyToken}`,
        },
      });
      const data = await response.json();
      this.setState({ restResponse: data });
    } catch (error) {
      console.error('Error fetching objects:', error);
    }
  };


  handleAutoApprove = async (value) => {

    const data = {
      "autoApprovedValuesToAdd": [
        {
          "type": value.requestValue.type,
          "value": value.requestValue.value,
          "metaData": {}
        }
      ]
    };
    this.handleTemplatePatch(value.templateId, data);

  }

  handleAutoReject = (value) => {
    const data = {
      "autoRejectedValuesToAdd": [
        {
          "type": value.requestValue.type,
          "value": value.requestValue.value,
          "metaData": {}
        }
      ]
    };
    this.handleTemplatePatch(value.templateId, data);

  }

  handleTemplatePatch = async (templateId, data) => {
    const patchUrl = `${nl3ApiBase}/approvalTemplates/${templateId}`;
    const apiResponse = await fetch(patchUrl, {
        method: 'PATCH',
        headers: {
           Authorization: `Bearer ${this.state.companyToken}`,
           "Content-Type": 'application/json'
        },
        body: JSON.stringify(data)
      });
      if ( apiResponse.ok){
        nl3Utils.nl3Toast("Success! Template Updated.",true,"success");
        this.fetchObjects();
      }else {
        nl3Utils.nl3Toast("Error! Failed to Update Template",true,"error");
      }
     return apiResponse;
  }



  render() {
    //const { restResponse, isDeleteConfirmationVisible, isActionCallPreviewVisible } = this.state;
    const { restResponse } = this.state;

    return (

      <Row>
        <Col md="12" lg="12">
          <Row className="row-cols-1">
            <div className="whiteCard card overflow-hidden">
              <div className="card-header d-flex justify-content-between flex-wrap">
                <div className="header-title">
                  <h4 className="card-title">JIT Approvals Recent History</h4>
                </div>

              </div>
              <div className="card-body p-3">
              <div className="">
                <TableContainer >
                <Table size="small"  >
                  <TableHead >
                    <TableRow>
                      <TableCell>Template</TableCell>
                      <TableCell>Action Date</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>User</TableCell>
                      <TableCell>Approval Request <br /> Value Type</TableCell>
                      <TableCell>Approval Request <br /> Value</TableCell>
                      <TableCell align='center'>Auto Approved</TableCell>
                      <TableCell align='center'>Auto Rejected</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {restResponse?.items?.map((object, index) => (
                      <TableRow key={object.id}>
                        <TableCell className='overflow-hidden'>{object.templateName}</TableCell>
                        <TableCell className='overflow-hidden'>
                          <Moment utc tz={moment.tz.guess()} format="MM-DD-YYYY hh:mm:ssa z">{object.requestDate}</Moment>
                        </TableCell>
                        <TableCell className='overflow-hidden'> <h6>
                          {object.status === 'APPROVED' && (
                            <Badge bg="success">Approved</Badge>
                          )}
                          {object.status === 'REJECTED' && (
                            <Badge bg="danger">Rejected</Badge>
                          )}</h6>

                        </TableCell>
                        <TableCell className='overflow-hidden'>{object.username}</TableCell>
                        <TableCell className='overflow-hidden'>{object.requestValue?.type}</TableCell>
                        <TableCell className='overflow-hidden'>
                          <div className='text-truncate' style={{width:200}} title={object.requestValue?.value}>
                          {object.requestValue?.value}
                          </div>
                          </TableCell>
                        <TableCell className='overflow-hidden' align='center'>
                          {object.existsAutoApprovedList && (
                            <MdCheckCircle size={20} fill='green'></MdCheckCircle>
                          )}
                        </TableCell>
                        <TableCell className='overflow-hidden' align='center'>
                          {object.existsAutoRejectedList && (
                            <MdCheckCircle size={20} fill='green'></MdCheckCircle>
                          )}
                        </TableCell>

                        <TableCell>

                          { !object.existsAutoApprovedList && !object.existsAutoRejectedList && (
                            <Button variant="success" size="sm" style={{marginRight:5}} onClick={() => this.handleAutoApprove(object)}>
                              Add to Auto Approve
                            </Button>
                          )}
                           { !object.existsAutoApprovedList && !object.existsAutoRejectedList && (
                            <Button variant="danger" size="sm" onClick={() => this.handleAutoReject(object)}>
                              Add to Auto Reject
                            </Button>
                          )}


                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                </TableContainer>
              </div>
            </div>
              <div className="card-footer d-flex justify-content-between flex-wrap p-1">
              </div>
            </div>



          </Row>
        </Col>
      </Row>
    );
  }
}

export default SafeList;
