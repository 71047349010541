import React, { Component } from 'react'
import {Row,Col} from 'react-bootstrap'
import {Prompt} from 'react-router-dom';
import Card from '../../components/Card'
import Store from '../../store'
import Switch from 'react-input-switch';
import ReactTooltip from 'react-tooltip';
import * as cognito from "../../libs/cognito";
import * as nl3Utils from "../../libs/nl3Utils";

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;

class userEdit extends Component {

   constructor(props) {
      super(props)
      this.state= {
         email : "",
         first_name : "",
         last_name : "",
         phone : "",
         userToken : "",
         statusCode : 0,
         emailChecked: false,
         smsChecked: false,
         autoLockAttempt: false,
         autoLockSuccess: false,
         autoLockBreach: false,
         autoLockMinutes: 0,
         isDirty: false,
      };
      // User edit page
      Store.dispatch({type: 'USER'});
   }

   async componentDidMount() {
      let cognitoUserSession = null;
      try {
         // Get the access token
         cognitoUserSession = await cognito.getSession();
         if (cognitoUserSession === null) {
            console.log("Error: cognitoUserSession is null");
            return;
         }
      } catch (e) {
          console.log(e.message);
          this.props.history.push('/');
          return;
      }
      this.setState({userToken : cognitoUserSession.accessToken.jwtToken});
      if (cognitoUserSession !== null) {
         this.getUserData();
      }
   }

   async getUserData() {
        if (this.state.userToken === "") return;
        try {
            //Get User Details from Database
            const userDetailsByIdUrl = nl3ApiBase + `/users/userinfo`;
            const apiResponse = await fetch(userDetailsByIdUrl, {
                method: "GET",
                headers: {
                "Authorization": `Bearer ${this.state.userToken}`
                },
            });
            if (apiResponse.status === 200) {
                const user_data = await apiResponse.json();
                const fullName = user_data.first_name + " " + user_data.last_name;
                this.setState({email : user_data.email,
                               full_name : fullName,
                               first_name : user_data.first_name,
                               last_name : user_data.last_name,
                               emailChecked : user_data.allowEmailNotification,
                               smsChecked : false,
                               autoLockAttempt : user_data.autoLockOnAttempt,
                               autoLockSuccess : user_data.autoLockOnSuccess,
                               autoLockBreach : user_data.autoLockOnBreach,
                               autoLockMinutes : user_data.autoLockMinutes,
                               phone : user_data.phone});
            }
            this.setState({statusCode : apiResponse.status});
        } catch (e) {
           console.log(e.message);
        }
  }

  handleBack() {
    this.props.history.push('/dashboard');
  }

  checkPhoneNumber() {
      // eslint-disable-next-line
      var regEx = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
      if (this.state.phone.match(regEx)) {
          return true;
      } else {
          return false;
      }
  }

  async updateDatabase() {
       const sb = document.querySelector('#lockMinutes')
       this.setState({autoLockMinutes : sb.value});

       try {
          const fullName = this.state.first_name + " " + this.state.last_name;
          const userData = JSON.stringify({"full_name": fullName,
                                           "first_name" : `${this.state.first_name}`,
                                           "last_name" : `${this.state.last_name}`,
                                           "allowEmailNotification": `${this.state.emailChecked}`,
                                           "allowSmsNotification": false,
                                           "autoLockOnAttempt": `${this.state.autoLockAttempt}`,
                                           "autoLockOnSuccess": `${this.state.autoLockSuccess}`,
                                           "autoLockOnBreach": `${this.state.autoLockBreach}`,
                                           "autoLockMinutes": sb.value,
                                           "phone": `${this.state.phone}`});

          const userDetailsByIdUrl = nl3ApiBase + `/users/userinfo`;
          const apiResponse = await fetch(userDetailsByIdUrl, {
               method: 'PATCH',
               headers: {
                 'Authorization': `Bearer ${this.state.userToken}`,
                 'Content-Type': 'application/json',
               },
               body: `${userData}`,
             });

          if (apiResponse.status === 200) {
              const user_data = await apiResponse.json();
              this.setState({phone : user_data.phone, isDirty: false});
          }
          this.setState({statusCode : apiResponse.status});
          return(apiResponse.status);
       } catch (e) {
          console.log(e.message);
       }
  }

  handleUpdateData(e) {
     e.preventDefault();
     if (this.checkPhoneNumber()) {
         this.updateDatabase()
            .then(status => {
                   if (status === 200) {
                       nl3Utils.nl3Toast("Successfully updated account information",true,"success");
                   } else {
                       nl3Utils.nl3Toast("Error! account information was not updated.",true,"error");
                   }
             })
            .catch(e => {
                   console.log(e.message);
                 });
     } else {
         nl3Utils.nl3Toast("Error! Phone number format incorrect.",true,"error");
     }
  }

  showPopup() {
     const modal = document.querySelector(".nl3Window")
     const closeBtn = document.querySelector(".nl3Window_close")
     modal.style.display = "block";
     closeBtn.addEventListener("click", () => {
       modal.style.display = "none";
     });
  }

  handleRemoveAccount(e) {
     e.preventDefault();
     this.showPopup();
  }

  handleKeepAccount(e) {
     e.preventDefault();
     const modal = document.querySelector(".nl3Window")
     modal.style.display = "none";
  }

  handlePhoneChange(e) {
    this.setState({phone: e.target.value, isDirty: true});
  }

  handleEmailChange(e) {
    this.setState({emailChecked : e, isDirty: true});
  }

  handleBreachChange(e) {
    this.setState({autoLockBreach : e, isDirty: true});
  }

  handleRelockChange(e) {
    this.setState({isDirty: true});
  }

  render () {
      if (this.state.statusCode === 0) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
               </button>
            </div>
          </>
        )
      } else if (this.state.statusCode > 200) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Error Retrieving or Updating User Data. Please Contact support.
               </button>
            </div>
          </>
        )
      } else {
        return (
            <>
              <Prompt message="Unsaved Changes! Are you sure you want leave?" when={this.state.isDirty}/>
              <div>
                  <Row>
                     <Col xl="6" lg="5">
                        <Card className="whiteCard">
                           <Card.Header className="d-flex justify-content-between">
                              <div className="header-title">
                                 <h4 className="card-title">Modify Account Information</h4>
                              </div>
                           </Card.Header>
                           <Card.Body>
                              <div className="new-user-info">
                                 <span className="blackText"><b>Name: {this.state.first_name} {this.state.last_name}</b></span><br/>
                                 <span className="blackText"><b>Email: {this.state.email}</b></span><br/>
                                 <hr/>
                                 <table>
                                   <tbody>
                                     <tr>
                                      <td><label className="blackText">Phone Number:&nbsp;</label></td>
                                      <td><input type="text" value={this.state.phone} onChange={this.handlePhoneChange.bind(this)} /></td>
                                     </tr>
                                   </tbody>
                                 </table>
                              </div>
                              <br/><br/><br/>
                             <div align="left">
                               <button className="clearButton" onClick={this.handleRemoveAccount.bind(this)}>Delete My Account</button>
                             </div>
                           </Card.Body>
                        </Card>
                     </Col>
                     <Col xl="4" lg="3">
                        <Card className="whiteCard">
                           <Card.Header className="d-flex justify-content-between">
                              <div className="header-title">
                                 <h4 className="card-title"
                                    data-tip="Determines how Next Level3 notifies you when<br/>
                                              security conditions are detetected or account status changes."
                                    data-for="nl3UserEditTips" data-place="top">Notification Settings</h4>
                              </div>
                           </Card.Header>
                           <Card.Body>
                              <table>
                                 <tbody>
                                   <tr>
                                      <td><Switch value={this.state.emailChecked} on={true} off={false}
                                           onChange={this.handleEmailChange.bind(this)}/></td>
                                      <td><label className="blackText">&nbsp;Receive Email Alerts</label></td>
                                   </tr>
                                 </tbody>
                              </table>
                           </Card.Body>
                        </Card>
                        <Card className="whiteCard">
                           <Card.Header className="d-flex justify-content-between">
                              <div className="header-title">
                                 <h4 className="card-title"
                                    data-tip="These settings allow you to set personal preferences for your<br/>
                                              accounts when security events are detected or account status changes."
                                    data-for="nl3UserEditTips" data-place="top">Security Settings</h4>
                              </div>
                           </Card.Header>
                           <Card.Body>
                              <label className="blackText">
                                 <Switch value={this.state.autoLockBreach} on={true} off={false}
                                     onChange={this.handleBreachChange.bind(this)}/>
                                 &nbsp;Lock All Accounts When Data Breach Detected</label>
                              <br/><br/>
                              <div>
                                 <select id="lockMinutes" defaultValue={this.state.autoLockMinutes}
                                         onChange={() => this.handleRelockChange()}>
                                           <option value="0">Never</option>
                                           <option value="1">After 1 Minute</option>
                                           <option value="3">After 3 Minutes</option>
                                           <option value="5">After 5 Minutes</option>
                                           <option value="10">After 10 Minutes</option>
                                           <option value="15">After 15 Minutes</option>
                                 </select>
                                 <label className="blackText">&nbsp;Relock Any Unlocked Accounts</label>
                              </div>
                           </Card.Body>
                        </Card>
                     </Col>
                  </Row>
                  <Row>
                     <div align="center">
                                   <button className="formButton" onClick={this.handleUpdateData.bind(this)}>Save</button>&nbsp;&nbsp;&nbsp;
                                   <button className="formButton" onClick={this.handleBack.bind(this)}>Return</button>
                     </div>
                  </Row>
                  <ReactTooltip className="nl3Tooltip" id="nl3UserEditTips" html={true} />
               </div>
               {/* The following code creates the popup */}
                   <div className="nl3Window">
                      <div className="nl3Window_content">
                         <div className="nl3Window_title">
                            <span className="nl3Window_close">&times;</span>
                            <p>Delete My Account</p>
                         </div>
                         <span className="nl3Window_text">
                             Deleting an account is permanent action. All of your registered protected accounts
                             will no longer be protected by Next Level3 services. All of the history and other asscoiated
                             data will be removed as well.<br/><br/><b>This action can not be reversed!</b><br/><br/>
                             If you still wish to delete your Next Level3 account, please email our team at
                             <a href="mailto:nl3@nextlevel3.com?subject=Delete Next Level3 Account"> NL3 Support.</a><br/><br/>
                         </span>
                         <div align="center">
                            <button className="formButton" onClick={this.handleKeepAccount.bind(this)}>Keep My Account</button>&nbsp;&nbsp;&nbsp;
                         </div>
                      </div>
                   </div>
               {/* End of popup code */}
            </>
        )
      }
  }
}
export default userEdit
