import React, { Component } from 'react'
import {Row,Col, Button} from 'react-bootstrap'
import {Prompt} from 'react-router-dom';
import Card from '../../components/Card'
import Store from '../../store'
import "../../assets/react-sliding-pane.css";
import * as cognito from '../../libs/cognito';
import * as nl3Utils from '../../libs/nl3Utils';

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;

class CompanyAddUser extends Component {

   constructor(props) {
      super(props)
      this.state= {
         companyToken : "",
         domains : [],
         statusCode : 0,
         isDirty : false,
      };
      // Company edit page
      Store.dispatch({type: 'COMPANY'});
   }

   async componentDidMount() {
       try {
          // Get the access token
          const session = await cognito.getSession();
          this.setState({companyToken : session.accessToken.jwtToken});

          const companyDetailsByIdUrl = nl3ApiBase + `/companies/mycompany`;
          const apiResponse = await fetch(companyDetailsByIdUrl, {
               method: 'GET',
               headers: {
                 'Authorization': `Bearer ${this.state.companyToken}`,
               },
             });

          if (apiResponse.status === 200) {
              const company_data = await apiResponse.json();
              if (company_data.domains.length > 1) {
                 var newItem = { id: 0, name: 'All Applications', url: 'All Applications'};
                 var newDomains = [newItem].concat(company_data.domains);
                 this.setState({domains : newDomains});
              } else {
                 this.setState({domains : company_data.domains});
              }
          }
          this.setState({statusCode : apiResponse.status});
       } catch (e) {
          console.log(e.message);
          this.props.history.push('/');
          return;
       }
  }

   handleBack(e) {
     e.preventDefault();
     this.props.history.push('/dashboard/company-users');
   }

   checkPhoneNumber(phoneNumber) {
       // eslint-disable-next-line
       var regEx = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
       if (phoneNumber.match(regEx)) {
           return true;
       } else {
           return false;
       }
   }

   async updateDatabase(body) {
        try {
           const userData = JSON.stringify(body);
           const companyAddUsersUrl = nl3ApiBase + `/companies/mycompany/users`;
           const apiResponse = await fetch(companyAddUsersUrl, {
                method: 'POST',
                headers: {
                  'Authorization': `Bearer ${this.state.companyToken}`,
                  'Content-Type': 'application/json',
                },
                body: `${userData}`,
              });

           return(apiResponse.status);
        } catch (e) {
           console.log(e.message);
        }
   }

   handleChanges() {
       this.setState({isDirty: true});
   }

   handleFormSubmit(e) {
     e.preventDefault();
     var validData = false;
     var build_body = [];

     // check the form data for user data input
     // every 7th index is a new record
     for(var i=0;i<e.target.length-2;i=i+7) {
         // see if we have an empty input record
         if ((e.target[i].value === "") || (e.target[i+1].value === "")||
             (e.target[i+3].value === "") || (e.target[i+4].value === "") ||
             (e.target[i+6].value === "") ||
             (!this.checkPhoneNumber(e.target[i+3].value))) {
             // Error skip this record
         } else {
             // see if they are adding this user for all applications
             var full_phone = e.target[i+2].value + e.target[i+3].value;
             if (e.target[i+5].value === 'All Applications') {
                // adding for ALL applications
                for (var k=1; k < this.state.domains.length; k++) {
                   const record = {"first_name": e.target[i].value,
                                "last_name":  e.target[i+1].value,
                                "phone": full_phone,
                                "email": e.target[i+4].value,
                                "userAccountId": e.target[i+6].value,
                                "domain": this.state.domains[k].url};
                   build_body.push(record);
                }
                validData = true;
             } else {
                // just adding for 1 application
                const record = {"first_name": e.target[i].value,
                                "last_name":  e.target[i+1].value,
                                "phone": full_phone,
                                "email": e.target[i+4].value,
                                "domain": e.target[i+5].value,
                                "userAccountId": e.target[i+6].value};
                build_body.push(record);
                validData = true;
             }
         }
     }
     if (validData) {
         this.updateDatabase(build_body)
            .then(status => {
                   if (status === 202) {
                       nl3Utils.nl3Toast("New user data is being processed!",true,"success");
                   } else {
                       nl3Utils.nl3Toast("Error! New user data could not be processed.",true,"error");
                   }
                 })
            .catch(e => {
                   console.log(e.message);
                 });
         this.setState({isDirty: false});
     } else {
         nl3Utils.nl3Toast("Error! No valid user data to send.",true,"error");
     }
   }

  phoneCountryCodes() {
     return(
      <>
      <option key='01' value="+1">+1</option>
      <option key='02' value="+1242">+1-242</option>
      <option key='03' value="+1340">+1-340</option>
      <option key='04' value="+1345">+1-345</option>
      <option key='05' value="+1441">+1-441</option>
      <option key='06' value="+1649">+1-649</option>
      <option key='07' value="+1787">+1-787</option>
      <option key='08' value="+1939">+1-939</option>
      <option key='09' value="+27">+27</option>
      <option key='10' value="+297">+297</option>
      <option key='11' value="+30">+30</option>
      <option key='12' value="+31">+31</option>
      <option key='13' value="+32">+32</option>
      <option key='14' value="+33">+33</option>
      <option key='15' value="+34">+34</option>
      <option key='16' value="+39">+39</option>
      <option key='17' value="+351">+351</option>
      <option key='18' value="+353">+353</option>
      <option key='19' value="+354">+354</option>
      <option key='20' value="+358">+358</option>
      <option key='21' value="+377">+377</option>
      <option key='22' value="+41">+41</option>
      <option key='23' value="+44">+44</option>
      <option key='24' value="+45">+45</option>
      <option key='25' value="+46">+46</option>
      <option key='26' value="+47">+47</option>
      <option key='27' value="+48">+48</option>
      <option key='28' value="+49">+49</option>
      <option key='29' value="+420">+420</option>
      <option key='30' value="+52">+52</option>
      <option key='31' value="+55">+55</option>
      <option key='32' value="+506">+506</option>
      <option key='33' value="+60">+60</option>
      <option key='34' value="+61">+61</option>
      <option key='35' value="+63">+63</option>
      <option key='36' value="+64">+64</option>
      <option key='37' value="+65">+65</option>
      <option key='38' value="+66">+66</option>
      <option key='39' value="+7">+7</option>
      <option key='40' value="+81">+81</option>
      <option key='41' value="+82">+82</option>
      <option key='42' value="+86">+86</option>
      <option key='43' value="+853">+853</option>
      <option key='44' value="+886">+886</option>
      <option key='45' value="+91">+91</option>
      <option key='46' value="+972">+972</option>
      </>
     )
  }

   renderFormRows() {
       const numberOfUsers = [1];
       return (
          <table className="table-responsive-sm w-auto"
              style={{borderCollapse: "separate", borderSpacing: "10px 10px",

                      }}>
          <tbody>
          {
            numberOfUsers.map((index) => {
              return (
                 <>
                 <tr key={index}>
                     <td colSpan="3" ><label className="blackText">New User Info</label></td>
                 </tr>
                 <tr key={index+10}>
                     <td></td>
                     <td><label style={{color:"#4052A4"}}>First Name</label><br/>
                         <input type="text" onChange={() => this.handleChanges()} placeholder="First Name"/></td>
                     <td><label style={{color:"#4052A4"}}>Last Name</label><br/>
                         <input type="text" onChange={() => this.handleChanges()} placeholder="Last Name"/></td>
                 </tr>
                 <tr key={index+20}>
                     <td></td>
                     <td>
                          <div>
                             <label style={{color:"#4052A4"}}>Phone Number</label><br/>
                             <select id="countryCodeList" defaultValue="+1">
                                   {this.phoneCountryCodes()}
                             </select>&nbsp;&nbsp;
                             <input style={{width:"165px"}} type="text" onChange={() => this.handleChanges()} placeholder="Phone Number"/>
                          </div>
                     </td>
                  <td>
                        <label style={{color:"#4052A4"}}>NextLevel3 Username</label><br/>
                        <input type="text" onChange={() => this.handleChanges()} placeholder="Email Address"/>
                     </td>
                 </tr>
                 <tr key={index+30} style={{borderCollapse: "separate", borderSpacing: "10px 10px", borderBottom: "1px solid #000000"}}>
                     <td></td>
                     <td><div>
                         <label style={{color:"#4052A4"}}>Protected Application</label><br/>
                         <select defaultValue={this.state.domains[0]}
                                onChange={() => this.handleChanges()}>
                               {this.state.domains.map((domain, index) => {
                                   return ( <option key={index} value={domain.url}>{domain.url}</option> ) })
                               }
                         </select>
                     </div></td>
                     <td>
                        <label style={{color:"#4052A4"}}>Protected Application Username</label><br/>
                        <input type="text" onChange={() => this.handleChanges()} placeholder="Application User ID"/>
                     </td>
                 </tr>
                 <tr/>
                 { (index < 3) &&
                    <tr>
                        <td colSpan="3"><hr className="blackText"/></td>
                    </tr>
                 }
                 </>
              )
            })
          }
          </tbody>
          </table>
       )
  }

  render () {
      if (this.state.statusCode === 0) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
               </button>
            </div>
          </>
        )
      } else if (this.state.statusCode > 200) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Error Retrieving Company Application Data. Please Contact support.
               </button>
            </div>
          </>
        )
      } else {
        return (
            <>
              <Prompt message="Unsaved Changes! Are you sure you want leave?" when={this.state.isDirty}/>
              <div>
                  <Row>
                     <Col xl="12" lg="10">
                        <Card className="whiteCard">
                           <Card.Header className="d-flex justify-content-between">
                              <div className="header-title">
                                 <h4>Add Users to Protected Applications</h4>
                                 <p>If you need to add multiple users, please import the users from a CSV file on  the user management page.</p>
                              </div>
                           </Card.Header>
                           <Card.Body>
                             <form onSubmit={(e) => this.handleFormSubmit(e)}>
                                 <div>
                                   {this.renderFormRows()}
                                   <br/>
                                   <div >
                                      {/* <button className="formButton" type="submit">Submit</button>&nbsp;&nbsp;&nbsp;
                                      <button className="formButton" onClick={this.handleBack.bind(this)}>Return</button> */}

                                         <Button variant="secondary" onClick={this.handleBack.bind(this)} style={{marginRight:10}}>
                                            Cancel
                                          </Button>
                                          <Button variant="primary" type='submit'>
                                            Save
                                          </Button>
                                   </div>
                                 </div>
                             </form>
                           </Card.Body>
                        </Card>
                     </Col>
                  </Row>
               </div>
            </>
        )
      }
  }
}
export default CompanyAddUser
