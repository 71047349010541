import React, { Component } from 'react'
import {Row,Col} from 'react-bootstrap'
import Card from '../../components/Card'
import Store from '../../store'
import * as cognito from '../../libs/cognito';
import * as nl3Utils from '../../libs/nl3Utils';

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;

class AddDomain extends Component {

   constructor(props) {
     super(props)
     this.state = {
         domainName : "",
         domainURL: "",
         companyToken: "",
         status: 0,
     };
     Store.dispatch({type: 'COMPANY'});
   }

   async componentDidMount () {
       try {
          // Get the access token
          const session = await cognito.getSession();
          this.setState({companyToken : session.accessToken.jwtToken});
       } catch (e) {
          console.log(e.message);
          this.props.history.push('/');
          return;
       }
   }

   handleBack() {
     this.props.history.push('/dashboard/company-accounts');
   }

   handleNameChange(e) {
     this.setState({domainName: e.target.value});
   }

   handleURLChange(e) {
     this.setState({domainURL: e.target.value});
   }

   async createNewDomain(appDomain) {
      const domainData = JSON.stringify({"name": `${this.state.domainName}` ,"url": `${appDomain}`});
      const addDomainURL = nl3ApiBase + "/domains";
      const apiResponse = await fetch(addDomainURL, {
         method: 'POST',
         headers: {
           'Authorization': `Bearer ${this.state.companyToken}`,
           'Content-Type': 'application/json',
         },
         body: `${domainData}`,
       });
      return apiResponse.status;
   }

   handleNewDomain(e) {
      let appDomain = this.state.domainURL;
      let newDomain = this.state.domainURL;
      let httpDomain = appDomain.substr(0,7);
      let httpsDomain = appDomain.substr(0,8);
      if (httpDomain === 'http://') {
          newDomain = appDomain.substr(7,);
      }
      if (httpsDomain === 'https://') {
          newDomain = appDomain.substr(8,);
      }
      this.createNewDomain(newDomain)
         .then(status => {
                if (status === 200) {
                    nl3Utils.nl3Toast("Successfully added your application",true,"success");
                } else {
                    nl3Utils.nl3Toast("Error! your application was not added.",true,"error");
                }
              })
         .catch(e => {
                console.log(e.message);
              });
      this.setState({domainName : "", domainURL : ""});
   }

  render() {
    return (
      <>
        <div>
            <Row>
               <Col xl="9" lg="8">
                  <Card className="whiteCard">
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">Add Application Protection</h4>
                        </div>
                     </Card.Header>
                     <Card.Body>
                        <div className="new-user-info">
                           <table>
                              <tbody>
                                <tr>
                                   <td><label className="blackText">Application Name:&nbsp;</label></td>
                                   <td><input type="text" placeholder="example" value={this.state.domainName} onChange={this.handleNameChange.bind(this)} /></td>
                                </tr>
                                <tr>
                                   <td><label className="blackText">Application Domain:&nbsp;</label></td>
                                   <td><input type="text" placeholder="www.example.com"
                                          value={this.state.domainURL} onChange={this.handleURLChange.bind(this)}/>&nbsp;
                                   </td>
                                </tr>
                              </tbody>
                           </table>
                           <hr/>
                           <div align="center" className="button-container">
                              <button className="formButton" onClick={this.handleNewDomain.bind(this)}>Save</button>&nbsp;&nbsp;&nbsp;
                              <button className="formButton" onClick={this.handleBack.bind(this)}>Return</button>
                           </div>
                        </div>
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
         </div>
      </>
    )
  }
}

export default AddDomain
