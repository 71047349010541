import React, { Component } from 'react'
import { Row,Col } from 'react-bootstrap'
import Store from '../../store'
import moment from 'moment'
import ReactTooltip from 'react-tooltip';
import * as cognito from '../../libs/cognito';
import * as nl3Utils from '../../libs/nl3Utils';

//NL3 Styling
import "../../assets/nl3.css"

import { MdGroups } from 'react-icons/md'
import { BsPlayFill } from 'react-icons/bs'
import { Table, IconButton, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { BiPencil, BiTrash } from 'react-icons/bi';

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;

class PolicyManager extends Component {
   constructor(props) {
     super(props)
     this.state= {
        statusCode: 0,
        policyList: [],
        companyToken: "",
        policyID: null,
     }
     // This is the company dashboard page
     Store.dispatch({type: 'COMPANY'});
   }

   async componentDidMount() {
      // Get the access token
      try {
         const session = await cognito.getSession();
         this.setState({companyToken : session.accessToken.jwtToken});
         this.getPolicies();
      } catch (e) {
          console.log(e.message);
          this.props.history.push('/');
          return;
      }
   }

   async getPolicies() {
       try {
          // get the company policies
          const policyUrl = nl3ApiBase + `/policies`;
          const apiResponse = await fetch(policyUrl, {
               method: 'GET',
               headers: {
                 Authorization: `Bearer ${this.state.companyToken}`,
               },
             });

          if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
              const records = await apiResponse.json();
              this.setState({policyList: records, statusCode: 200});
          } else {
              this.setState({statusCode: apiResponse.status});
          }
       } catch (e) {
          console.log(e.message);
       }
    }

   async removePolicyFromDB(policyID) {
       try {
          const policyUrl = nl3ApiBase + `/policies/` + policyID;
          const apiResponse = await fetch(policyUrl, {
               method: 'DELETE',
               headers: {
                 Authorization: `Bearer ${this.state.companyToken}`,
               },
             });

          if (apiResponse.status === 200) {
            nl3Utils.nl3Toast("Success! Policy has been removed.",true,"success");
          } else {
            nl3Utils.nl3Toast("Error! Policy was not removed. Contact support if the problem persists.",true,"error");
          }
          this.getPolicies();
       } catch (e) {
          console.log(e.message);
       }
   }

   handleAddPolicy() {
       this.props.history.push('/dashboard/policies-add');
   }

   handleCancelDelete() {
       const modal = document.querySelector(".nl3Window_guide")
       modal.style.display = "none";
   }

   handleConfirmDelete() {
       const modal = document.querySelector(".nl3Window_guide")
       modal.style.display = "none";
       this.removePolicyFromDB(this.state.policyID);
   }

   handleRemovePolicy(currentPolicyID) {
       const modal = document.querySelector(".nl3Window_guide")
       modal.style.display = "block";
       this.setState({policyID: currentPolicyID});
   }

   handleEditPolicy(id) {
       Store.dispatch({type: 'POLICY', text: {id}});
       this.props.history.push('/dashboard/policies-edit');
   }

   handleEditPolicyGroups(id) {
       Store.dispatch({type: 'POLICY', text: {id}});
       this.props.history.push('/dashboard/policies-edit-groups');
   }

   async handleExecutePolicy(policyID) {
       try {
          const policyUrl = nl3ApiBase + `/policies/` + policyID +'/execute';
          const apiResponse = await fetch(policyUrl, {
               method: 'POST',
               headers: {
                 Authorization: `Bearer ${this.state.companyToken}`,
               },
             });

          if ((apiResponse.status === 200) || (apiResponse.status === 202)) {
            nl3Utils.nl3Toast("Success! Policy is being executed.",true,"success");
          } else {
            nl3Utils.nl3Toast("Error! Policy was not executed. Contact support if the problem persists.",true,"error");
          }
       } catch (e) {
          console.log(e.message);
       }
   }

   renderTableData() {
        return this.state.policyList.map((entry, index) => {
           var typeText = "";
           var frequencyText = "";
           var actionText = "";
           var noEnd = false;
           if (entry.policyType === "SCHEDULED") {
               typeText = entry.policyType;
               frequencyText = (entry.frequencyOfAction === "BI_WEEKLY") ? "BI-WEEKLY" : entry.frequencyOfAction;
               actionText = (entry.scheduledAction === "ALLOW_UNLOCK") ? "ALLOW UNLOCK" : entry.scheduledAction;
               let startDate = new Date(entry.activeDate);
               let endDate = new Date(entry.endDate);
               noEnd = (endDate.getFullYear() > startDate.getFullYear() + 100) ? true : false;
           } else if (entry.policyType === "MULTI_APPROVAL") {
               typeText = "MULTI-APPROVAL";
               frequencyText = "ALWAYS";
               actionText = (entry.multiApprovalAction === "ALLOW_UNLOCK") ? "ALLOW UNLOCK" : entry.multiApprovalAction;
           } else if (entry.policyType === "ALERT") {
               typeText = "ALERT";
               frequencyText = "ALWAYS";
               actionText = entry.alertAction;
           }
           return (
               <TableRow key={index}>
                 <TableCell>{typeText}</TableCell>
                 <TableCell style={{"whiteSpace":"pre-wrap"}}>{entry.policyName}</TableCell>
                 { (entry.policyType === "SCHEDULED") && (!noEnd) &&
                     <TableCell>{moment(entry.activeDate).format("MM/DD/YYYY")}<br/>{moment(entry.endDate).format("MM/DD/YYYY")}</TableCell>
                 }
                 { (entry.policyType === "SCHEDULED") && (noEnd) &&
                     <TableCell>{moment(entry.activeDate).format("MM/DD/YYYY")}<br/>No End Date</TableCell>
                 }
                 { ((entry.policyType === "MULTI_APPROVAL") || (entry.policyType === "ALERT")) &&
                     <TableCell>ALWAYS</TableCell>
                 }
                 <TableCell>{frequencyText}</TableCell>
                 <TableCell>{actionText}</TableCell>
                 <TableCell>
                     { (entry.policyType === "ALERT") &&

                      <IconButton title="Execute Policy" aria-label="Execute Policy" onClick={(e) => this.handleExecutePolicy(entry.id)}>
                        <BsPlayFill size={20} ></BsPlayFill>
                      </IconButton>
                        //  <Link to="#" className="nav-link" role="button" onClick={(e) => this.handleExecutePolicy(entry.id)}>
                        //       <BsPlayFill fill="black"  style={{fontSize:"2.2rem"}}
                        //             data-tip="Execute Policy" data-for="policyTips" data-place="top"/>
                        //  </Link>
                     }
                     { ((entry.policyType === "SCHEDULED") || (entry.policyType === "MULTI_APPROVAL")) &&
                          <IconButton title="Edit Policy"  aria-label="Edit Policy" onClick={(e) => this.handleEditPolicy(entry.id)}>
                            <BiPencil size={20}></BiPencil>
                         </IconButton>
                        //  <Link to="#" className="nav-link" role="button" onClick={(e) => this.handleEditPolicy(entry.id)}>
                        //       <MdModeEditOutline fill="black" style={{fontSize:'2.2rem'}}
                        //             data-tip="Edit Policy" data-for="policyTips" data-place="top"/>
                        //  </Link>
                     }

                         <IconButton aria-label="Assign Groups and Users to Policy" title="Assign Groups and Users to Policy" onClick={(e) => this.handleEditPolicyGroups(entry.id)}>
                            <MdGroups></MdGroups>
                          </IconButton>
                     {/* <Link to="#" className="nav-link" role="button" onClick={(e) => this.handleEditPolicyGroups(entry.id)}>
                          <MdGroups fill="black" style={{fontSize:'2.2rem'}}
                                data-tip="Assign Groups and Users to Policy" data-for="policyTips" data-place="top"/>
                     </Link> */}

                       <IconButton title="Delete Policy" aria-label="Delete Policy" onClick={(e) => this.handleRemovePolicy(entry.id)}>
                            <BiTrash></BiTrash>
                          </IconButton>
                     {/* <Link to="#" className="nav-link" role="button" onClick={(e) => this.handleRemovePolicy(entry.id)}>
                          <IoTrash fill="red" style={{fontSize:'2.2rem'}}
                                data-tip="Delete Policy" data-for="policyTips" data-place="top"/>
                     </Link> */}
                 </TableCell>

               </TableRow>
           )
        })
   }

   render() {
     //Company Dashboard
     if ((isNaN(this.state.statusCode)) || (this.state.statusCode === 0)) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
               </button>
            </div>
          </>
        )
     } else if (this.state.statusCode > 201) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Error Retrieving Company Policy Data. Please Contact support.
               </button>
            </div>
          </>
        )
     } else {
        if (this.state.policyList.length === 0) {
          return (
            <>
               <Row>
                 <Col md="12" lg="12">
                   <Row className="row-cols-1">
                     <div className="whiteCard card overflow-hidden">
                         <div className="card-header d-flex justify-content-between flex-wrap">
                             <div className="header-title">
                               <h4 className="card-title">Policy Manager - No Policies Defined</h4>
                             </div>
                             <button className="actnButton" onClick={() => this.handleAddPolicy()}>+ New Policy</button>
                         </div>
                         <br/>
                     </div>
                   </Row>
                 </Col>
               </Row>
            </>
          )
        } else {
          return (
            <>
            <div className="whiteCard card overflow-hidden">
                         <div className="card-header d-flex justify-content-between flex-wrap">
                             <div className="header-title">
                               <h4 className="card-title">Policy Manager</h4>
                             </div>
                             <button className="actnButton" onClick={() => this.handleAddPolicy()}>+ New Policy</button>
                         </div>


                     <div className="card-body p-3">
               <Row>
                 <Col md="12" lg="12">
                   <Row className="row-cols-1">

                            <div className="table-wrapper-scroll-y">
                              <Table size="small"  >
                                  <TableHead>
                                      <TableRow>
                                          <TableCell>Type</TableCell>
                                          <TableCell>Name</TableCell>
                                          <TableCell>Dates Valid</TableCell>
                                          <TableCell>Frequency</TableCell>
                                          <TableCell>Execution</TableCell>
                                          <TableCell style={{"width":"20px"}}>&nbsp;&nbsp;&nbsp;&nbsp;Action</TableCell>

                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                      {this.renderTableData()}
                                  </TableBody>
                              </Table>
                            </div>
                   </Row>
                 </Col>
             </Row>
                     </div>
             </div>
             <ReactTooltip className="nl3Tooltip" id="policyTips" html={true}/>
             {/* The following code creates the popup */}
                 <div className="nl3Window_guide">
                    <div className="nl3Window_content" style={{"width":"300px"}}>
                       <div className="nl3Window_title">
                          <p align="center">Remove Policy?</p>
                       </div>
                         <div align="center">
                            <button className="formButton" onClick={this.handleCancelDelete.bind(this)}>Cancel</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button className="formButton" onClick={this.handleConfirmDelete.bind(this)}>Remove</button>&nbsp;&nbsp;&nbsp;
                         </div>
                    </div>
                 </div>
             {/* End of popup code */}
         </>
        )
      }
     }
   }
}

export default PolicyManager
