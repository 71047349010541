import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { CookiesProvider } from 'react-cookie';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//router
import { BrowserRouter } from 'react-router-dom';

//store
import { Provider } from 'react-redux';

//reducer
import Store from './store'

ReactDOM.render(


  <BrowserRouter basename="/">
    <React.StrictMode>
      <Provider store={Store}>
          <CookiesProvider>
             <App />
             <ToastContainer/>
          </CookiesProvider>
      </Provider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);
