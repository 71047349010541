import React from 'react';
import * as cognito from '../../libs/cognito';
import { Row,Col, Modal, Button } from 'react-bootstrap'
import { BiPencil, BiTrash } from 'react-icons/bi';
import { Table, IconButton, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;

class JitTemplates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      objects: [],
      isDeleteConfirmationVisible: false,
      isActionCallPreviewVisible: false,
      deleteObjectId: null,
    };
  }

  async componentDidMount() {
    // Get the access token
    try {
      const session = await cognito.getSession();
      this.setState({companyToken : session.accessToken.jwtToken});
      this.fetchObjects();

   } catch (e) {
       console.log(e.message);
       this.props.history.push('/');
       return;
   }
  }

  fetchObjects = async () => {
    try {
      const response = await fetch(nl3ApiBase + '/approvalTemplates', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.state.companyToken}`,
        },
      });
      const data = await response.json();
      this.setState({ objects: data });
    } catch (error) {
      console.error('Error fetching objects:', error);
    }
  };

  handleDelete = (objectId) => {
    this.setState({ isDeleteConfirmationVisible: true, deleteObjectId: objectId });
  };

  confirmDelete = async () => {
    const { deleteObjectId } = this.state;
    try {
      await fetch(`${nl3ApiBase}/approvalTemplates/${deleteObjectId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${this.state.companyToken}`,
        },
      });
      this.fetchObjects();
      this.setState({ isDeleteConfirmationVisible: false, deleteObjectId: null });
    } catch (error) {
      console.error('Error deleting object:', error);
    }
  };

  cancelDelete = () => {
    this.setState({ isDeleteConfirmationVisible: false, deleteObjectId: null });
  };

  handleActionCallPreview = (template) => {


    let payload = {
      "templateId": template.key,
      "metaData": template.metaData ? template.metaData : {},
      "links": [
        {
          "linkUrl": "https://www.sample1.com",
          "linkTitle": "Sample Link Title 1"
        },
        {
          "linkUrl": "https://www.sample2.com",
          "linkTitle": "Sample Link Title 2"
        }
      ]
    };

    let previewString = `
curl -X "POST" "https://api.dev.nextlevel3.com/nl3/api/v1/approvalRequest" \

-H 'Accept: */*' \

-H 'Accept-Language: en-US,en;q=0.9' \

-H 'Content-Type: application/json' \

-H 'X-Nl3-Authorization-Token: <PUT TOKEN HERE> \

-H 'X-nl3-device-location: 28.495560,-81.534798' \

-d $'${JSON.stringify(payload, null, 3) }'

    `;
    this.setState({ isActionCallPreviewVisible: true, previewString});
  };

  closeActionPreview = async () => {
    this.setState({ isActionCallPreviewVisible: false, previewString: null});
  }

  handleAddTemplate() {
    this.props.history.push('/dashboard/jit-templates-edit');
}

  handleEditTemplate(tid) {
    this.props.history.push(`/dashboard/jit-templates-edit?id=${tid}`);
  }


  render() {
    const { objects, isDeleteConfirmationVisible, isActionCallPreviewVisible } = this.state;

    return (

      <Row>
        <Col md="12" lg="12">
          <Row className="row-cols-1">
            <div className="whiteCard card overflow-hidden">
              <div className="card-header d-flex justify-content-between flex-wrap">
                <div className="header-title">
                  <h4 className="card-title">JIT Approval Templates</h4>
                </div>
                <button className="actnButton" onClick={() => this.handleAddTemplate()}>+ New Template</button>
              </div>
              <div className="card-body p-3">
              <div className="">
                <TableContainer >
                <Table size="small"  >
                  <TableHead >
                    <TableRow>
                      <TableCell>Name</TableCell>
                      {/* <th>ID</th> */}
                      <TableCell>Key</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {objects.map((object) => (
                      <TableRow key={object.id}>
                        <TableCell className='overflow-hidden'>{object.name}</TableCell>
                        {/* <td className='overflow-hidden'>{object.id}</td> */}
                        <TableCell className='overflow-hidden'>{object.key}</TableCell>
                        <TableCell className='overflow-hidden'>{object.type}</TableCell>
                        <TableCell>
                          <IconButton aria-label="edit" onClick={() => this.handleEditTemplate(object.id)}>
                            <BiPencil size={20}></BiPencil>
                           </IconButton>
                          <IconButton aria-label="delete" onClick={() => this.handleDelete(object.id)}>
                            <BiTrash></BiTrash>
                          </IconButton>

                          {/* <button type="button" className="btn btn-light btn-sm" onClick={() => this.handleEditTemplate(object.id)}>Edit</button>&nbsp;
                          <button type="button" className="btn btn-danger btn-sm" onClick={() => this.handleDelete(object.id)}>Delete</button> */}
                          <button type="button" className="btn btn-light btn-sm" onClick={() => this.handleActionCallPreview(object)}>Sample</button> &nbsp;
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                </TableContainer>
              </div>
            </div>
              <div className="card-footer d-flex justify-content-between flex-wrap p-1">
              </div>
            </div>


            {/* {isDeleteConfirmationVisible && (
              <div className="popup">
                <div className="popup-content">
                  <p>Are you sure you want to delete this object?</p>
                  <button onClick={this.confirmDelete}>Yes</button>
                  <button onClick={this.cancelDelete}>No</button>
                </div>
              </div>
            )} */}

          <Modal show={isDeleteConfirmationVisible} onHide={this.cancelDelete}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p>Are you sure you want to delete this approval template?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={this.cancelDelete}>
                no
              </Button>
              <Button variant="secondary" onClick={this.confirmDelete}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={isActionCallPreviewVisible} onHide={this.closeActionPreview} size='xl'>
            <Modal.Header closeButton>
              <Modal.Title>Sample Action</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <pre>
                {this.state.previewString}
              </pre>

            </Modal.Body>
            <Modal.Footer>

              <Button variant="secondary" onClick={this.closeActionPreview}>
                Done
              </Button>
            </Modal.Footer>
          </Modal>


          </Row>
        </Col>
      </Row>
    );
  }
}

export default JitTemplates;
