import React, { Component } from 'react'
import {Row,Col} from 'react-bootstrap'
import {Prompt} from 'react-router-dom';
import Card from '../../components/Card'
import Store from '../../store'
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker'
import Checkbox from '@material-ui/core/Checkbox'
import NL3DualListBox from "./dual-listbox.js";
import * as cognito from '../../libs/cognito';
import * as nl3Utils from '../../libs/nl3Utils';

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;

class EditPolicy extends Component {
   constructor(props) {
     super(props)
     this.handlePolicyChange = this.handlePolicyChange.bind(this);
     this.handlePolicyGroupChange = this.handlePolicyGroupChange.bind(this);
     this.state = {
         policyID : null,
         policyName : "",
         policyType: "",
         companyToken: "",
         status: 0,
         isDirty: false,
         startDate: "",
         endDate: "",
         selectStartDate: null,
         selectEndDate: null,
         origDailyAction: "",
         dailyAction: "UNLOCK",
         origDailyTime: 0,
         dailyTime: 0,
         origFrequency: "",
         origMultiAction: "",
         noEnd: false,
         frequency: "DAILY",
         approvers: [],
         initMembers: [] ,
         members: [] ,
         userList: [],
         initGroupMembers: [] ,
         groupMembers: [] ,
         groupList: [],
     };
     Store.dispatch({type: 'COMPANY'});
   }

   async componentDidMount () {
      // Get the access token
      try {
         const session = await cognito.getSession();
         this.setState({companyToken : session.accessToken.jwtToken,
                        policyID : Store.getState().policy.id});

         this.getPolicy()
         .then(status => {
             if ((status === 200) || (status === 201)) {
                 this.getUsers()
                 .then(status => {
                    if ((status === 200) || (status === 201)) {
                       var tmpMem = [];
                       for (let t=0; t<this.state.approvers.length; t++) {
                          tmpMem.push(this.state.approvers[t].id);
                       }
                       this.getGroups()
                       .then(rc => {
                          if ((rc === 200) || (rc === 201)) {
                             var tmpGrp = [];
                             for (let u=0; u<this.state.groupApprovers.length; u++) {
                                tmpGrp.push(this.state.groupApprovers[u].id);
                             }
                             this.setState({initMembers: tmpMem, members: tmpMem,
                                            initGroupMembers: tmpGrp, groupMembers: tmpGrp,
                                            status: 200});
                          }
                       })
                    } else {
                       this.setState({status: 400});
                    }
                 })
                 .catch(e => {
                    console.log(e.message);
                 });
             } else {
                 this.setState({status: 400});
             }
         })
         .catch(e => {
             console.log(e.message);
         });
      } catch (e) {
          console.log(e.message);
          this.props.history.push('/');
          return;
      }
   }

   async getPolicy() {
      // get the company policy
      const policyUrl = nl3ApiBase + `/policies/${this.state.policyID}`;
      const apiResponse = await fetch(policyUrl, {
           method: 'GET',
           headers: {
             Authorization: `Bearer ${this.state.companyToken}`,
           },
         });

      if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
          const record = await apiResponse.json();
          let start = moment(record.activeDate).format("MM/DD/YYYY");
          let end = moment(record.endDate).format("MM/DD/YYYY");
          let stDate = moment.utc(record.scheduleDateTime).toDate();
          let stTime = moment(stDate).local().format("h:mm a");
          let stTimeVal = moment(stDate).local().format("H");
          let selStDate = moment(record.activeDate).toDate();
          let selEndDate = moment(record.endDate).toDate();
          var startDate = new Date(record.activeDate)
          var endDate = new Date(record.endDate)
          var noEnd = (endDate.getFullYear() > startDate.getFullYear() + 100) ? true : false;
          this.setState({policyName: record.policyName, policyType: record.policyType, 
                         policyKey: record.policyKey,
                         startDate: start, endDate: end, noEnd: noEnd,
                         selectStartDate: selStDate, selectEndDate: selEndDate,
                         origFrequency: record.frequencyOfAction, frequency: record.frequencyOfAction,
                         origDailyAction: record.scheduledAction, dailyAction: record.scheduledAction,
                         origDailyTime: stTime, dailyTime: stTimeVal, origMultiAction: record.multiApprovalAction,
                         approvers: record.approvers, groupApprovers: record.groupApprovers});
      } else {
          this.setState({policyName: ""});
      }
      return(apiResponse.status);
    }

   async getUsers() {
      // try to get the company user list
      var companyUsersUrl = nl3ApiBase;
      companyUsersUrl += `/companies/mycompany/users?orderBy=first_name:asc`;
      const apiResponse = await fetch(companyUsersUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.state.companyToken}`,
        },
      });

      if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
         const records = await apiResponse.json();
         this.setState({userList: records.items});
      }
      return( apiResponse.status);
   }

   async getGroups() {
     const groupUrl = nl3ApiBase + `/groups`;
     const apiResponse = await fetch(groupUrl, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${this.state.companyToken}`,
          },
        });

     if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
         const records = await apiResponse.json();
         this.setState({groupList: records});
     }
     return(apiResponse.status);
   }

   async updateSchedulePolicyDB () {
      let startDate = new Date(this.state.selectStartDate);
      startDate.setHours(0);
      const allowUnlock = (this.state.dailyAction === "LOCK") ? false : true;
      let endDate = new Date(this.state.selectEndDate);
      if (this.state.noEnd) {
         endDate.setFullYear(endDate.getFullYear() + 200);
      }
      let hours = +this.state.dailyTime;
      var time = startDate.getTime();
      var addHours = hours * (60 * 60 * 1000);
      let scheduleTime = new Date(time + addHours);
      const newPolicyUrl = nl3ApiBase + `/policies/${this.state.policyID}`;
      const policyData = JSON.stringify({ "allowUnlock" : `${allowUnlock}`,
                                          "activeDate": `${startDate.toISOString()}`,
                                          "endDate": `${endDate.toISOString()}`,
                                          "frequencyOfAction": `${this.state.frequency}`,
                                          "scheduleDateTime": `${scheduleTime.toISOString()}`,
                                          "scheduledAction": `${this.state.dailyAction}`});
      const apiResponse = await fetch(newPolicyUrl, {
             method: 'PATCH',
             headers: {
               'Authorization': `Bearer ${this.state.companyToken}`,
               'Content-Type': 'application/json',
             },
             body: `${policyData}`,
           });
      return(apiResponse.status);
   }

   async updateApprovalPolicyDB () {
      let removeMembers = [];
      let addMembers = [];
      let removeGroups = [];
      let addGroups = [];
      for (let i=0; i<this.state.members.length; i++) {
          // see if useraccount was added
          if (!this.state.initMembers.includes(this.state.members[i])) {
             addMembers.push(this.state.members[i]);
          }
      }
      for (let i=0; i<this.state.initMembers.length; i++) {
          // see if useraccount was removed
          if (!this.state.members.includes(this.state.initMembers[i])) {
             removeMembers.push(this.state.initMembers[i]);
          }
      }
      for (let i=0; i<this.state.groupMembers.length; i++) {
          // see if group was added
          if (!this.state.initGroupMembers.includes(this.state.groupMembers[i])) {
             addGroups.push(this.state.groupMembers[i]);
          }
      }
      for (let i=0; i<this.state.initGroupMembers.length; i++) {
          // see if group was removed
          if (!this.state.groupMembers.includes(this.state.initGroupMembers[i])) {
             removeGroups.push(this.state.initGroupMembers[i]);
          }
      }
      if ((removeMembers.length === 0) && (addMembers.length === 0) &&
          (removeGroups.length === 0) && (addGroups.length === 0)) {
         //nothing to do
         return(-1);
      }
      const newPolicyUrl = nl3ApiBase + `/policies/${this.state.policyID}`;
      const policyData = JSON.stringify({"approversToAdd":  addMembers,
                                         "approversToRemove": removeMembers,
                                         "groupApproversToAdd": addGroups,
                                         "groupApproversToRemove": removeGroups});
      const apiResponse = await fetch(newPolicyUrl, {
             method: 'PATCH',
             headers: {
               'Authorization': `Bearer ${this.state.companyToken}`,
               'Content-Type': 'application/json',
             },
             body: `${policyData}`,
           });
      return(apiResponse.status);
   }

   clearStateVariables() {
       this.setState({initMembers: this.state.members, isDirty: false});
   }

   handleSchedulePolicyUpdate() {
     this.updateSchedulePolicyDB()
       .then(status => {
            if (status === 200) {
               nl3Utils.nl3Toast("Success! Policy Added.",true,"success");
               this.clearStateVariables();
               this.getPolicy();
            } else {
               nl3Utils.nl3Toast("Error! Failed to add policy",true,"error");
            }})
        .catch(e => {
             console.log(e.message);
          });
   }

   handleApprovalPolicyUpdate() {
     this.updateApprovalPolicyDB()
       .then(status => {
            if (status === -1) {
               nl3Utils.nl3Toast("No Changes Made.",true,"info");
            } else if (status === 200) {
               nl3Utils.nl3Toast("Success! Policy Added.",true,"success");
               this.clearStateVariables();
               this.getPolicy();
            } else {
               nl3Utils.nl3Toast("Error! Failed to add policy",true,"error");
            }})
        .catch(e => {
             console.log(e.message);
          });
   }

   handleBack() {
     this.props.history.push('/dashboard/policies');
   }

   handlePolicyChange(members) {
     this.setState({members: members, isDirty: true});
   }

   handlePolicyGroupChange(members) {
     this.setState({groupMembers: members, isDirty: true});
   }

   handleUpdatePolicy() {
      if (this.state.policyType === "SCHEDULED") {
          this.handleSchedulePolicyUpdate();
      } else if (this.state.policyType === "MULTI_APPROVAL") {
          this.handleApprovalPolicyUpdate();
      } else if (this.state.policyType === "ALERT") {
          this.handleAlertPolicyUpdate();
      }
   }

   handleStartDateChange = e => {
     this.setState({selectStartDate: e});
   }

   handleEndDateChange = e => {
     this.setState({selectEndDate: e});
   }

   handleFrequencyChange(e) {
     this.setState({frequency : e.target.value, isDirty: true});
   }

   handleDailyActionChange(e) {
     this.setState({dailyAction : e.target.value, isDirty: true});
   }

   handleDailyTimeChange(e) {
     this.setState({dailyTime : e.target.value, isDirty: true});
   }

   handleCheckBox(e) {
      this.setState({noEnd:e.target.checked,
             selectEndDate: this.state.selectStartDate});
   }

   renderPolicyForm() {
     if (this.state.policyType === "SCHEDULED") {
        return(
           <>
           <tr>
             <td><label className="blackText">Policy Type:&nbsp;</label></td>
             <td><label className="blackText">{this.state.policyType}</label></td>
           </tr>
           <tr>
             <td><label className="blackText">Policy Name:&nbsp;</label></td>
             <td><label className="blackText">{this.state.policyName}</label></td>
           </tr>
           <tr>
             <td><label className="blackText">Dates Valid:&nbsp;</label></td>
             { (this.state.noEnd) &&
                <td><label className="blackText">{this.state.startDate} - No End Date</label></td>
             }
             { (!this.state.noEnd) &&
                <td><label className="blackText">{this.state.startDate} - {this.state.endDate}</label></td>
             }
           </tr>
           <tr>
             <td><label className="blackText">Frequency:&nbsp;</label></td>
             <td><label className="blackText">{this.state.origFrequency}</label></td>
           </tr>
           <tr>
             <td><label className="blackText">Action:&nbsp;</label></td>
             { (this.state.origDailyAction === "ALLOW_UNLOCK") ?
                 <td><label className="blackText">ALLOW UNLOCK</label></td>
               :
                 <td><label className="blackText">{this.state.origDailyAction}</label></td>
             }
           </tr>
           <tr>
             <td><label className="blackText">Time:&nbsp;</label></td>
             <td><label className="blackText">{this.state.origDailyTime}</label></td>
           </tr>
           <tr><td><br/></td></tr>
           <tr><td><br/></td></tr>
           <tr>
              <td><label className="blackText">New Start Date:&nbsp;</label></td>
              <td><DatePicker
                     dateFormat="MM/dd/yyyy"
                     minDate={this.state.selectStartDate}
                     selected={this.state.selectStartDate}
                     onChange={this.handleStartDateChange}
                  />
              </td>
           </tr>
           <tr>
              <td><label className="blackText">New End Date:&nbsp;</label></td>
              { !this.state.noEnd &&
                  <td><DatePicker
                         dateFormat="MM/dd/yyyy"
                         minDate={this.state.selectStartDate}
                         selected={this.state.selectEndDate}
                         onChange={this.handleEndDateChange}
                      />
                  </td>
              }
              { this.state.noEnd &&
                  <td><DatePicker
                         dateFormat="MM/dd/yyyy"
                         minDate={this.state.selectStartDate}
                         selected=""
                         onChange={this.handleEndDateChange}
                      />
                  </td>
              }
              <td><Checkbox size="small" color="primary" checked={this.state.noEnd}
                        disabled={false} onChange={(e) => this.handleCheckBox(e)}/>
                  <label className="blackText">No End Date</label>
              </td>
           </tr>
           <tr>
              <td><label className="blackText">New Frequency:&nbsp;</label></td>
              <td><select id="Frequency1" defaultValue={this.state.frequency}
                                onChange={this.handleFrequencyChange.bind(this)}>
                                <option value="DAILY">Daily</option>
                                <option value="WEEKLY">Weekly</option>
                                <option value="WEEKDAYS">Weekdays</option>
                                <option value="BI_WEEKLY">Bi-Weekly</option>
                                <option value="MONTHLY">Monthly</option>
                                <option value="YEARLY">Yearly</option>
                           </select></td>
           </tr>
           <tr>
              <td><label className="blackText">New Action:&nbsp;</label></td>
              <td><select id="Action1" defaultValue={this.state.dailyAction}
                                onChange={this.handleDailyActionChange.bind(this)}>
                                <option value="UNLOCK">Unlock Accounts</option>
                                <option value="LOCK">Lock Accounts</option>
                                <option value="ALLOW_UNLOCK">Allow Accounts to be Unlocked</option>
                           </select></td>
           </tr>
           <tr>
              <td><label className="blackText">New Time:&nbsp;</label></td>
              <td><select id="Time1" defaultValue={this.state.dailyTime}
                                onChange={this.handleDailyTimeChange.bind(this)}>
                                <option value="0">12:00 am</option>
                                <option value="1">1:00 am</option>
                                <option value="2">2:00 am</option>
                                <option value="3">3:00 am</option>
                                <option value="4">4:00 am</option>
                                <option value="5">5:00 am</option>
                                <option value="6">6:00 am</option>
                                <option value="7">7:00 am</option>
                                <option value="8">8:00 am</option>
                                <option value="9">9:00 am</option>
                                <option value="10">10:00 am</option>
                                <option value="11">11:00 am</option>
                                <option value="12">12:00 pm</option>
                                <option value="13">1:00 pm</option>
                                <option value="14">2:00 pm</option>
                                <option value="15">3:00 pm</option>
                                <option value="16">4:00 pm</option>
                                <option value="17">5:00 pm</option>
                                <option value="18">6:00 pm</option>
                                <option value="19">7:00 pm</option>
                                <option value="20">8:00 pm</option>
                                <option value="21">9:00 pm</option>
                                <option value="22">10:00 pm</option>
                                <option value="23">11:00 pm</option>
                           </select></td>
           </tr></>
        )
     }
     if (this.state.policyType === "MULTI_APPROVAL") {
        return(
           <>
           <tr>
             <td><label className="blackText">Policy Type:&nbsp;</label></td>
             <td><label className="blackText">{this.state.policyType}</label></td>
           </tr>
           <tr>
             <td><label className="blackText">Policy Name:&nbsp;</label></td>
             <td><label className="blackText">{this.state.policyName}</label></td>
           </tr>
           <tr>
             <td><label className="blackText">Dates Valid:&nbsp;</label></td>
             <td><label className="blackText">ALWAYS</label></td>
           </tr>
           <tr>
             <td><label className="blackText">Frequency:&nbsp;</label></td>
             <td><label className="blackText">ALWAYS</label></td>
           </tr>
           <tr>
             <td><label className="blackText">Action:&nbsp;</label></td>
             <td><label className="blackText">{this.state.origMultiAction}</label></td>
           </tr>
           </>
        )
     }
   }

   render() {
    if ((isNaN(this.state.status)) || (this.state.status === 0)) {
       return (
         <>
           <div>
              <br/><br/><br/>
              <button className="btn btn-primary" type="button" disabled>
                 <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                 Loading...
              </button>
           </div>
         </>
       )
    } else if (this.state.status > 201) {
       return (
         <>
           <div>
              <br/><br/><br/>
              <button className="btn btn-primary" type="button" disabled>
                 <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                 Error Retrieving Policy Data. Please Contact support.
              </button>
           </div>
         </>
       )
    } else {
       return (
         <>
           <Prompt message="Unsaved Changes! Are you sure you want leave?" when={this.state.isDirty}/>
           <div>
               <Row>
                  <Col xl="9" lg="8">
                     <Card className="whiteCard">
                        <Card.Header className="d-flex justify-content-between">
                           <div className="header-title">
                              <h4 className="card-title">Edit Policy</h4>
                           </div>
                        </Card.Header>
                        <Card.Body>
                           <div className="new-user-info">
                              <table>
                                 <tbody>
                                    {this.renderPolicyForm()}
                                 </tbody>
                              </table>
                              { (this.state.policyType === "MULTI_APPROVAL") &&
                                  <>
                                    <div className="container">
                                       <hr/>
                                       <h4 className="card-title">Edit Secondary User Approvers</h4>
                                       <NL3DualListBox type="policy" data={this.state.userList}
                                           members={this.state.initMembers} action={this.handlePolicyChange}/>
                                    </div>
                                    <div className="container">
                                       <hr/>
                                       <h4 className="card-title">Edit Secondary Group Approvers</h4>
                                       <NL3DualListBox type="policy-group" data={this.state.groupList}
                                           members={this.state.initGroupMembers} action={this.handlePolicyGroupChange}/>
                                    </div>
                                  </>
                              }
                           </div>
                           <hr/>
                           <div align="center" className="button-container">
                                 <button className="formButton" onClick={this.handleUpdatePolicy.bind(this)}>Save</button>&nbsp;&nbsp;&nbsp;
                                 <button className="formButton" onClick={this.handleBack.bind(this)}>Return</button>
                           </div>
                        </Card.Body>
                     </Card>
                  </Col>
               </Row>
            </div>
         </>
       )
    }
  }
}

export default EditPolicy
