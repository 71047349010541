export default function AccessType(state = [], action) {
  switch (action.type) {
    case 'USER':
      state = "USER"
      return state 
    case 'COMPANY':
      state = "COMPANY"
      return state 
    default:
      return state
  }
}

