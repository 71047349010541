import React, { Component } from 'react'
import { Row,Col, Form, FloatingLabel, Button, OverlayTrigger, Popover } from 'react-bootstrap'
import Select from 'react-select'
import Store from '../../store'
import { Map, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react'
//import MarkerClusterer from '@google/markerclusterer'
import moment from 'moment-timezone'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import * as cognito from '../../libs/cognito'
import {CWidgetStatsA, CDropdown,
        CDropdownMenu, CDropdownItem, CDropdownToggle } from '@coreui/react'
import {CChartBar, CChartLine} from '@coreui/react-chartjs'
import CIcon from '@coreui/icons-react'
import {cilOptions} from '@coreui/icons'

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/components/navigation/navigation.scss';

//NL3 Styling
import "../../assets/nl3.css"
//import mapIcon from '../../assets/images/NL3-20x20.png';

import * as NL3_MAP from './dashboardMap';
import { left } from '@popperjs/core'
import { Chip, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;

const filterOptions =  [
      {value: '1', label: 'Policy Events' },
      {value: '2', label: 'Account Status'},
      {value: '3', label: 'Lock Status'},
      {value: '4', label: 'Approvals'}
]

const customStyles = {
  control: base => ({
    ...base,
    width: "400px",
  }),
  menu: base => ({
    ...base,
    width: "400px",
  })
};

class CompanyDashboard extends Component {
   constructor(props) {
     super(props)
     this.handleSelect = this.handleSelect.bind(this)
     this.state= {
        statusCode: 0,
        companyToken: "",
        showInfoWindow: true,
        selectedElement: null,
        activeMarker: null,
        startDate: null,
        endDate: null,
        currentPage: 1,
        totalPages: 1,
        totalItems: 0,
        itemsPerPage: 0,
        pageSize: 5000,
        allEvents: [],
        stats: [],
        policyStats: [],
        accountStats: [],
        lockStats: [],
        approvalStats: [],
        searchFilters: [],
        selectedFilter: '',
        selectedFilterValue: '',
        selectedOptions: null,
        todayPassed: 0,
        todayRejected: 0,
        totalPassed: 0,
        totalRejected: 0,
     }
     // This is the company dashboard page
     Store.dispatch({type: 'COMPANY'});
   }

   async componentDidMount() {
       try {
          // Get the access token
          var now = new Date();
          var currDate = moment(moment.utc(now).toDate()).format("YYYY-MM-DD");
          var startingDate = moment(moment.utc(now).subtract(7,"days").toDate()).format("YYYY-MM-DD");
          const user = await cognito.getCurrentUser();
          const session = await cognito.getSession();
          this.setState({startDate: startingDate, endDate: currDate,
                         companyToken : session.accessToken.jwtToken});

          // get the company stats
          const companyLoginUrl = nl3ApiBase + `/companies/login`;
          const apiResponse = await fetch(companyLoginUrl, {
            method: 'POST',
            headers: {
             'Content-Type': 'application/json',
              Authorization: `Bearer ${session.accessToken.jwtToken}`,
            },
          });

          if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
             const records = await apiResponse.json();
             if (records.domains.length > 0) {
                 // set the email for this admin in the cognito session
                 for (var i=0; i < records.admins.length; i++) {
                    if (records.admins[i].user.username === user.username) {
                       cognito.setCurrentUserEmail(records.admins[i].user.email);
                       break;
                    }
                 }
                 this.setState({todayPassed: records.aggregations.todayPassed,
                                todayRejected: records.aggregations.todayRejected,
                                totalPassed: records.aggregations.totalPassed,
                                totalRejected: records.aggregations.totalRejected});
             } else {
                 this.setState({todayPassed: 0, todayRejected: 0,
                                totalPassed: 0, totalRejected: 0});
             }
             // get the account info
             this.getCompanyHistoryData(startingDate, currDate);
          }
       } catch (e) {
          console.log(e.message);
          this.props.history.push('/');
          return;
       }
   }

   async getCompanyHistoryData(startDate, endDate) {
      // try to get the company history
      var companyHistoryUrl = nl3ApiBase;
      var queryStartDate = moment(startDate).format("yyyy-MM-DDT00:00:00.000-00:00");
      var queryEndDate = moment(endDate).format("yyyy-MM-DDT23:59:59.999-00:00");


      let filterString = '';
      if  (this.state.searchFilters && this.state.searchFilters.length > 0 ){
        this.state.searchFilters.forEach((next) => {
          filterString += encodeURIComponent(` and ${next.filter} eq ${next.value}`);
        })
      }

      companyHistoryUrl += `/companies/mycompany/history?filterAnd=requestDate lte ${queryEndDate} and requestDate gte ${queryStartDate}${filterString}&orderBy=requestDate%3Adesc&page=${this.state.currentPage}&pageSize=${this.state.pageSize}`;

      const apiResponse = await fetch(companyHistoryUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.state.companyToken}`,
        },
      });

      if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
         const records = await apiResponse.json();
         var policyEvents = [];
         var accountEvents = [];
         var lockEvents = [];
         var approvals = [];
         var totalRecs = records.items.length
         for (let i=0; i < totalRecs; i++) {
             if ((records.items[i].location.coordinates[0] === 0) && (records.items[i].location.coordinates[1] === 0)) {
                continue;
             }
             if (records.items[i].requestType === 'Company') {
                 policyEvents.push(records.items[i]);
             } else if (records.items[i].requestType.substr(0,15) === 'ApprovalRequest') {
                 approvals.push(records.items[i]);
             } else if ((records.items[i].requestType === 'LockUnlockUserAccount') ||
                 (records.items[i].requestType === 'LockUnlockAllUserAccount')) {
                 accountEvents.push(records.items[i]);
             } else if ((records.items[i].requestType === 'AccountProtectionCheckRequest') ||
                 (records.items[i].requestType === 'UnlockAccountRequestRejected')) {
                 lockEvents.push(records.items[i]);
             }
         }
         this.setState({statusCode : apiResponse.status,
                        stats: [...policyEvents, ...accountEvents, ...lockEvents, ...approvals],
                        allEvents: [...policyEvents, ...accountEvents, ...lockEvents, ...approvals],
                        policyStats: policyEvents, accountStats: accountEvents, lockStats: lockEvents,
                        approvalStats: approvals, currentPage: records.currentPage, totalPages: records.totalPages,
                        totalItems: records.totalItems, itemsPerPage: records.itemsPerPage,
                        pageSize: records.pageSize,
                        startDate: moment(startDate).toDate(),
                        endDate: moment(endDate).toDate()});
      } else {
         this.setState({statusCode : apiResponse.status});
      }
      return apiResponse.status;
   }

   handleMarkerClick(props,marker) {
     this.setState({selectedElement: props.info, activeMarker: marker});
   }

   handleStartDateChange = e => {
     var newStartDate = moment(e).toDate();
     if (e !== null) {
        if (this.state.endDate !== null){
           this.setState({statusCode: 0});
           this.getCompanyHistoryData(newStartDate, this.state.endDate);
        }
     }
   }

   handleEndDateChange = e => {
     var newEndDate = moment(e).toDate();
     if (e !== null) {
        if (this.state.startDate !== null){
           this.setState({statusCode: 0});
           this.getCompanyHistoryData(this.state.startDate, newEndDate);
        }
     }
   }

   handleSelect(data) {
     let tmp = [];
     for (let i=0; i < data.length; i++) {
         if (data[i].value === '1') {
            tmp = [...tmp, ...this.state.policyStats];
         } else if (data[i].value === '2') {
            tmp = [...tmp, ...this.state.accountStats];
         } else if (data[i].value === '3') {
            tmp = [...tmp, ...this.state.lockStats];
         } else if (data[i].value === '4') {
            tmp = [...tmp, ...this.state.approvalStats];
         }
     }
     if (data.length === 0) {
         tmp = this.state.allEvents;
     }
     this.setState({stats: tmp, selectedOptions: data});
   }

   showInfoWindowContent() {
      if (this.state.selectedElement.requestType === 'Company') {
          return (
             <div style={{ color: "black", background: "white" }}>
                  <b>Time:</b> {moment.utc(this.state.selectedElement.requestDate).local().format("MM/DD/YY h:mm a")}<br/><br/>
                  <b>Action:</b> {this.state.selectedElement.actionType.replace(/_/g,' ')}<br/><br/>
                  <b>UserId:</b> {this.state.selectedElement.userId}<br/><br/>
                  <b>Details:</b> {this.state.selectedElement.message.substr(0,this.state.selectedElement.message.length-33)}
             </div>
          )
      } else if ((this.state.selectedElement.requestType === 'LockUnlockUserAccount') ||
                 (this.state.selectedElement.requestType === 'LockUnlockAllUserAccount')) {
          return (
             <div style={{ color: "black", background: "white" }}>
                  <b>Time:</b> {moment.utc(this.state.selectedElement.requestDate).local().format("MM/DD/YY h:mm a")}<br/><br/>
                  <b>Action:</b> {this.state.selectedElement.lockUnlockStatus}<br/><br/>
                  <b>Account Id:</b> {this.state.selectedElement.userAccountId}<br/><br/>
                  <b>Details:</b> {this.state.selectedElement.message.substr(0,this.state.selectedElement.message.length-33)}
             </div>
          )
      } else if (this.state.selectedElement.requestType === 'AccountProtectionCheckRequest') {
          return (
             <div style={{ color: "black", background: "white" }}>
                  <b>Time:</b> {moment.utc(this.state.selectedElement.requestDate).local().format("MM/DD/YY h:mm a")}<br/><br/>
                  <b>Action:</b> {this.state.selectedElement.accountProtectionCheckStatus}<br/><br/>
                  <b>Account Id:</b> {this.state.selectedElement.userAccountId}<br/><br/>
                  <b>Details:</b> {this.state.selectedElement.message.substr(0,this.state.selectedElement.message.length-33)}
             </div>
          )
      } else if( this.state.selectedElement.requestType === 'UnlockAccountRequestRejected') {
          return (
             <div style={{ color: "black", background: "white" }}>
                  <b>Time:</b> {moment.utc(this.state.selectedElement.requestDate).local().format("MM/DD/YY h:mm a")}<br/><br/>
                  <b>Action:</b> REJECTED<br/><br/>
                  <b>Account Id:</b> {this.state.selectedElement.userAccountId}<br/><br/>
                  <b>Details:</b> {this.state.selectedElement.message.substr(0,this.state.selectedElement.message.length-33)}
             </div>
          )
      } else if( this.state.selectedElement.requestType === 'ApprovalRequest') {
          return (
             <div style={{ color: "black", background: "white" }}>
                  <b>Time:</b> {moment.utc(this.state.selectedElement.requestDate).local().format("MM/DD/YY h:mm a")}<br/><br/>
                  <b>Action:</b> APPROVAL REQUEST<br/><br/>
                  <b>Account Id:</b> {this.state.selectedElement.userAccountId}<br/><br/>
                  <b>Details:</b> {this.state.selectedElement.message.substr(0,this.state.selectedElement.message.length-33)}
             </div>
           )
      } else if( this.state.selectedElement.requestType === 'ApprovalRequestApproved') {
          return (
             <div style={{ color: "black", background: "white" }}>
                  <b>Time:</b> {moment.utc(this.state.selectedElement.requestDate).local().format("MM/DD/YY h:mm a")}<br/><br/>
                  <b>Action:</b> REQUEST APPROVED<br/><br/>
                  <b>Account Id:</b> {this.state.selectedElement.userAccountId}<br/><br/>
                  <b>Details:</b> {this.state.selectedElement.message.substr(0,this.state.selectedElement.message.length-33)}
             </div>
           )
      } else if( this.state.selectedElement.requestType === 'ApprovalRequestRejected') {
          return (
             <div style={{ color: "black", background: "white" }}>
                  <b>Time:</b> {moment.utc(this.state.selectedElement.requestDate).local().format("MM/DD/YY h:mm a")}<br/><br/>
                  <b>Action:</b> REQUEST REJECTED<br/><br/>
                  <b>Account Id:</b> {this.state.selectedElement.userAccountId}<br/><br/>
                  <b>Details:</b> {this.state.selectedElement.message.substr(0,this.state.selectedElement.message.length-33)}
             </div>
           )
      } else {
          return (
             <div style={{ color: "black", background: "white" }}>
                  <b>Time:</b> {moment.utc(this.state.selectedElement.requestDate).local().format("MM/DD/YY h:mm a")}<br/><br/>
                  <b>Details:</b> {this.state.selectedElement.message.substr(0,this.state.selectedElement.message.length-33)}<br/>
             </div>
          )
      }
   }

   renderMarkers() {
       return this.state.stats.map((statObj,i) => {
            var color = "https://maps.google.com/mapfiles/ms/icons/";
            if (statObj.requestType === 'Company') {
                  color += "blue-dot.png"
            } else if ((statObj.requestType === 'LockUnlockUserAccount') ||
                       (statObj.requestType === 'LockUnlockAllUserAccount')) {
                  color += "green-dot.png"
            } else if ((statObj.requestType === 'AccountProtectionCheckRequest') ||
                       (statObj.requestType === 'UnlockAccountRequestRejected')) {
                  color += "orange-dot.png"
            } else if (statObj.requestType.substr(0,15) === 'ApprovalRequest') {
               color += "red-dot.png"
            } else {
               color += "pink-dot.png"
            }
            return(
               <Marker
                   info={statObj}
                   position={{lat: statObj.location.coordinates[1]+(i/10000),
                              lng: statObj.location.coordinates[0]+(i/10000)}}
                   key={i}
                   id={i}
                   icon={color}
                   onClick={(props,marker) => {this.handleMarkerClick(props,marker)}}
                />
            )
       })
   }

   logoControlOptions = {
    position: this.props.google.maps.ControlPosition.RIGHT_BOTTOM, // Change this position as needed
  };

  _mapLoaded(mapProps, map) {
    map.setOptions({
       styles: NL3_MAP.NL3_MAP_STYLE_LIGHT,
       mapTypeControl: false,
       streetViewControl: false
    })
 }

    handleOpenPopover = () => {
      this.setState({ isPopoverOpen: true });
    };

    handleClosePopover = () => {
      this.setState({
        isPopoverOpen: false,
        selectedFilter: '',
        selectedFilterValue: '',
      });
    };

    handleAddFilter = () => {
      const { searchFilters, selectedFilter, selectedFilterValue } = this.state;
      const newFilters = [...searchFilters, { filter: selectedFilter, value: selectedFilterValue }];
      this.setState({
        searchFilters: newFilters,
        isPopoverOpen: false,
        selectedFilter: '',
        selectedFilterValue: '',
        statusCode: 0
      }, () => {
        console.log("REMOVE FILTER", this.state.searchFilters);
        this.getCompanyHistoryData(this.state.startDate, this.state.endDate);
      });

    };

    handleRemoveFilter = (indexToRemove) => {
      const { searchFilters } = this.state;
      const updatedFilters = searchFilters.filter((_, index) => index !== indexToRemove);
      this.setState({ searchFilters: updatedFilters, statusCode: 0 }, () => {
        console.log("REMOVE FILTER", this.state.searchFilters);
        this.getCompanyHistoryData(this.state.startDate, this.state.endDate);
      });

    };

   FilterComponent( ) {
    return (
      <div>
      {this.searchFilters.map((filter, index) => (
        <>
          <Chip label={`${filter.filter} - ${filter.value}`}
                onDelete={() => this.handleRemoveFilter(index)}
            />
        </>
      ))}
    </div>
    );
  }

  filterToLabel(key) {
    const resources = {
      actionType: "Action Type",
      requestType: "Request Type",
      lockUnlockStatus: "Lock Status",
      userAccountId: "User Account ID",
      ApprovalRequest: "Approval Request",
      ApprovalRequestApproved: "Request Approved",
      ApprovalRequestRejected: "Request Rejected",
      Company: "Company",
      AccountProtectionCheckRequest: "Account Protection Check Request",
      LockUnlockAllUserAccount: "Lock/Unlock all Accounts",
      LockUnlockUserAccount: "Lock/Unlock Account",
      UnlockAccountRequestRejected: "Unlock Account Rejected",
      LOCKED: "Locked",
      UNLOCKED: "Unlocked",
      ADD_COMPANY_ADMIN: 'Add Company Admin',
      COMPANY_UPDATED: 'Company Upadate',
      ADD_DOMAIN: 'Add Domain',
      EXECUTE_POLICY: 'Execute Policy',
      ADD_POLICY: 'Add Policy',
      POLICY_UPDATED: 'Policy Update',
      DELETE_POLICY: 'Delete Policy',
      IMPORT_USERS: 'Import Users',
      USER_CREATED: 'Create User',
      RESET_USER: 'User Reset',
      ADD_GROUP: 'Add Group',
      DELETE_GROUP: 'Delete Group',
      USER_STATUS_CHANGE: 'User Status Changed'

    };

    return resources[key] ? resources[key] : key;
  }

  filterValueComponent() {
    const { selectedFilter, selectedFilterValue } = this.state;
    if (selectedFilter === 'requestType'){
      return (
        <Form.Select
        value={selectedFilterValue}
        onChange={(e) => this.setState({ selectedFilterValue: e.target.value })}
      >
          <option value="">Select a value</option>
          <option value="ApprovalRequest">{this.filterToLabel('ApprovalRequest')}</option>
          <option value="ApprovalRequestApproved"> {this.filterToLabel('ApprovalRequestApproved')} </option>
          <option value="ApprovalRequestRejected"> {this.filterToLabel('ApprovalRequestRejected')} </option>
          <option value="Company"> {this.filterToLabel('Company')}</option>
          <option value="AccountProtectionCheckRequest"> {this.filterToLabel('AccountProtectionCheckRequest')}</option>
          <option value="LockUnlockAllUserAccount"> {this.filterToLabel('LockUnlockAllUserAccount')}</option>
          <option value="LockUnlockUserAccount"> {this.filterToLabel('LockUnlockUserAccount')}</option>
          <option value="UnlockAccountRequestRejected"> {this.filterToLabel('UnlockAccountRequestRejected')}</option>
        </Form.Select>
      );
    } else if (selectedFilter === 'lockUnlockStatus'){
      return(
        <Form.Select
          value={selectedFilterValue}
          onChange={(e) => this.setState({ selectedFilterValue: e.target.value })}
        >
            <option value="">Select a value</option>
            <option value="LOCKED">{this.filterToLabel('LOCKED')}</option>
            <option value="UNLOCKED">{this.filterToLabel('UNLOCKED')}</option>
          </Form.Select>
      );

    } else if (selectedFilter === 'actionType'){
        return(
          <Form.Select
          value={selectedFilterValue}
          onChange={(e) => this.setState({ selectedFilterValue: e.target.value })}
        >
            <option value="">Select a value</option>
            <option value="ADD_COMPANY_ADMIN">{this.filterToLabel('ADD_COMPANY_ADMIN')}</option>
            <option value="COMPANY_UPDATED">{this.filterToLabel('COMPANY_UPDATED')}</option>
            <option value="ADD_DOMAIN">{this.filterToLabel('ADD_DOMAIN')}</option>
            <option value="EXECUTE_POLICY">{this.filterToLabel('EXECUTE_POLICY')}</option>
            <option value="ADD_POLICY">{this.filterToLabel('ADD_POLICY')}</option>
            <option value="POLICY_UPDATED">{this.filterToLabel('POLICY_UPDATED')}</option>
            <option value="DELETE_POLICY">{this.filterToLabel('DELETE_POLICY')}</option>
            <option value="IMPORT_USERS">{this.filterToLabel('IMPORT_USERS')}</option>
            <option value="USER_CREATED">{this.filterToLabel('USER_CREATED')}</option>
            <option value="RESET_USER">{this.filterToLabel('RESET_USER')}</option>
            <option value="ADD_GROUP">{this.filterToLabel('ADD_GROUP')}</option>
            <option value="DELETE_GROUP">{this.filterToLabel('DELETE_GROUP')}</option>
            <option value="USER_STATUS_CHANGE">{this.filterToLabel('USER_STATUS_CHANGE')}</option>
          </Form.Select>
        );
    }else{

      return (
          <Form.Control
            type="text"
            value={selectedFilterValue}
            onChange={(e) => this.setState({ selectedFilterValue: e.target.value })}
          />

      );
    }
  }



   render() {
    const { searchFilters, selectedFilter, isPopoverOpen } = this.state;
    // const markerClustererComp = null;

    // markerClustererComp = new MarkerClusterer({
    //   map: this.map,
    //   markers: this.state.stats,
    //   customMarker: (marker) => {
    //     return new this.props.google.maps.Marker({
    //       position: marker.location.coordinates,
    //       icon: marker.icon,
    //       title: marker.title,
    //     });
    //   },
    // });


    const popover = (
      <Popover id="popover-basic">
        <Popover.Header as="h3">Add Filter</Popover.Header>
        <Popover.Body>
        <FloatingLabel controlId="selectCustomFilter" label="Filter" className="mb-3">
            <Form.Select
              value={selectedFilter}
              onChange={(e) => this.setState({ selectedFilter: e.target.value })}
            >
              <option value="">Select a filter</option>
              <option value="requestType">{this.filterToLabel('requestType')}</option>
              <option value="actionType">{this.filterToLabel('actionType')}</option>
              <option value="lockUnlockStatus">{this.filterToLabel('lockUnlockStatus')}</option>
              <option value="userAccountId">{this.filterToLabel('userAccountId')}</option>

            </Form.Select>
          </FloatingLabel>
          <FloatingLabel controlId="selectCustomFilter" label="Filter" className="mb-3">

            {this.filterValueComponent()}
          </FloatingLabel>
          <div>
            <Button variant="secondary" onClick={this.handleAddFilter} style={{marginRight:5}}>Add</Button>
            <Button variant="primary" onClick={this.handleClosePopover}>Cancel</Button>
          </div>

        </Popover.Body>
      </Popover>
    );

     if ((isNaN(this.state.statusCode)) || (this.state.statusCode === 0)) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
               </button>
            </div>
          </>
        )
     } else if (this.state.statusCode > 201) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Error Retrieving Company Data. Please Contact support.
               </button>
            </div>
          </>
        )
     } else {
        return (
          <div className="whiteCard card overflow-hidden">
              <div className="card-header d-flex justify-content-between flex-wrap">
                <div className="header-title">
                  <h4 className="card-title">Dashboard</h4>
                </div>

              </div>
              <div className="card-body p-3">
           <Row>
             <Col className='d-slider1 overflow-hidden'>
                <Row className='mb-3'>
                  <Col>
                      <div style={{float: left, marginTop:2, marginRight: 7}}  className="blackText mb-2">Date Range </div>
                      <div style={{float:left, marginRight:5}} >
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          selected={this.state.startDate}
                          onChange={this.handleStartDateChange}

                        />
                        </div>
                        <div style={{float:left}}>
                      <DatePicker
                        dateFormat="MM/dd/yyyy"
                        minDate={moment(moment(this.state.startDate).add(1, "days")).toDate()}
                        maxDate={new Date()}
                        selected={this.state.endDate}
                        onChange={this.handleEndDateChange}

                      />
                      </div>


                  </Col>
                  <Col>
                  <div className="progress-detail" style={{"marginLeft":"10%"}}>
                      <div style={{float: left, marginTop:7, marginRight: 7}} className="blackText mb-2">Filter</div>
                      <div style={{float:left}} className="blackText">
                        <Select
                          styles={customStyles}
                          options={filterOptions}
                          isMulti
                          onChange={this.handleSelect}
                          value={this.state.selectedOptions}
                        />
                      </div>

                  </div>
                  <OverlayTrigger trigger="click"  placement="bottom"  overlay={popover}  show={isPopoverOpen} >
                              <Button variant="secondary" onClick={this.handleOpenPopover} style={{marginTop:5, marginLeft:7}}>
                                Add Filter
                              </Button>
                          </OverlayTrigger>
                  </Col>

                </Row>
                <Row>
                  <Col>

                  </Col>
                </Row>

                <div>
                    <div className="filter-chip" style={{marginBottom:10}}>
                  {searchFilters?.map((filter, index) => (
                      <Chip
                        style={{marginRight:2}}
                        key={index}
                        label={`${this.filterToLabel(filter.filter)}: ${this.filterToLabel(filter.value)}`}
                        onDelete={() => this.handleRemoveFilter(index)}
                      />
                      ))}
                    </div>
                </div>

                <Row >
                  <Col>
                  <div className="purpleCard card card-slide">
                               <div className="card-body">
                                   <div className="progress-widget" >
                                       <div className="progress-detail">
                                           <p  className="mb-2">Todays Logins Allowed</p>
                                           <h4 className="counter">{this.state.todayPassed}</h4>
                                       </div>
                                   </div>
                               </div>
                           </div>
                  </Col>
                  <Col>
                  <div className="purpleCard card card-slide">
                               <div className="card-body">
                                   <div className="progress-widget" >
                                       <div className="progress-detail">
                                           <p  className="mb-2">Todays Logins Rejected</p>
                                           <h4 className="counter">{this.state.todayRejected}</h4>
                                       </div>
                                   </div>
                               </div>
                           </div>
                           </Col>
                  <Col>
                  <div className="purpleCard card card-slide">
                               <div className="card-body">
                                   <div className="progress-widget" >
                                       <div className="progress-detail">
                                           <p  className="mb-2">Total Logins Allowed</p>
                                           <h4 className="counter">{this.state.totalPassed}</h4>
                                       </div>
                                   </div>
                               </div>
                           </div>
                  </Col>
                  <Col>
                  <div className="purpleCard card card-slide">
                               <div className="card-body">
                                   <div className="progress-widget">
                                       <div className="progress-detail">
                                       <p  className="mb-2">Total Logins Rejected</p>
                                       <h4 className="counter">{this.state.totalRejected}</h4>
                                       </div>
                                   </div>
                               </div>
                           </div>
                  </Col>


               </Row>

               <Row className="">
                <Col sm={2}>
                <CWidgetStatsA
                       className="mb-4"
                       color="primary"
                       value={
                         <>
                           {this.state.policyStats.length}{' '}
                         </>
                       }
                       title="Policy Events"
                       chart={
                         <CChartLine
                           className="mt-3 mx-3"
                           style={{ height: '70px' }}
                           data={{
                             labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                             datasets: [
                               {
                                 label: 'My First dataset',
                                 backgroundColor: 'transparent',
                                 borderColor: 'rgba(255,255,255,.55)',
                                 pointBackgroundColor: '#321fdb',
                                 data: [65, 59, 84, 84, 51, 55, 40],
                               },
                             ],
                           }}
                           options={{
                             plugins: {
                               legend: {
                                 display: false,
                               },
                             },
                             maintainAspectRatio: false,
                             scales: {
                               x: {
                                 grid: {
                                   display: false,
                                   drawBorder: false,
                                 },
                                 ticks: {
                                   display: false,
                                 },
                               },
                               y: {
                                 min: 30,
                                 max: 89,
                                 display: false,
                                 grid: {
                                   display: false,
                                 },
                                 ticks: {
                                   display: false,
                                 },
                               },
                             },
                             elements: {
                               line: {
                                 borderWidth: 1,
                                 tension: 0.4,
                               },
                               point: {
                                 radius: 4,
                                 hitRadius: 10,
                                 hoverRadius: 4,
                               },
                             },
                           }}
                         />
                       }
                     />
                      <CWidgetStatsA
                       className="mb-4"
                       color="info"
                       value={
                         <>
                           {this.state.accountStats.length}{' '}
                         </>
                       }
                       title="Account Status"
                       chart={
                         <CChartLine
                           className="mt-3 mx-3"
                           style={{ height: '70px' }}
                           data={{
                             labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                             datasets: [
                               {
                                 label: 'My First dataset',
                                 backgroundColor: 'transparent',
                                 borderColor: 'rgba(255,255,255,.55)',
                                 pointBackgroundColor: '#39f',
                                 data: [1, 18, 9, 17, 34, 22, 11],
                               },
                             ],
                           }}
                           options={{
                             plugins: {
                               legend: {
                                 display: false,
                               },
                             },
                             maintainAspectRatio: false,
                             scales: {
                               x: {
                                 grid: {
                                   display: false,
                                   drawBorder: false,
                                 },
                                 ticks: {
                                   display: false,
                                 },
                               },
                               y: {
                                 min: -9,
                                 max: 39,
                                 display: false,
                                 grid: {
                                   display: false,
                                 },
                                 ticks: {
                                   display: false,
                                 },
                               },
                             },
                             elements: {
                               line: {
                                 borderWidth: 1,
                               },
                               point: {
                                 radius: 4,
                                 hitRadius: 10,
                                 hoverRadius: 4,
                               },
                             },
                           }}
                         />
                       }
                     />

                    <CWidgetStatsA
                       className="mb-4"
                       color="warning"
                       value={
                         <>
                           {this.state.lockStats.length}{' '}
                         </>
                       }
                       title="Lock Status"
                       chart={
                         <CChartBar
                           className="mt-3 mx-3"
                           style={{ height: '70px' }}
                           data={{
                             labels: [
                               'January',
                               'February',
                               'March',
                               'April',
                               'May',
                               'June',
                               'July',
                               'August',
                               'September',
                               'October',
                               'November',
                               'December',
                               'January',
                               'February',
                               'March',
                               'April',
                             ],
                             datasets: [
                               {
                                 label: 'My First dataset',
                                 backgroundColor: 'rgba(255,255,255,.2)',
                                 borderColor: 'rgba(255,255,255,.55)',
                                 data: [78, 81, 80, 45, 34, 12, 40, 85, 65, 23, 12, 98, 34, 84, 67, 82],
                                 barPercentage: 0.6,
                               },
                             ],
                           }}
                           options={{
                             maintainAspectRatio: false,
                             plugins: {
                               legend: {
                                 display: false,
                               },
                             },
                             scales: {
                               x: {
                                 grid: {
                                   display: false,
                                   drawTicks: false,
                                 },
                                 ticks: {
                                   display: false,
                                 },
                               },
                               y: {
                                 grid: {
                                   display: false,
                                   drawBorder: false,
                                   drawTicks: false,
                                 },
                                 ticks: {
                                   display: false,
                                 },
                               },
                             },
                           }}
                         />
                       }
                     />

                      <CWidgetStatsA
                       className="mb-4"
                       color="danger"
                       value={
                         <>
                           {this.state.approvalStats.length}{' '}
                         </>
                       }
                       title="Approval Actions"
                       action={
                         <CDropdown alignment="end">
                           <CDropdownToggle color="transparent" caret={false} className="p-0">
                             <CIcon icon={cilOptions} className="text-high-emphasis-inverse" />
                           </CDropdownToggle>
                           <CDropdownMenu>
                             <CDropdownItem>Approvals Sent</CDropdownItem>
                             <CDropdownItem>Approved</CDropdownItem>
                             <CDropdownItem>Rejected</CDropdownItem>
                           </CDropdownMenu>
                         </CDropdown>
                       }
                       chart={
                         <CChartBar
                           className="mt-3 mx-3"
                           style={{ height: '70px' }}
                           data={{
                             labels: [
                               'January',
                               'February',
                               'March',
                               'April',
                               'May',
                               'June',
                               'July',
                               'August',
                               'September',
                               'October',
                               'November',
                               'December',
                               'January',
                               'February',
                               'March',
                               'April',
                             ],
                             datasets: [
                               {
                                 label: 'My First dataset',
                                 backgroundColor: 'rgba(255,255,255,.2)',
                                 borderColor: 'rgba(255,255,255,.55)',
                                 data: [78, 81, 80, 45, 34, 12, 40, 85, 65, 23, 12, 98, 34, 84, 67, 82],
                                 barPercentage: 0.6,
                               },
                             ],
                           }}
                           options={{
                             maintainAspectRatio: false,
                             plugins: {
                               legend: {
                                 display: false,
                               },
                             },
                             scales: {
                               x: {
                                 grid: {
                                   display: false,
                                   drawTicks: false,
                                 },
                                 ticks: {
                                   display: false,
                                 },
                               },
                               y: {
                                 grid: {
                                   display: false,
                                   drawBorder: false,
                                   drawTicks: false,
                                 },
                                 ticks: {
                                   display: false,
                                 },
                               },
                             },
                           }}
                         />
                       }
                     />

                </Col>
                <Col sm={10}>
                  <div className="d-slider1 overflow-hidden" style={{height:window.innerHeight-500, width:"100%"}}>
                    <Map
                      google={this.props.google}

                      zoom={5}
                      resetBoundsOnResize={true}
                      initialCenter={{lat: 37.69, lng: -97.31}}
                      onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}
                    >


                      { this.renderMarkers() }

                       { this.state.selectedElement ? (
                          <InfoWindow
                            maxWidth={300}
                            maxHeight={500}
                            visible={this.state.showInfoWindow}
                            marker={this.state.activeMarker}
                            onCloseClick={() => this.setState({selectedElement: null})}
                          >
                            {this.showInfoWindowContent()}
                          </InfoWindow>
                       ):(<></>)}
                    </Map>
                  </div>
                  </Col>
               </Row>
               <Row>
                  <br/><br/>
               </Row>


             </Col>
           </Row>
           <Row>
            <Col>
            <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Event</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.state.allEvents.map((object, i) => (
                           <TableRow key={i}>
                           <TableCell>{object.message}</TableCell>
                         </TableRow>
                        ))}

                        </TableBody>
                      </Table>

                      </Col>
           </Row>
           </div>
           </div>
        )
     }
   }
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyCQLlOhv3DlBCITapkgRNRWSj0wB_peckE'
})(CompanyDashboard);

