import React from 'react'

//router
import { Switch,Route } from 'react-router'
//layoutpages
import SignUp from '../views/signUp'
import AutoUnlock from '../views/autoUnlock'
import AutoApprove from '../views/autoApprove'
import RecoverAcct from '../views/recoverAcct'
import EnableAcct from '../views/enableAcct'
import Index from '../views/index'
import Eula from '../views/eula'
import Dashboard from '../layouts/dashboard/default'
import CompanyDashboard from '../layouts/dashboard/company'

const IndexRouters = () => {
        return (
            <>
              <Switch>
                 {/* Auth Pages */}
                 <Route  path="/signup" component={SignUp}></Route>
                 <Route  path="/autoUnlock" component={AutoUnlock}></Route>
                 <Route  path="/autoApprove" component={AutoApprove}></Route>
                 <Route  path="/recoverAcct" component={RecoverAcct}></Route>
                 <Route  path="/enableAcct" component={EnableAcct}></Route>
                 <Route  path="/eula" component={Eula}></Route>
                 {/* Site Pages */}
                 <Route  path="/dashboard" component={Dashboard}></Route>
                 <Route  path="/mydomains" component={CompanyDashboard}></Route>
                 <Route  path="/*" exact component={Index}></Route>
              </Switch>
            </>
        )
}

export default IndexRouters
