import React, { Component } from 'react'
import { Row,Col } from 'react-bootstrap'
import Store from '../../store'


//circular
import Circularprogressbar from '../../components/circularprogressbar.js'

//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/components/navigation/navigation.scss';

//Count-up
import CountUp from 'react-countup';

import "../../assets/nl3.css"

  
// install Swiper modules
SwiperCore.use([Navigation]);

//const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;

class Index extends Component {

   constructor(props) {
     super(props)
     this.state= { 
        current_logins: 0,
        previous_logins: 0,
        current_percentage: 0,
        billing: [ ],
     }
     // This is the user dashboard page
     Store.dispatch({type: 'COMPANY'});
   }

   async componentDidMount() {
       try {
          // Get the access token
          // When API is ready enable getting data about accounts

          var billing_records = [
            { id: 1, period: 'Jul 15th - Aug 15th 2021', invoice: '#215462', status: 'paid', users: 2500, rate: 3.00, discount: 0.50},
            { id: 2, period: 'Aug 15th - Sep 15th 2021', invoice: '#215603', status: 'paid', users: 2750, rate: 3.00, discount: 0.50},
            { id: 3, period: 'Sep 15th - Oct 15th 2021', invoice: '#215749', status: 'paid', users: 3035, rate: 3.00, discount: 0.50},
            { id: 4, period: 'Oct 15th - Nov 15th 2021', invoice: '#216121', status: 'pending', users: 3450, rate: 3.00, discount: 0.50},
          ];
          let percentage = Math.trunc(this.state.current_logins / 30000 * 100);
          this.setState({current_logins: 2321, previous_logins: 54321, billing: billing_records, current_percentage: percentage});
       } catch (e) {
          console.log(e.message);
       }
   }

   renderTableData() {
      return this.state.billing.map((record, index) => {
         const { id, period, invoice, status, users, rate, discount } = record //destructuring
         const actionBtn = <button className="detailsButton" onClick={() => this.handleAction(id,invoice)}>Details</button>
         const amount = ((rate - discount) * users) * 1.075;

         return (
             <tr key={id}>
               <td>{period}</td>
               <td>{invoice}</td>
               <td>{status}</td>
               <td>${(amount).toFixed(2)}</td>
               <td>{actionBtn}</td>
             </tr>
         )
      })
   }

   handleAction(id) {
       Store.dispatch({type: 'INVOICE', text: {id}});
       this.props.history.push('/dashboard/invoice');
   }

   render() {
     //User Dashboard
     if (this.state.billing.length === 0) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="actnButton" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
               </button>
            </div>
          </>
        )
     } else {
        return (
          <>
             <Row>
                 <Col md="12" lg="12">
                     <Row className="row-cols-1">
                         <div className="d-slider1 overflow-hidden ">
                             <Swiper className="list-inline m-0 p-0 mb-2 "
                                 slidesPerView={2}
                                 spaceBetween={32}
                             >
                                 <SwiperSlide className="purpleCard card card-slide">
                                     <div className="card-body">
                                         <div className="progress-widget" >
                                             <Circularprogressbar stroke="#3a57e8" width="60px" height="60px" Linecap='rounded' trailstroke='#ddd' strokewidth="4px" style={{width:60, height:60,}} value={(this.state.current_percentage)} id="circle-progress-01" >
                                                 <div className="progress-value">{this.state.current_percentage}%</div>
                                             </Circularprogressbar>
                                             <div className="progress-detail">
                                                 <p  className="mb-2">Todays Attempted Logins this Billing Cycle</p>
                                                 <h4 className="counter"><CountUp  start={1} end={this.state.current_logins} duration={2}/></h4>
                                             </div>
                                         </div>
                                     </div>
                                 </SwiperSlide>
                                 <SwiperSlide className="purpleCard card card-slide">
                                     <div className="card-body">
                                         <div className="progress-widget">
                                             <Circularprogressbar stroke="#4bc7d2" width="60px" height="60px" trailstroke='#ddd' strokewidth="4px"  Linecap='rounded' style={{width:60, height:60,}} value={90} id="circle-progress-02" >
                                                 <div className="progress-value">90%</div>
                                             </Circularprogressbar>
                                             <div className="progress-detail">
                                             <p  className="mb-2">Total Attemptd Logins Prior Billing Cycle</p>
                                             <h4 className="counter"><CountUp  start={50} end={this.state.previous_logins} duration={2}/></h4>
                                             </div>
                                         </div>
                                     </div>
                                 </SwiperSlide>
                             </Swiper>
                         </div>
                     </Row>
                 </Col>
                 <Col md="12" lg="12">
                   <Row className="row-cols-1">
                     <div className="card whiteCard overflow-hidden">
                         <div className="card-header d-flex justify-content-between flex-wrap p-1">
                             <div className="header-title">
                                 <h4 className="card-title">Subscription</h4><span className="blackText">Authentication Requests</span>
                             </div>
                         </div>
                     </div>
                     <div className="card-body p-0">
                          <div className="table-responsive mt-4">
                            <div className="table-wrapper-scroll-y accounts-table">
                              <table id="acct-table" className="table table-primary mb-0" role="grid">
                                  <thead className="thead-dark">
                                      <tr>
                                          <th>PERIOD</th>
                                          <th>INVOICE</th>
                                          <th>STAUS</th>
                                          <th>AMOUNT</th>
                                          <th>DETAILS</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                     {this.renderTableData()}
                                  </tbody>
                              </table>
                            </div>
                          </div>
                     </div>
                   </Row>
                 </Col>
             </Row>
         </>
        )
     }
   }
}

export default Index
