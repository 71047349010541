import React, { Component } from 'react'
import { Row,Col } from 'react-bootstrap'
import Store from '../../store'
import Pagination from '../../components/pagination';
import ImportTableRow from './import-table-row';
import * as cognito from '../../libs/cognito';

//NL3 Styling
import "../../assets/nl3.css"
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;

class CompanyImports extends Component {
   constructor(props) {
     super(props)
     this.handleDetails = this.handleDetails.bind(this);
     this.state= {
        statusCode: 0,
        importList: [],
        companyToken: "",
        currentPage: 1,
        totalPages: 1,
        totalItems: 0,
        itemsPerPage: 0,
        pageSize: 20,
     }
     // This is the company dashboard page
     Store.dispatch({type: 'COMPANY'});
   }

   async componentDidMount() {
       try {
          // Get the access token
          const session = await cognito.getSession();
          this.setState({companyToken : session.accessToken.jwtToken});

          // get the account info
          this.getImportData(this.state.currentPage, this.state.pageSize);
       } catch (e) {
          console.log(e.message);
          this.props.history.push('/');
          return;
       }
    }

   async getImportData(pageNum, pageSize) {
      // try to get the company import list
      var companyImportUrl = nl3ApiBase;
      companyImportUrl += `/companies/mycompany/imports?orderBy=startTime:desc&page=${pageNum}&pageSize=${pageSize}`;
      const apiResponse = await fetch(companyImportUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.state.companyToken}`,
        },
      });

      if ((apiResponse.status === 200) || (apiResponse.status === 201)) {
         const records = await apiResponse.json();
         this.setState({statusCode : apiResponse.status, importList: records, currentPage: records.currentPage,
                        totalPages: records.totalPages, totalItems: records.totalItems,
                        itemsPerPage: records.itemsPerPage, pageSize: records.pageSize});
      } else {
         this.setState({statusCode : apiResponse.status});
      }
      return apiResponse.status;
    }

   onPageChanged(data) {
       this.getImportData(data.currentPage, data.pageLimit)
       .then(status => { if (status > 201) console.log("Error from getImportData, RC=",status);})
       .catch(e => { console.log(e.message);});
   }

   handlePageLimitChange(e) {
       const newPageLimit = parseInt(e.target.value,10);
       this.setState({pageSize: newPageLimit});
       this.getImportData(0, newPageLimit)
       .then(status => { if (status > 201) console.log("Error pageLimit from getImportData, RC=",status);})
       .catch(e => { console.log(e.message);});
   }

   handleDetails(importId) {
      Store.dispatch({type: 'IMPORT', text: {importId}});
      this.props.history.push('/dashboard/import-details');
   }

   handleBack() {
      this.props.history.push('/dashboard/company-users');
   }

   renderTableData() {
      return this.state.importList.items.map((importItem, itemIdx) => {
            const {id} = importItem; //destructuring
            return (
                 <ImportTableRow key={id} itemIdx={itemIdx} importItem={importItem} action={this.handleDetails}/>
             )
      })
   }

   render() {
     //Company Dashboard
     if ((isNaN(this.state.statusCode)) || (this.state.statusCode === 0)) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
               </button>
            </div>
          </>
        )
     } else if (this.state.statusCode > 201) {
        return (
          <>
            <div>
               <br/><br/><br/>
               <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Error Retrieving Company Import Data. Please Contact support.
               </button>
            </div>
          </>
        )
     } else {
        if (this.state.importList.totalItems === 0) {
          return (
            <>
               <Row>
                 <Col md="12" lg="12">
                   <Row className="row-cols-1">
                     <div className="whiteCard card overflow-hidden">
                         <div className="card-header d-flex justify-content-between flex-wrap">
                           <h4 className="card-title">No user import records were found.</h4>
                           <br/>
                         </div>
                     </div>
                   </Row>
                 </Col>
               </Row>
            </>
          )
        } else {
          return (
            <>
            <div className="card whiteCard overflow-hidden">
                         <div className="card-header d-flex justify-content-between flex-wrap">
                            <h4 className="card-title">User Import Records</h4>
                            <button type="button" className="actnButton" onClick={() => this.handleBack()}>Back</button>
                         </div>


               <Row>
                 <Col md="12" lg="12">
                   <Row className="row-cols-1">

                     <div className="card-body p-3 ">
                         <div className="">
                           <Table >
                               <TableHead >
                                   <TableRow>
                                       <TableCell>Import ID</TableCell>
                                       <TableCell>Status</TableCell>
                                       <TableCell>Start Time</TableCell>
                                       <TableCell>End Time</TableCell>
                                   </TableRow>
                               </TableHead>
                               <TableBody>
                                  {this.renderTableData()}
                               </TableBody>
                           </Table>
                         </div>
                     </div>
                   </Row>
                 </Col>
                 <Col md="6" lg="6">
                     <div className="d-flex flex-row py-4 align-items-center">
                           <span className="blackText">Show&nbsp;
                             <select defaultValue={this.state.pageSize} onChange={(e) => this.handlePageLimitChange(e)}>
                                   <option value="1">1 record</option>
                                   <option value="5">5 record</option>
                                   <option value="20">20 record</option>
                                   <option value="50">50 record</option>
                                   <option value="100">100 record</option>
                             </select>
                           </span>&nbsp;&nbsp;&nbsp;
                           <Pagination totalRecords={this.state.totalItems}
                                  pageLimit={this.state.pageSize} pageNeighbours={1}
                                  onPageChanged={(data) => this.onPageChanged(data)} />
                           &nbsp;&nbsp;&nbsp;
                      </div>
                 </Col>
             </Row>
             </div>
         </>
        )
      }
     }
   }
}

export default CompanyImports
