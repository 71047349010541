import React from 'react'
import { Navbar,Container,Nav,Dropdown} from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import CustomToggle from '../../../dropdowns'
import * as cognito from '../../../../libs/cognito'
import { CgMenuGridO } from 'react-icons/cg'
import '../../../../assets/nl3.css'

const accType = process.env.REACT_APP_NL3_PORTALTYPE;

const HeaderStyle3 = () => {
    const currentUser = (cognito.getCurrentUser()) ? cognito.getCurrentUser().username : "";
    const profilePath = (accType === 'COMPANY') ? '/dashboard/company-edit' : '/dashboard/user-edit';
    const history = useHistory();
    return (
        <>
            <Navbar expand="lg" variant="light" className="nav iq-navbar default navbar">
                <Container fluid className="navbar-inner">
                        <Navbar.Toggle aria-controls="navbarSupportedContent">
                            <span className="navbar-toggler-icon">
                                <span className="navbar-toggler-bar bar1 mt-2"></span>
                                <span className="navbar-toggler-bar bar2"></span>
                                <span className="navbar-toggler-bar bar3"></span>
                            </span>
                        </Navbar.Toggle>
                             
                        <Navbar.Collapse  id="navbarSupportedContent" className="col-md-auto">
                            <Nav as="ul" className=" ms-auto mb-2 mb-lg-0 align-items-center">
                                <Dropdown as="li" className="nav-item">
                                    <Dropdown.Toggle as={CustomToggle} variant=" nav-link py-0 d-flex align-items-center" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <div className="caption ms-3 d-none d-md-block">
                                            <span style={{"color":"black"}}>
                                               <CgMenuGridO style={{color:'#000000',fontSize:'1.5rem'}}/>
                                               &nbsp;{currentUser}
                                            </span>
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu  className="dropdown-menu-end" aria-labelledby="navbarDropdown">
                                        <Dropdown.Item onClick={() => {history.push(profilePath)}}>Profile</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={() => {history.push('/dashboard/device-mgmt')}}>Paired Devices</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={cognito.signOut}>Logout</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Nav>
                        </Navbar.Collapse>
                </Container>
            </Navbar>
        <br/>
        </>
    )
}

export default HeaderStyle3
