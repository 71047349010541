import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { useValidUsername } from '../hooks/useAuthHooks'
import Store from '../store'
import * as cognito from '../libs/cognito'
import * as nl3Utils from '../libs/nl3Utils'
import * as base64url from '../libs/base64url-arraybuffer'
import ReactTooltip from 'react-tooltip';

import nl3_icon from '../assets/images/NL3-banner-icon.png'
import { GiUsbKey } from 'react-icons/gi'
import { IoFingerPrint } from 'react-icons/io5'
import { TbFaceId } from 'react-icons/tb'
import { MdOutlinePhoneAndroid } from 'react-icons/md'

import '../assets/nl3.css'

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;
const nl3ApiLocalHost = process.env.REACT_APP_NL3_USE_LOCALHOST;
const nl3Production = process.env.REACT_APP_NL3_PRODUCTION;
const nl3PortalType = process.env.REACT_APP_NL3_PORTALTYPE;

const useStyles = makeStyles({
  root: {
    height: '100vh',
  },
  hover: {
    '&:hover': { cursor: 'pointer', color: 'red' },
  },
})

let cognitoUser = null;
let userCredentials = null;

const AutoApprove: React.FunctionComponent<{}> = () => {
  const classes = useStyles()
  const portalType = nl3PortalType;
  const backGround = (portalType === 'COMPANY') ? 'company-login-component' : 'login-component';
  Store.dispatch({type: portalType});

  let usrName = "";
  let domain = "";
  let approvalRequestSessionId = "";
  const params = new URLSearchParams(window.location.search);
  if ( params &&  params.has("uid")) {
    usrName = decodeURIComponent(params.get("uid"));
    console.log(usrName);
  } else {
    usrName = nl3Utils.nl3getUserName();
    console.log(usrName);
  }

  const domainMap = {
    "logindemo.nextlevel3.com": {"appName": "Filevine Demo", "origin": "https://logindemo.nextlevel3.com"},
    "write.logindemo.nextlevel3.com": {"appName": "Filevine Demo Write", "origin": "https://logindemo.nextlevel3.com"}
  }
  if ( params &&  params.has("fqdn")) {
    domain = decodeURIComponent(params.get("fqdn"));
    console.log(domain);
  } else {
    alert("Error - Domain (fqdn) must be provided for auto-approve!");
  } 

  if ( params &&  params.has("approvalRequestSessionId")) {
   approvalRequestSessionId = decodeURIComponent(params.get("approvalRequestSessionId"));
   console.log(domain);
 } else {
   alert("Error - Session Id (sessionId) must be provided for auto-approve!");
 }   

  const { username, usernameIsValid } =
       useValidUsername( (usrName !== null) ? usrName : '')
  const [checkBox ] =
       useState( (usrName !== null) ? true : false)
  const [error, setError] = useState('')
  const [disableButtons, setDisableButtons] = useState(false);
  var isValid = (!usernameIsValid || username.length === 0 || disableButtons);
  const history = useHistory()

  const userLookup = async () => {
     let lookupURL = nl3ApiBase + '/registration/lookup_';
     var lookupAPIRsp = await fetch(lookupURL, {
           method: "POST",
           headers: { "Content-Type": 'application/json'},
           body: `{ "username": "${username}"}`,
     });
     return lookupAPIRsp;
  }

  const signInThisDevice = async (e) => {
     setDisableButtons(true);
     Store.dispatch({type: 'REGULAR'});
     const lookupAPIRsp = await userLookup();
     const lookupAPIRspJson = await lookupAPIRsp.json();
     console.log("lookupAPIRsp = " + lookupAPIRspJson)
     const status = lookupAPIRsp.status;
     userCredentials = lookupAPIRspJson.credentials;
     if (status === 225) {
        localStorage.setItem('NL3IMPORTUSERNAME', username);
        history.push('/signUp');
     } else if (status === 223) {
        localStorage.setItem('NL3ADDDEVICE', username);
        history.push('/signUp');
     } else {
        signInClicked()
     }
  }

  const signInExternalDevice = async (e) => {
     setDisableButtons(true);
     Store.dispatch({type: 'EXTERNAL'});
     const lookupAPIRsp = await userLookup();
     const lookupAPIRspJson = await lookupAPIRsp.json();
     console.log("lookupAPIRsp = " + lookupAPIRspJson)
     const status = lookupAPIRsp.status;
     userCredentials = lookupAPIRspJson.credentials;
     if (status === 225) {
        localStorage.setItem('NL3IMPORTUSERNAME', username);
        history.push('/signUp');
     } else {
        signInClicked()
     }
  }

  //eslint-disable-next-line
  const signInClicked = async () => {
     setError('');
     if (checkBox) {
        nl3Utils.nl3setUserName(username);
     } else {
        nl3Utils.nl3delUserName();
     }
     const authenticationDetails = new cognito.authenticationDetails(username)
     cognitoUser = cognito.getCognitoUser(username);

     cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');
     cognitoUser.initiateAuth(authenticationDetails, authCallBack);
  }

  const authCallBack = {
    onSuccess: async function(result) {
      //var accessToken = result.getAccessToken().getJwtToken();
      //var idToken = result.getIdToken().getJwtToken();
      //var refreshToken = result.getRefreshToken().getToken();
      const sessionID = approvalRequestSessionId;
      let userNotifs = process.env.REACT_APP_NL3_API_BASE + `/users/approveNotification`;
      const requestData = {
         "sessionId": sessionID
      };
      const apiResponse = await fetch(userNotifs, {
         method: "POST",
         headers: {
            "Authorization": `Bearer ${await result.getAccessToken().getJwtToken()}`,
            'Content-Type': 'application/json',
         },
         body: JSON.stringify(requestData),
      });
      let notificationResponse = await apiResponse.json();
      if (apiResponse.status > 202) {
         const textBody = "Error approving the notification."
         nl3Utils.nl3Toast(textBody,true,"error");
         window.opener.postMessage(JSON.stringify(notificationResponse), domainMap[domain]["origin"]);         
      } else {         
         const textBody = "Successfully approved the notification."
         nl3Utils.nl3Toast(textBody,true,"success");
         console.log("Approval successful!");
         window.opener.postMessage(JSON.stringify(notificationResponse), domainMap[domain]["origin"]);         
      }
  
      window.close();        
    },
    customChallenge: async function(challengeParameters) {
      // User authentication depends on challenge response
      let loginType = Store.getState().login;
      var rpIDOrigin = 'dev.nextlevel3.com';
      if (nl3Production === 'TRUE') {
          rpIDOrigin = 'nextlevel3.com';
      }
      if (nl3ApiLocalHost === 'TRUE') {
          rpIDOrigin = window.location.hostname;
      }

      //----------get creds from security key or platform authenticator
      let allowCredentials = [];
      console.log("allowCredentials = " + allowCredentials);
      for(const val of userCredentials) {
        const cred = {
          "id": base64url.decode(val.id),
          "type":val.type,
          "transports": val.transports
        }
        allowCredentials.push(cred);
      }
      console.log("allowCredentials = " + allowCredentials);
      let signinOptions = {
         //challenge was generated and sent from CreateAuthChallenge lambda trigger
         "challenge": base64url.decode(challengeParameters.challenge),
         "timeout":1800000,
         "rpId": rpIDOrigin,
         "userVerification":"preferred",
         "allowCredentials": (loginType === 'EXTERNAL') ? [] : (allowCredentials === null) ? [] : allowCredentials
      }

      //get sign in credentials from authenticator
      const cred = await navigator.credentials.get({
        publicKey: signinOptions
      });

      //prepare credentials challenge response
      const credential = {};
      if (cred.response) {
        const clientDataJSON = base64url.encode(cred.response.clientDataJSON);
        const authenticatorData = base64url.encode(cred.response.authenticatorData);
        const signature = base64url.encode(cred.response.signature);
        const userHandle = base64url.encode(cred.response.userHandle);

        credential.response = {clientDataJSON, authenticatorData, signature, userHandle};
      }

      //send credentials to Cognito VerifyAuthChallenge lambda trigger for verification
      //sending in cred.id for authVerify match as deviceID
      let deviceID = cred.id;
      nl3Utils.nl3setCredId(cred.id);
      cognitoUser.sendCustomChallengeAnswer(JSON.stringify(credential), this, {"deviceId":deviceID});

    },
    onFailure: function(err) {
      if (err.message.includes("User does not exist.")) {
          setError("Validate username or register a new account if needed.");
      } else if (err.message.includes('The operation either timed out or was not allowed')) {
          setError("Authentication either timed out or was cancelled by the user.");
      } else if (err.message.includes('The first argument must be of type string or an instance of Buffer, ArrayBuffer, or Array or an Array-like Object. Received undefined.')) {
          setError("Invalid Credential. Credential validation failed.");
      } else {
          setError(err.message);
      }
      setDisableButtons(false);
    },
  }

  const getUrlParameter = (name) => {
      const params = new URLSearchParams(window.location.search);
      if ((params !== undefined) && (params !== null)) {
         const token = params.get(name);
         if ((token !== null) && (token.length > 0)) {
            return(token);
         } else {
            return(null);
         }
      } else {
         return(null);
      }
  }

  useEffect(() => {
      if (portalType === 'USER') {
          const enableToken = getUrlParameter('auth-token');
          if (enableToken !== null) {
             localStorage.setItem("auth-token", enableToken);
             history.push('/enableAcct');
          }
      } else if (portalType === 'COMPANY') {
          const regToken = getUrlParameter('x-amzn-marketplace-token');
          if (regToken !== null) {
             localStorage.setItem("awsToken", regToken);
          }
      }
  }, [usrName, portalType, history]);

  useEffect(() => {
    signInThisDevice();
    //eslint-disable-next-line
  }, []);

  return (
    <section className="login-content">
      <div className={backGround}>
        <Grid className={classes.root} container direction="row" justifyContent="center" alignItems="center">
          <Grid xs={11} sm={6} lg={4} container direction="row" justifyContent="center" alignItems="center" item>
            <div align="center" >
                <img src={nl3_icon} alt=""/><br/><br/>
            </div>
            <Paper style={{ backgroundColor: '#EBF4FA', width: '100%', padding: 32 }}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Box m={2}>
                  <Typography variant="h4">Passkey Unlock</Typography>
                </Box>
              </Grid>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Box mt={2}>
                  <Typography color="error" variant="body2">
                    {error}
                  </Typography>
                </Box>
              </Grid>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Box mt={2}>
                  <Grid container direction="row" justifyContent="center">
                    <Box m={1}>
                        <>
                         <Grid container direction="row" justifyContent="center">
                           <Button disabled={isValid} color="primary" variant="contained" onClick={signInThisDevice}
                                style={{width:"100%"}}
                                data-tip="Sign In with passkey stored on this device"
                                data-for="signInTips" data-place="top">
                             This Device
                             <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                             <TbFaceId fill="#FFFFFF" style={{fontSize:"1.5rem"}} />
                             <IoFingerPrint fill="#FFFFFF" style={{fontSize:"1.5rem"}} />
                           </Button>
                         </Grid><br/>
                         <Grid container direction="row" justifyContent="center">
                           <Button disabled={isValid} color="primary" variant="contained" onClick={signInExternalDevice}
                                style={{width:"100%"}}
                                data-tip="Sign In with an authorized physical device<br/> like a USB Key or Phone Passkey"
                                data-for="signInTips" data-place="top">
                             Phone or USB Device
                             <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                             <MdOutlinePhoneAndroid fill="#FFFFFF" style={{fontSize:"1.5rem"}} />
                             <GiUsbKey fill="#FFFFFF" style={{fontSize:"1.5rem"}} />
                           </Button>
                         </Grid>
                        </>
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <ReactTooltip className="nl3Tooltip" id="signInTips" html={true}/>
    </section>
  )
}

export default AutoApprove
