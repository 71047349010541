import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js'

const userPoolId = process.env.REACT_APP_COGNITO_USERPOOL_ID
const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID

const poolData = {
  UserPoolId: `${userPoolId}`,
  ClientId: `${clientId}`,
}

const userPool: CognitoUserPool = new CognitoUserPool(poolData)

let currentUser: any = userPool.getCurrentUser()

let authenticated = false;

export function getUserPool() {
  return userPool
}

export function getCurrentUser() {
  return currentUser;
}

export function getCognitoUser(username: string) {
  const userData = {
    Username: username,
    Pool: userPool,
  }
  const cognitoUser = new CognitoUser(userData)

  return cognitoUser
}

export function setCurrentUserEmail(email: string) {
   currentUser.email = email;
}

export function authenticationDetails(username: string) {
    const authenticationData = {
      Username: username
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    return authenticationDetails
}

export async function getSession() {
  if (!currentUser) {
    currentUser = userPool.getCurrentUser()
  }

  return new Promise(function (resolve, reject) {
    currentUser.getSession(function (err: any, session: any) {
      if (err) {
        reject(err)
      } else {
        resolve(session)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export function isAuthenticated() {
   return (authenticated)
}

export function setIsAuthenticated(value: boolean) {
   authenticated = value;
}


export function signOut() {
  if (currentUser) {
    currentUser.signOut()
  }
  window.location = "/";;
}

