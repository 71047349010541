import React from 'react'
import nl3Icon from '../../../assets/images/Next Level 3 - Square .png'
const Logo = (props ) => {
    
   return (
      <>
        <img src={nl3Icon} width="30px" height="30x" alt=""/>
      </>
   )
}
    
export default Logo
