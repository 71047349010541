import React, { Component } from 'react'
import {Row,Col} from 'react-bootstrap'
import {Prompt} from 'react-router-dom';
import Card from '../../components/Card'
import Store from '../../store'
import moment from 'moment'
import * as cognito from '../../libs/cognito';
import * as nl3Utils from '../../libs/nl3Utils';

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;

class NewGroup extends Component {
   constructor(props) {
     super(props)
     this.state = {
         groupName : "",
         groupKey : "",
         groupDesc : "",
         companyToken: "",
         status: 0,
         isDirty: false,
     };
     Store.dispatch({type: 'COMPANY'});
   }

   clearStateVariables() {
       this.setState({groupName : "",
           groupDesc: "",
           isDirty: false});
   }

   async componentDidMount () {
      // Get the access token
      try {
         const session = await cognito.getSession();
         this.setState({companyToken : session.accessToken.jwtToken});
      } catch (e) {
          console.log(e.message);
          this.props.history.push('/');
          return;
      }
   }

   async updateGroupDB () {
      var time = new Date();
      const keyData = moment(time).format("HH-mmss");
      const groupData = JSON.stringify({"groupKey": `${keyData}`,
                                        "groupDescription":`${this.state.groupDesc}`,
                                        "groupName": `${this.state.groupName}`});
      const groupUrl = nl3ApiBase + `/groups`;
      const apiResponse = await fetch(groupUrl, {
             method: 'POST',
             headers: {
               'Authorization': `Bearer ${this.state.companyToken}`,
               'Content-Type': 'application/json',
             },
             body: `${groupData}`,
           });
      return(apiResponse.status);
   }

   handleNewGroup() {
     this.updateGroupDB()
       .then(status => {
            if (status === 200) {
               nl3Utils.nl3Toast("Success! Group Added.",true,"success");
               this.clearStateVariables();
            } else {
               nl3Utils.nl3Toast("Error! Failed to add group",true,"error");
            }})
        .catch(e => {
             console.log(e.message);
          });
   }

   handleBack() {
     this.props.history.push('/dashboard/groups');
   }

   handleNameChange(e) {
     this.setState({groupName: e.target.value, isDirty: true});
   }

   handleDescChange(e) {
     this.setState({groupDesc: e.target.value, isDirty: true});
   }

   render() {
    return (
      <>
        <Prompt message="Unsaved Changes! Are you sure you want leave?" when={this.state.isDirty}/>
        <div>
            <Row>
               <Col xl="9" lg="8">
                  <Card className="whiteCard">
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">New Group</h4>
                        </div>
                     </Card.Header>
                     <Card.Body>
                        <div className="new-user-info">
                           <table>
                              <tbody>
                                <tr>
                                  <td><label className="blackText">Group Name:&nbsp;</label></td>
                                  <td><input type="text" value={this.state.groupName} onChange={this.handleNameChange.bind(this)} /></td>
                                </tr>
                                <tr>
                                  <td><label className="blackText">Group Description:&nbsp;</label></td>
                                  <td><input type="text" value={this.state.groupDesc} onChange={this.handleDescChange.bind(this)} /></td>
                                </tr>
                              </tbody>
                           </table>
                           <hr/>
                           <div align="center" className="button-container">
                              <button className="formButton" onClick={this.handleNewGroup.bind(this)}>Save</button>&nbsp;&nbsp;&nbsp;
                              <button className="formButton" onClick={this.handleBack.bind(this)}>Return</button>
                           </div>
                        </div>
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
         </div>
      </>
    )
   }
}

export default NewGroup
