import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { useValidUsername } from '../hooks/useAuthHooks'
import { Username } from '../components/authComponents'
import Store from '../store'
import * as cognito from '../libs/cognito'
import * as nl3Utils from '../libs/nl3Utils'
import * as base64url from '../libs/base64url-arraybuffer'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ReactTooltip from 'react-tooltip';

import nl3_icon from '../assets/images/NL3-banner-icon.png'
import { GiUsbKey } from 'react-icons/gi'
import { IoFingerPrint } from 'react-icons/io5'
import { TbFaceId } from 'react-icons/tb'
import { MdOutlinePhoneAndroid } from 'react-icons/md'

import '../assets/nl3.css'

const nl3ApiBase = process.env.REACT_APP_NL3_API_BASE;
const nl3ApiLocalHost = process.env.REACT_APP_NL3_USE_LOCALHOST;
const nl3Production = process.env.REACT_APP_NL3_PRODUCTION;
const nl3PortalType = process.env.REACT_APP_NL3_PORTALTYPE;

const useStyles = makeStyles({
  root: {
    height: '100vh',
  },
  hover: {
    '&:hover': { cursor: 'pointer', color: 'red' },
  },
})

let cognitoUser = null;
let userCredentials = null;

const SignIn: React.FunctionComponent<{}> = () => {
  const classes = useStyles()
  const [ newDevice, setNewDevice ] = useState(false);
  const [ partnerHeader, setPartnerHeader ] = useState('');
  const portalType = nl3PortalType;
  const backGround = (portalType === 'COMPANY') ? 'company-login-component' : 'login-component';
  const regText = (portalType === 'COMPANY') ? 'Register a New Company' : 'Register a New Account';
  Store.dispatch({type: portalType});

  let usrName = "";
  const params = new URLSearchParams(window.location.search);
  if ( params &&  params.has("uid")) {
    usrName = params.get("uid");
  } else {
    usrName = nl3Utils.nl3getUserName();
  }


  const { username, setUsername, usernameIsValid } =
       useValidUsername( (usrName !== null) ? usrName : '')
  const [checkBox, setCheckBox] =
       useState( (usrName !== null) ? true : false)
  const [error, setError] = useState('')
  const [disableButtons, setDisableButtons] = useState(false);
  var isValid = (!usernameIsValid || username.length === 0 || disableButtons);
  const history = useHistory()

  const userLookup = async () => {
     let lookupURL = nl3ApiBase + '/registration/lookup_';
     if (nl3ApiLocalHost === 'TRUE') {
         lookupURL += '?localhost=true';
     }
     var lookupAPIRsp = await fetch(lookupURL, {
           method: "POST",
           headers: { "Content-Type": 'application/json'},
           body: `{ "username": "${username}"}`,
     });
     return lookupAPIRsp;
  }

  const signInThisDevice = async (e) => {
     setDisableButtons(true);
     Store.dispatch({type: 'REGULAR'});
     const lookupAPIRsp = await userLookup();
     const lookupAPIRspJson = await lookupAPIRsp.json();
     const status = lookupAPIRsp.status;
     userCredentials = lookupAPIRspJson.credentials;
     if (status === 225) {
        localStorage.setItem('NL3IMPORTUSERNAME', username);
        history.push('/signUp');
     } else if (status === 223) {
        localStorage.setItem('NL3ADDDEVICE', username);
        history.push('/signUp');
     } else {
        signInClicked()
     }
  }

  const signInExternalDevice = async (e) => {
     setDisableButtons(true);
     Store.dispatch({type: 'EXTERNAL'});
     const lookupAPIRsp = await userLookup();
     const lookupAPIRspJson = await lookupAPIRsp.json();
     console.log("lookupAPIRsp = " + lookupAPIRspJson)
     const status = lookupAPIRsp.status;
     userCredentials = lookupAPIRspJson.credentials;
     if (status === 225) {
        localStorage.setItem('NL3IMPORTUSERNAME', username);
        history.push('/signUp');
     } else {
        signInClicked()
     }
  }

  const authorizeThisDevice = async (e) => {
     setDisableButtons(true);
     Store.dispatch({type: 'REGULAR'});
     const lookupAPIRsp = await userLookup();
     const lookupAPIRspJson = await lookupAPIRsp.json();
     console.log("lookupAPIRsp = " + lookupAPIRspJson)
     const status = lookupAPIRsp.status;
     userCredentials = lookupAPIRspJson.credentials;
     if (status === 225) {
        localStorage.setItem('NL3IMPORTUSERNAME', username);
        history.push('/signUp');
     } else if (status === 223) {
        localStorage.setItem('NL3ADDDEVICE', username);
        history.push('/signUp');
     } else if (status === 222) {
        if ( newDevice ){
          localStorage.setItem('NL3ADDDEVICE', username);
          history.push('/signUp');
        }else{
          signInClicked()
        }
     } else {
        setError('Authorization failed (' + status + ') - Contact support.');
     }
     setDisableButtons(false);
  }

  const handleKeyPress = async (e) => {
     if (e.key === 'Enter') {
        setDisableButtons(true);
        Store.dispatch({type: 'REGULAR'});
        if (newDevice) {
           await authorizeThisDevice();
        } else {
           await signInThisDevice();
        }
     }
  }

  const handleCheckBoxChange = (e) => {
     setCheckBox(e.target.checked);
     if (e.target.checked) {
        nl3Utils.nl3setUserName(username);
     } else {
        nl3Utils.nl3delUserName();
     }
  }

  const executeLoginAPI = async () => {
     let loginURL = nl3ApiBase + '/users/login';
     if (portalType === 'COMPANY') {
        loginURL = nl3ApiBase + '/companies/login';
     }
     var awsToken = localStorage.getItem('awsToken');
     var awsTokenData = "";
     if (awsToken !== null) {
        awsTokenData = {'awsMarketplaceToken': awsToken}
     }
     const session = await cognito.getSession();
     var loginAPIRsp = await fetch(loginURL, {
           method: "POST",
           headers: {
              'Content-Type': 'application/json',
               'Authorization': `Bearer ${session.accessToken.jwtToken}`,
           },
           body: JSON.stringify(awsTokenData),
     });
     if (loginAPIRsp.status !== 200) {
        setError("Sign In Failed");
     } else {
        await cognito.setIsAuthenticated(true);
        localStorage.removeItem('awsToken');
        history.push("/eula");
     }
  }

  const signInClicked = async () => {
     setError('');
     if (checkBox) {
        nl3Utils.nl3setUserName(username);
     } else {
        nl3Utils.nl3delUserName();
     }
     const authenticationDetails = new cognito.authenticationDetails(username)
     cognitoUser = cognito.getCognitoUser(username);

     cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');
     cognitoUser.initiateAuth(authenticationDetails, authCallBack);
  }

  const authCallBack = {
    onSuccess: function(result) {
      //var accessToken = result.getAccessToken().getJwtToken();
      //var idToken = result.getIdToken().getJwtToken();
      //var refreshToken = result.getRefreshToken().getToken();
      executeLoginAPI();
    },
    customChallenge: async function(challengeParameters) {
      // User authentication depends on challenge response
      let loginType = Store.getState().login;
      var rpIDOrigin = 'dev.nextlevel3.com';
      if (nl3Production === 'TRUE') {
          rpIDOrigin = 'nextlevel3.com';
      }
      if (nl3ApiLocalHost === 'TRUE') {
          rpIDOrigin = window.location.hostname;
      }

      //----------get creds from security key or platform authenticator
      //const credId = nl3Utils.nl3getCredId(username);
      let allowCredentials = [];
      for(const val of userCredentials) {
        const cred = {
          "id": base64url.decode(val.id),
          "type":val.type,
          "transports": val.transports
        }
        allowCredentials.push(cred);
      }
      let signinOptions = {
         //challenge was generated and sent from CreateAuthChallenge lambda trigger
         "challenge": base64url.decode(challengeParameters.challenge),
         "timeout":1800000,
         "rpId": rpIDOrigin,
         "userVerification":"preferred",
         "allowCredentials": (loginType === 'EXTERNAL') ? [] : (allowCredentials === null) ? [] : allowCredentials
      }

      //get sign in credentials from authenticator
      const cred = await navigator.credentials.get({
        publicKey: signinOptions
      });

      //prepare credentials challenge response
      const credential = {};
      if (cred.response) {
        const clientDataJSON = base64url.encode(cred.response.clientDataJSON);
        const authenticatorData = base64url.encode(cred.response.authenticatorData);
        const signature = base64url.encode(cred.response.signature);
        const userHandle = base64url.encode(cred.response.userHandle);

        credential.response = {clientDataJSON, authenticatorData, signature, userHandle};
      }

      //send credentials to Cognito VerifyAuthChallenge lambda trigger for verification
      //sending in cred.id for authVerify match as deviceID
      //let deviceID = nl3Utils.nl3getDeviceId(username);
      let deviceID = cred.id;
      nl3Utils.nl3setCredId(cred.id);
      cognitoUser.sendCustomChallengeAnswer(JSON.stringify(credential), this, {"deviceId":deviceID});

    },
    onFailure: function(err) {
      if (err.message.includes("User does not exist.")) {
          setError("Validate username or register a new account if needed.");
      } else if (err.message.includes('The operation either timed out or was not allowed')) {
          setError("Authentication either timed out or was cancelled by the user.");
      } else if (err.message.includes('The first argument must be of type string or an instance of Buffer, ArrayBuffer, or Array or an Array-like Object. Received undefined.')) {
          setError("Invalid Credential. Credential validation failed.");
      } else {
          setError(err.message);
      }
      setDisableButtons(false);
    },
  }

  const getUrlParameter = (name) => {
      const params = new URLSearchParams(window.location.search);
      if ((params !== undefined) && (params !== null)) {
         const token = params.get(name);
         if ((token !== null) && (token.length > 0)) {
            return(token);
         } else {
            return(null);
         }
      } else {
         return(null);
      }
  }

  useEffect(() => {
      setNewDevice((nl3Utils.nl3getUserName() === "") ? true : false);
      if (portalType === 'USER') {
          const enableToken = getUrlParameter('auth-token');
          if (enableToken !== null) {
             localStorage.setItem("auth-token", enableToken);
             history.push('/enableAcct');
          }
      } else if (portalType === 'COMPANY') {
          const regToken = getUrlParameter('x-amzn-marketplace-token');
          if (regToken !== null) {
             localStorage.setItem("awsToken", regToken);
             setPartnerHeader('AWS Subscription Portal');
          }
      }
  }, [usrName, portalType, history]);

  return (
    <section className="login-content">
      <div className={backGround}>
        <Grid className={classes.root} container direction="row" justifyContent="center" alignItems="center">
          <Grid xs={11} sm={6} lg={4} container direction="row" justifyContent="center" alignItems="center" item>
            <div align="center" >
                <img src={nl3_icon} alt=""/><br/><br/>
                <div className="header-content-container center">
                   { (portalType === 'COMPANY') &&
                       <div className="header-content">
                            <h4 className="et_pb_module_header sub-header blackText">{partnerHeader}</h4>
                            <h4 className="et_pb_module_header sub-header blackText">JIT Access Management and Monitoring for your company.</h4>
                       </div>
                   }
                   { (portalType === 'USER') &&
                       <div className="header-content">
                            <h4 className="et_pb_module_header sub-header">Lock your account. On demand protection.</h4>
                       </div>
                   }
                   { (newDevice) &&
                       <div className="header-content">
                         <br/>
                         <span>New device detected!</span>
                         <br/>
                         <span>If you have an account already, log in with an authorized
                               external device (Phone/USB Key) or authorize this device.
                         </span>
                       </div>
                   }
                </div><br/><br/>
            </div>
            <Paper style={{ backgroundColor: '#EBF4FA', width: '100%', padding: 32 }}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Box m={2}>
                  <Typography variant="h3">Passkey Sign In</Typography>
                </Box>
              </Grid>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Box width="80%" m={1}>
                  <Username usernameIsValid={usernameIsValid} setUsername={setUsername}
                      defUserName={username} keyPressFunc={handleKeyPress} />
                  <FormControlLabel label="Remember Me" control={
                      <Checkbox size="small" color="primary" checked={checkBox} onChange={handleCheckBoxChange}/>
                      }
                  />
                </Box>
              </Grid>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Box mt={2}>
                  <Typography color="error" variant="body2">
                    {error}
                  </Typography>
                </Box>
              </Grid>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Box mt={2}>
                  <Grid container direction="row" justifyContent="center">
                    <Box m={1}>
                      { (newDevice) &&
                        <>
                         <Grid container direction="row" justifyContent="center">
                           <Button disabled={isValid} color="primary" variant="contained" onClick={authorizeThisDevice}
                                style={{width:"100%"}}
                                data-tip="Authorize a New Passkey on this device to access your account"
                                data-for="signInTips" data-place="top">
                             Create New Passkey<br/>on this device
                             <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                             <TbFaceId fill="#FFFFFF" style={{fontSize:"1.5rem"}} />
                             <IoFingerPrint fill="#FFFFFF" style={{fontSize:"1.5rem"}} />
                           </Button>
                         </Grid><br/>
                         <Grid container direction="row" justifyContent="center">
                           <Button disabled={isValid} color="primary" variant="contained" onClick={signInExternalDevice}
                              style={{width:"100%"}}
                                data-tip="Sign In with an authorized physical device<br/> like a USB Key or Phone Passkey"
                                data-for="signInTips" data-place="top">
                              Create a Passkey on<br/>Phone or USB Device
                             <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                             <MdOutlinePhoneAndroid fill="#FFFFFF" style={{fontSize:"1.5rem"}} />
                             <GiUsbKey fill="#FFFFFF" style={{fontSize:"1.5rem"}} />
                           </Button>
                         </Grid>
                        </>
                      }
                      { (!newDevice) &&
                        <>
                         <Grid container direction="row" justifyContent="center">
                           <Button disabled={isValid} color="primary" variant="contained" onClick={signInThisDevice}
                                style={{width:"100%"}}
                                data-tip="Sign In with passkey stored on this device"
                                data-for="signInTips" data-place="top">
                             Use a Passkey on<br/>this Device
                             <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                             <TbFaceId fill="#FFFFFF" style={{fontSize:"1.5rem"}} />
                             <IoFingerPrint fill="#FFFFFF" style={{fontSize:"1.5rem"}} />
                           </Button>
                         </Grid><br/>
                         <Grid container direction="row" justifyContent="center">
                           <Button disabled={isValid} color="primary" variant="contained" onClick={signInExternalDevice}
                                style={{width:"100%"}}
                                data-tip="Sign In with an authorized physical device<br/> like a USB Key or Phone Passkey"
                                data-for="signInTips" data-place="top">
                             Use a Passkey on<br/>Phone or USB Device
                             <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                             <MdOutlinePhoneAndroid fill="#FFFFFF" style={{fontSize:"1.5rem"}} />
                             <GiUsbKey fill="#FFFFFF" style={{fontSize:"1.5rem"}} />
                           </Button>
                         </Grid>
                        </>
                      }
                    </Box>
                  </Grid>
                </Box>
              </Grid>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Box mt={2}>
                  <Box onClick={() => history.push('/signup')}>
                    <Typography className={classes.hover} variant="body1">
                      {regText}
                    </Typography>
                  </Box>
                </Box>
                <Box mt={2}>
                  <Box onClick={() => history.push('/recoverAcct')}>
                    <Typography className={classes.hover} variant="body2">
                      Reset your Paired Devices using a Recovery Key
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <ReactTooltip className="nl3Tooltip" id="signInTips" html={true}/>
    </section>
  )
}

export default SignIn
